import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
const token = cookies.get('access_token') 

export const HarvestLogs = ({apiaryData}) => {
    const [harvestLogs, setharvestLogs] = useState(false)
    const [loading, setloading] = useState(true)
    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
      }
      
    const getHarvestLogs=async()=>{
      try {
        const apiaryId=apiaryData[0]?._id||''
        if(apiaryId=='')
          return
        const config={
          headers: {
            source:'HumblebeeWebApp',
            Authorization: 'Bearer'+ token,
          },
        }
        const res = await axios.get(`${process.env.REACT_APP_API_MENTOR_VAlUECHAIN_SERVICES}/harvest-log/logs/${apiaryId}`,config)
        setharvestLogs(res.data.harvestlogs)
        setloading(false)
      } catch (error) {
        setloading(false)
        console.error('Error fetching harvest logs:', error)
      }
    }
    useEffect(() => {
       getHarvestLogs()
    }, [])
    
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {loading ?
            <p>Loading ...</p>
            :
          harvestLogs && harvestLogs?.length>0?
           harvestLogs?.map((it, idx) => {
            return (
              <div
                key={idx}
                style={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                  flexDirection: "row",
                  border: "1px solid #CBCBCB",
                  padding: "10px 20px",
                  margin: "10px 20px",
                  borderRadius: 12,
                  gap:10
                }}
              >
                <div style={{
                    display:'flex',
                    flexDirection:'column'
                }}>
                <p
                      style={{
                        color: "#EF5A20",
                        fontSize: 16,
                        marginTop: 5,
                        fontWeight:'600',
                        marginBottom:10,
                        textTransform:'capitalize'
                      }}
                    >
                      {it.product_harvest}
                      
                    </p> 
                  <div style={{
                    display:'flex',
                    flexDirection:'row',
                    gap:25,
                    marginBottom:10
                  }}>
                    <p
                      style={{
                        color: "#262626",
                        fontSize: 16,
                        marginBottom: 0,
                        
                      }}
                    >
                      {" "}
                      Harvested on: {formatDate(it.created_at)}
                    </p>
                    <p
                      style={{
                        color: "#262626",
                        fontSize: 16,
                        marginBottom: 0,
                      }}
                    >
                      {" "}
                      Quantity: {it.quantity_harvested} kg
                    </p>
                    {it.product_harvest == "honey" && (
                      <p
                        style={{
                            color: "#262626",
                            fontSize: 16,
                         
                        }}
                      >
                        {" "}
                        Type: {it.type_of_honey}
                      </p>
                    )}
                  </div>
                  <p
                      style={{
                        color: "#262626",
                        fontSize: 16,
                        marginBottom: 0,
                        
                      }}
                    >
                      {" "}
                      {it.created_by.toString() === apiaryData[0].beekeeper_id.toString() ? 'Self' : 'Bee-mitra'}
                    </p>
                </div>
                {/* <div style={{display:'flex',alignItems:'center'}}>
                  <p
                    style={{
                      color: "#EF5A20",
                      fontSize: 16,
                      marginBottom: 0,
                    }}
                  >
                    {" "}
                    {">"}
                  </p>
                </div> */}
              </div>
            );
          })
        :
        <p>No harvest logs found</p>}
        </div>
      </>
    );
}
