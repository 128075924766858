import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import * as Styles from "../../Farmers/components/FarmerForm/style";
import close from "../../assets/images/close.png";

const cookies = new Cookies();
const token = cookies.get("access_token");

const Modal = ({ onClose, data, apiaryData }) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <>
      <Styles.ModalWrapper>
        <Styles.ModalContent2>
          <div
            style={{
              display: "flex",
              // justifyContent:'space-between',
              flexDirection: "column",
              boxShadow:
                "rgba(67, 71, 85, 0.27) 0px 0px 0.25em,rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
              padding: 20,
              width: "100%",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h3>Activity On {formatDate(data.created_at)} </h3>
                <p>
                  Logged By:
                  {data.created_by.toString() ===
                  apiaryData.beekeeper_id.toString()
                    ? "Self"
                    : "Bee-mitra"}
                </p>
              </div>
              <div style={{
                cursor:'pointer'
              }} onClick={handleClose}>
                <img
                  style={{
                    width: 20,
                    height: 20,
                  }}
                  onClick={handleClose}
                  src={close}
                  alt="Close"
                />
              </div>
            </div>

            {/* Main Bode of Form Starts */}
            <Styles.MainFarmerFormDiv2>
              <Styles.RowFlex>
                <div>
                  <p
                    style={{
                      fontSize: 20,
                      fontWeight: "600",
                      color: "#262626",
                      marginBottom: 5,
                    }}
                  >
                    Hive Health
                  </p>

                  <p
                    style={{
                      fontSize: 18,
                      marginBottom: 0,
                      fontWeight: "400",
                      color: "#262626",
                    }}
                  >
                    1. Number of strong colonies:{" "}
                    <span
                      style={{
                        fontSize: 18,
                        fontWeight: "600",
                        color: "#262626",
                      }}
                    >
                      {data.observation.number_of_strong_colonies}
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      marginBottom: 0,
                      fontWeight: "400",
                      color: "#262626",
                    }}
                  >
                    2. Number of weak colonies:{" "}
                    <span
                      style={{
                        fontSize: 18,
                        fontWeight: "600",
                        color: "#262626",
                      }}
                    >
                      {data.observation.number_of_weak_colonies}
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      marginBottom: 0,
                      fontWeight: "400",
                      color: "#262626",
                    }}
                  >
                    3. Number of colonies without queen:{" "}
                    <span
                      style={{
                        fontSize: 18,
                        fontWeight: "600",
                        color: "#262626",
                      }}
                    >
                      {data.observation.number_of_colonies_without_queen}
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      marginBottom: 0,
                      fontWeight: "400",
                      color: "#262626",
                    }}
                  >
                    4. Number of colonies with pests:{" "}
                    <span
                      style={{
                        fontSize: 18,
                        fontWeight: "600",
                        color: "#262626",
                      }}
                    >
                      {data.observation.number_of_colonies_with_pests}
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      marginBottom: 0,
                      fontWeight: "400",
                      color: "#262626",
                    }}
                  >
                    5. Number of colonies with diseases:{" "}
                    <span
                      style={{
                        fontSize: 18,
                        fontWeight: "600",
                        color: "#262626",
                      }}
                    >
                      {data.observation.number_of_colonies_with_diseases}
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      marginBottom: 0,
                      fontWeight: "400",
                      color: "#262626",
                    }}
                  >
                    6. Cleanliness of hives:{" "}
                    <span
                      style={{
                        fontSize: 18,
                        fontWeight: "600",
                        color: "#262626",
                      }}
                    >
                      {data.observation.cleanliness_of_hives}
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      marginBottom: 0,
                      fontWeight: "400",
                      color: "#262626",
                    }}
                  >
                    7. Condition of boxes:{" "}
                    <span
                      style={{
                        fontSize: 18,
                        fontWeight: "600",
                        color: "#262626",
                      }}
                    >
                      {data.observation.condition_of_beeboxes}
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      marginBottom: 0,
                      fontWeight: "400",
                      color: "#262626",
                    }}
                  >
                    8. Availability of flowers:{" "}
                    <span
                      style={{
                        fontSize: 18,
                        fontWeight: "600",
                        color: "#262626",
                      }}
                    >
                      {data.observation.availability_of_flowers}
                    </span>
                  </p>
                </div>
                <div
                  style={{
                    width: 1,
                    background: "#CBCBCB",
                    height: 200,
                  }}
                />
                <div>
                  <p
                    style={{
                      fontSize: 20,
                      fontWeight: "600",
                      color: "#262626",
                      marginBottom: 5,
                    }}
                  >
                    Activities done
                  </p>

                  {[
                    { label: "Colony", key: "colony" },
                    { label: "Environment", key: "environment" },
                    { label: "Feeding", key: "feeding" },
                    { label: "Harvesting", key: "harvesting" },
                    { label: "Hive Related", key: "hive_related" },
                  ].map(({ label, key }, index) => (
                    <p
                      key={index}
                      style={{
                        fontSize: 18,
                        marginBottom: 0,
                        fontWeight: "400",
                        color: "#262626",
                      }}
                    >
                      {index + 1}. {label}:{" "}
                      <span
                        style={{
                          fontSize: 18,
                          fontWeight: "600",
                          color: "#262626",
                        }}
                      >
                        {data.activities_performed_during_visit?.[key]?.length
                          ? data.activities_performed_during_visit[key].join(
                              ", "
                            )
                          : "None"}
                      </span>
                    </p>
                  ))}
                </div>

                <div
                  style={{
                    width: 1,
                    background: "#CBCBCB",
                    height: 200,
                  }}
                />

                <div>
                  <p
                    style={{
                      fontSize: 20,
                      fontWeight: "600",
                      color: "#262626",
                      marginBottom: 5,
                    }}
                  >
                    Planned Activities
                  </p>

                  {[
                    { label: "Colony", key: "activities_recommended_colony" },
                    {
                      label: "Environment",
                      key: "activities_recommended_environment",
                    },
                    { label: "Feeding", key: "activities_recommended_feeding" },
                    {
                      label: "Harvesting",
                      key: "activities_recommended_harvesting",
                    },
                    {
                      label: "Hive Related",
                      key: "activities_recommended_hive_related",
                    },
                  ].map(({ label, key }, index) => (
                    <p
                      key={index}
                      style={{
                        fontSize: 18,
                        marginBottom: 0,
                        fontWeight: "400",
                        color: "#262626",
                      }}
                    >
                      {index + 1}. {label}:{" "}
                      <span
                        style={{
                          fontSize: 18,
                          fontWeight: "600",
                          color: "#262626",
                        }}
                      >
                        {data.activities_recommended_for_beekeeper?.[key]
                          ?.length
                          ? data.activities_recommended_for_beekeeper[key].join(
                              ", "
                            )
                          : "None"}
                      </span>
                    </p>
                  ))}
                </div>
              </Styles.RowFlex>
              {data.mediaUrls?.length > 0 && (
                <p
                  style={{
                    marginTop: 15,
                    fontSize: 20,
                    fontWeight: "600",
                    color: "#262626",
                  }}
                >
                  Images
                </p>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                {data.mediaUrls?.length > 0 &&
                  data.mediaUrls.map((it) => (
                    <img src={it} style={{ width: 338, height: 270 }} />
                  ))}
              </div>
            </Styles.MainFarmerFormDiv2>
            {/* Main Body of Form stops */}
          </div>
        </Styles.ModalContent2>
      </Styles.ModalWrapper>
    </>
  );
};
function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: "2-digit", month: "long", year: "numeric" };
  return date.toLocaleDateString("en-GB", options);
}
export const ApiaryActivity = ({ apiaryData }) => {
  const [apiaryActivityLog, setapiaryActivityLog] = useState(false);
  const [selectedIndex, setselectedIndex] = useState(-1);
  const [loading, setloading] = useState(true)
  const getapiaryActivityLog = async () => {
    try {
      const apiaryId = apiaryData[0]?._id || "";
      if (apiaryId == "") return;
      const config = {
        headers: {
          source: "HumblebeeWebApp",
          Authorization: "Bearer" + token,
        },
      };
      const res = await axios.get(
        `${process.env.REACT_APP_API_MENTOR_VAlUECHAIN_SERVICES}/hive-activity-log/${apiaryId}`,
        config
      );
      console.log(res.data.activities);
      setloading(false)
      setapiaryActivityLog(res.data.activities);
    } catch (error) {
      console.error("Error fetching harvest logs:", error);
    }
  };
  useEffect(() => {
    getapiaryActivityLog();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loading ?
        <p>Loading ...</p>
        :apiaryActivityLog && apiaryActivityLog?.length>0?
          apiaryActivityLog?.map((it, idx) => {
            return (
              <div
                key={idx}
                onClick={(e) => setselectedIndex(idx)}
                style={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                  flexDirection: "row",
                  border: "1px solid #CBCBCB",
                  padding: "10px 20px",
                  margin: "10px 20px",
                  borderRadius: 12,
                  gap: 10,
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    style={{
                      color: "#EF5A20",
                      fontSize: 16,
                      marginTop: 5,
                      fontWeight: "600",
                      marginBottom: 10,
                    }}
                  >
                    Activity on {formatDate(it.created_at)}
                  </p>
                  <p
                    style={{
                      color: "#262626",
                      fontSize: 16,
                      marginBottom: 0,
                    }}
                  >
                    {" "}
                    {it.created_by.toString() ===
                    apiaryData[0].beekeeper_id.toString()
                      ? "Self"
                      : "Bee-mitra"}
                  </p>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p
                    style={{
                      color: "#EF5A20",
                      fontSize: 16,
                      marginBottom: 0,
                    }}
                  >
                    {" "}
                    {">"}
                  </p>
                </div>
              </div>
            );
          })
        : <p>No activities found.</p>}
      </div>
      {selectedIndex !== -1 ? (
        <Modal
          data={apiaryActivityLog[selectedIndex]}
        onClose={(e) => {
            setselectedIndex(-1);
          }}
          apiaryData={apiaryData[0]}
        />
      ) : null}
    </>
  );
};
