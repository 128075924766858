import React from 'react'

export const ApiaryInformation = ({apiary,index}) => {
    if(apiary==undefined)
        return null;
  return (
    <div
    key={apiary._id}
    style={{
      marginBottom: '20px',
      border: '1px solid #ddd',
      padding: '10px',
      borderRadius: '5px',
    }}
  >
    <h3>{`${apiary.name}`}</h3>
    <img
      src={
        apiary.apiaryImage ||
        'https://static.vecteezy.com/system/resources/previews/026/148/953/non_2x/apiary-fill-outline-icon-design-illustration-nature-and-ecology-symbol-on-white-background-eps-10-file-vector.jpg'
      }
      alt={`${apiary.name} Image`}
      style={{
        width: '150px',
        height: '150px',
        objectFit: 'cover',
        borderRadius: '5px',
      }}
    />
    <table
      style={{
        width: '100%',
        marginTop: '10px',
        borderCollapse: 'collapse',
      }}
    >
      <tbody>
        <tr>
          <td
            style={{
              padding: '10px',
              borderBottom: '1px solid #ddd',
            }}
          >
            <strong>Location</strong>
          </td>
          <td
            style={{
              padding: '10px',
              borderBottom: '1px solid #ddd',
            }}
          >
            {apiary.location?.geoCoordinates.latitude &&
            apiary.location?.geoCoordinates.longitude ? (
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${apiary.location.geoCoordinates.latitude},${apiary.location.geoCoordinates.longitude}`}
                target="_blank"
                rel="noreferrer"
              >
                Map Location
              </a>
            ) : (
              'Location not available'
            )}
          </td>
        </tr>
        <tr>
          <td
            style={{
              padding: '10px',
              borderBottom: '1px solid #ddd',
            }}
          >
            <strong>Environment Type</strong>
          </td>
          <td
            style={{
              padding: '10px',
              borderBottom: '1px solid #ddd',
            }}
          >
            {apiary.environmentType}
          </td>
        </tr>
        <tr>
          <td
            style={{
              padding: '10px',
              borderBottom: '1px solid #ddd',
            }}
          >
            <strong>Bee Type</strong>
          </td>
          <td
            style={{
              padding: '10px',
              borderBottom: '1px solid #ddd',
            }}
          >
            {apiary.beeType}
          </td>
        </tr>
        <tr>
          <td
            style={{
              padding: '10px',
              borderBottom: '1px solid #ddd',
            }}
          >
            <strong>Brood Chamber Count</strong>
          </td>
          <td
            style={{
              padding: '10px',
              borderBottom: '1px solid #ddd',
            }}
          >
            {apiary.broodChamberCount}
          </td>
        </tr>
        <tr>
          <td
            style={{
              padding: '10px',
              borderBottom: '1px solid #ddd',
            }}
          >
            <strong>Super Chamber Count</strong>
          </td>
          <td
            style={{
              padding: '10px',
              borderBottom: '1px solid #ddd',
            }}
          >
            {apiary.broodSuperChamberCount}
          </td>
        </tr>
        <tr>
          <td
            style={{
              padding: '10px',
              borderBottom: '1px solid #ddd',
            }}
          >
            <strong>Frame Count Per Chamber</strong>
          </td>
          <td
            style={{
              padding: '10px',
              borderBottom: '1px solid #ddd',
            }}
          >
            {apiary.frameCountPerChamber}
          </td>
        </tr>
        <tr>
          <td
            style={{
              padding: '10px',
              borderBottom: '1px solid #ddd',
            }}
          >
            <strong>Total Chamber Count</strong>
          </td>
          <td
            style={{
              padding: '10px',
              borderBottom: '1px solid #ddd',
            }}
          >
            {apiary.totalChamberCount}
          </td>
        </tr>
        <tr>
          <td
            style={{
              padding: '10px',
              borderBottom: '1px solid #ddd',
            }}
          >
            <strong>Setup Date</strong>
          </td>
          <td
            style={{
              padding: '10px',
              borderBottom: '1px solid #ddd',
            }}
          >
            {new Date(apiary.setupDate).toLocaleDateString()}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  )
}
