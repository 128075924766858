import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import * as Styles from "../../Farmers/components/FarmerForm/style";
import close from "../../assets/images/close.png";
import Pdf from "../../assets/images/Pdf.png"
const cookies = new Cookies();
const token = cookies.get("access_token");

const Modal = ({ onClose, data, apiaryData }) => {
  const [expandedIndexes, setExpandedIndexes] = useState({});
  if (!data || !apiaryData){
    return null;
  }


  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const toggleReadMore = (index) => {
    setExpandedIndexes((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            boxShadow:
              "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
            padding: 20,
            width: "100%",
            overflow: "scroll",
            height: typeof window !== "undefined" ? window.innerHeight - 200 : "auto",
          }}
        >
          {/* Header Section */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: 10,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h3
                style={{
                  color: "#EF5A20",
                  fontWeight: "600",
                  fontSize: 24,
                }}
              >
                Inspected on {data?.created_at ? formatDate(data.created_at) : "N/A"}
              </h3>
              <p>
                Inspection type: Checklist based
                <span style={{ marginLeft: 20 }}></span>
                Inspected By:{" "}
                {data?.created_by?.toString() === apiaryData?.beekeeper_id?.toString()
                  ? "Self"
                  : "Bee-mitra"}
              </p>
            </div>
            <div style={{ cursor: "pointer" }} onClick={handleClose}>
              <img style={{ width: 20, height: 20 }} src={close} alt="Close" />
            </div>
          </div>

          {/* Main Content */}
          <Styles.MainFarmerFormDiv2>
            <p
              style={{
                fontSize: 20,
                fontWeight: "600",
                color: "#262626",
                marginBottom: 10,
              }}
            >
              Diagnosis: {data?.IS_HEALTHY_HIVE ? "Healthy" : "Unhealthy"}
            </p>

            {data?.PREDICTIONS?.length > 0 ? (
              data.PREDICTIONS.map((it, idx) => {
                const showAll = expandedIndexes[idx];
                const actionsToShow = showAll ? it?.actionables : it?.actionables?.slice(0, 1);

                return (
                  <div key={idx} style={{ display: "flex", flexDirection: "column", marginBottom: 10 }}>
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: "400",
                        color: "#262626",
                        marginBottom: 2,
                      }}
                    >
                      Selected: {it?.matchedSymptoms?.[0]?.symptom || "N/A"}
                    </p>
                    <div
                      style={{
                        padding: "8px 16px",
                        backgroundColor: !data?.IS_HEALTHY_HIVE ? (it?.percentage_possibility||0)>30?"rgba(255, 0, 0, 0.1)":"rgba(255, 244, 0, 0.1)" : "white",
                        border: !data?.IS_HEALTHY_HIVE ? (it?.percentage_possibility||0)>30? "1px solid #FF0000":"1px solid #FFF000" : "1px solid #CBCBCB",
                        borderRadius: 12,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: 2,
                        }}
                      >
                        <span style={{ fontSize: 18, fontWeight: "600", color: "#262626" }}>{it.disease}</span>
                        <span style={{ fontSize: 18, fontWeight: "600", color: "#262626" }}>
                          {it?.percentage_possibility ?it?.percentage_possibility + "%" : ""}
                        </span>
                      </div>
                      <span style={{ fontSize: 18, fontWeight: "600", color: "#262626", marginBottom: 4 }}>
                        Recommendations:
                      </span>

                      {actionsToShow?.length > 0 ? (
                        actionsToShow.map((action, actionIdx) => (
                          <p
                            key={actionIdx}
                            style={{
                              fontSize: 12,
                              fontWeight: "400",
                              color: "#262626",
                              marginBottom: 2,
                            }}
                          >
                            {actionIdx + 1}. {action}
                          </p>
                        ))
                      ) : (
                        <p style={{ fontSize: 12, fontWeight: "400", color: "#262626", marginBottom: 2 }}>
                          No actions available
                        </p>
                      )}

                      {/* Read More Button */}
                      {it?.actionables?.length > 1 && (
                        <button
                          onClick={() => toggleReadMore(idx)}
                          style={{
                            background: "none",
                            border: "none",
                            color: "#EF5A20",
                            cursor: "pointer",
                            fontSize: 18,
                            fontWeight: "600",
                            marginTop: 0,
                          }}
                        >
                          {showAll ? "Read Less" : "Read More"}
                        </button>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No predictions available</p>
            )}
          </Styles.MainFarmerFormDiv2>
        </div>
      </Styles.ModalContent2>
    </Styles.ModalWrapper>
  );
};


export default Modal;

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: "2-digit", month: "long", year: "numeric" };
  return date.toLocaleDateString("en-GB", options);
}
export const InspectHiveLog = ({ apiaryData }) => {
  const [inspectHiveLog, setinspectHiveLog] = useState(false);
  const [selectedIndex, setselectedIndex] = useState(-1);
  const [loading, setloading] = useState(true)
  const getinspectHiveLog = async () => {
    try {
      const apiaryId = apiaryData[0]?._id || "";
      if (apiaryId == "") return;
      const config = {
        headers: {
          source: "HumblebeeWebApp",
          Authorization: "Bearer" + token,
        },
      };
      const res = await axios.get(
        `${process.env.REACT_APP_API_MENTOR_VAlUECHAIN_SERVICES}/hive-inspection/past-inspection/${apiaryId}`,
        config
      );
      console.log(res.data.pastInspection);
      setinspectHiveLog(res.data.pastInspection);
      setloading(false)
    } catch (error) {
      setloading(false)
      console.error("Error fetching harvest logs:", error);
    }
  };
  useEffect(() => {
    getinspectHiveLog();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loading && <p>
          Loading ...</p>}
        { inspectHiveLog && inspectHiveLog?.length>0?
          inspectHiveLog?.map((it, idx) => {
            return (
              <div
                key={idx}
                onClick={(e) => setselectedIndex(idx)}
                style={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                  flexDirection: "row",
                  border: "1px solid #CBCBCB",
                  padding: "10px 20px",
                  margin: "10px 20px",
                  borderRadius: 12,
                  gap: 10,
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    style={{
                      color: "#EF5A20",
                      fontSize: 16,
                      marginTop: 5,
                      fontWeight: "600",
                      marginBottom: 10,
                    }}
                  >
                    Inspected on {formatDate(it.created_at)}
                  </p>
                  <p
                    style={{
                      color: "#262626",
                      fontSize: 16,
                      marginBottom: 5,
                    }}
                  >
                    <span>
                      <img style={{
                        width:24,
                        height: 24,
                      }}  src={Pdf}/>
                    </span>
                   Checklist Based
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 25,
                    }}
                  >
                    <p
                      style={{
                        color: "#262626",
                        fontSize: 16,
                        marginBottom: 0,
                      }}
                    >
                      {" "}
                      Diagnosis: {it.IS_HEALTHY_HIVE?'Healthy':'Unhealthy'}
                    </p>
                    <p
                    style={{
                      color: "#262626",
                      fontSize: 16,
                      marginBottom: 0,
                    }}
                  > Inspected By: 
                    {" "}
                    {it.created_by.toString() ===
                    apiaryData[0].beekeeper_id.toString()
                      ? "Self"
                      : "Bee-mitra"}
                  </p>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p
                    style={{
                      color: "#EF5A20",
                      fontSize: 16,
                      marginBottom: 0,
                    }}
                  >
                    {" "}
                    {">"}
                  </p>
                </div>
              </div>
            );
          })
        
        :!loading ? <p>No Inspect Hive Logs Found</p>
      
      :null}
      </div>
      {selectedIndex !== -1 ? (
        <Modal
          data={inspectHiveLog[selectedIndex]}
        onClose={(e) => {
            setselectedIndex(-1);
          }}
          apiaryData={apiaryData[0]}
        />
      ) : null}
    </>
  );
};
