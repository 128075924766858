import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import * as Styles from "../../Farmers/components/FarmerForm/style";
import close from "../../assets/images/close.png";

const cookies = new Cookies();
const token = cookies.get("access_token");

const ProfitModal = ({ onClose, data, apiaryData }) => {
  if (!data) return null;

  const handleClose = () => {
    onClose();
  };

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            boxShadow:
              "rgba(67, 71, 85, 0.27) 0px 0px 0.25em,rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
            padding: 20,
            width: "100%",
            overflow: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: 10,
            }}
          >
            <div>
              <h3>Income Of Rs {data.earned_amount} </h3>
              <p style={{ marginBottom: 0 }}>
                Logged On: {formatDate(data.created_at)} &nbsp; Logged By:{" "}
                {data.created_by?.toString() ===
                apiaryData?.beekeeper_id?.toString()
                  ? "Self"
                  : "Bee-mitra"}
              </p>
            </div>
            <div style={{ cursor: "pointer" }} onClick={handleClose}>
              <img style={{ width: 20, height: 20 }} src={close} alt="Close" />
            </div>
          </div>

          <Styles.MainFarmerFormDiv2>
            <Styles.RowFlex>
              <div>
                <p style={{ marginBottom: 0 }}>
                  1. Date of Earning:{" "}
                  <strong>{formatDate(data.created_at)}</strong>
                </p>
                <p style={{ marginBottom: 0 }}>
                  2. Product sold:{" "}
                  <strong style={{
                    textTransform:'capitalize'
                  }}>{data.product_details?.commodity || "N/A"}</strong>
                </p>
                 
                <p style={{ marginBottom: 0 }}>
                  3. Quantity sold:{" "}
                  <strong>
                    {data.product_details?.quantity_sold || "N/A"}
                  </strong>
                </p>
                <p style={{ marginBottom: 0 }}>
                  4. Buyer: <strong>{data.buyers || "N/A"}</strong>
                </p>
              </div>
            </Styles.RowFlex>
            {data.receipt_photos && (
              <>
                <p style={{ marginBottom: 10, marginTop: 20 }}>Bill Receipt</p>
                <img
                  src={data.receipt_photos}
                  style={{ width: 338, height: 438 }}
                  alt="Receipt"
                />
              </>
            )}
          </Styles.MainFarmerFormDiv2>
        </div>
      </Styles.ModalContent2>
    </Styles.ModalWrapper>
  );
};

const LossModal = ({ onClose, data, apiaryData }) => {
  if (!data) return null;

  const handleClose = () => {
    onClose();
  };

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            boxShadow:
              "rgba(67, 71, 85, 0.27) 0px 0px 0.25em,rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
            padding: 20,
            width: "100%",
            overflow: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: 10,
            }}
          >
            <div>
              <h3>Expense Of Rs {data.sum_spent} </h3>
              <p style={{ marginBottom: 0 }}>
                Logged On: {formatDate(data.created_at)} &nbsp; Logged By:{" "}
                {data.created_by?.toString() ===
                apiaryData?.beekeeper_id?.toString()
                  ? "Self"
                  : "Bee-mitra"}
              </p>
            </div>
            <div style={{ cursor: "pointer" }} onClick={handleClose}>
              <img style={{ width: 20, height: 20 }} src={close} alt="Close" />
            </div>
          </div>

          <Styles.MainFarmerFormDiv2>
            <Styles.RowFlex>
              <div>
                <p style={{ marginBottom: 0 }}>
                  1. Date of Expense:{" "}
                  <strong>{formatDate(data.created_at)}</strong>
                </p>
                <p style={{ marginBottom: 0 }}>
                  2. Product bought: <strong tyle={{
                    textTransform:'capitalize'
                  }}>{data.spent_on || "N/A"}</strong>
                </p>
                <p style={{ marginBottom: 0 }}>
                  3. Seller: <strong tyle={{
                    textTransform:'capitalize'
                  }}>{data.seller || "N/A"}</strong>
                </p>
              </div>
            </Styles.RowFlex>
            {Array.isArray(data.bill_photos) && data.bill_photos.length > 0 && (
              <>
                <p style={{ marginBottom: 10, marginTop: 20 }}>Bill Receipt</p>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {data.bill_photos.map((photo, index) => {
                    if (photo == "") return;
                    return (
                      <img
                        key={index}
                        src={photo}
                        style={{ width: 338, height: 438 }}
                        alt={`Receipt ${index}`}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </Styles.MainFarmerFormDiv2>
        </div>
      </Styles.ModalContent2>
    </Styles.ModalWrapper>
  );
};

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: "2-digit", month: "long", year: "numeric" };
  return date.toLocaleDateString("en-GB", options);
}
export const Profitexpense = ({ apiaryData }) => {
  const [apiaryActivityLog, setapiaryActivityLog] = useState(false);
  const [profitLog, setprofitLog] = useState(false);
  const [expenseLog, setexpenseLog] = useState(false);
  const [selectedIndex, setselectedIndex] = useState(-1);
  const [lossSelectedIndex, setLossSelectedIndex] = useState(-1);
  const [choosentab, setChoosentab] = useState(0);
  const [loading, setloading] = useState(true)

  const getProfitLog = async () => {
    try {
      const apiaryId = apiaryData[0]?._id || "";
      if (apiaryId == "") return;
      const config = {
        headers: {
          source: "HumblebeeWebApp",
          Authorization: "Bearer" + token,
        },
      };
      const res = await axios.get(
        `${process.env.REACT_APP_API_MENTOR_VAlUECHAIN_SERVICES}/beekeeper-income-log/income-log/${apiaryId}`,
        config
      );
      console.log(res.data);
      setprofitLog(res.data);
      setloading(false)
    } catch (error) {
        setloading(false)

      console.error("Error fetching profit logs:", error);
    }
  };
  const getExpenseLog = async () => {
    try {
      const apiaryId = apiaryData[0]?._id || "";
      if (apiaryId == "") return;
      const config = {
        headers: {
          source: "HumblebeeWebApp",
          Authorization: "Bearer" + token,
        },
      };
      const res = await axios.get(
        `${process.env.REACT_APP_API_MENTOR_VAlUECHAIN_SERVICES}/beekeeping/expense-log/${apiaryId}`,
        config
      );
      console.log(res.data.expenses);
      setexpenseLog(res.data.expenses)
      setloading(false)

    } catch (error) {
        setloading(false)
      console.error("Error fetching loss logs:", error);
    }
  };
  useEffect(() => {
    getProfitLog();
    getExpenseLog();
  }, []);
  if(loading){
    return (
    <p>Loading ...</p>)
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
       {profitLog && expenseLog && <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            alignItems: "center",

            marginBottom: 10,
          }}
        >
          <div
            style={{
              border: "1px solid #CBCBCB",
              borderRadius: 12,
              flexDirection: "column",
              display: "flex",
              padding: "8px 22px",
              background: choosentab == 0 ? "#EF5A20" : "#FFFFFF",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={(e) => setChoosentab(0)}
          >
            <p
              style={{
                color: choosentab == 0 ? "white" : "#EF5A20",
                fontSize: 24,
                fontWeight: "600",
                marginBottom: 0,
              }}
            >
              {profitLog?.length + expenseLog?.length}
            </p>
            <p
              style={{
                color: choosentab == 0 ? "white" : "#262626",
                fontSize: 14,
                fontWeight: "400",
                marginBottom: 0,
              }}
            >
              All
            </p>
          </div>
          <div
            style={{
              border: "1px solid #CBCBCB",
              borderRadius: 12,
              flexDirection: "column",
              display: "flex",
              padding: "8px 22px",
              background: choosentab == 1 ? "#EF5A20" : "#FFFFFF",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={(e) => setChoosentab(1)}
          >
            <p
              style={{
                color: choosentab == 1 ? "white" : "#EF5A20",
                fontSize: 24,
                fontWeight: "600",
                marginBottom: 0,
              }}
            >
              {profitLog?.length}
            </p>
            <p
              style={{
                color: choosentab == 1 ? "white" : "#262626",
                fontSize: 14,
                fontWeight: "400",
                marginBottom: 0,
              }}
            >
              Income
            </p>
          </div>
          <div
            style={{
              border: "1px solid #CBCBCB",
              borderRadius: 12,
              flexDirection: "column",
              display: "flex",
              padding: "8px 22px",
              cursor: "pointer",
              background: choosentab == 2 ? "#EF5A20" : "#FFFFFF",
              justifyContent: "center",
            }}
            onClick={(e) => setChoosentab(2)}
          >
            <p
              style={{
                color: choosentab == 2 ? "white" : "#EF5A20",
                fontSize: 24,
                fontWeight: "600",
                marginBottom: 0,
              }}
            >
              { expenseLog?.length}
            </p>
            <p
              style={{
                color: choosentab == 2 ? "white" : "#262626",
                fontSize: 14,
                fontWeight: "400",
                marginBottom: 0,
              }}
            >
              Expense
            </p>
          </div>
        </div>}
        { profitLog &&
          (choosentab == 0 || choosentab == 1) &&
          profitLog?.map((it, idx) => {
            return (
              <div
                key={idx}
                onClick={(e) => setselectedIndex(idx)}
                style={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                  flexDirection: "row",
                  border: "1px solid #CBCBCB",
                  padding: "10px 20px",
                  margin: "10px 20px",
                  borderRadius: 12,
                  gap: 10,
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    style={{
                      color: "#EF5A20",
                      fontSize: 16,
                      marginTop: 5,
                      fontWeight: "600",
                      marginBottom: 10,
                    }}
                  >
                    ₹ {it.earned_amount}{" "}
                    <span
                      style={{
                        fontSize: 9,
                        fontWeight: "400",
                        color: "#025729",
                        background: "#DDFCEB",
                        padding: "3px 5px",
                        borderRadius: 8,
                      }}
                    >
                      Income
                    </span>
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 25,
                    }}
                  >
                    <p
                      style={{
                        color: "#262626",
                        fontSize: 16,
                        marginBottom: 0,
                      }}
                    >
                      {" "}
                      Logged on: {formatDate(it.created_at)}
                    </p>
                    <p
                      style={{
                        color: "#262626",
                        fontSize: 16,
                        marginBottom: 0,
                        textTransform:'capitalize'
                      }}
                    >
                      {" "}
                      Product Sold: {it.product_details.commodity}
                    </p>

                    <p
                      style={{
                        color: "#262626",
                        fontSize: 16,
                      }}
                    >
                      {" "}
                      Amount: {it.product_details.quantity_sold} kg
                    </p>
                  </div>

                  <p
                    style={{
                      color: "#262626",
                      fontSize: 16,
                      marginBottom: 0,
                    }}
                  >
                    {" "}
                    {it.created_by.toString() ===
                    apiaryData[0].beekeeper_id.toString()
                      ? "Self"
                      : "Bee-mitra"}
                  </p>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p
                    style={{
                      color: "#EF5A20",
                      fontSize: 16,
                      marginBottom: 0,
                    }}
                  >
                    {" "}
                    {">"}
                  </p>
                </div>
              </div>
            );
          })}
        {expenseLog &&
          (choosentab == 0 || choosentab == 2) &&
          expenseLog?.map((it, idx) => {
            return (
              <div
                key={idx}
                onClick={(e) => setLossSelectedIndex(idx)}
                style={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                  flexDirection: "row",
                  border: "1px solid #CBCBCB",
                  padding: "10px 20px",
                  margin: "10px 20px",
                  borderRadius: 12,
                  gap: 10,
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    style={{
                      color: "#EF5A20",
                      fontSize: 16,
                      marginTop: 5,
                      fontWeight: "600",
                      marginBottom: 10,
                    }}
                  >
                    ₹ {it.sum_spent}{" "}
                    <span
                      style={{
                        fontSize: 9,
                        fontWeight: "400",
                        color: "#FF0000",
                        background: "rgba(255, 0, 0, 0.1)", // Fixed rgba syntax
                        padding: "3px 5px",
                        borderRadius: 8,
                      }}
                    >
                      Expense
                    </span>
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 25,
                      marginBottom: 10,
                    }}
                  >
                    <p
                      style={{
                        color: "#262626",
                        fontSize: 16,
                        marginBottom: 0,
                      }}
                    >
                      {" "}
                      Logged on: {formatDate(it.created_at)}
                    </p>
                    <p
                      style={{
                        color: "#262626",
                        fontSize: 16,
                        marginBottom: 0,
                      }}
                    >
                      {" "}
                      Spent on: {it.spent_on}
                    </p>
                  </div>

                  <p
                    style={{
                      color: "#262626",
                      fontSize: 16,
                      marginBottom: 0,
                    }}
                  >
                    {" "}
                    {it.created_by.toString() ===
                    apiaryData[0].beekeeper_id.toString()
                      ? "Self"
                      : "Bee-mitra"}
                  </p>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p
                    style={{
                      color: "#EF5A20",
                      fontSize: 16,
                      marginBottom: 0,
                    }}
                  >
                    {" "}
                    {">"}
                  </p>
                </div>
              </div>
            );
          })}
          {profitLog?.length ==0 && expenseLog?.length==0 && !loading && <p>
            No profit and expense logs found.</p>}
      </div>
      {selectedIndex !== -1 ? (
        <ProfitModal
          data={profitLog[selectedIndex]}
          onClose={(e) => {
            setselectedIndex(-1);
          }}
          apiaryData={apiaryData[0]}
        />
      ) : null}
      {lossSelectedIndex !== -1 ? (
        <LossModal
          data={expenseLog[lossSelectedIndex]}
          onClose={(e) => {
            setLossSelectedIndex(-1);
          }}
          apiaryData={apiaryData[0]}
        />
      ) : null}
    </>
  );
};
