export const BlockData = [
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc33',
    },
    state: 'Andaman And Nicobar Islands',
    district: 'Nicobars',
    city: 'Campbell Bay',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc34',
    },
    state: 'Andaman And Nicobar Islands',
    district: 'Nicobars',
    city: 'Car Nicobar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc35',
    },
    state: 'Andaman And Nicobar Islands',
    district: 'Nicobars',
    city: 'Nancowrie',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc36',
    },
    state: 'Andaman And Nicobar Islands',
    district: 'North And Middle Andaman',
    city: 'Diglipur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc37',
    },
    state: 'Andaman And Nicobar Islands',
    district: 'North And Middle Andaman',
    city: 'Mayabunder',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc38',
    },
    state: 'Andaman And Nicobar Islands',
    district: 'South Andamans',
    city: 'Ferrargunj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc39',
    },
    state: 'Andaman And Nicobar Islands',
    district: 'South Andamans',
    city: 'Little Andaman',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc3a',
    },
    state: 'Andaman And Nicobar Islands',
    district: 'South Andamans',
    city: 'Prothrapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7b',
    },
    state: 'Andhra Pradesh',
    district: 'Alluri Sitharama Raju',
    city: '',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7c',
    },
    state: 'Andhra Pradesh',
    district: 'Anakapalli',
    city: '',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7d',
    },
    state: 'Andhra Pradesh',
    district: 'Ananthapuramu',
    city: '',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7e',
    },
    state: 'Andhra Pradesh',
    district: 'Annamayya',
    city: '',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7f',
    },
    state: 'Andhra Pradesh',
    district: 'Bapatla',
    city: '',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7g',
    },
    state: 'Andhra Pradesh',
    district: 'Dr. B.R. Ambedkar Konaseema',
    city: '',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7h',
    },
    state: 'Andhra Pradesh',
    district: 'Eluru',
    city: '',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7i',
    },
    state: 'Andhra Pradesh',
    district: 'Kakinada',
    city: '',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7j',
    },
    state: 'Andhra Pradesh',
    district: 'Nandyal',
    city: '',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7k',
    },
    state: 'Andhra Pradesh',
    district: 'Ntr',
    city: '',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7l',
    },
    state: 'Andhra Pradesh',
    district: 'Palnadu',
    city: '',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7m',
    },
    state: 'Andhra Pradesh',
    district: 'Parvathipuram Manyam',
    city: '',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7n',
    },
    state: 'Andhra Pradesh',
    district: 'Sri Potti Sriramulu Nellore',
    city: '',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7o',
    },
    state: 'Andhra Pradesh',
    district: 'Sri Sathya Sai',
    city: '',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7p',
    },
    state: 'Andhra Pradesh',
    district: 'Tirupati',
    city: '',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7q',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatnam',
    city: '',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc3b',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Agali',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc3c',
    },
    state: 'Andaman And Nicobar Islands',
    district: 'North And Middle Andaman',
    city: 'Rangat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc3d',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Amadagur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc3e',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Amarapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc3f',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Anantapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc40',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Atmakur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc41',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Bathalapalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc42',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Bommanahal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc43',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Beluguppa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc44',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Bukkapatnam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc45',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Brahmasamudram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc46',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Chennekothapalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc47',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Chilamathur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc48',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Dharmavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc49',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'D.Hirehal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc4a',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Gandlapenta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc4b',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Garladinne',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc4c',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Gooty',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc4d',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Gorantla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc4e',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Gudibanda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc4f',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Gummagatta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc50',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Guntakal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc51',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Hindupur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc52',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Bukkarayasamudram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc53',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Kalyandrug',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc54',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Kadiri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc55',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Kambadur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc56',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Kanaganapalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc57',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Kanekal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc58',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Kudair',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc59',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Kundurpi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc5a',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Lepakshi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc5b',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Madakasira',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc5c',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Kothacheruvu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc5d',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Nallacheruvu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc5e',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Nallamada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc5f',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Nambulapulikunta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc60',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Narpala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc61',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Obuladevarecheruvu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc62',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Pamidi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc63',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Parigi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc64',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Peddapappur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc65',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Peddavadugur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc66',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Penukonda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc67',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Putlur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc68',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Puttaparthi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc69',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Mudigubba',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc6a',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Ramagiri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc6b',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Raptadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc6c',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Rayadurg',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc6d',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Roddam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc6e',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Settur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc6f',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Singanamala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc70',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Somandepalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc71',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Tadimarri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc72',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Tadipatri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc73',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Talupula',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc74',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Tanakal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc75',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Rolla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc76',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Uravakonda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc77',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Vidapanakal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc78',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Vajrakarur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc79',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Yadiki',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7a',
    },
    state: 'Andhra Pradesh',
    district: 'Anantapur',
    city: 'Yellanur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7b',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Baireddipalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7c',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'B.Kothakota',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7d',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Bangarupalem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7e',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Buchinadidu Khandriga',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc7f',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Chandragiri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc80',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Chinnagottigallu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc81',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Chittoor',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc82',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Chowdepalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc83',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Gangadhara Nellore',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc84',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Gangavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc85',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Gudipala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc86',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Gurramkonda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc87',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Irala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc88',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Gudupalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc89',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Kalakada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc8a',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Kalikiri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc8b',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Karvetinagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc8c',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Kambhamvaripalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc8d',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Kuppam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc8e',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Kurabalakota',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc8f',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'K.V.B.Puram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc90',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Madanapalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc91',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Mulakalacheruvu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc92',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Nagalapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc93',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Narayanavanam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc94',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Nagari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc95',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Nimmanapalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc96',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Nindra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc97',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Pakala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc98',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Palasamudram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc99',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Palamaner',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc9a',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Peddapanjani',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc9b',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Peddathippasamudram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc9c',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Peddamandyam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc9d',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Penumuru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc9e',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Piler',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dc9f',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Pulicherla H/O Reddivaripalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dca0',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Punganur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dca1',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Puthalapattu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dca2',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Puttur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dca3',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Ramachandrapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dca4',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Ramakuppam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dca5',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Ramasamudram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dca6',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Renigunta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dca7',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Rompicherla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dca8',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Santhipuram Ho Arimuthanapalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dca9',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Satyavedu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcaa',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Sodam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcab',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Pitchatur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcac',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Srikalahasti',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcad',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Srirangarajapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcae',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Somala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcaf',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Thamballapalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcb0',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Thottambedu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcb1',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Thavanampalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcb2',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Tirupati (Rural)',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcb3',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Vadamalapeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcb4',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Valmikipuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcb5',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Varadaiahpalem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcb6',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Vedurukuppam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcb7',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Venkatagirikota',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcb8',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Vijayapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcb9',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Yerpedu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcba',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Yerravaripalem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcbb',
    },
    state: 'Andhra Pradesh',
    district: 'Chittoor',
    city: 'Yadamari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcbc',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Ainavilli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcbd',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Addateegala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcbe',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Alamuru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcbf',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Allavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcc0',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Amalapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcc1',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Ambajipeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcc2',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Anaparthy',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcc3',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Biccavolu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcc4',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Chintoor',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcc5',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Atreyapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcc6',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Devipatnam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcc7',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Gandepalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcc8',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Gangavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcc9',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Gollaprolu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcca',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'I. Polavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dccb',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Gokavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dccc',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Jaggampeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dccd',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Kadiam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcce',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Kajuluru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dccf',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Kakinada Rural',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcd0',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Kakinada Urban',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcd1',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Kapileswarapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcd2',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Katrenikona',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcd3',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Karapa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcd4',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Kirlampudi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcd5',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Korukonda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcd6',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Kotananduru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcd7',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Kunavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcd8',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Kothapeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcd9',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Malikipuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcda',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Mamidikuduru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcdb',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Mandapeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcdc',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Mummidivaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcdd',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Maredumilli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcde',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Nellipaka',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcdf',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Pamarru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dce0',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Peddapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dce1',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Pedapudi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dce2',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Pithapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dce3',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'P.Gannavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dce4',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Prathipadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dce5',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Rajahmundry Rural',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dce6',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Rajahmundry Urban',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dce7',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Rajavommangi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dce8',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Ramachandrapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dce9',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Rampachodavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcea',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Rangampeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dceb',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Ravulapalem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcec',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Rayavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dced',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Razole',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcee',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Routhulapudi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcef',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Sakhinetipalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcf0',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Samalkota',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcf1',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Sankhavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcf2',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Seethanagaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcf3',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Thallarevu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcf4',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Thondangi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcf5',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Tuni',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcf6',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'U.Kothapalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcf7',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Uppalaguptam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcf8',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Vararamachandrapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcf9',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Rajanagaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcfa',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Yeleswaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcfb',
    },
    state: 'Andhra Pradesh',
    district: 'East Godavari',
    city: 'Y. Ramavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcfc',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Amaravathi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcfd',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Atchempet',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcfe',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Bapatla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dcff',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Bellamkonda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd00',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Bhattiprolu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd01',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Bollapalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd02',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Chebrole',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd03',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Amruthalur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd04',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Cherukupalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd05',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Chilakaluripet',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd06',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Duggirala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd07',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Durgi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd08',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Edlapadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd09',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Guntur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd0a',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Gurazala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd0b',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Dachepalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd0c',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Kakumanu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd0d',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Ipur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd0e',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Karempudi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd0f',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Karlapalem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd10',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Kollur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd11',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Krosuru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd12',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Kollipara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd13',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Machavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd14',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Macherla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd15',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Medikonduru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd16',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Mangalagiri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd17',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Muppalla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd18',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Nadendla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd19',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Nagaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd1a',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Nekarikallu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd1b',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Narasaraopeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd1c',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Nizampatnam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd1d',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Pedakakani',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd1e',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Nuzendla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd1f',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Pedanandipadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd20',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Pedakurapadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd21',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Phirangipuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd22',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Piduguralla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd23',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Pittalavanipalem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd24',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Prathipadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd25',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Rajupalem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd26',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Ponnur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd27',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Rentachintala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd28',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Repalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd29',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Rompicherla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd2a',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Sattenapalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd2b',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Savalyapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd2c',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Tadepalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd2d',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Tadikonda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd2e',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Tenali',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd2f',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Thullur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd30',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Tsundur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd31',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Vatticherukuru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd32',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Veldurthy',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd33',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Vinukonda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd34',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Agiripalli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd35',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'A.Konduru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd36',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Avanigadda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd37',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Bantumilli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd38',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Bapulapadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd39',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Challapalli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd3a',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Chandarlapadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd3b',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Chatrai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd3c',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Gampalagudem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd3d',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Gannavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd3e',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Ghantasala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd3f',
    },
    state: 'Andhra Pradesh',
    district: 'Guntur',
    city: 'Vemuru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd40',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'G.Konduru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd41',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Gudivada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd42',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Guduru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd43',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Gudlavalleru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd44',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Jaggayyapeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd45',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Kaikalur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd46',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Kalidindi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd47',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Kanchikacherla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd48',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Kankipadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd49',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Koduru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd4a',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Kruttivennu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd4b',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Ibrahimpatnam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd4c',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Machilipatnam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd4d',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Mandavalli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd4e',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Mopidevi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd4f',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Movva',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd50',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Musunuru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd51',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Mudinepalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd52',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Mylavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd53',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Nagayalanka',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd54',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Nandigama',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd55',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Nandivada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd56',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Pamarru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd57',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Nuzvid',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd58',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Pamidimukkala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd59',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Pedana',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd5a',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Pedaparupudi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd5b',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Penuganchiprolu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd5c',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Penamaluru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd5d',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Reddigudem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd5e',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Tiruvuru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd5f',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Thotlavalluru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd60',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Unguturu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd61',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Vatsavai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd62',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Veerullapadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd63',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Vijayawada Rural',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd64',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Vijayawada Urban',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd65',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Vuyyuru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd66',
    },
    state: 'Andhra Pradesh',
    district: 'Krishna',
    city: 'Vissannapet',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd67',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Allagadda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd68',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Adoni',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd69',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Alur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd6a',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Atmakur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd6b',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Aspari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd6c',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Bandi Atmakur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd6d',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Bethamcherla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd6e',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'C.Belagal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd6f',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Chagalamarri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd70',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Banaganapalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd71',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Chippagiri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd72',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Devanakonda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd73',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Dhone Alias Dronachalam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd74',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Dornipadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd75',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Gadivemula',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd76',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Gonegandla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd77',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Gospadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd78',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Gudur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd79',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Halaharvi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd7a',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Holagunda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd7b',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Kallur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd7c',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Kodumur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd7d',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Koilakuntla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd7e',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Kolimigundla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd7f',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Kosigi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd80',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Kothapalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd81',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Kowthalam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd82',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Krishnagiri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd83',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Kurnool',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd84',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Maddikera (East)',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd85',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Mahanandi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd86',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Mantralayam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd87',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Midthur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd88',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Nandavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd89',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Jupadu Bungalow',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd8a',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Nandyal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd8b',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Nandikotkur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd8c',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Owk',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd8d',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Pagidyala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd8e',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Orvakal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd8f',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Pamulapadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd90',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Panyam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd91',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Peapully',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd92',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Pattikonda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd93',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Rudravaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd94',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Peddakadabur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd95',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Sanjamala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd96',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Srisailam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd97',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Sirivel',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd98',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Tuggali',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd99',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Uyyalawada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd9a',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Veldurthi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd9b',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Velugodu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd9c',
    },
    state: 'Andhra Pradesh',
    district: 'Kurnool',
    city: 'Yemmiganur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd9d',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Addanki',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd9e',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Ballikurava',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dd9f',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Bestavaripeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dda0',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Ardhaveedu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dda1',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Chandra Sekhara Puram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dda2',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Chimakurthi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dda3',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Chinaganjam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dda4',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Cumbum',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dda5',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Chirala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dda6',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Donakonda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dda7',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Darsi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dda8',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Dornala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dda9',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Gudluru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddaa',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Giddaluru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddab',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Hanumanthuni Padu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddac',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Inkollu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddad',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Janakavaram Ponguluru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddae',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Kanigiri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddaf',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Karamchedu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddb0',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Komarolu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddb1',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Konakanamittla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddb2',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Kondapi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddb3',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Korisapadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddb4',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Kotha Patnam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddb5',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Kurichedu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddb6',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Lingasamudram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddb7',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Maddipadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddb8',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Markapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddb9',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Marripudi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddba',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Kandukur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddbb',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Martur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddbc',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Mundlamuru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddbd',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Naguluppala Padu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddbe',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Pamur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddbf',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Parchur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddc0',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Ongole',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddc1',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Peda Araveedu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddc2',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Pedacherlo Palle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddc3',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Ponnaluru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddc4',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Podili',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddc5',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Pullalacheruvu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddc6',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Racherla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddc7',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Santhamaguluru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddc8',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Singarayakonda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddc9',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Santhanuthala Padu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddca',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Tallur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddcb',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Tangutur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddcc',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Tarlupadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddcd',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Ulavapadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddce',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Veligandla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddcf',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Vetapalem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddd0',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Voletivari Palem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddd1',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Yddana Pudi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddd2',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Yerragondapalem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddd3',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Zarugumilli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddd4',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Allur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddd5',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Ananthasagaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddd6',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Anumasamudrampeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddd7',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Atmakur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddd8',
    },
    state: 'Andhra Pradesh',
    district: 'Prakasam',
    city: 'Tripuranthakam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddd9',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Balayapalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddda',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Bogole',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dddb',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Butchireddipalem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dddc',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Chillakur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dddd',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Chittamur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddde',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Chejerla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dddf',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Dagadarthi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dde0',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',  //not included
    city: 'Dakkili',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dde1',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Duttalur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dde2',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Doravarisatram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dde3',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Gudur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dde4',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Indukurpet',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dde5',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Jaladanki',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dde6',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Kaligiri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dde7',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Kaluvoya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dde8',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Kodavalur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dde9',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Kavali',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddea',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Kondapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddeb',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Kovur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddec',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Kota',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dded',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Manubolu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddee',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Marripadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddef',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Muthukur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddf0',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Nellore Rural',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddf1',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Ozili',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddf2',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Pellakur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddf3',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Podalakur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddf4',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Rapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddf5',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Sangam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddf6',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Seetharamapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddf7',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Sullurpeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddf8',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Sydapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddf9',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Tada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddfa',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Naidupeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddfb',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Thotapalligudur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddfc',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Udayagiri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddfd',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Varikuntapadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddfe',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Venkatachalam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ddff',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Venkatagiri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de00',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Vakadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de01',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Vidavalur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de02',
    },
    state: 'Andhra Pradesh',
    district: 'Spsr Nellore',
    city: 'Vinjamur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de03',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Amadalavalasa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de04',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Bhamini',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de05',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Burja',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de06',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Ganguvarisigadam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de07',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Gara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de08',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Hiramandalam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de09',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Ichapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de0a',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Etcherla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de0b',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Jalumuru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de0c',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Kaviti',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de0d',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Kotabommili',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de0e',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Kanchili',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de0f',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Laveru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de10',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'L.N Peta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de11',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Kotturu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de12',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Mandasa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de13',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Meliaputti',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de14',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Nandigam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de15',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Narasannapeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de16',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Palakonda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de17',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Palasa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de18',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Pathapatnam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de19',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Polaki',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de1a',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Rajam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de1b',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Ponduru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de1c',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Regidi Amadalavalasa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de1d',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Ranastalam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de1e',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Santhabommali',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de1f',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Santhakavati',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de20',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Sarubujjili',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de21',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Seethampeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de22',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Saravakota',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de23',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Srikakulam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de24',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Tekkali',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de25',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Vajrapukotturu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de26',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Vangara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de27',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Veeraghattam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de28',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Achutapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de29',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Anakapalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de2a',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Anandapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de2b',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Ananthagiri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de2c',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Araku Valley',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de2d',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Bheemunipatnam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de2e',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Butchayyapeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de2f',
    },
    state: 'Andhra Pradesh',
    district: 'Srikakulam',
    city: 'Sompeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de30',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Chintapalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de31',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Chodavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de32',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Cheedikada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de33',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Devarapalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de34',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Dumbriguda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de35',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Gajuwaka',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de36',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'G.Madugula',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de37',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Golugonda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de38',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Gudem Kotha Veedhi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de39',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Hukumpeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de3a',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Kasimkota',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de3b',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'K.Kotapadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de3c',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Kotauratla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de3d',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Koyyuru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de3e',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Makavarapalem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de3f',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Munagapaka',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de40',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Madugula',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de41',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Munchingi Puttu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de42',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Nakkapalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de43',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Narsipatnam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de44',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Nathavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de45',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Paderu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de46',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Padmanabham',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de47',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Paravada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de48',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Payakaraopeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de49',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Peda Bayalu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de4a',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Pedagantyada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de4b',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Pendurthi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de4c',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Rambilli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de4d',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Rolugunta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de4e',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Sabbavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de4f',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Ravikamatham',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de50',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'S.Rayavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de51',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam',
    city: 'Yelamanchili',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de52',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Badangi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de53',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Balijipeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de54',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Bhoghapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de55',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Bobbili',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de56',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Bondapalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de57',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Cheepurupalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de58',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Dattirajeru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de59',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Denkada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de5a',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Gajapathinagaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de5b',
    },
    state: 'Andhra Pradesh',
    district: 'Visakhapatanam', //wrong spelling Visakhapatnam
    city: 'Visakhapatnam(Rural)',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de5c',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Gantyada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de5d',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Garividi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de5e',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Gummalakshmipuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de5f',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Gurla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de60',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Jami',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de61',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Jiyyammavalasa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de62',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Garugubilli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de63',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Komarada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de64',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Kurupam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de65',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Kothavalasa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de66',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Lakkavarapukota',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de67',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Mentada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de68',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Merakamudidam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de69',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Makkuva',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de6a',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Nellimarla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de6b',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Pachipenta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de6c',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Parvathipuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de6d',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Pusapatirega',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de6e',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Ramabhadrapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de6f',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Salur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de70',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Seethanagaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de71',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Therlam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de72',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Vepada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de73',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Vizianagaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de74',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Achanta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de75',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Akividu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de76',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Attili',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de77',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Bhimadole',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de78',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Bhimavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de79',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Buttayagudem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de7a',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Chagallu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de7b',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Chintalapudi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de7c',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Denduluru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de7d',
    },
    state: 'Andhra Pradesh',
    district: 'Vizianagaram',
    city: 'Srungavarapukota',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de7e',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Devarapalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de7f',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Dwarakatirumala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de80',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Elamanchili',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de81',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Eluru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de82',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Gopalapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de83',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Iragavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de84',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Jangareddigudem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de85',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Ganapavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de86',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Jeelugumilli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de87',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Kamavarapukota',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de88',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Kalla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de89',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Kovvur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de8a',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Koyyalagudem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de8b',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Lingapalem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de8c',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Kukkunur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de8d',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Nallajerla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de8e',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Mogalthur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de8f',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Narsapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de90',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Nidadavole',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de91',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Palacole',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de92',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Nidamarru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de93',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Palakoderu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de94',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Pedapadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de95',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Pedavegi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de96',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Pentapadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de97',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Penugonda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de98',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Penumantra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de99',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Peravali',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de9a',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Poduru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de9b',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Polavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de9c',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Tadepalligudem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de9d',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Thallapudi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de9e',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Tanuku',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559de9f',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'T.Narasapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dea0',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Undrajavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dea1',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Unguturu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dea2',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Veeravasaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dea3',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Undi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dea4',
    },
    state: 'Andhra Pradesh',
    district: 'West Godavari',
    city: 'Velairpad',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dea5',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Badvel',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dea6',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Atlur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dea7',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'B.Kodur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dea8',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Chakrayapet',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dea9',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Chapadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deaa',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Brahmamgarimatham.',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deab',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Chennur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deac',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Chinnamandem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dead',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Chitvel',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deae',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Chintakomma Dinne',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deaf',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Duvvur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deb0',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Galiveedu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deb1',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Gopavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deb2',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Jammalamadugu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deb3',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Kadapa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deb4',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Kalasapadu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deb5',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Kamalapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deb6',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Khajipet',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deb7',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Kodur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deb8',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Kondapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deb9',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Lakkireddipalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deba',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Lingala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559debb',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Muddanur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559debc',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Mydukur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559debd',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Mylavaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559debe',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Nandalur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559debf',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Obulavaripalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dec0',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Peddamudium',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dec1',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Pendlimarri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dec2',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Penagalur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dec3',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Porumamilla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dec4',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Proddatur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dec5',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Pulivendla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dec6',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Rajampet',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dec7',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Pullampeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dec8',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Ramapuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dec9',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Rajupalem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deca',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Rayachoty',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559decb',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Sidhout',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559decc',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Simhadripuram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559decd',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Sambepalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dece',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Sri Avadutha Kasinayana',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559decf',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Thondur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ded0',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Vallur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ded1',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'T.Sundupalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ded2',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Veeraballi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ded3',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Veerapanayani Palle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ded4',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Vempalle',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ded5',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Vemula',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ded6',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Vontimitta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ded7',
    },
    state: 'Andhra Pradesh',
    district: 'Y.S.R.',
    city: 'Yerraguntla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ded8',
    },
    state: 'Arunachal Pradesh',
    district: 'Anjaw',
    city: 'Chaglagam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559ded9',
    },
    state: 'Arunachal Pradesh',
    district: 'Anjaw',
    city: 'Hawai-Walong',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deda',
    },
    state: 'Arunachal Pradesh',
    district: 'Anjaw',
    city: 'Hayuliang',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dedb',
    },
    state: 'Arunachal Pradesh',
    district: 'Anjaw',
    city: 'Manchal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dedc',
    },
    state: 'Arunachal Pradesh',
    district: 'Changlang',
    city: 'Bordumsa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dedd',
    },
    state: 'Arunachal Pradesh',
    district: 'Changlang',
    city: 'Changlang',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dede',
    },
    state: 'Arunachal Pradesh',
    district: 'Changlang',
    city: 'Diyum',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dedf',
    },
    state: 'Arunachal Pradesh',
    district: 'Changlang',
    city: 'Khagam-Miao',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dee0',
    },
    state: 'Arunachal Pradesh',
    district: 'Changlang',
    city: 'Manmao',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dee1',
    },
    state: 'Arunachal Pradesh',
    district: 'Changlang',
    city: 'Khimiyang',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dee2',
    },
    state: 'Arunachal Pradesh',
    district: 'Changlang',
    city: 'Nampong',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dee3',
    },
    state: 'Arunachal Pradesh',
    district: 'Changlang',
    city: 'Vijoynagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dee4',
    },
    state: 'Arunachal Pradesh',
    district: 'Changlang',
    city: 'Yatdam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dee5',
    },
    state: 'Arunachal Pradesh',
    district: 'Dibang Valley',
    city: 'Anelih Arzu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dee6',
    },
    state: 'Arunachal Pradesh',
    district: 'Dibang Valley',
    city: 'Anini-Mepi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dee7',
    },
    state: 'Arunachal Pradesh',
    district: 'Dibang Valley',
    city: 'Etalin Malinye',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dee8',
    },
    state: 'Arunachal Pradesh',
    district: 'East Kameng',
    city: 'Bameng',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dee9',
    },
    state: 'Arunachal Pradesh',
    district: 'East Kameng',
    city: 'Bana',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deea',
    },
    state: 'Arunachal Pradesh',
    district: 'East Kameng',
    city: 'Khenewa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deeb',
    },
    state: 'Arunachal Pradesh',
    district: 'East Kameng',
    city: 'Pipu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deec',
    },
    state: 'Arunachal Pradesh',
    district: 'East Kameng',
    city: 'Sawa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deed',
    },
    state: 'Arunachal Pradesh',
    district: 'East Kameng',
    city: 'Seppa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deee',
    },
    state: 'Arunachal Pradesh',
    district: 'East Siang',
    city: 'Mebo',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deef',
    },
    state: 'Arunachal Pradesh',
    district: 'East Siang',
    city: 'Pasighat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559def0',
    },
    state: 'Arunachal Pradesh',
    district: 'East Siang',
    city: 'Ruksin',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559def1',
    },
    state: 'Arunachal Pradesh',
    district: 'Kamle',
    city: 'Puchi Geko',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559def2',
    },
    state: 'Arunachal Pradesh',
    district: 'Kamle',
    city: 'Tamen-Raga',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559def3',
    },
    state: 'Arunachal Pradesh',
    district: 'Kra Daadi',
    city: 'Chambang',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559def4',
    },
    state: 'Arunachal Pradesh',
    district: 'Kra Daadi',
    city: 'Gangte',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559def5',
    },
    state: 'Arunachal Pradesh',
    district: 'Kra Daadi',
    city: 'Palin',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559def6',
    },
    state: 'Arunachal Pradesh',
    district: 'East Kameng',
    city: 'Chayangtajo',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559def7',
    },
    state: 'Arunachal Pradesh',
    district: 'Kra Daadi',
    city: 'Pipsorang',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559def8',
    },
    state: 'Arunachal Pradesh',
    district: 'Kra Daadi',
    city: 'Tali',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559def9',
    },
    state: 'Arunachal Pradesh',
    district: 'Kra Daadi',
    city: 'Yangte',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559defa',
    },
    state: 'Arunachal Pradesh',
    district: 'Kurung Kumey',
    city: 'Damin',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559defb',
    },
    state: 'Arunachal Pradesh',
    district: 'Kurung Kumey',
    city: 'Nyapin',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559defc',
    },
    state: 'Arunachal Pradesh',
    district: 'Kurung Kumey',
    city: 'Parsiparlo',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559defd',
    },
    state: 'Arunachal Pradesh',
    district: 'Kurung Kumey',
    city: 'Sangram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559defe',
    },
    state: 'Arunachal Pradesh',
    district: 'Kurung Kumey',
    city: 'Sarli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559deff',
    },
    state: 'Arunachal Pradesh',
    district: 'Leparada',
    city: 'Basar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df00',
    },
    state: 'Arunachal Pradesh',
    district: 'Kurung Kumey',
    city: 'Koloriang',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df01',
    },
    state: 'Arunachal Pradesh',
    district: 'Leparada',
    city: 'Daring',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df02',
    },
    state: 'Arunachal Pradesh',
    district: 'Lohit',
    city: 'Tezu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df03',
    },
    state: 'Arunachal Pradesh',
    district: 'Leparada',
    city: 'Tirbin',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df04',
    },
    state: 'Arunachal Pradesh',
    district: 'Lohit',
    city: 'Wakro',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df05',
    },
    state: 'Arunachal Pradesh',
    district: 'Longding',
    city: 'Kanubari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df06',
    },
    state: 'Arunachal Pradesh',
    district: 'Longding',
    city: 'Niausa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df07',
    },
    state: 'Arunachal Pradesh',
    district: 'Longding',
    city: 'Pongchau',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df08',
    },
    state: 'Arunachal Pradesh',
    district: 'Lower Dibang Valley',
    city: 'Dambuk',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df09',
    },
    state: 'Arunachal Pradesh',
    district: 'Lower Dibang Valley',
    city: 'Hunli-Kronli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df0a',
    },
    state: 'Arunachal Pradesh',
    district: 'Longding',
    city: 'Wakka',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df0b',
    },
    state: 'Arunachal Pradesh',
    district: 'Lower Dibang Valley',
    city: 'Roing',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df0c',
    },
    state: 'Arunachal Pradesh',
    district: 'Lower Siang',
    city: 'Gensi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df0d',
    },
    state: 'Arunachal Pradesh',
    district: 'Lower Siang',
    city: 'Likabali',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df0e',
    },
    state: 'Arunachal Pradesh',
    district: 'Lower Siang',
    city: 'Ramle Bango',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df0f',
    },
    state: 'Arunachal Pradesh',
    district: 'Lower Subansiri',
    city: 'Hong-Hari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df10',
    },
    state: 'Arunachal Pradesh',
    district: 'Lower Subansiri',
    city: 'Pistana',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df11',
    },
    state: 'Arunachal Pradesh',
    district: 'Lower Subansiri',
    city: 'Ziro-I',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df12',
    },
    state: 'Arunachal Pradesh',
    district: 'Lower Subansiri',
    city: 'Ziro-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df13',
    },
    state: 'Arunachal Pradesh',
    district: 'Namsai',
    city: 'Chowkham',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df14',
    },
    state: 'Arunachal Pradesh',
    district: 'Namsai',
    city: 'Namsai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df15',
    },
    state: 'Arunachal Pradesh',
    district: 'Namsai',
    city: 'Lekang',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df16',
    },
    state: 'Arunachal Pradesh',
    district: 'Pakke Kessang',
    city: 'Pakkekessang',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df17',
    },
    state: 'Arunachal Pradesh',
    district: 'Pakke Kessang',
    city: 'Seijosa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df18',
    },
    state: 'Arunachal Pradesh',
    district: 'Papum Pare',
    city: 'Balijan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df19',
    },
    state: 'Arunachal Pradesh',
    district: 'Papum Pare',
    city: 'Borum',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df1a',
    },
    state: 'Arunachal Pradesh',
    district: 'Papum Pare',
    city: 'Doimukh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df1b',
    },
    state: 'Arunachal Pradesh',
    district: 'Papum Pare',
    city: 'Mengio',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df1c',
    },
    state: 'Arunachal Pradesh',
    district: 'Papum Pare',
    city: 'Sagalee',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df1d',
    },
    state: 'Arunachal Pradesh',
    district: 'Papum Pare',
    city: 'Kimin',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df1e',
    },
    state: 'Arunachal Pradesh',
    district: 'Shi Yomi',
    city: 'Manigoan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df1f',
    },
    state: 'Arunachal Pradesh',
    district: 'Shi Yomi',
    city: 'Mechuka-Tato',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df20',
    },
    state: 'Arunachal Pradesh',
    district: 'Siang',
    city: 'Boleng',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df21',
    },
    state: 'Arunachal Pradesh',
    district: 'Siang',
    city: 'Kaying Payum',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df22',
    },
    state: 'Arunachal Pradesh',
    district: 'Siang',
    city: 'Rebo Parging',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df23',
    },
    state: 'Arunachal Pradesh',
    district: 'Siang',
    city: 'Riga',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df24',
    },
    state: 'Arunachal Pradesh',
    district: 'Siang',
    city: 'Pangin',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df25',
    },
    state: 'Arunachal Pradesh',
    district: 'Tawang',
    city: 'Jang-Thingbu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df26',
    },
    state: 'Arunachal Pradesh',
    district: 'Tawang',
    city: 'Kitpi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df27',
    },
    state: 'Arunachal Pradesh',
    district: 'Tawang',
    city: 'Lumla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df28',
    },
    state: 'Arunachal Pradesh',
    district: 'Tawang',
    city: 'Mukto Bongkhar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df29',
    },
    state: 'Arunachal Pradesh',
    district: 'Tawang',
    city: 'Tawang',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df2a',
    },
    state: 'Arunachal Pradesh',
    district: 'Tawang',
    city: 'Zemithang Dudunghar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df2b',
    },
    state: 'Arunachal Pradesh',
    district: 'Tirap',
    city: 'Borduria',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df2c',
    },
    state: 'Arunachal Pradesh',
    district: 'Tirap',
    city: 'Dadam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df2d',
    },
    state: 'Arunachal Pradesh',
    district: 'Tirap',
    city: 'Khonsa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df2e',
    },
    state: 'Arunachal Pradesh',
    district: 'Tirap',
    city: 'Lazu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df2f',
    },
    state: 'Arunachal Pradesh',
    district: 'Tirap',
    city: 'Namsang',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df30',
    },
    state: 'Arunachal Pradesh',
    district: 'Upper Siang',
    city: 'Geku',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df31',
    },
    state: 'Arunachal Pradesh',
    district: 'Siang',
    city: 'Rumgong',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df32',
    },
    state: 'Arunachal Pradesh',
    district: 'Upper Siang',
    city: 'Jengging',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df33',
    },
    state: 'Arunachal Pradesh',
    district: 'Upper Siang',
    city: 'Singa Gelling',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df34',
    },
    state: 'Arunachal Pradesh',
    district: 'Upper Siang',
    city: 'Mariyang',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df35',
    },
    state: 'Arunachal Pradesh',
    district: 'Upper Siang',
    city: 'Tuting',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df36',
    },
    state: 'Arunachal Pradesh',
    district: 'Upper Subansiri',
    city: 'Baririjo',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df37',
    },
    state: 'Arunachal Pradesh',
    district: 'Upper Subansiri',
    city: 'Chetam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df38',
    },
    state: 'Arunachal Pradesh',
    district: 'Upper Subansiri',
    city: 'Daporijo',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df39',
    },
    state: 'Arunachal Pradesh',
    district: 'Upper Subansiri',
    city: 'Dumporijo',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df3a',
    },
    state: 'Arunachal Pradesh',
    district: 'Upper Subansiri',
    city: 'Giba',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df3b',
    },
    state: 'Arunachal Pradesh',
    district: 'Upper Siang',
    city: 'Yingkiong',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df3c',
    },
    state: 'Arunachal Pradesh',
    district: 'Upper Subansiri',
    city: 'Limeking',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df3d',
    },
    state: 'Arunachal Pradesh',
    district: 'Upper Subansiri',
    city: 'Nacho',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df3e',
    },
    state: 'Arunachal Pradesh',
    district: 'Upper Subansiri',
    city: 'Payeng',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df3f',
    },
    state: 'Arunachal Pradesh',
    district: 'Upper Subansiri',
    city: 'Taliha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df40',
    },
    state: 'Arunachal Pradesh',
    district: 'Upper Subansiri',
    city: 'Siyum',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df41',
    },
    state: 'Arunachal Pradesh',
    district: 'West Kameng',
    city: 'Kalaktang',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df42',
    },
    state: 'Arunachal Pradesh',
    district: 'West Kameng',
    city: 'Dirang',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df43',
    },
    state: 'Arunachal Pradesh',
    district: 'West Kameng',
    city: 'Nafra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df44',
    },
    state: 'Arunachal Pradesh',
    district: 'West Kameng',
    city: 'Thrizino',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df45',
    },
    state: 'Arunachal Pradesh',
    district: 'West Siang',
    city: 'Along East',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df46',
    },
    state: 'Arunachal Pradesh',
    district: 'West Kameng',
    city: 'Singchung',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df47',
    },
    state: 'Arunachal Pradesh',
    district: 'West Siang',
    city: 'Along West',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df48',
    },
    state: 'Arunachal Pradesh',
    district: 'West Siang',
    city: 'Darak',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df49',
    },
    state: 'Arunachal Pradesh',
    district: 'West Siang',
    city: 'Liromoba-Yomcha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df4a',
    },
    state: 'Assam',
    district: 'Baksa',
    city: 'Baska',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df4b',
    },
    state: 'Assam',
    district: 'Baksa',
    city: 'Dhamdhama',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df4c',
    },
    state: 'Assam',
    district: 'Baksa',
    city: 'Gobardhana',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df4d',
    },
    state: 'Assam',
    district: 'Baksa',
    city: 'Barama',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df4e',
    },
    state: 'Assam',
    district: 'Baksa',
    city: 'Goreswar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df4f',
    },
    state: 'Assam',
    district: 'Baksa',
    city: 'Nagrijuli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df50',
    },
    state: 'Assam',
    district: 'Baksa',
    city: 'Jalah',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df51',
    },
    state: 'Assam',
    district: 'Baksa',
    city: 'Tamulpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df52',
    },
    state: 'Assam',
    district: 'Barpeta',
    city: 'Bajali',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df53',
    },
    state: 'Assam',
    district: 'Barpeta',
    city: 'Barpeta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df54',
    },
    state: 'Assam',
    district: 'Barpeta',
    city: 'Bhabanipur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df55',
    },
    state: 'Assam',
    district: 'Barpeta',
    city: 'Chakchaka',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df56',
    },
    state: 'Assam',
    district: 'Barpeta',
    city: 'Chenga',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df57',
    },
    state: 'Assam',
    district: 'Barpeta',
    city: 'Gomaphulbari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df58',
    },
    state: 'Assam',
    district: 'Barpeta',
    city: 'Gobardhana',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df59',
    },
    state: 'Assam',
    district: 'Barpeta',
    city: 'Pakabetbari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df5a',
    },
    state: 'Assam',
    district: 'Barpeta',
    city: 'Ruposhi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df5b',
    },
    state: 'Assam',
    district: 'Barpeta',
    city: 'Sarukhetri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df5c',
    },
    state: 'Assam',
    district: 'Barpeta',
    city: 'Mandia',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df5d',
    },
    state: 'Assam',
    district: 'Biswanath',
    city: 'Baghmara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df5e',
    },
    state: 'Assam',
    district: 'Biswanath',
    city: 'Biswanath',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df5f',
    },
    state: 'Assam',
    district: 'Biswanath',
    city: 'Behali',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df60',
    },
    state: 'Assam',
    district: 'Biswanath',
    city: 'Chaiduar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df61',
    },
    state: 'Assam',
    district: 'Biswanath',
    city: 'Pub Chaiduar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df62',
    },
    state: 'Assam',
    district: 'Biswanath',
    city: 'Sakomatha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df63',
    },
    state: 'Assam',
    district: 'Biswanath',
    city: 'Sootea',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df64',
    },
    state: 'Assam',
    district: 'Bongaigaon',
    city: 'Boitamari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df65',
    },
    state: 'Assam',
    district: 'Bongaigaon',
    city: 'Dangtol',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df66',
    },
    state: 'Assam',
    district: 'Bongaigaon',
    city: 'Manikpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df67',
    },
    state: 'Assam',
    district: 'Bongaigaon',
    city: 'Srijangram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df68',
    },
    state: 'Assam',
    district: 'Bongaigaon',
    city: 'Tapattary',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df69',
    },
    state: 'Assam',
    district: 'Cachar',
    city: 'Barjalenga',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df6a',
    },
    state: 'Assam',
    district: 'Cachar',
    city: 'Banskandi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df6b',
    },
    state: 'Assam',
    district: 'Cachar',
    city: 'Borkhola',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df6c',
    },
    state: 'Assam',
    district: 'Cachar',
    city: 'Binnakandi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df6d',
    },
    state: 'Assam',
    district: 'Cachar',
    city: 'Kalain',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df6e',
    },
    state: 'Assam',
    district: 'Cachar',
    city: 'Katigorah',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df6f',
    },
    state: 'Assam',
    district: 'Cachar',
    city: 'Lakhipur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df70',
    },
    state: 'Assam',
    district: 'Cachar',
    city: 'Narsingpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df71',
    },
    state: 'Assam',
    district: 'Cachar',
    city: 'Palonghat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df72',
    },
    state: 'Assam',
    district: 'Cachar',
    city: 'Salchapra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df73',
    },
    state: 'Assam',
    district: 'Cachar',
    city: 'Rajabazar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df74',
    },
    state: 'Assam',
    district: 'Cachar',
    city: 'Silchar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df75',
    },
    state: 'Assam',
    district: 'Cachar',
    city: 'Tapang',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df76',
    },
    state: 'Assam',
    district: 'Cachar',
    city: 'Sonai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df77',
    },
    state: 'Assam',
    district: 'Charaideo',
    city: 'Lakuwa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df78',
    },
    state: 'Assam',
    district: 'Charaideo',
    city: 'Sapekhati',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df79',
    },
    state: 'Assam',
    district: 'Charaideo',
    city: 'Sonari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df7a',
    },
    state: 'Assam',
    district: 'Cachar',
    city: 'Udharbond',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df7b',
    },
    state: 'Assam',
    district: 'Chirang',
    city: 'Borobazar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df7c',
    },
    state: 'Assam',
    district: 'Chirang',
    city: 'Manikpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df7d',
    },
    state: 'Assam',
    district: 'Charaideo',
    city: 'West Abhaipur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df7e',
    },
    state: 'Assam',
    district: 'Chirang',
    city: 'Manikpur Part',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df7f',
    },
    state: 'Assam',
    district: 'Chirang',
    city: 'Sidli-Chirang',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df80',
    },
    state: 'Assam',
    district: 'Darrang',
    city: 'Bechimari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df81',
    },
    state: 'Assam',
    district: 'Darrang',
    city: 'Dalgaon-Sialmari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df82',
    },
    state: 'Assam',
    district: 'Darrang',
    city: 'Kalaigaon',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df83',
    },
    state: 'Assam',
    district: 'Darrang',
    city: 'Khoirabari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df84',
    },
    state: 'Assam',
    district: 'Darrang',
    city: 'Pachim-Mangaldai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df85',
    },
    state: 'Assam',
    district: 'Darrang',
    city: 'Pub-Mangaldai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df86',
    },
    state: 'Assam',
    district: 'Dhemaji',
    city: 'Bordoloni',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df87',
    },
    state: 'Assam',
    district: 'Dhemaji',
    city: 'Dhemaji',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df88',
    },
    state: 'Assam',
    district: 'Darrang',
    city: 'Sipajhar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df89',
    },
    state: 'Assam',
    district: 'Dhemaji',
    city: 'Murkongselek',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df8a',
    },
    state: 'Assam',
    district: 'Dhemaji',
    city: 'Machkhowa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df8b',
    },
    state: 'Assam',
    district: 'Dhubri',
    city: 'Agomani',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df8c',
    },
    state: 'Assam',
    district: 'Dhemaji',
    city: 'Sissiborgaon',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df8d',
    },
    state: 'Assam',
    district: 'Dhubri',
    city: 'Bilasipara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df8e',
    },
    state: 'Assam',
    district: 'Dhubri',
    city: 'Birshing-Jarua',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df8f',
    },
    state: 'Assam',
    district: 'Dhubri',
    city: 'Chapar Salkocha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df90',
    },
    state: 'Assam',
    district: 'Dhubri',
    city: 'Debitola',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df91',
    },
    state: 'Assam',
    district: 'Dhubri',
    city: 'Gauripur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df92',
    },
    state: 'Assam',
    district: 'Dhubri',
    city: 'Golakganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df93',
    },
    state: 'Assam',
    district: 'Dhubri',
    city: 'Hatidhura',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df95',
    },
    state: 'Assam',
    district: 'Dhubri',
    city: 'Mahamaya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df96',
    },
    state: 'Assam',
    district: 'Dhubri',
    city: 'Nayeralga',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df97',
    },
    state: 'Assam',
    district: 'Dhubri',
    city: 'Rupshi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df98',
    },
    state: 'Assam',
    district: 'Dibrugarh',
    city: 'Barbaruah',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df99',
    },
    state: 'Assam',
    district: 'Dibrugarh',
    city: 'Joypur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df9a',
    },
    state: 'Assam',
    district: 'Dibrugarh',
    city: 'Khowang',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df9b',
    },
    state: 'Assam',
    district: 'Dibrugarh',
    city: 'Lahoal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df9c',
    },
    state: 'Assam',
    district: 'Dibrugarh',
    city: 'Panitola',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df9d',
    },
    state: 'Assam',
    district: 'Dibrugarh',
    city: 'Tengakhat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df9e',
    },
    state: 'Assam',
    district: 'Dibrugarh',
    city: 'Tingkhong',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559df9f',
    },
    state: 'Assam',
    district: 'Dima Hasao',
    city: 'Diyang Valley',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfa0',
    },
    state: 'Assam',
    district: 'Dima Hasao',
    city: 'Diyungbra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfa1',
    },
    state: 'Assam',
    district: 'Dima Hasao',
    city: 'Harangajao',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfa2',
    },
    state: 'Assam',
    district: 'Dima Hasao',
    city: 'Jatinga Valley',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfa3',
    },
    state: 'Assam',
    district: 'Dima Hasao',
    city: 'New Sangbar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfa4',
    },
    state: 'Assam',
    district: 'Goalpara',
    city: 'Balijana',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfa5',
    },
    state: 'Assam',
    district: 'Goalpara',
    city: 'Jaleswar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfa6',
    },
    state: 'Assam',
    district: 'Goalpara',
    city: 'Kharmuza',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfa7',
    },
    state: 'Assam',
    district: 'Dhubri',
    city: 'Jamadarhat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfa8',
    },
    state: 'Assam',
    district: 'Goalpara',
    city: 'Krishnai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfa9',
    },
    state: 'Assam',
    district: 'Goalpara',
    city: 'Kuchdhowa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfaa',
    },
    state: 'Assam',
    district: 'Goalpara',
    city: 'Lakhipur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfab',
    },
    state: 'Assam',
    district: 'Goalpara',
    city: 'Rongjuli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfac',
    },
    state: 'Assam',
    district: 'Golaghat',
    city: 'Golaghat Central',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfad',
    },
    state: 'Assam',
    district: 'Golaghat',
    city: 'Golaghat East',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfae',
    },
    state: 'Assam',
    district: 'Golaghat',
    city: 'Golaghat North',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfaf',
    },
    state: 'Assam',
    district: 'Golaghat',
    city: 'Golaghat South',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfb0',
    },
    state: 'Assam',
    district: 'Golaghat',
    city: 'Golaghat West',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfb1',
    },
    state: 'Assam',
    district: 'Golaghat',
    city: 'Gomariguri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfb2',
    },
    state: 'Assam',
    district: 'Golaghat',
    city: 'Kakodonga',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfb3',
    },
    state: 'Assam',
    district: 'Golaghat',
    city: 'Morongi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfb4',
    },
    state: 'Assam',
    district: 'Hailakandi',
    city: 'Algapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfb5',
    },
    state: 'Assam',
    district: 'Hailakandi',
    city: 'Hailakandi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfb6',
    },
    state: 'Assam',
    district: 'Hailakandi',
    city: 'Katlicherra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfb7',
    },
    state: 'Assam',
    district: 'Hailakandi',
    city: 'Lala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfb8',
    },
    state: 'Assam',
    district: 'Hailakandi',
    city: 'South Hailakandi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfb9',
    },
    state: 'Assam',
    district: 'Hojai',
    city: 'Binakandi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfba',
    },
    state: 'Assam',
    district: 'Hojai',
    city: 'Dhalpukhuri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfbb',
    },
    state: 'Assam',
    district: 'Hojai',
    city: 'Jugijan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfbc',
    },
    state: 'Assam',
    district: 'Hojai',
    city: 'Lumding',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfbd',
    },
    state: 'Assam',
    district: 'Goalpara',
    city: 'Matia',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfbe',
    },
    state: 'Assam',
    district: 'Hojai',
    city: 'Udali',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfbf',
    },
    state: 'Assam',
    district: 'Jorhat',
    city: 'Jorhat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfc0',
    },
    state: 'Assam',
    district: 'Jorhat',
    city: 'Jorhat Central',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfc1',
    },
    state: 'Assam',
    district: 'Jorhat',
    city: 'Kaliapani',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfc2',
    },
    state: 'Assam',
    district: 'Jorhat',
    city: 'North West Jorhat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfc3',
    },
    state: 'Assam',
    district: 'Jorhat',
    city: 'Titabor',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfc4',
    },
    state: 'Assam',
    district: 'Kamrup',
    city: 'Bezera',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfc5',
    },
    state: 'Assam',
    district: 'Kamrup',
    city: 'Bihdia -Jajikona',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfc6',
    },
    state: 'Assam',
    district: 'Kamrup',
    city: 'Boko',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfc7',
    },
    state: 'Assam',
    district: 'Kamrup',
    city: 'Bongaon',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfc8',
    },
    state: 'Assam',
    district: 'Kamrup',
    city: 'Chamaria',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfc9',
    },
    state: 'Assam',
    district: 'Kamrup',
    city: 'Chayani',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfca',
    },
    state: 'Assam',
    district: 'Kamrup',
    city: 'Chaygaon',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfcb',
    },
    state: 'Assam',
    district: 'Kamrup',
    city: 'Goroimari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfcc',
    },
    state: 'Assam',
    district: 'Kamrup',
    city: 'Hajo',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfcd',
    },
    state: 'Assam',
    district: 'Kamrup',
    city: 'Kamalpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfce',
    },
    state: 'Assam',
    district: 'Kamrup',
    city: 'Rampur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfcf',
    },
    state: 'Assam',
    district: 'Kamrup',
    city: 'Rangia',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfd0',
    },
    state: 'Assam',
    district: 'Kamrup',
    city: 'Rani',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfd1',
    },
    state: 'Assam',
    district: 'Kamrup',
    city: 'Sualkuchi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfd2',
    },
    state: 'Assam',
    district: 'Kamrup Metro',
    city: 'Bezera (Pt)',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfd3',
    },
    state: 'Assam',
    district: 'Jorhat',
    city: 'Jorhat East',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfd4',
    },
    state: 'Assam',
    district: 'Kamrup Metro',
    city: 'Chandrapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfd5',
    },
    state: 'Assam',
    district: 'Kamrup Metro',
    city: 'Dimoria',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfd6',
    },
    state: 'Assam',
    district: 'Kamrup Metro',
    city: 'Rani (Pt)',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfd7',
    },
    state: 'Assam',
    district: 'Karbi Anglong',
    city: 'Howraghat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfd8',
    },
    state: 'Assam',
    district: 'Karbi Anglong',
    city: 'Langsomepi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfd9',
    },
    state: 'Assam',
    district: 'Karbi Anglong',
    city: 'Lumbajong',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfda',
    },
    state: 'Assam',
    district: 'Karbi Anglong',
    city: 'Nilip',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfdb',
    },
    state: 'Assam',
    district: 'Karbi Anglong',
    city: 'Rongmongwe',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfdc',
    },
    state: 'Assam',
    district: 'Karbi Anglong',
    city: 'Samelangso',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfdd',
    },
    state: 'Assam',
    district: 'Karimganj',
    city: 'Badarpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfde',
    },
    state: 'Assam',
    district: 'Karimganj',
    city: 'Dullavcherra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfdf',
    },
    state: 'Assam',
    district: 'Karimganj',
    city: 'Lowairpoa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfe0',
    },
    state: 'Assam',
    district: 'Karimganj',
    city: 'North Karimganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfe1',
    },
    state: 'Assam',
    district: 'Karimganj',
    city: 'Patharkandi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfe2',
    },
    state: 'Assam',
    district: 'Karimganj',
    city: 'Ramkrishna Nagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfe3',
    },
    state: 'Assam',
    district: 'Karimganj',
    city: 'South Karimganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfe4',
    },
    state: 'Assam',
    district: 'Kokrajhar',
    city: 'Bilashipara-Btc',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfe5',
    },
    state: 'Assam',
    district: 'Kokrajhar',
    city: 'Bilasipara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfe6',
    },
    state: 'Assam',
    district: 'Kokrajhar',
    city: 'Chapar Salkocha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfe7',
    },
    state: 'Assam',
    district: 'Kokrajhar',
    city: 'Chapor-Salkocha-Btc',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfe8',
    },
    state: 'Assam',
    district: 'Kokrajhar',
    city: 'Debitola',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfe9',
    },
    state: 'Assam',
    district: 'Karbi Anglong',
    city: 'Bokajan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfea',
    },
    state: 'Assam',
    district: 'Kokrajhar',
    city: 'Debitola-Btc',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfeb',
    },
    state: 'Assam',
    district: 'Kokrajhar',
    city: 'Dotma',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfec',
    },
    state: 'Assam',
    district: 'Kokrajhar',
    city: 'Golakganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfed',
    },
    state: 'Assam',
    district: 'Kokrajhar',
    city: 'Gossaigaon',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfee',
    },
    state: 'Assam',
    district: 'Kokrajhar',
    city: 'Hatidhura',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfef',
    },
    state: 'Assam',
    district: 'Kokrajhar',
    city: 'Kachugaon',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dff0',
    },
    state: 'Assam',
    district: 'Kokrajhar',
    city: 'Kokrajhar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dff1',
    },
    state: 'Assam',
    district: 'Kokrajhar',
    city: 'Mahamaya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dff2',
    },
    state: 'Assam',
    district: 'Kokrajhar',
    city: 'Mahamaya-Btc',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dff3',
    },
    state: 'Assam',
    district: 'Kokrajhar',
    city: 'Rupshi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dff4',
    },
    state: 'Assam',
    district: 'Kokrajhar',
    city: 'Rupshi-Btc',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dff5',
    },
    state: 'Assam',
    district: 'Lakhimpur',
    city: 'Bihpuria',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dff6',
    },
    state: 'Assam',
    district: 'Lakhimpur',
    city: 'Boginadi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dff7',
    },
    state: 'Assam',
    district: 'Lakhimpur',
    city: 'Dhakuakhana',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dff8',
    },
    state: 'Assam',
    district: 'Lakhimpur',
    city: 'Ghilamara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dff9',
    },
    state: 'Assam',
    district: 'Lakhimpur',
    city: 'Karunabari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dffa',
    },
    state: 'Assam',
    district: 'Lakhimpur',
    city: 'Lakhimpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dffb',
    },
    state: 'Assam',
    district: 'Lakhimpur',
    city: 'Narayanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dffc',
    },
    state: 'Assam',
    district: 'Lakhimpur',
    city: 'Nowboicha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dffd',
    },
    state: 'Assam',
    district: 'Lakhimpur',
    city: 'Telahi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dffe',
    },
    state: 'Assam',
    district: 'Majuli',
    city: 'Majuli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559dfff',
    },
    state: 'Assam',
    district: 'Kokrajhar',
    city: 'Golakganj-Btc',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e000',
    },
    state: 'Assam',
    district: 'Majuli',
    city: 'Ujani Majuli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e001',
    },
    state: 'Assam',
    district: 'Marigaon',
    city: 'Batabraba (Part)',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e002',
    },
    state: 'Assam',
    district: 'Marigaon',
    city: 'Bhurbandha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e003',
    },
    state: 'Assam',
    district: 'Marigaon',
    city: 'Kapili',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e004',
    },
    state: 'Assam',
    district: 'Marigaon',
    city: 'Laharighat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e005',
    },
    state: 'Assam',
    district: 'Marigaon',
    city: 'Mayang',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e006',
    },
    state: 'Assam',
    district: 'Marigaon',
    city: 'Moirabari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e007',
    },
    state: 'Assam',
    district: 'Nagaon',
    city: 'Bajiagaon',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e008',
    },
    state: 'Assam',
    district: 'Nagaon',
    city: 'Barhampur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e009',
    },
    state: 'Assam',
    district: 'Nagaon',
    city: 'Batadrava',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e00a',
    },
    state: 'Assam',
    district: 'Nagaon',
    city: 'Dolongghat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e00b',
    },
    state: 'Assam',
    district: 'Nagaon',
    city: 'Juria',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e00c',
    },
    state: 'Assam',
    district: 'Nagaon',
    city: 'Kaliabor',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e00d',
    },
    state: 'Assam',
    district: 'Nagaon',
    city: 'Kapili Pt.I',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e00e',
    },
    state: 'Assam',
    district: 'Nagaon',
    city: 'Kathiatoli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e00f',
    },
    state: 'Assam',
    district: 'Nagaon',
    city: 'Khagarijan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e010',
    },
    state: 'Assam',
    district: 'Nagaon',
    city: 'Laokhowa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e011',
    },
    state: 'Assam',
    district: 'Nagaon',
    city: 'Moirabari Part',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e012',
    },
    state: 'Assam',
    district: 'Nagaon',
    city: 'Pachim Kaliabor',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e013',
    },
    state: 'Assam',
    district: 'Nagaon',
    city: 'Pakhimoria',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e014',
    },
    state: 'Assam',
    district: 'Nagaon',
    city: 'Raha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e015',
    },
    state: 'Assam',
    district: 'Marigaon',
    city: 'Dolongghat (Part)',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e016',
    },
    state: 'Assam',
    district: 'Nagaon',
    city: 'Rupahi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e017',
    },
    state: 'Assam',
    district: 'Nalbari',
    city: 'Barigog Banbhag',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e018',
    },
    state: 'Assam',
    district: 'Nalbari',
    city: 'Barkhetri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e019',
    },
    state: 'Assam',
    district: 'Nalbari',
    city: 'Madhupur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e01a',
    },
    state: 'Assam',
    district: 'Nalbari',
    city: 'Paschim Nalbari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e01c',
    },
    state: 'Assam',
    district: 'Nalbari',
    city: 'Pub Nalbari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e01d',
    },
    state: 'Assam',
    district: 'Nalbari',
    city: 'Tihu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e01e',
    },
    state: 'Assam',
    district: 'Sivasagar',
    city: 'Amguri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e01f',
    },
    state: 'Assam',
    district: 'Sivasagar',
    city: 'Demow',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e020',
    },
    state: 'Assam',
    district: 'Sivasagar',
    city: 'Gaurisagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e021',
    },
    state: 'Assam',
    district: 'Sivasagar',
    city: 'Nazira',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e022',
    },
    state: 'Assam',
    district: 'Sivasagar',
    city: 'Sivasagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e023',
    },
    state: 'Assam',
    district: 'Sonitpur',
    city: 'Balipara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e024',
    },
    state: 'Assam',
    district: 'Sonitpur',
    city: 'Bihaguri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e025',
    },
    state: 'Assam',
    district: 'Sonitpur',
    city: 'Borchala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e026',
    },
    state: 'Assam',
    district: 'Sonitpur',
    city: 'Dhekiajuli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e027',
    },
    state: 'Assam',
    district: 'Sonitpur',
    city: 'Gabhoru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e028',
    },
    state: 'Assam',
    district: 'Sonitpur',
    city: 'Naduar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e029',
    },
    state: 'Assam',
    district: 'Sonitpur',
    city: 'Rangapara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e02a',
    },
    state: 'Assam',
    district: 'South Salmara Mancachar',
    city: 'Fekamari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e02b',
    },
    state: 'Assam',
    district: 'South Salmara Mancachar',
    city: 'Mankachar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e02c',
    },
    state: 'Assam',
    district: 'Nalbari',
    city: 'Borbhag',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e02d',
    },
    state: 'Assam',
    district: 'South Salmara Mancachar',
    city: 'South Salmara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e02e',
    },
    state: 'Assam',
    district: 'Tinsukia',
    city: 'Guijan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e02f',
    },
    state: 'Assam',
    district: 'Tinsukia',
    city: 'Hapjan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e030',
    },
    state: 'Assam',
    district: 'Tinsukia',
    city: 'Itakhuli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e031',
    },
    state: 'Assam',
    district: 'Tinsukia',
    city: 'Kakopathar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e032',
    },
    state: 'Assam',
    district: 'Tinsukia',
    city: 'Sadiya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e033',
    },
    state: 'Assam',
    district: 'Tinsukia',
    city: 'Saikhowa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e034',
    },
    state: 'Assam',
    district: 'Udalguri',
    city: 'Bechimari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e035',
    },
    state: 'Assam',
    district: 'Udalguri',
    city: 'Bhergaon',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e036',
    },
    state: 'Assam',
    district: 'Tinsukia',
    city: 'Margherita',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e037',
    },
    state: 'Assam',
    district: 'Udalguri',
    city: 'Dalgaon-Sialmari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e038',
    },
    state: 'Assam',
    district: 'Udalguri',
    city: 'Borsola',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e039',
    },
    state: 'Assam',
    district: 'Udalguri',
    city: 'Kalaigaon',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e03a',
    },
    state: 'Assam',
    district: 'Udalguri',
    city: 'Khoirabari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e03b',
    },
    state: 'Assam',
    district: 'Udalguri',
    city: 'Odalguri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e03c',
    },
    state: 'Assam',
    district: 'Udalguri',
    city: 'Paschim-Mangaldai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e03d',
    },
    state: 'Assam',
    district: 'Udalguri',
    city: 'Mazbat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e03e',
    },
    state: 'Assam',
    district: 'Udalguri',
    city: 'Pub-Mangaldai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e03f',
    },
    state: 'Assam',
    district: 'West Karbi Anglong',
    city: 'Amri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e040',
    },
    state: 'Assam',
    district: 'Udalguri',
    city: 'Rowta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e041',
    },
    state: 'Assam',
    district: 'West Karbi Anglong',
    city: 'Chinthong',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e042',
    },
    state: 'Assam',
    district: 'West Karbi Anglong',
    city: 'Rongkhang',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e043',
    },
    state: 'Assam',
    district: 'West Karbi Anglong',
    city: 'Socheng',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e044',
    },
    state: 'Bihar',
    district: 'Araria',
    city: 'Araria',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e045',
    },
    state: 'Bihar',
    district: 'Araria',
    city: 'Bhargama',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e046',
    },
    state: 'Bihar',
    district: 'Araria',
    city: 'Forbesganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e047',
    },
    state: 'Bihar',
    district: 'Araria',
    city: 'Kursakanta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e048',
    },
    state: 'Bihar',
    district: 'Araria',
    city: 'Narpatganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e049',
    },
    state: 'Bihar',
    district: 'Araria',
    city: 'Palasi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e04a',
    },
    state: 'Bihar',
    district: 'Araria',
    city: 'Raniganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e04b',
    },
    state: 'Bihar',
    district: 'Araria',
    city: 'Sikty',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e04c',
    },
    state: 'Bihar',
    district: 'Arwal',
    city: 'Arwal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e04d',
    },
    state: 'Bihar',
    district: 'Arwal',
    city: 'Kaler',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e04e',
    },
    state: 'Bihar',
    district: 'Arwal',
    city: 'Kapri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e04f',
    },
    state: 'Bihar',
    district: 'Arwal',
    city: 'Kurtha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e050',
    },
    state: 'Bihar',
    district: 'Araria',
    city: 'Jokihat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e051',
    },
    state: 'Bihar',
    district: 'Arwal',
    city: 'Sonbhadra-Bansi-Surajpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e052',
    },
    state: 'Bihar',
    district: 'Aurangabad',
    city: 'Barun',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e053',
    },
    state: 'Bihar',
    district: 'Aurangabad',
    city: 'Aurangabad',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e054',
    },
    state: 'Bihar',
    district: 'Aurangabad',
    city: 'Daudnagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e055',
    },
    state: 'Bihar',
    district: 'Aurangabad',
    city: 'Deo',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e056',
    },
    state: 'Bihar',
    district: 'Aurangabad',
    city: 'Goh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e057',
    },
    state: 'Bihar',
    district: 'Aurangabad',
    city: 'Kutumba',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e058',
    },
    state: 'Bihar',
    district: 'Aurangabad',
    city: 'Haspura',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e059',
    },
    state: 'Bihar',
    district: 'Aurangabad',
    city: 'Madanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e05a',
    },
    state: 'Bihar',
    district: 'Aurangabad',
    city: 'Obra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e05b',
    },
    state: 'Bihar',
    district: 'Aurangabad',
    city: 'Rafiganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e05c',
    },
    state: 'Bihar',
    district: 'Aurangabad',
    city: 'Nabinagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e05d',
    },
    state: 'Bihar',
    district: 'Banka',
    city: 'Banka',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e05e',
    },
    state: 'Bihar',
    district: 'Banka',
    city: 'Amarpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e05f',
    },
    state: 'Bihar',
    district: 'Banka',
    city: 'Bausi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e060',
    },
    state: 'Bihar',
    district: 'Banka',
    city: 'Barahat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e061',
    },
    state: 'Bihar',
    district: 'Banka',
    city: 'Belhar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e062',
    },
    state: 'Bihar',
    district: 'Banka',
    city: 'Chandan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e063',
    },
    state: 'Bihar',
    district: 'Banka',
    city: 'Dhuraiya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e064',
    },
    state: 'Bihar',
    district: 'Banka',
    city: 'Katoria',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e065',
    },
    state: 'Bihar',
    district: 'Banka',
    city: 'Rajaun',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e066',
    },
    state: 'Bihar',
    district: 'Banka',
    city: 'Shambhuganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e067',
    },
    state: 'Bihar',
    district: 'Banka',
    city: 'Fullidumar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e068',
    },
    state: 'Bihar',
    district: 'Begusarai',
    city: 'Bachhwara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e069',
    },
    state: 'Bihar',
    district: 'Begusarai',
    city: 'Ballia',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e06a',
    },
    state: 'Bihar',
    district: 'Begusarai',
    city: 'Barauni',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e06b',
    },
    state: 'Bihar',
    district: 'Begusarai',
    city: 'Begusarai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e06c',
    },
    state: 'Bihar',
    district: 'Begusarai',
    city: 'Bhagwanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e06d',
    },
    state: 'Bihar',
    district: 'Begusarai',
    city: 'Birpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e06e',
    },
    state: 'Bihar',
    district: 'Begusarai',
    city: 'Cheria Bariarpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e06f',
    },
    state: 'Bihar',
    district: 'Begusarai',
    city: 'Chhaurahi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e070',
    },
    state: 'Bihar',
    district: 'Begusarai',
    city: 'Dandari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e071',
    },
    state: 'Bihar',
    district: 'Begusarai',
    city: 'Bakhri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e072',
    },
    state: 'Bihar',
    district: 'Begusarai',
    city: 'Khodawandpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e073',
    },
    state: 'Bihar',
    district: 'Begusarai',
    city: 'Gadhpura',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e074',
    },
    state: 'Bihar',
    district: 'Begusarai',
    city: 'Mansurchak',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e075',
    },
    state: 'Bihar',
    district: 'Begusarai',
    city: 'Nawkothi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e076',
    },
    state: 'Bihar',
    district: 'Begusarai',
    city: 'Matihani',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e077',
    },
    state: 'Bihar',
    district: 'Begusarai',
    city: 'Sahebpur Kamal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e078',
    },
    state: 'Bihar',
    district: 'Begusarai',
    city: 'Teghra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e079',
    },
    state: 'Bihar',
    district: 'Bhagalpur',
    city: 'Bihpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e07a',
    },
    state: 'Bihar',
    district: 'Bhagalpur',
    city: 'Gopalpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e07b',
    },
    state: 'Bihar',
    district: 'Bhagalpur',
    city: 'Goradih',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e07c',
    },
    state: 'Bihar',
    district: 'Bhagalpur',
    city: 'Ismailpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e07d',
    },
    state: 'Bihar',
    district: 'Bhagalpur',
    city: 'Jagdishpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e07e',
    },
    state: 'Bihar',
    district: 'Bhagalpur',
    city: 'Kahalgaon',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e07f',
    },
    state: 'Bihar',
    district: 'Bhagalpur',
    city: 'Kharik',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e080',
    },
    state: 'Bihar',
    district: 'Bhagalpur',
    city: 'Narayanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e081',
    },
    state: 'Bihar',
    district: 'Bhagalpur',
    city: 'Nathnagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e082',
    },
    state: 'Bihar',
    district: 'Bhagalpur',
    city: 'Naugachhia',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e083',
    },
    state: 'Bihar',
    district: 'Bhagalpur',
    city: 'Pirpainti',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e084',
    },
    state: 'Bihar',
    district: 'Begusarai',
    city: 'Samho Akha Kurha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e085',
    },
    state: 'Bihar',
    district: 'Bhagalpur',
    city: 'Rangrachowk',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e086',
    },
    state: 'Bihar',
    district: 'Bhagalpur',
    city: 'Shahkund',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e087',
    },
    state: 'Bihar',
    district: 'Bhagalpur',
    city: 'Sonhaula',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e088',
    },
    state: 'Bihar',
    district: 'Bhagalpur',
    city: 'Sultanganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e089',
    },
    state: 'Bihar',
    district: 'Bhojpur',
    city: 'Agiaon',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e08a',
    },
    state: 'Bihar',
    district: 'Bhagalpur',
    city: 'Sabour',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e08b',
    },
    state: 'Bihar',
    district: 'Bhojpur',
    city: 'Barhara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e08c',
    },
    state: 'Bihar',
    district: 'Bhojpur',
    city: 'Ara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e08d',
    },
    state: 'Bihar',
    district: 'Bhojpur',
    city: 'Behea',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e08e',
    },
    state: 'Bihar',
    district: 'Bhojpur',
    city: 'Charpokhari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e08f',
    },
    state: 'Bihar',
    district: 'Bhojpur',
    city: 'Jagdishpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e090',
    },
    state: 'Bihar',
    district: 'Bhojpur',
    city: 'Koilwar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e091',
    },
    state: 'Bihar',
    district: 'Bhojpur',
    city: 'Piro',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e092',
    },
    state: 'Bihar',
    district: 'Bhojpur',
    city: 'Sahar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e093',
    },
    state: 'Bihar',
    district: 'Bhojpur',
    city: 'Sandesh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e094',
    },
    state: 'Bihar',
    district: 'Bhojpur',
    city: 'Garhani',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e095',
    },
    state: 'Bihar',
    district: 'Bhojpur',
    city: 'Shahpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e096',
    },
    state: 'Bihar',
    district: 'Bhojpur',
    city: 'Udwantnagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e097',
    },
    state: 'Bihar',
    district: 'Bhojpur',
    city: 'Tarari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e098',
    },
    state: 'Bihar',
    district: 'Buxar',
    city: 'Brahmpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e099',
    },
    state: 'Bihar',
    district: 'Buxar',
    city: 'Buxar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e09a',
    },
    state: 'Bihar',
    district: 'Buxar',
    city: 'Chakki',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e09b',
    },
    state: 'Bihar',
    district: 'Buxar',
    city: 'Chausa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e09c',
    },
    state: 'Bihar',
    district: 'Buxar',
    city: 'Chougain',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e09d',
    },
    state: 'Bihar',
    district: 'Buxar',
    city: 'Itarhi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e09e',
    },
    state: 'Bihar',
    district: 'Buxar',
    city: 'Kesath',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e09f',
    },
    state: 'Bihar',
    district: 'Buxar',
    city: 'Dumraon',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0a0',
    },
    state: 'Bihar',
    district: 'Buxar',
    city: 'Rajpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0a1',
    },
    state: 'Bihar',
    district: 'Buxar',
    city: 'Simri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0a2',
    },
    state: 'Bihar',
    district: 'Darbhanga',
    city: 'Alinagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0a3',
    },
    state: 'Bihar',
    district: 'Darbhanga',
    city: 'Bahadurpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0a4',
    },
    state: 'Bihar',
    district: 'Darbhanga',
    city: 'Baheri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0a5',
    },
    state: 'Bihar',
    district: 'Darbhanga',
    city: 'Benipur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0a6',
    },
    state: 'Bihar',
    district: 'Darbhanga',
    city: 'Biraul',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0a7',
    },
    state: 'Bihar',
    district: 'Darbhanga',
    city: 'Darbhanga',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0a8',
    },
    state: 'Bihar',
    district: 'Darbhanga',
    city: 'Gaurabauram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0a9',
    },
    state: 'Bihar',
    district: 'Buxar',
    city: 'Nawanagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0aa',
    },
    state: 'Bihar',
    district: 'Darbhanga',
    city: 'Ghanshyampur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0ab',
    },
    state: 'Bihar',
    district: 'Darbhanga',
    city: 'Hanuman Nagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0ac',
    },
    state: 'Bihar',
    district: 'Darbhanga',
    city: 'Hayaghat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0ad',
    },
    state: 'Bihar',
    district: 'Darbhanga',
    city: 'Keotirunway',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0ae',
    },
    state: 'Bihar',
    district: 'Darbhanga',
    city: 'Kiratpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0af',
    },
    state: 'Bihar',
    district: 'Darbhanga',
    city: 'Kusheshwar Asthan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0b0',
    },
    state: 'Bihar',
    district: 'Darbhanga',
    city: 'Kusheswar Asthan East',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0b1',
    },
    state: 'Bihar',
    district: 'Darbhanga',
    city: 'Jale',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0b2',
    },
    state: 'Bihar',
    district: 'Darbhanga',
    city: 'Singhwara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0b3',
    },
    state: 'Bihar',
    district: 'Darbhanga',
    city: 'Tardih',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0b4',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Amas',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0b5',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Atri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0b6',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Bankey Bazar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0b7',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Barachatti',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0b8',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Belaganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0b9',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Bodhgaya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0ba',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Dobhi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0bb',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Dumaria',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0bc',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Fatehpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0bd',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Gaya Town',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0be',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Guraru',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0bf',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Gurua',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0c0',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Imamganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0c1',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Khizarsarai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0c2',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Konch',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0c3',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Manpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0c4',
    },
    state: 'Bihar',
    district: 'Darbhanga',
    city: 'Manigachhi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0c5',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Mohanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0c6',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Mohra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0c7',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Neemchak Bathani',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0c8',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Paraiya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0c9',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Sherghatty',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0ca',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Tankuppa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0cb',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Wazirganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0cc',
    },
    state: 'Bihar',
    district: 'Gaya',
    city: 'Tekari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0cd',
    },
    state: 'Bihar',
    district: 'Gopalganj',
    city: 'Barauli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0ce',
    },
    state: 'Bihar',
    district: 'Gopalganj',
    city: 'Baikunthpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0cf',
    },
    state: 'Bihar',
    district: 'Gopalganj',
    city: 'Bhorey',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0d0',
    },
    state: 'Bihar',
    district: 'Gopalganj',
    city: 'Bijaipur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0d1',
    },
    state: 'Bihar',
    district: 'Gopalganj',
    city: 'Gopalganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0d2',
    },
    state: 'Bihar',
    district: 'Gopalganj',
    city: 'Kataiya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0d3',
    },
    state: 'Bihar',
    district: 'Gopalganj',
    city: 'Hathua',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0d4',
    },
    state: 'Bihar',
    district: 'Gopalganj',
    city: 'Kuchaikote',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0d5',
    },
    state: 'Bihar',
    district: 'Gopalganj',
    city: 'Manjha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0d6',
    },
    state: 'Bihar',
    district: 'Gopalganj',
    city: 'Panchdeori',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0d7',
    },
    state: 'Bihar',
    district: 'Gopalganj',
    city: 'Sidhwaliya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0d8',
    },
    state: 'Bihar',
    district: 'Gopalganj',
    city: 'Phulwariya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0d9',
    },
    state: 'Bihar',
    district: 'Gopalganj',
    city: 'Thawe',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0da',
    },
    state: 'Bihar',
    district: 'Gopalganj',
    city: 'Uchkagaon',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0db',
    },
    state: 'Bihar',
    district: 'Jamui',
    city: 'Barhat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0dc',
    },
    state: 'Bihar',
    district: 'Jamui',
    city: 'Gidhor',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0dd',
    },
    state: 'Bihar',
    district: 'Jamui',
    city: 'Chakai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0de',
    },
    state: 'Bihar',
    district: 'Jamui',
    city: 'Islamnagar Aliganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0df',
    },
    state: 'Bihar',
    district: 'Jamui',
    city: 'Jamui',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0e0',
    },
    state: 'Bihar',
    district: 'Jamui',
    city: 'Jhajha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0e1',
    },
    state: 'Bihar',
    district: 'Jamui',
    city: 'Laxmipur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0e2',
    },
    state: 'Bihar',
    district: 'Jamui',
    city: 'Sikandra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0e3',
    },
    state: 'Bihar',
    district: 'Jamui',
    city: 'Sono',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0e4',
    },
    state: 'Bihar',
    district: 'Jehanabad',
    city: 'Ghoshi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0e5',
    },
    state: 'Bihar',
    district: 'Jehanabad',
    city: 'Hulasganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0e6',
    },
    state: 'Bihar',
    district: 'Jehanabad',
    city: 'Jehanabad',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0e7',
    },
    state: 'Bihar',
    district: 'Jehanabad',
    city: 'Kako',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0e8',
    },
    state: 'Bihar',
    district: 'Jehanabad',
    city: 'Makhdumpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0e9',
    },
    state: 'Bihar',
    district: 'Jehanabad',
    city: 'Modanganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0ea',
    },
    state: 'Bihar',
    district: 'Jehanabad',
    city: 'Ratni Faridpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0eb',
    },
    state: 'Bihar',
    district: 'Kaimur',
    city: 'Adhaura',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0ec',
    },
    state: 'Bihar',
    district: 'Kaimur',
    city: 'Bhabua',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0ed',
    },
    state: 'Bihar',
    district: 'Jamui',
    city: 'Khaira',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0ee',
    },
    state: 'Bihar',
    district: 'Kaimur',
    city: 'Chainpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0ef',
    },
    state: 'Bihar',
    district: 'Kaimur',
    city: 'Chand',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0f0',
    },
    state: 'Bihar',
    district: 'Kaimur',
    city: 'Bhagwanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0f1',
    },
    state: 'Bihar',
    district: 'Kaimur',
    city: 'Kudra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0f2',
    },
    state: 'Bihar',
    district: 'Kaimur',
    city: 'Durgawati',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0f3',
    },
    state: 'Bihar',
    district: 'Kaimur',
    city: 'Nuaon',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0f4',
    },
    state: 'Bihar',
    district: 'Kaimur',
    city: 'Ramgarh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0f5',
    },
    state: 'Bihar',
    district: 'Kaimur',
    city: 'Rampur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0f6',
    },
    state: 'Bihar',
    district: 'Kaimur',
    city: 'Mohania',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0f7',
    },
    state: 'Bihar',
    district: 'Katihar',
    city: 'Amdabad',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0f8',
    },
    state: 'Bihar',
    district: 'Katihar',
    city: 'Balrampur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0f9',
    },
    state: 'Bihar',
    district: 'Katihar',
    city: 'Barari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0fa',
    },
    state: 'Bihar',
    district: 'Katihar',
    city: 'Barsoi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0fb',
    },
    state: 'Bihar',
    district: 'Katihar',
    city: 'Dandkhora',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0fc',
    },
    state: 'Bihar',
    district: 'Katihar',
    city: 'Falka',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0fd',
    },
    state: 'Bihar',
    district: 'Katihar',
    city: 'Hasanganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0fe',
    },
    state: 'Bihar',
    district: 'Katihar',
    city: 'Azamnagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e0ff',
    },
    state: 'Bihar',
    district: 'Katihar',
    city: 'Kadwa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e100',
    },
    state: 'Bihar',
    district: 'Katihar',
    city: 'Katihar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e101',
    },
    state: 'Bihar',
    district: 'Katihar',
    city: 'Kursela',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e102',
    },
    state: 'Bihar',
    district: 'Katihar',
    city: 'Korha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e103',
    },
    state: 'Bihar',
    district: 'Katihar',
    city: 'Manihari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e104',
    },
    state: 'Bihar',
    district: 'Katihar',
    city: 'Mansahi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e105',
    },
    state: 'Bihar',
    district: 'Katihar',
    city: 'Pranpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e106',
    },
    state: 'Bihar',
    district: 'Khagaria',
    city: 'Alauli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e107',
    },
    state: 'Bihar',
    district: 'Khagaria',
    city: 'Beldaur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e108',
    },
    state: 'Bihar',
    district: 'Khagaria',
    city: 'Chautham',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e109',
    },
    state: 'Bihar',
    district: 'Khagaria',
    city: 'Gogri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e10a',
    },
    state: 'Bihar',
    district: 'Katihar',
    city: 'Sameli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e10b',
    },
    state: 'Bihar',
    district: 'Khagaria',
    city: 'Khagaria',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e10c',
    },
    state: 'Bihar',
    district: 'Khagaria',
    city: 'Mansi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e10d',
    },
    state: 'Bihar',
    district: 'Khagaria',
    city: 'Parbatta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e10e',
    },
    state: 'Bihar',
    district: 'Kishanganj',
    city: 'Bahadurganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e10f',
    },
    state: 'Bihar',
    district: 'Kishanganj',
    city: 'Dighalbank',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e110',
    },
    state: 'Bihar',
    district: 'Kishanganj',
    city: 'Kishanganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e111',
    },
    state: 'Bihar',
    district: 'Kishanganj',
    city: 'Kochadhaman',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e112',
    },
    state: 'Bihar',
    district: 'Kishanganj',
    city: 'Terhagachh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e113',
    },
    state: 'Bihar',
    district: 'Kishanganj',
    city: 'Pothia',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e114',
    },
    state: 'Bihar',
    district: 'Kishanganj',
    city: 'Thakurganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e115',
    },
    state: 'Bihar',
    district: 'Lakhisarai',
    city: 'Barahiya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e116',
    },
    state: 'Bihar',
    district: 'Lakhisarai',
    city: 'Channan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e117',
    },
    state: 'Bihar',
    district: 'Lakhisarai',
    city: 'Halsi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e118',
    },
    state: 'Bihar',
    district: 'Lakhisarai',
    city: 'Lakhisarai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e119',
    },
    state: 'Bihar',
    district: 'Lakhisarai',
    city: 'Ramgarh Chowk',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e11a',
    },
    state: 'Bihar',
    district: 'Lakhisarai',
    city: 'Surajgarha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e11b',
    },
    state: 'Bihar',
    district: 'Madhepura',
    city: 'Alamnagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e11c',
    },
    state: 'Bihar',
    district: 'Madhepura',
    city: 'Bihariganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e11d',
    },
    state: 'Bihar',
    district: 'Madhepura',
    city: 'Chausa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e11e',
    },
    state: 'Bihar',
    district: 'Madhepura',
    city: 'Gamhariya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e11f',
    },
    state: 'Bihar',
    district: 'Madhepura',
    city: 'Ghelarh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e120',
    },
    state: 'Bihar',
    district: 'Madhepura',
    city: 'Gwalpara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e121',
    },
    state: 'Bihar',
    district: 'Madhepura',
    city: 'Kumarkhand',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e122',
    },
    state: 'Bihar',
    district: 'Madhepura',
    city: 'Madhepura',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e123',
    },
    state: 'Bihar',
    district: 'Madhepura',
    city: 'Murliganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e124',
    },
    state: 'Bihar',
    district: 'Madhepura',
    city: 'Purani',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e125',
    },
    state: 'Bihar',
    district: 'Madhepura',
    city: 'Shankarpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e126',
    },
    state: 'Bihar',
    district: 'Madhepura',
    city: 'Singheshwar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e127',
    },
    state: 'Bihar',
    district: 'Madhepura',
    city: 'Udakishunganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e128',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Andhratharhi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e129',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Babu Barhi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e12a',
    },
    state: 'Bihar',
    district: 'Lakhisarai',
    city: 'Pipariya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e12b',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Basopatti',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e12c',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Benipatti',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e12d',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Bisfi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e12e',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Ghoghardiha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e12f',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Jainagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e130',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Jhanjharpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e131',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Kaluahi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e132',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Khajauli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e133',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Harlakhi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e134',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Ladania',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e135',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Lakhnaur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e136',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Laukaha (Khutauna)',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e137',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Laukahi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e138',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Madhepur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e139',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Madhwapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e13a',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Pandaul',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e13b',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Phulparas',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e13c',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Rahika',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e13d',
    },
    state: 'Bihar',
    district: 'Madhubani',
    city: 'Rajnagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e13e',
    },
    state: 'Bihar',
    district: 'Munger',
    city: 'Asarganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e13f',
    },
    state: 'Bihar',
    district: 'Munger',
    city: 'Dharhara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e140',
    },
    state: 'Bihar',
    district: 'Munger',
    city: 'Bariyarpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e141',
    },
    state: 'Bihar',
    district: 'Munger',
    city: 'Jamalpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e142',
    },
    state: 'Bihar',
    district: 'Munger',
    city: 'Kharagpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e143',
    },
    state: 'Bihar',
    district: 'Munger',
    city: 'Sangrampur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e144',
    },
    state: 'Bihar',
    district: 'Munger',
    city: 'Tarapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e145',
    },
    state: 'Bihar',
    district: 'Munger',
    city: 'Munger Sadar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e146',
    },
    state: 'Bihar',
    district: 'Munger',
    city: 'Tetiabambar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e147',
    },
    state: 'Bihar',
    district: 'Muzaffarpur',
    city: 'Aurai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e148',
    },
    state: 'Bihar',
    district: 'Muzaffarpur',
    city: 'Bochahan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e149',
    },
    state: 'Bihar',
    district: 'Muzaffarpur',
    city: 'Bandra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e14a',
    },
    state: 'Bihar',
    district: 'Muzaffarpur',
    city: 'Gaighat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e14b',
    },
    state: 'Bihar',
    district: 'Muzaffarpur',
    city: 'Kanti',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e14c',
    },
    state: 'Bihar',
    district: 'Muzaffarpur',
    city: 'Katra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e14d',
    },
    state: 'Bihar',
    district: 'Muzaffarpur',
    city: 'Kurhani',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e14e',
    },
    state: 'Bihar',
    district: 'Muzaffarpur',
    city: 'Marwan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e14f',
    },
    state: 'Bihar',
    district: 'Muzaffarpur',
    city: 'Minapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e150',
    },
    state: 'Bihar',
    district: 'Muzaffarpur',
    city: 'Muraul',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e151',
    },
    state: 'Bihar',
    district: 'Muzaffarpur',
    city: 'Mushahari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e152',
    },
    state: 'Bihar',
    district: 'Muzaffarpur',
    city: 'Paroo',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e153',
    },
    state: 'Bihar',
    district: 'Muzaffarpur',
    city: 'Motipur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e154',
    },
    state: 'Bihar',
    district: 'Muzaffarpur',
    city: 'Sahebganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e155',
    },
    state: 'Bihar',
    district: 'Muzaffarpur',
    city: 'Sakra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e156',
    },
    state: 'Bihar',
    district: 'Nalanda',
    city: 'Asthawan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e157',
    },
    state: 'Bihar',
    district: 'Nalanda',
    city: 'Ben',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e158',
    },
    state: 'Bihar',
    district: 'Nalanda',
    city: 'Biharsharif',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e159',
    },
    state: 'Bihar',
    district: 'Nalanda',
    city: 'Bind',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e15a',
    },
    state: 'Bihar',
    district: 'Muzaffarpur',
    city: 'Saraiya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e15b',
    },
    state: 'Bihar',
    district: 'Nalanda',
    city: 'Chandi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e15c',
    },
    state: 'Bihar',
    district: 'Nalanda',
    city: 'Ekangarsarai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e15d',
    },
    state: 'Bihar',
    district: 'Nalanda',
    city: 'Harnaut',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e15e',
    },
    state: 'Bihar',
    district: 'Nalanda',
    city: 'Giriak',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e15f',
    },
    state: 'Bihar',
    district: 'Nalanda',
    city: 'Hilsa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e160',
    },
    state: 'Bihar',
    district: 'Nalanda',
    city: 'Islampur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e161',
    },
    state: 'Bihar',
    district: 'Nalanda',
    city: 'Karai Parsurai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e162',
    },
    state: 'Bihar',
    district: 'Nalanda',
    city: 'Katrisarai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e163',
    },
    state: 'Bihar',
    district: 'Nalanda',
    city: 'Noorsarai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e164',
    },
    state: 'Bihar',
    district: 'Nalanda',
    city: 'Parbalpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e165',
    },
    state: 'Bihar',
    district: 'Nalanda',
    city: 'Nagar Nausa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e166',
    },
    state: 'Bihar',
    district: 'Nalanda',
    city: 'Rahui',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e167',
    },
    state: 'Bihar',
    district: 'Nalanda',
    city: 'Rajgir',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e168',
    },
    state: 'Bihar',
    district: 'Nalanda',
    city: 'Silao',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e169',
    },
    state: 'Bihar',
    district: 'Nalanda',
    city: 'Tharthari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e16a',
    },
    state: 'Bihar',
    district: 'Nalanda',
    city: 'Sarmera',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e16b',
    },
    state: 'Bihar',
    district: 'Nawada',
    city: 'Gobindpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e16c',
    },
    state: 'Bihar',
    district: 'Nawada',
    city: 'Hisua',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e16d',
    },
    state: 'Bihar',
    district: 'Nawada',
    city: 'Akbarpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e16e',
    },
    state: 'Bihar',
    district: 'Nawada',
    city: 'Kashichak',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e16f',
    },
    state: 'Bihar',
    district: 'Nawada',
    city: 'Kawakole',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e170',
    },
    state: 'Bihar',
    district: 'Nawada',
    city: 'Mescaur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e171',
    },
    state: 'Bihar',
    district: 'Nawada',
    city: 'Nardiganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e172',
    },
    state: 'Bihar',
    district: 'Nawada',
    city: 'Narhat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e173',
    },
    state: 'Bihar',
    district: 'Nawada',
    city: 'Nawada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e174',
    },
    state: 'Bihar',
    district: 'Nawada',
    city: 'Pakri Barawan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e175',
    },
    state: 'Bihar',
    district: 'Nawada',
    city: 'Rajauli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e176',
    },
    state: 'Bihar',
    district: 'Nawada',
    city: 'Roh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e177',
    },
    state: 'Bihar',
    district: 'Nawada',
    city: 'Sirdala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e178',
    },
    state: 'Bihar',
    district: 'Nawada',
    city: 'Warisaliganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e179',
    },
    state: 'Bihar',
    district: 'Pashchim Champaran',
    city: 'Bagaha-I',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e17a',
    },
    state: 'Bihar',
    district: 'Pashchim Champaran',
    city: 'Bairia',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e17b',
    },
    state: 'Bihar',
    district: 'Pashchim Champaran',
    city: 'Bettiah',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e17c',
    },
    state: 'Bihar',
    district: 'Pashchim Champaran',
    city: 'Bhitaha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e17d',
    },
    state: 'Bihar',
    district: 'Pashchim Champaran',
    city: 'Chanpatia',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e17e',
    },
    state: 'Bihar',
    district: 'Pashchim Champaran',
    city: 'Gaunaha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e17f',
    },
    state: 'Bihar',
    district: 'Pashchim Champaran',
    city: 'Jogapatti',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e180',
    },
    state: 'Bihar',
    district: 'Pashchim Champaran',
    city: 'Lauriya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e181',
    },
    state: 'Bihar',
    district: 'Pashchim Champaran',
    city: 'Madhubani',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e182',
    },
    state: 'Bihar',
    district: 'Pashchim Champaran',
    city: 'Mainatand',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e183',
    },
    state: 'Bihar',
    district: 'Pashchim Champaran',
    city: 'Majhaulia',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e184',
    },
    state: 'Bihar',
    district: 'Pashchim Champaran',
    city: 'Narkatiaganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e185',
    },
    state: 'Bihar',
    district: 'Pashchim Champaran',
    city: 'Nautan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e186',
    },
    state: 'Bihar',
    district: 'Pashchim Champaran',
    city: 'Piprasi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e187',
    },
    state: 'Bihar',
    district: 'Pashchim Champaran',
    city: 'Ramnagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e188',
    },
    state: 'Bihar',
    district: 'Pashchim Champaran',
    city: 'Sikta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e189',
    },
    state: 'Bihar',
    district: 'Pashchim Champaran',
    city: 'Thakrahan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e18a',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Athamalgola',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e18b',
    },
    state: 'Bihar',
    district: 'Pashchim Champaran',
    city: 'Bagaha-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e18c',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Bakhtiarpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e18d',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Barh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e18e',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Belchchi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e18f',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Bihta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e190',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Bikram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e191',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Dhanarua',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e192',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Daniyawan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e193',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Dulhin Bazar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e194',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Dinapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e195',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Fatuha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e196',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Ghoswari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e197',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Khusrupur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e198',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Maner',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e199',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Mokama',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e19a',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Naubatpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e19b',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Paliganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e19c',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Pandarak',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e19d',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Masaurhi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e19e',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Patna Sadar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e19f',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Phulwari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1a0',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Punpun',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1a1',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Adapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1a2',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Areraj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1a3',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Banjariya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1a4',
    },
    state: 'Bihar',
    district: 'Patna',
    city: 'Sampatchak',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1a5',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Bankatwa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1a6',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Chakia (Pipra)',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1a7',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Chiraiya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1a8',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Chawradano',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1a9',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Dhaka',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1aa',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Ghorasahan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1ab',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Harsidhi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1ac',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Kesaria',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1ad',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Kotwa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1ae',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Madhuban',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1af',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Mehsi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1b0',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Motihari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1b1',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Paharpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1b2',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Pakridayal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1b3',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Kalyanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1b4',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Phenhara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1b5',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Patahi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1b6',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Ramgarhwa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1b7',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Pipra Kothi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1b8',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Sangrampur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1b9',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Sugauli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1ba',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Raxaul',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1bb',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Tetariya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1bc',
    },
    state: 'Bihar',
    district: 'Purvi Champaran',
    city: 'Turkaulia',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1bd',
    },
    state: 'Bihar',
    district: 'Purnia',
    city: 'Amour',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1be',
    },
    state: 'Bihar',
    district: 'Purnia',
    city: 'Baisi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1bf',
    },
    state: 'Bihar',
    district: 'Purnia',
    city: 'Banmankhi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1c0',
    },
    state: 'Bihar',
    district: 'Purnia',
    city: 'Barhara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1c1',
    },
    state: 'Bihar',
    district: 'Purnia',
    city: 'Baisa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1c2',
    },
    state: 'Bihar',
    district: 'Purnia',
    city: 'Dagraua',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1c3',
    },
    state: 'Bihar',
    district: 'Purnia',
    city: 'Dhamdaha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1c4',
    },
    state: 'Bihar',
    district: 'Purnia',
    city: 'Bhawanipur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1c5',
    },
    state: 'Bihar',
    district: 'Purnia',
    city: 'Jalalgarh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1c6',
    },
    state: 'Bihar',
    district: 'Purnia',
    city: 'Kasba',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1c7',
    },
    state: 'Bihar',
    district: 'Purnia',
    city: 'Purnia East',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1c8',
    },
    state: 'Bihar',
    district: 'Purnia',
    city: 'Rupouli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1c9',
    },
    state: 'Bihar',
    district: 'Purnia',
    city: 'Srinagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1ca',
    },
    state: 'Bihar',
    district: 'Rohtas',
    city: 'Akorhigola',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1cb',
    },
    state: 'Bihar',
    district: 'Rohtas',
    city: 'Bikramganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1cc',
    },
    state: 'Bihar',
    district: 'Rohtas',
    city: 'Chenari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1cd',
    },
    state: 'Bihar',
    district: 'Rohtas',
    city: 'Dawath',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1ce',
    },
    state: 'Bihar',
    district: 'Rohtas',
    city: 'Dehri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1cf',
    },
    state: 'Bihar',
    district: 'Rohtas',
    city: 'Dinara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1d0',
    },
    state: 'Bihar',
    district: 'Purnia',
    city: 'Krityanand Nagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1d1',
    },
    state: 'Bihar',
    district: 'Rohtas',
    city: 'Kargahar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1d2',
    },
    state: 'Bihar',
    district: 'Rohtas',
    city: 'Karakat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1d3',
    },
    state: 'Bihar',
    district: 'Rohtas',
    city: 'Kochas',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1d4',
    },
    state: 'Bihar',
    district: 'Rohtas',
    city: 'Nasriganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1d5',
    },
    state: 'Bihar',
    district: 'Rohtas',
    city: 'Nokha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1d6',
    },
    state: 'Bihar',
    district: 'Rohtas',
    city: 'Nawhatta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1d7',
    },
    state: 'Bihar',
    district: 'Rohtas',
    city: 'Rohtas',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1d8',
    },
    state: 'Bihar',
    district: 'Rohtas',
    city: 'Rajpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1d9',
    },
    state: 'Bihar',
    district: 'Rohtas',
    city: 'Sasaram',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1da',
    },
    state: 'Bihar',
    district: 'Rohtas',
    city: 'Sheosagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1db',
    },
    state: 'Bihar',
    district: 'Rohtas',
    city: 'Surajpura',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1dc',
    },
    state: 'Bihar',
    district: 'Rohtas',
    city: 'Tilouthu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1dd',
    },
    state: 'Bihar',
    district: 'Saharsa',
    city: 'Banma Itahari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1de',
    },
    state: 'Bihar',
    district: 'Saharsa',
    city: 'Kahara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1df',
    },
    state: 'Bihar',
    district: 'Saharsa',
    city: 'Mahishi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1e0',
    },
    state: 'Bihar',
    district: 'Rohtas',
    city: 'Sanjhouli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1e1',
    },
    state: 'Bihar',
    district: 'Saharsa',
    city: 'Nauhatta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1e2',
    },
    state: 'Bihar',
    district: 'Saharsa',
    city: 'Salkhua',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1e3',
    },
    state: 'Bihar',
    district: 'Saharsa',
    city: 'Sattar Kattaiya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1e4',
    },
    state: 'Bihar',
    district: 'Saharsa',
    city: 'Simri Bakhtiarpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1e5',
    },
    state: 'Bihar',
    district: 'Saharsa',
    city: 'Sonbarsa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1e6',
    },
    state: 'Bihar',
    district: 'Saharsa',
    city: 'Sour Bazar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1e7',
    },
    state: 'Bihar',
    district: 'Samastipur',
    city: 'Bibhutpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1e8',
    },
    state: 'Bihar',
    district: 'Samastipur',
    city: 'Bithan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1e9',
    },
    state: 'Bihar',
    district: 'Samastipur',
    city: 'Dalsinghsarai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1ea',
    },
    state: 'Bihar',
    district: 'Samastipur',
    city: 'Hasanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1eb',
    },
    state: 'Bihar',
    district: 'Samastipur',
    city: 'Kalyanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1ec',
    },
    state: 'Bihar',
    district: 'Samastipur',
    city: 'Khanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1ed',
    },
    state: 'Bihar',
    district: 'Samastipur',
    city: 'Mohanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1ee',
    },
    state: 'Bihar',
    district: 'Saharsa',
    city: 'Patarghat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1ef',
    },
    state: 'Bihar',
    district: 'Samastipur',
    city: 'Mohiuddinagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1f0',
    },
    state: 'Bihar',
    district: 'Samastipur',
    city: 'Morwa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1f1',
    },
    state: 'Bihar',
    district: 'Samastipur',
    city: 'Pusa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1f2',
    },
    state: 'Bihar',
    district: 'Samastipur',
    city: 'Patori',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1f3',
    },
    state: 'Bihar',
    district: 'Samastipur',
    city: 'Rosera',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1f4',
    },
    state: 'Bihar',
    district: 'Samastipur',
    city: 'Samastipur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1f5',
    },
    state: 'Bihar',
    district: 'Samastipur',
    city: 'Sarairanjan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1f6',
    },
    state: 'Bihar',
    district: 'Samastipur',
    city: 'Shivaji Nagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1f7',
    },
    state: 'Bihar',
    district: 'Samastipur',
    city: 'Singhia',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1f8',
    },
    state: 'Bihar',
    district: 'Samastipur',
    city: 'Tajpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1f9',
    },
    state: 'Bihar',
    district: 'Samastipur',
    city: 'Vidyapati Nagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1fa',
    },
    state: 'Bihar',
    district: 'Samastipur',
    city: 'Warisnagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1fb',
    },
    state: 'Bihar',
    district: 'Saran',
    city: 'Amnour',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1fc',
    },
    state: 'Bihar',
    district: 'Saran',
    city: 'Baniapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1fd',
    },
    state: 'Bihar',
    district: 'Saran',
    city: 'Chhapra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1fe',
    },
    state: 'Bihar',
    district: 'Saran',
    city: 'Dariapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e1ff',
    },
    state: 'Bihar',
    district: 'Saran',
    city: 'Dighwara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e200',
    },
    state: 'Bihar',
    district: 'Saran',
    city: 'Ekma',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e201',
    },
    state: 'Bihar',
    district: 'Saran',
    city: 'Garkha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e202',
    },
    state: 'Bihar',
    district: 'Samastipur',
    city: 'Ujiarpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e203',
    },
    state: 'Bihar',
    district: 'Saran',
    city: 'Jalalpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e204',
    },
    state: 'Bihar',
    district: 'Saran',
    city: 'Isuapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e205',
    },
    state: 'Bihar',
    district: 'Saran',
    city: 'Lahladpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e206',
    },
    state: 'Bihar',
    district: 'Saran',
    city: 'Manjhi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e207',
    },
    state: 'Bihar',
    district: 'Saran',
    city: 'Marhaurah',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e208',
    },
    state: 'Bihar',
    district: 'Saran',
    city: 'Mashrakh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e209',
    },
    state: 'Bihar',
    district: 'Saran',
    city: 'Nagra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e20a',
    },
    state: 'Bihar',
    district: 'Saran',
    city: 'Maker',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e20b',
    },
    state: 'Bihar',
    district: 'Saran',
    city: 'Panapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e20c',
    },
    state: 'Bihar',
    district: 'Saran',
    city: 'Parsa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e20d',
    },
    state: 'Bihar',
    district: 'Saran',
    city: 'Revelganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e20e',
    },
    state: 'Bihar',
    district: 'Saran',
    city: 'Sonepur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e20f',
    },
    state: 'Bihar',
    district: 'Saran',
    city: 'Taraiya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e210',
    },
    state: 'Bihar',
    district: 'Sheikhpura',
    city: 'Barbigha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e211',
    },
    state: 'Bihar',
    district: 'Sheikhpura',
    city: 'Ariari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e212',
    },
    state: 'Bihar',
    district: 'Sheikhpura',
    city: 'Chewara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e213',
    },
    state: 'Bihar',
    district: 'Sheikhpura',
    city: 'Ghatkusumbha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e214',
    },
    state: 'Bihar',
    district: 'Sheikhpura',
    city: 'Shekhopur Sarai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e215',
    },
    state: 'Bihar',
    district: 'Sheohar',
    city: 'Dumari Katsari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e216',
    },
    state: 'Bihar',
    district: 'Sheohar',
    city: 'Piprahi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e217',
    },
    state: 'Bihar',
    district: 'Sheohar',
    city: 'Purnahiya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e218',
    },
    state: 'Bihar',
    district: 'Sheikhpura',
    city: 'Sheikhpura',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e219',
    },
    state: 'Bihar',
    district: 'Sheohar',
    city: 'Tariyani',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e21a',
    },
    state: 'Bihar',
    district: 'Sitamarhi',
    city: 'Bairgania',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e21b',
    },
    state: 'Bihar',
    district: 'Sitamarhi',
    city: 'Bajpatti',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e21c',
    },
    state: 'Bihar',
    district: 'Sitamarhi',
    city: 'Bathanaha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e21d',
    },
    state: 'Bihar',
    district: 'Sitamarhi',
    city: 'Belsand',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e21e',
    },
    state: 'Bihar',
    district: 'Sitamarhi',
    city: 'Bokhra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e21f',
    },
    state: 'Bihar',
    district: 'Sitamarhi',
    city: 'Choraut',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e220',
    },
    state: 'Bihar',
    district: 'Sitamarhi',
    city: 'Dumra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e221',
    },
    state: 'Bihar',
    district: 'Sitamarhi',
    city: 'Majorganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e222',
    },
    state: 'Bihar',
    district: 'Sitamarhi',
    city: 'Nanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e223',
    },
    state: 'Bihar',
    district: 'Sitamarhi',
    city: 'Parihar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e224',
    },
    state: 'Bihar',
    district: 'Sitamarhi',
    city: 'Parsauni',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e225',
    },
    state: 'Bihar',
    district: 'Sitamarhi',
    city: 'Pupri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e226',
    },
    state: 'Bihar',
    district: 'Sitamarhi',
    city: 'Riga',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e227',
    },
    state: 'Bihar',
    district: 'Sitamarhi',
    city: 'Runnisaidpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e228',
    },
    state: 'Bihar',
    district: 'Sitamarhi',
    city: 'Sonbarsa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e229',
    },
    state: 'Bihar',
    district: 'Sitamarhi',
    city: 'Suppi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e22a',
    },
    state: 'Bihar',
    district: 'Sheohar',
    city: 'Sheohar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e22b',
    },
    state: 'Bihar',
    district: 'Sitamarhi',
    city: 'Sursand',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e22c',
    },
    state: 'Bihar',
    district: 'Siwan',
    city: 'Andar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e22d',
    },
    state: 'Bihar',
    district: 'Siwan',
    city: 'Barharia',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e22e',
    },
    state: 'Bihar',
    district: 'Siwan',
    city: 'Bhagwanpur Hat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e22f',
    },
    state: 'Bihar',
    district: 'Siwan',
    city: 'Darauli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e230',
    },
    state: 'Bihar',
    district: 'Siwan',
    city: 'Daraundha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e231',
    },
    state: 'Bihar',
    district: 'Siwan',
    city: 'Goriakothi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e232',
    },
    state: 'Bihar',
    district: 'Siwan',
    city: 'Guthani',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e233',
    },
    state: 'Bihar',
    district: 'Siwan',
    city: 'Hasan Pura',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e234',
    },
    state: 'Bihar',
    district: 'Siwan',
    city: 'Hussainganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e235',
    },
    state: 'Bihar',
    district: 'Siwan',
    city: 'Lakri Nabiganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e236',
    },
    state: 'Bihar',
    district: 'Siwan',
    city: 'Maharajganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e237',
    },
    state: 'Bihar',
    district: 'Siwan',
    city: 'Mairwa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e238',
    },
    state: 'Bihar',
    district: 'Siwan',
    city: 'Nautan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e239',
    },
    state: 'Bihar',
    district: 'Siwan',
    city: 'Pachrukhi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e23a',
    },
    state: 'Bihar',
    district: 'Siwan',
    city: 'Raghunathpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e23b',
    },
    state: 'Bihar',
    district: 'Siwan',
    city: 'Siswan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e23c',
    },
    state: 'Bihar',
    district: 'Siwan',
    city: 'Siwan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e23d',
    },
    state: 'Bihar',
    district: 'Siwan',
    city: 'Ziradei',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e23e',
    },
    state: 'Bihar',
    district: 'Supaul',
    city: 'Basantpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e23f',
    },
    state: 'Bihar',
    district: 'Supaul',
    city: 'Chhatapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e240',
    },
    state: 'Bihar',
    district: 'Siwan',
    city: 'Basantpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e241',
    },
    state: 'Bihar',
    district: 'Supaul',
    city: 'Marauna',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e242',
    },
    state: 'Bihar',
    district: 'Supaul',
    city: 'Kishanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e243',
    },
    state: 'Bihar',
    district: 'Supaul',
    city: 'Nirmali',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e244',
    },
    state: 'Bihar',
    district: 'Supaul',
    city: 'Pipra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e245',
    },
    state: 'Bihar',
    district: 'Supaul',
    city: 'Pratapganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e246',
    },
    state: 'Bihar',
    district: 'Supaul',
    city: 'Raghopur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e247',
    },
    state: 'Bihar',
    district: 'Supaul',
    city: 'Saraigarh Bhaptiyahi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e248',
    },
    state: 'Bihar',
    district: 'Supaul',
    city: 'Supaul',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e249',
    },
    state: 'Bihar',
    district: 'Supaul',
    city: 'Tribeniganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e24a',
    },
    state: 'Bihar',
    district: 'Vaishali',
    city: 'Bidupur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e24b',
    },
    state: 'Bihar',
    district: 'Vaishali',
    city: 'Chehrakala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e24c',
    },
    state: 'Bihar',
    district: 'Vaishali',
    city: 'Desri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e24d',
    },
    state: 'Bihar',
    district: 'Vaishali',
    city: 'Garaul',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e24e',
    },
    state: 'Bihar',
    district: 'Vaishali',
    city: 'Hajipur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e24f',
    },
    state: 'Bihar',
    district: 'Vaishali',
    city: 'Jandaha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e250',
    },
    state: 'Bihar',
    district: 'Vaishali',
    city: 'Lalganj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e251',
    },
    state: 'Bihar',
    district: 'Vaishali',
    city: 'Mahnar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e252',
    },
    state: 'Bihar',
    district: 'Vaishali',
    city: 'Mahua',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e253',
    },
    state: 'Bihar',
    district: 'Vaishali',
    city: 'Patedhi Belsar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e254',
    },
    state: 'Bihar',
    district: 'Vaishali',
    city: 'Patepur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e255',
    },
    state: 'Bihar',
    district: 'Vaishali',
    city: 'Raghopur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e256',
    },
    state: 'Bihar',
    district: 'Vaishali',
    city: 'Rajapakar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e257',
    },
    state: 'Bihar',
    district: 'Vaishali',
    city: 'Sahdei Buzurg',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e258',
    },
    state: 'Bihar',
    district: 'Vaishali',
    city: 'Vaishali',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e259',
    },
    state: 'Chandigarh',
    district: 'Chandigarh',
    city: 'Chandigarh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e25a',
    },
    state: 'Bihar',
    district: 'Vaishali',
    city: 'Bhagwanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e25b',
    },
    state: 'Chhattisgarh',
    district: 'Balod',
    city: 'Dondi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e25c',
    },
    state: 'Chhattisgarh',
    district: 'Balod',
    city: 'Dondilohara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e25d',
    },
    state: 'Chhattisgarh',
    district: 'Balod',
    city: 'Gunderdehi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e25e',
    },
    state: 'Chhattisgarh',
    district: 'Balod',
    city: 'Gurur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e25f',
    },
    state: 'Chhattisgarh',
    district: 'Balod',
    city: 'Balod',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e260',
    },
    state: 'Chhattisgarh',
    district: 'Baloda Bazar',
    city: 'Baloda Bazar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e261',
    },
    state: 'Chhattisgarh',
    district: 'Baloda Bazar',
    city: 'Bhatapara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e262',
    },
    state: 'Chhattisgarh',
    district: 'Baloda Bazar',
    city: 'Bilaigarh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e263',
    },
    state: 'Chhattisgarh',
    district: 'Baloda Bazar',
    city: 'Kasdol',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e264',
    },
    state: 'Chhattisgarh',
    district: 'Baloda Bazar',
    city: 'Palari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e265',
    },
    state: 'Chhattisgarh',
    district: 'Balrampur',
    city: 'Balrampur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e266',
    },
    state: 'Chhattisgarh',
    district: 'Baloda Bazar',
    city: 'Simga',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e267',
    },
    state: 'Chhattisgarh',
    district: 'Balrampur',
    city: 'Kusmi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e268',
    },
    state: 'Chhattisgarh',
    district: 'Balrampur',
    city: 'Rajpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e269',
    },
    state: 'Chhattisgarh',
    district: 'Balrampur',
    city: 'Ramchandrapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e26a',
    },
    state: 'Chhattisgarh',
    district: 'Balrampur',
    city: 'Shankargarh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e26b',
    },
    state: 'Chhattisgarh',
    district: 'Balrampur',
    city: 'Wadrafnagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e26c',
    },
    state: 'Chhattisgarh',
    district: 'Bastar',
    city: 'Bakawand',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e26d',
    },
    state: 'Chhattisgarh',
    district: 'Bastar',
    city: 'Bastanar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e26e',
    },
    state: 'Chhattisgarh',
    district: 'Bastar',
    city: 'Bastar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e26f',
    },
    state: 'Chhattisgarh',
    district: 'Bastar',
    city: 'Darbha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e270',
    },
    state: 'Chhattisgarh',
    district: 'Bastar',
    city: 'Jagdalpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e271',
    },
    state: 'Chhattisgarh',
    district: 'Bastar',
    city: 'Lohandiguda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e272',
    },
    state: 'Chhattisgarh',
    district: 'Bastar',
    city: 'Tokapal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e273',
    },
    state: 'Chhattisgarh',
    district: 'Bemetara',
    city: 'Berla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e274',
    },
    state: 'Chhattisgarh',
    district: 'Bemetara',
    city: 'Bemetara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e275',
    },
    state: 'Chhattisgarh',
    district: 'Bemetara',
    city: 'Saja',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e276',
    },
    state: 'Chhattisgarh',
    district: 'Bemetara',
    city: 'Nawagarh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e277',
    },
    state: 'Chhattisgarh',
    district: 'Bijapur',
    city: 'Bhairamgarh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e278',
    },
    state: 'Chhattisgarh',
    district: 'Bijapur',
    city: 'Bhopal Patnam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e279',
    },
    state: 'Chhattisgarh',
    district: 'Bijapur',
    city: 'Bijapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e27a',
    },
    state: 'Chhattisgarh',
    district: 'Bijapur',
    city: 'Usoor',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e27b',
    },
    state: 'Chhattisgarh',
    district: 'Bilaspur',
    city: 'Belha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e27c',
    },
    state: 'Chhattisgarh',
    district: 'Bilaspur',
    city: 'Kota',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e27d',
    },
    state: 'Chhattisgarh',
    district: 'Bilaspur',
    city: 'Masturi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e27e',
    },
    state: 'Chhattisgarh',
    district: 'Bilaspur',
    city: 'Takhatpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e27f',
    },
    state: 'Chhattisgarh',
    district: 'Dantewada',
    city: 'Dantewada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e280',
    },
    state: 'Chhattisgarh',
    district: 'Dantewada',
    city: 'Geedam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e281',
    },
    state: 'Chhattisgarh',
    district: 'Dantewada',
    city: 'Katekalyan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e282',
    },
    state: 'Chhattisgarh',
    district: 'Dantewada',
    city: 'Kuwakonda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e283',
    },
    state: 'Chhattisgarh',
    district: 'Dhamtari',
    city: 'Dhamtari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e284',
    },
    state: 'Chhattisgarh',
    district: 'Dhamtari',
    city: 'Kurud',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e285',
    },
    state: 'Chhattisgarh',
    district: 'Dhamtari',
    city: 'Magarlod',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e286',
    },
    state: 'Chhattisgarh',
    district: 'Dhamtari',
    city: 'Nagari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e287',
    },
    state: 'Chhattisgarh',
    district: 'Durg',
    city: 'Dhamdha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e288',
    },
    state: 'Chhattisgarh',
    district: 'Durg',
    city: 'Durg',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e289',
    },
    state: 'Chhattisgarh',
    district: 'Gariyaband',
    city: 'Chhurra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e28a',
    },
    state: 'Chhattisgarh',
    district: 'Gariyaband',
    city: 'Deobhog',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e28b',
    },
    state: 'Chhattisgarh',
    district: 'Gariyaband',
    city: 'Fingeshwar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e28c',
    },
    state: 'Chhattisgarh',
    district: 'Gariyaband',
    city: 'Gariyaband',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e28d',
    },
    state: 'Chhattisgarh',
    district: 'Gariyaband',
    city: 'Mainpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e28e',
    },
    state: 'Chhattisgarh',
    district: 'Gaurella Pendra Marwahi',
    city: 'Gaurella-1',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e28f',
    },
    state: 'Chhattisgarh',
    district: 'Durg',
    city: 'Patan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e290',
    },
    state: 'Chhattisgarh',
    district: 'Gaurella Pendra Marwahi',
    city: 'Gaurella-2',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e291',
    },
    state: 'Chhattisgarh',
    district: 'Gaurella Pendra Marwahi',
    city: 'Marwahi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e292',
    },
    state: 'Chhattisgarh',
    district: 'Janjgir-Champa',
    city: 'Baloda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e293',
    },
    state: 'Chhattisgarh',
    district: 'Janjgir-Champa',
    city: 'Bamhindih',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e294',
    },
    state: 'Chhattisgarh',
    district: 'Janjgir-Champa',
    city: 'Dabhara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e295',
    },
    state: 'Chhattisgarh',
    district: 'Janjgir-Champa',
    city: 'Akaltara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e296',
    },
    state: 'Chhattisgarh',
    district: 'Janjgir-Champa',
    city: 'Jaijaipur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e297',
    },
    state: 'Chhattisgarh',
    district: 'Janjgir-Champa',
    city: 'Malkharoda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e298',
    },
    state: 'Chhattisgarh',
    district: 'Janjgir-Champa',
    city: 'Nawagarh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e299',
    },
    state: 'Chhattisgarh',
    district: 'Janjgir-Champa',
    city: 'Pamgarh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e29a',
    },
    state: 'Chhattisgarh',
    district: 'Janjgir-Champa',
    city: 'Sakti',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e29b',
    },
    state: 'Chhattisgarh',
    district: 'Jashpur',
    city: 'Bagicha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e29c',
    },
    state: 'Chhattisgarh',
    district: 'Jashpur',
    city: 'Duldula',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e29d',
    },
    state: 'Chhattisgarh',
    district: 'Jashpur',
    city: 'Jashpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e29e',
    },
    state: 'Chhattisgarh',
    district: 'Jashpur',
    city: 'Kansabel',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e29f',
    },
    state: 'Chhattisgarh',
    district: 'Jashpur',
    city: 'Kunkuri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2a0',
    },
    state: 'Chhattisgarh',
    district: 'Jashpur',
    city: 'Manora',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2a1',
    },
    state: 'Chhattisgarh',
    district: 'Jashpur',
    city: 'Pharsabahar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2a2',
    },
    state: 'Chhattisgarh',
    district: 'Kabirdham',
    city: 'Bodla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2a3',
    },
    state: 'Chhattisgarh',
    district: 'Kabirdham',
    city: 'Kawardha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2a4',
    },
    state: 'Chhattisgarh',
    district: 'Kabirdham',
    city: 'Pandariya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2a5',
    },
    state: 'Chhattisgarh',
    district: 'Kabirdham',
    city: 'S.Lohara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2a6',
    },
    state: 'Chhattisgarh',
    district: 'Kanker',
    city: 'Antagarh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2a7',
    },
    state: 'Chhattisgarh',
    district: 'Kanker',
    city: 'Bhanupratappur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2a8',
    },
    state: 'Chhattisgarh',
    district: 'Kanker',
    city: 'Charama',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2a9',
    },
    state: 'Chhattisgarh',
    district: 'Kanker',
    city: 'Durgukondal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2aa',
    },
    state: 'Chhattisgarh',
    district: 'Kanker',
    city: 'Kanker',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2ab',
    },
    state: 'Chhattisgarh',
    district: 'Kanker',
    city: 'Koilebeda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2ac',
    },
    state: 'Chhattisgarh',
    district: 'Kanker',
    city: 'Narharpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2ad',
    },
    state: 'Chhattisgarh',
    district: 'Kondagaon',
    city: 'Baderajpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2ae',
    },
    state: 'Chhattisgarh',
    district: 'Kondagaon',
    city: 'Keshkal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2af',
    },
    state: 'Chhattisgarh',
    district: 'Jashpur',
    city: 'Patthalgaon',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2b0',
    },
    state: 'Chhattisgarh',
    district: 'Kondagaon',
    city: 'Kondagaon',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2b1',
    },
    state: 'Chhattisgarh',
    district: 'Kondagaon',
    city: 'Makdi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2b2',
    },
    state: 'Chhattisgarh',
    district: 'Korba',
    city: 'Kartala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2b3',
    },
    state: 'Chhattisgarh',
    district: 'Korba',
    city: 'Katghora',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2b4',
    },
    state: 'Chhattisgarh',
    district: 'Korba',
    city: 'Korba',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2b5',
    },
    state: 'Chhattisgarh',
    district: 'Korba',
    city: 'Pali',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2b6',
    },
    state: 'Chhattisgarh',
    district: 'Korba',
    city: 'Podi Uparoda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2b7',
    },
    state: 'Chhattisgarh',
    district: 'Kondagaon',
    city: 'Pharasgaon',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2b8',
    },
    state: 'Chhattisgarh',
    district: 'Korea',
    city: 'Baikunthpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2b9',
    },
    state: 'Chhattisgarh',
    district: 'Korea',
    city: 'Bharatpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2ba',
    },
    state: 'Chhattisgarh',
    district: 'Korea',
    city: 'Khadgawana',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2bb',
    },
    state: 'Chhattisgarh',
    district: 'Korea',
    city: 'Sonhat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2bc',
    },
    state: 'Chhattisgarh',
    district: 'Mahasamund',
    city: 'Bagbahara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2bd',
    },
    state: 'Chhattisgarh',
    district: 'Korea',
    city: 'Manendragarh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2be',
    },
    state: 'Chhattisgarh',
    district: 'Mahasamund',
    city: 'Basna',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2bf',
    },
    state: 'Chhattisgarh',
    district: 'Mahasamund',
    city: 'Mahasamund',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2c0',
    },
    state: 'Chhattisgarh',
    district: 'Mahasamund',
    city: 'Saraipali',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2c1',
    },
    state: 'Chhattisgarh',
    district: 'Mungeli',
    city: 'Lormi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2c2',
    },
    state: 'Chhattisgarh',
    district: 'Mungeli',
    city: 'Mungeli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2c3',
    },
    state: 'Chhattisgarh',
    district: 'Mungeli',
    city: 'Pathariya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2c4',
    },
    state: 'Chhattisgarh',
    district: 'Narayanpur',
    city: 'Narayanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2c5',
    },
    state: 'Chhattisgarh',
    district: 'Narayanpur',
    city: 'Orchha(Abhujmad)',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2c6',
    },
    state: 'Chhattisgarh',
    district: 'Raigarh',
    city: 'Baramkela',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2c7',
    },
    state: 'Chhattisgarh',
    district: 'Raigarh',
    city: 'Dharamjaigarh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2c8',
    },
    state: 'Chhattisgarh',
    district: 'Raigarh',
    city: 'Gharghoda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2c9',
    },
    state: 'Chhattisgarh',
    district: 'Raigarh',
    city: 'Kharsia',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2ca',
    },
    state: 'Chhattisgarh',
    district: 'Raigarh',
    city: 'Lailunga',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2cb',
    },
    state: 'Chhattisgarh',
    district: 'Raigarh',
    city: 'Pussore',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2cc',
    },
    state: 'Chhattisgarh',
    district: 'Mahasamund',
    city: 'Pithora',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2cd',
    },
    state: 'Chhattisgarh',
    district: 'Raigarh',
    city: 'Sarangarh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2ce',
    },
    state: 'Chhattisgarh',
    district: 'Raigarh',
    city: 'Tamnar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2cf',
    },
    state: 'Chhattisgarh',
    district: 'Raipur',
    city: 'Abhanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2d0',
    },
    state: 'Chhattisgarh',
    district: 'Raipur',
    city: 'Arang',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2d1',
    },
    state: 'Chhattisgarh',
    district: 'Raigarh',
    city: 'Raigarh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2d2',
    },
    state: 'Chhattisgarh',
    district: 'Raipur',
    city: 'Dharsiwa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2d3',
    },
    state: 'Chhattisgarh',
    district: 'Raipur',
    city: 'Tilda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2d4',
    },
    state: 'Chhattisgarh',
    district: 'Rajnandgaon',
    city: 'A.Chowki (Td)',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2d5',
    },
    state: 'Chhattisgarh',
    district: 'Rajnandgaon',
    city: 'Chhuikhadan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2d6',
    },
    state: 'Chhattisgarh',
    district: 'Rajnandgaon',
    city: 'Chhuriya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2d7',
    },
    state: 'Chhattisgarh',
    district: 'Rajnandgaon',
    city: 'Dongargaon',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2d8',
    },
    state: 'Chhattisgarh',
    district: 'Rajnandgaon',
    city: 'Dongarghar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2d9',
    },
    state: 'Chhattisgarh',
    district: 'Rajnandgaon',
    city: 'Khairagarh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2da',
    },
    state: 'Chhattisgarh',
    district: 'Rajnandgaon',
    city: 'Manpur (Td)',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2db',
    },
    state: 'Chhattisgarh',
    district: 'Rajnandgaon',
    city: 'Mohala (Td)',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2dc',
    },
    state: 'Chhattisgarh',
    district: 'Rajnandgaon',
    city: 'Rajnandgaon',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2dd',
    },
    state: 'Chhattisgarh',
    district: 'Sukma',
    city: 'Chhindgarh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2de',
    },
    state: 'Chhattisgarh',
    district: 'Sukma',
    city: 'Konta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2df',
    },
    state: 'Chhattisgarh',
    district: 'Sukma',
    city: 'Sukma',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2e0',
    },
    state: 'Chhattisgarh',
    district: 'Surajpur',
    city: 'Odagi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2e1',
    },
    state: 'Chhattisgarh',
    district: 'Surajpur',
    city: 'Pratappur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2e2',
    },
    state: 'Chhattisgarh',
    district: 'Surajpur',
    city: 'Premnagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2e3',
    },
    state: 'Chhattisgarh',
    district: 'Surajpur',
    city: 'Ramanujnagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2e4',
    },
    state: 'Chhattisgarh',
    district: 'Surajpur',
    city: 'Surajpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2e5',
    },
    state: 'Chhattisgarh',
    district: 'Surguja',
    city: 'Ambikapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2e6',
    },
    state: 'Chhattisgarh',
    district: 'Surguja',
    city: 'Batauli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2e7',
    },
    state: 'Chhattisgarh',
    district: 'Surguja',
    city: 'Lakhanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2e8',
    },
    state: 'Chhattisgarh',
    district: 'Surguja',
    city: 'Lundra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2e9',
    },
    state: 'Chhattisgarh',
    district: 'Surguja',
    city: 'Mainpat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2ea',
    },
    state: 'Chhattisgarh',
    district: 'Surguja',
    city: 'Sitapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2eb',
    },
    state: 'Chhattisgarh',
    district: 'Surguja',
    city: 'Udaipur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2ec',
    },
    state: 'Goa',
    district: 'North Goa',
    city: 'Bardez',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2ed',
    },
    state: 'Goa',
    district: 'North Goa',
    city: 'Bicholim',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2ee',
    },
    state: 'Goa',
    district: 'North Goa',
    city: 'Pernem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2ef',
    },
    state: 'Goa',
    district: 'North Goa',
    city: 'Satari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2f0',
    },
    state: 'Chhattisgarh',
    district: 'Surajpur',
    city: 'Bhaiyathan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2f1',
    },
    state: 'Goa',
    district: 'South Goa',
    city: 'Canacona',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2f2',
    },
    state: 'Goa',
    district: 'South Goa',
    city: 'Dharbandora',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2f3',
    },
    state: 'Goa',
    district: 'South Goa',
    city: 'Mormugao',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2f4',
    },
    state: 'Goa',
    district: 'South Goa',
    city: 'Ponda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2f5',
    },
    state: 'Goa',
    district: 'South Goa',
    city: 'Quepem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2f6',
    },
    state: 'Goa',
    district: 'South Goa',
    city: 'Salcete',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2f7',
    },
    state: 'Goa',
    district: 'North Goa',
    city: 'Tiswadi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2f8',
    },
    state: 'Goa',
    district: 'South Goa',
    city: 'Sanguem',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2f9',
    },
    state: 'Gujarat',
    district: 'Ahmadabad',
    city: 'Ahmadabad City',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2fa',
    },
    state: 'Gujarat',
    district: 'Ahmadabad',
    city: 'Bavla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2fb',
    },
    state: 'Gujarat',
    district: 'Ahmadabad',
    city: 'Detroj Rampura',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2fc',
    },
    state: 'Gujarat',
    district: 'Ahmadabad',
    city: 'Dhandhuka',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2fd',
    },
    state: 'Gujarat',
    district: 'Ahmadabad',
    city: 'Dholera',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2fe',
    },
    state: 'Gujarat',
    district: 'Ahmadabad',
    city: 'Daskroi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e2ff',
    },
    state: 'Gujarat',
    district: 'Ahmadabad',
    city: 'Dholka',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e300',
    },
    state: 'Gujarat',
    district: 'Ahmadabad',
    city: 'Mandal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e301',
    },
    state: 'Gujarat',
    district: 'Ahmadabad',
    city: 'Sanand',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e302',
    },
    state: 'Gujarat',
    district: 'Ahmadabad',
    city: 'Viramgam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e303',
    },
    state: 'Gujarat',
    district: 'Amreli',
    city: 'Babra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e304',
    },
    state: 'Gujarat',
    district: 'Amreli',
    city: 'Bagasara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e305',
    },
    state: 'Gujarat',
    district: 'Amreli',
    city: 'Dhari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e306',
    },
    state: 'Gujarat',
    district: 'Amreli',
    city: 'Amreli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e307',
    },
    state: 'Gujarat',
    district: 'Amreli',
    city: 'Jafrabad',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e308',
    },
    state: 'Gujarat',
    district: 'Amreli',
    city: 'Kunkavav -Vadia',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e309',
    },
    state: 'Gujarat',
    district: 'Amreli',
    city: 'Lathi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e30a',
    },
    state: 'Gujarat',
    district: 'Amreli',
    city: 'Lilia',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e30b',
    },
    state: 'Gujarat',
    district: 'Amreli',
    city: 'Rajula',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e30c',
    },
    state: 'Gujarat',
    district: 'Amreli',
    city: 'Saverkundla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e30d',
    },
    state: 'Gujarat',
    district: 'Anand',
    city: 'Anand',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e30e',
    },
    state: 'Gujarat',
    district: 'Anand',
    city: 'Anklav',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e30f',
    },
    state: 'Gujarat',
    district: 'Anand',
    city: 'Borsad',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e310',
    },
    state: 'Gujarat',
    district: 'Anand',
    city: 'Khambhat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e311',
    },
    state: 'Gujarat',
    district: 'Anand',
    city: 'Petlad',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e312',
    },
    state: 'Gujarat',
    district: 'Amreli',
    city: 'Khambha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e313',
    },
    state: 'Gujarat',
    district: 'Anand',
    city: 'Tarapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e314',
    },
    state: 'Gujarat',
    district: 'Anand',
    city: 'Sojitra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e315',
    },
    state: 'Gujarat',
    district: 'Arvalli',
    city: 'Bayad',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e316',
    },
    state: 'Gujarat',
    district: 'Anand',
    city: 'Umreth',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e317',
    },
    state: 'Gujarat',
    district: 'Arvalli',
    city: 'Bhiloda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e318',
    },
    state: 'Gujarat',
    district: 'Arvalli',
    city: 'Dhansura',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e319',
    },
    state: 'Gujarat',
    district: 'Arvalli',
    city: 'Malpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e31a',
    },
    state: 'Gujarat',
    district: 'Arvalli',
    city: 'Meghraj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e31b',
    },
    state: 'Gujarat',
    district: 'Arvalli',
    city: 'Modasa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e31c',
    },
    state: 'Gujarat',
    district: 'Banas Kantha',
    city: 'Amirgadh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e31d',
    },
    state: 'Gujarat',
    district: 'Banas Kantha',
    city: 'Bhabhar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e31e',
    },
    state: 'Gujarat',
    district: 'Banas Kantha',
    city: 'Danta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e31f',
    },
    state: 'Gujarat',
    district: 'Banas Kantha',
    city: 'Dantivada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e320',
    },
    state: 'Gujarat',
    district: 'Banas Kantha',
    city: 'Deesa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e321',
    },
    state: 'Gujarat',
    district: 'Banas Kantha',
    city: 'Dhanera',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e322',
    },
    state: 'Gujarat',
    district: 'Banas Kantha',
    city: 'Kankrej',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e323',
    },
    state: 'Gujarat',
    district: 'Banas Kantha',
    city: 'Deodar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e324',
    },
    state: 'Gujarat',
    district: 'Banas Kantha',
    city: 'Palanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e325',
    },
    state: 'Gujarat',
    district: 'Banas Kantha',
    city: 'Suigam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e326',
    },
    state: 'Gujarat',
    district: 'Banas Kantha',
    city: 'Tharad',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e327',
    },
    state: 'Gujarat',
    district: 'Banas Kantha',
    city: 'Vadgam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e328',
    },
    state: 'Gujarat',
    district: 'Banas Kantha',
    city: 'Vav',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e329',
    },
    state: 'Gujarat',
    district: 'Bharuch',
    city: 'Amod',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e32a',
    },
    state: 'Gujarat',
    district: 'Bharuch',
    city: 'Anklesvar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e32b',
    },
    state: 'Gujarat',
    district: 'Bharuch',
    city: 'Bharuch',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e32c',
    },
    state: 'Gujarat',
    district: 'Bharuch',
    city: 'Hansot',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e32d',
    },
    state: 'Gujarat',
    district: 'Bharuch',
    city: 'Jambusar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e32e',
    },
    state: 'Gujarat',
    district: 'Bharuch',
    city: 'Jhagadia',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e32f',
    },
    state: 'Gujarat',
    district: 'Banas Kantha',
    city: 'Lakhani',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e330',
    },
    state: 'Gujarat',
    district: 'Bharuch',
    city: 'Netrang',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e331',
    },
    state: 'Gujarat',
    district: 'Bharuch',
    city: 'Vagra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e332',
    },
    state: 'Gujarat',
    district: 'Bharuch',
    city: 'Valia',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e333',
    },
    state: 'Gujarat',
    district: 'Bhavnagar',
    city: 'Gariadhar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e334',
    },
    state: 'Gujarat',
    district: 'Bhavnagar',
    city: 'Ghogha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e335',
    },
    state: 'Gujarat',
    district: 'Bhavnagar',
    city: 'Jesar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e336',
    },
    state: 'Gujarat',
    district: 'Bhavnagar',
    city: 'Mahuva',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e337',
    },
    state: 'Gujarat',
    district: 'Bhavnagar',
    city: 'Bhavnagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e338',
    },
    state: 'Gujarat',
    district: 'Bhavnagar',
    city: 'Palitana',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e339',
    },
    state: 'Gujarat',
    district: 'Bhavnagar',
    city: 'Talaja',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e33a',
    },
    state: 'Gujarat',
    district: 'Bhavnagar',
    city: 'Sihor',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e33b',
    },
    state: 'Gujarat',
    district: 'Bhavnagar',
    city: 'Umrala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e33c',
    },
    state: 'Gujarat',
    district: 'Botad',
    city: 'Barvala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e33d',
    },
    state: 'Gujarat',
    district: 'Botad',
    city: 'Botad',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e33e',
    },
    state: 'Gujarat',
    district: 'Bhavnagar',
    city: 'Vallabhipur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e33f',
    },
    state: 'Gujarat',
    district: 'Botad',
    city: 'Gadhada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e340',
    },
    state: 'Gujarat',
    district: 'Botad',
    city: 'Ranpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e341',
    },
    state: 'Gujarat',
    district: 'Chhotaudepur',
    city: 'Chhota Udepur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e342',
    },
    state: 'Gujarat',
    district: 'Chhotaudepur',
    city: 'Jetpur Pavi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e343',
    },
    state: 'Gujarat',
    district: 'Chhotaudepur',
    city: 'Kawant',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e344',
    },
    state: 'Gujarat',
    district: 'Chhotaudepur',
    city: 'Bodeli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e345',
    },
    state: 'Gujarat',
    district: 'Chhotaudepur',
    city: 'Naswadi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e346',
    },
    state: 'Gujarat',
    district: 'Chhotaudepur',
    city: 'Sankheda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e347',
    },
    state: 'Gujarat',
    district: 'Dang',
    city: 'Ahwa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e348',
    },
    state: 'Gujarat',
    district: 'Dang',
    city: 'Subir',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e349',
    },
    state: 'Gujarat',
    district: 'Dang',
    city: 'Waghai',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e34a',
    },
    state: 'Gujarat',
    district: 'Devbhumi Dwarka',
    city: 'Bhanvad',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e34b',
    },
    state: 'Gujarat',
    district: 'Devbhumi Dwarka',
    city: 'Kalyanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e34c',
    },
    state: 'Gujarat',
    district: 'Devbhumi Dwarka',
    city: 'Okhamandal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e34d',
    },
    state: 'Gujarat',
    district: 'Dohad',
    city: 'Dahod',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e34e',
    },
    state: 'Gujarat',
    district: 'Dohad',
    city: 'Devgad Bariya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e34f',
    },
    state: 'Gujarat',
    district: 'Dohad',
    city: 'Dhanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e350',
    },
    state: 'Gujarat',
    district: 'Dohad',
    city: 'Fatepura',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e351',
    },
    state: 'Gujarat',
    district: 'Dohad',
    city: 'Garbada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e352',
    },
    state: 'Gujarat',
    district: 'Dohad',
    city: 'Jhalod',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e353',
    },
    state: 'Gujarat',
    district: 'Dohad',
    city: 'Limkheda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e354',
    },
    state: 'Gujarat',
    district: 'Devbhumi Dwarka',
    city: 'Khambhalia',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e355',
    },
    state: 'Gujarat',
    district: 'Dohad',
    city: 'Singvad',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e356',
    },
    state: 'Gujarat',
    district: 'Dohad',
    city: 'Sanjeli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e357',
    },
    state: 'Gujarat',
    district: 'Gandhinagar',
    city: 'Dehgam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e358',
    },
    state: 'Gujarat',
    district: 'Gandhinagar',
    city: 'Gandhinagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e359',
    },
    state: 'Gujarat',
    district: 'Gandhinagar',
    city: 'Kalol',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e35a',
    },
    state: 'Gujarat',
    district: 'Gandhinagar',
    city: 'Mansa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e35b',
    },
    state: 'Gujarat',
    district: 'Gir Somnath',
    city: 'Kodinar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e35c',
    },
    state: 'Gujarat',
    district: 'Gir Somnath',
    city: 'Girgadhda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e35d',
    },
    state: 'Gujarat',
    district: 'Gir Somnath',
    city: 'Patan Veraval',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e35e',
    },
    state: 'Gujarat',
    district: 'Gir Somnath',
    city: 'Sutrapada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e35f',
    },
    state: 'Gujarat',
    district: 'Gir Somnath',
    city: 'Talala',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e360',
    },
    state: 'Gujarat',
    district: 'Gir Somnath',
    city: 'Una',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e361',
    },
    state: 'Gujarat',
    district: 'Jamnagar',
    city: 'Dhrol',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e362',
    },
    state: 'Gujarat',
    district: 'Jamnagar',
    city: 'Jamjodhpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e363',
    },
    state: 'Gujarat',
    district: 'Jamnagar',
    city: 'Jamnagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e364',
    },
    state: 'Gujarat',
    district: 'Jamnagar',
    city: 'Jodiya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e365',
    },
    state: 'Gujarat',
    district: 'Jamnagar',
    city: 'Kalavad',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e366',
    },
    state: 'Gujarat',
    district: 'Jamnagar',
    city: 'Lalpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e367',
    },
    state: 'Gujarat',
    district: 'Junagadh',
    city: 'Bhesan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e368',
    },
    state: 'Gujarat',
    district: 'Junagadh',
    city: 'Junagadh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e369',
    },
    state: 'Gujarat',
    district: 'Junagadh',
    city: 'Keshod',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e36a',
    },
    state: 'Gujarat',
    district: 'Junagadh',
    city: 'Malia',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e36b',
    },
    state: 'Gujarat',
    district: 'Junagadh',
    city: 'Mangrol',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e36c',
    },
    state: 'Gujarat',
    district: 'Junagadh',
    city: 'Mendarda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e36d',
    },
    state: 'Gujarat',
    district: 'Junagadh',
    city: 'Vanthali',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e36e',
    },
    state: 'Gujarat',
    district: 'Junagadh',
    city: 'Visavadar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e36f',
    },
    state: 'Gujarat',
    district: 'Junagadh',
    city: 'Manavadar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e370',
    },
    state: 'Gujarat',
    district: 'Kachchh',
    city: 'Anjar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e371',
    },
    state: 'Gujarat',
    district: 'Kachchh',
    city: 'Abdasa',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e372',
    },
    state: 'Gujarat',
    district: 'Kachchh',
    city: 'Bhachau',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e373',
    },
    state: 'Gujarat',
    district: 'Kachchh',
    city: 'Bhuj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e374',
    },
    state: 'Gujarat',
    district: 'Kachchh',
    city: 'Lakhpat',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e375',
    },
    state: 'Gujarat',
    district: 'Kachchh',
    city: 'Gandhidham',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e376',
    },
    state: 'Gujarat',
    district: 'Kachchh',
    city: 'Mandvi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e377',
    },
    state: 'Gujarat',
    district: 'Kachchh',
    city: 'Mundra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e378',
    },
    state: 'Gujarat',
    district: 'Kachchh',
    city: 'Nakhatrana',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e379',
    },
    state: 'Gujarat',
    district: 'Kachchh',
    city: 'Rapar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e37a',
    },
    state: 'Gujarat',
    district: 'Kheda',
    city: 'Galteshwar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e37b',
    },
    state: 'Gujarat',
    district: 'Kheda',
    city: 'Kapadvanj',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e37c',
    },
    state: 'Gujarat',
    district: 'Kheda',
    city: 'Kathlal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e37d',
    },
    state: 'Gujarat',
    district: 'Kheda',
    city: 'Kheda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e37e',
    },
    state: 'Gujarat',
    district: 'Kheda',
    city: 'Matar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e37f',
    },
    state: 'Gujarat',
    district: 'Kheda',
    city: 'Mehmedabad',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e380',
    },
    state: 'Gujarat',
    district: 'Kheda',
    city: 'Nadiad',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e381',
    },
    state: 'Gujarat',
    district: 'Kheda',
    city: 'Thasra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e382',
    },
    state: 'Gujarat',
    district: 'Kheda',
    city: 'Vaso',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e383',
    },
    state: 'Gujarat',
    district: 'Mahesana',
    city: 'Bechraji',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e384',
    },
    state: 'Gujarat',
    district: 'Mahesana',
    city: 'Jotana',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e385',
    },
    state: 'Gujarat',
    district: 'Mahesana',
    city: 'Kadi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e386',
    },
    state: 'Gujarat',
    district: 'Mahesana',
    city: 'Kheralu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e387',
    },
    state: 'Gujarat',
    district: 'Mahesana',
    city: 'Mahesana',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e388',
    },
    state: 'Gujarat',
    district: 'Kheda',
    city: 'Mahudha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e389',
    },
    state: 'Gujarat',
    district: 'Mahesana',
    city: 'Satlasna',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e38a',
    },
    state: 'Gujarat',
    district: 'Mahesana',
    city: 'Unjha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e38b',
    },
    state: 'Gujarat',
    district: 'Mahesana',
    city: 'Vijapur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e38c',
    },
    state: 'Gujarat',
    district: 'Mahesana',
    city: 'Vadnagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e38d',
    },
    state: 'Gujarat',
    district: 'Mahesana',
    city: 'Visnagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e38e',
    },
    state: 'Gujarat',
    district: 'Mahisagar',
    city: 'Kadana',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e38f',
    },
    state: 'Gujarat',
    district: 'Mahisagar',
    city: 'Balasinor',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e390',
    },
    state: 'Gujarat',
    district: 'Mahisagar',
    city: 'Khanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e391',
    },
    state: 'Gujarat',
    district: 'Mahisagar',
    city: 'Lunawada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e392',
    },
    state: 'Gujarat',
    district: 'Mahisagar',
    city: 'Santrampur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e393',
    },
    state: 'Gujarat',
    district: 'Mahisagar',
    city: 'Virpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e394',
    },
    state: 'Gujarat',
    district: 'Morbi',
    city: 'Maliya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e395',
    },
    state: 'Gujarat',
    district: 'Morbi',
    city: 'Morvi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e396',
    },
    state: 'Gujarat',
    district: 'Morbi',
    city: 'Tankara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e397',
    },
    state: 'Gujarat',
    district: 'Morbi',
    city: 'Wankaner',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e398',
    },
    state: 'Gujarat',
    district: 'Narmada',
    city: 'Dediyapada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e399',
    },
    state: 'Gujarat',
    district: 'Narmada',
    city: 'Garudeshwar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e39a',
    },
    state: 'Gujarat',
    district: 'Narmada',
    city: 'Nandod',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e39b',
    },
    state: 'Gujarat',
    district: 'Narmada',
    city: 'Sagbara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e39c',
    },
    state: 'Gujarat',
    district: 'Narmada',
    city: 'Tilakwada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e39d',
    },
    state: 'Gujarat',
    district: 'Navsari',
    city: 'Chikhali',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e39e',
    },
    state: 'Gujarat',
    district: 'Navsari',
    city: 'Gandevi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e39f',
    },
    state: 'Gujarat',
    district: 'Navsari',
    city: 'Jalalpore',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3a0',
    },
    state: 'Gujarat',
    district: 'Navsari',
    city: 'Khergam',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3a1',
    },
    state: 'Gujarat',
    district: 'Navsari',
    city: 'Navsari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3a2',
    },
    state: 'Gujarat',
    district: 'Navsari',
    city: 'Vansda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3a3',
    },
    state: 'Gujarat',
    district: 'Panch Mahals',
    city: 'Ghoghamba',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3a4',
    },
    state: 'Gujarat',
    district: 'Morbi',
    city: 'Halvad',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3a5',
    },
    state: 'Gujarat',
    district: 'Panch Mahals',
    city: 'Godhra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3a6',
    },
    state: 'Gujarat',
    district: 'Panch Mahals',
    city: 'Halol',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3a7',
    },
    state: 'Gujarat',
    district: 'Panch Mahals',
    city: 'Jambughoda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3a8',
    },
    state: 'Gujarat',
    district: 'Panch Mahals',
    city: 'Morvahadaf',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3a9',
    },
    state: 'Gujarat',
    district: 'Panch Mahals',
    city: 'Shehera',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3aa',
    },
    state: 'Gujarat',
    district: 'Patan',
    city: 'Chanasma',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3ab',
    },
    state: 'Gujarat',
    district: 'Patan',
    city: 'Harij',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3ac',
    },
    state: 'Gujarat',
    district: 'Panch Mahals',
    city: 'Kalol',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3ad',
    },
    state: 'Gujarat',
    district: 'Patan',
    city: 'Patan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3ae',
    },
    state: 'Gujarat',
    district: 'Patan',
    city: 'Sami',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3af',
    },
    state: 'Gujarat',
    district: 'Patan',
    city: 'Radhanpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3b0',
    },
    state: 'Gujarat',
    district: 'Patan',
    city: 'Sankheshwar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3b1',
    },
    state: 'Gujarat',
    district: 'Patan',
    city: 'Santalpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3b2',
    },
    state: 'Gujarat',
    district: 'Patan',
    city: 'Sidhpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3b3',
    },
    state: 'Gujarat',
    district: 'Porbandar',
    city: 'Kutiyana',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3b4',
    },
    state: 'Gujarat',
    district: 'Porbandar',
    city: 'Porbandar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3b5',
    },
    state: 'Gujarat',
    district: 'Patan',
    city: 'Saraswati',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3b6',
    },
    state: 'Gujarat',
    district: 'Porbandar',
    city: 'Ranavav',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3b7',
    },
    state: 'Gujarat',
    district: 'Rajkot',
    city: 'Dhoraji',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3b8',
    },
    state: 'Gujarat',
    district: 'Rajkot',
    city: 'Gondal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3b9',
    },
    state: 'Gujarat',
    district: 'Rajkot',
    city: 'Jasdan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3ba',
    },
    state: 'Gujarat',
    district: 'Rajkot',
    city: 'Jetpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3bb',
    },
    state: 'Gujarat',
    district: 'Rajkot',
    city: 'Kotda Sangani',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3bc',
    },
    state: 'Gujarat',
    district: 'Rajkot',
    city: 'Lodhika',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3bd',
    },
    state: 'Gujarat',
    district: 'Rajkot',
    city: 'Jamkandorna',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3be',
    },
    state: 'Gujarat',
    district: 'Rajkot',
    city: 'Paddhari',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3bf',
    },
    state: 'Gujarat',
    district: 'Rajkot',
    city: 'Rajkot',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3c0',
    },
    state: 'Gujarat',
    district: 'Rajkot',
    city: 'Upleta',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3c1',
    },
    state: 'Gujarat',
    district: 'Rajkot',
    city: 'Vinchhiya',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3c2',
    },
    state: 'Gujarat',
    district: 'Sabar Kantha',
    city: 'Himatnagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3c3',
    },
    state: 'Gujarat',
    district: 'Sabar Kantha',
    city: 'Idar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3c4',
    },
    state: 'Gujarat',
    district: 'Sabar Kantha',
    city: 'Khedbrahma',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3c5',
    },
    state: 'Gujarat',
    district: 'Sabar Kantha',
    city: 'Poshina',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3c6',
    },
    state: 'Gujarat',
    district: 'Sabar Kantha',
    city: 'Prantij',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3c7',
    },
    state: 'Gujarat',
    district: 'Sabar Kantha',
    city: 'Talod',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3c8',
    },
    state: 'Gujarat',
    district: 'Sabar Kantha',
    city: 'Vadali',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3c9',
    },
    state: 'Gujarat',
    district: 'Surat',
    city: 'Bardoli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3ca',
    },
    state: 'Gujarat',
    district: 'Surat',
    city: 'Chorasi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3cb',
    },
    state: 'Gujarat',
    district: 'Sabar Kantha',
    city: 'Vijaynagar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3cc',
    },
    state: 'Gujarat',
    district: 'Surat',
    city: 'Mahuva',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3cd',
    },
    state: 'Gujarat',
    district: 'Surat',
    city: 'Mandvi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3ce',
    },
    state: 'Gujarat',
    district: 'Surat',
    city: 'Mangrol',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3cf',
    },
    state: 'Gujarat',
    district: 'Surat',
    city: 'Olpad',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3d0',
    },
    state: 'Gujarat',
    district: 'Surat',
    city: 'Palsana',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3d1',
    },
    state: 'Gujarat',
    district: 'Surat',
    city: 'Suratcity',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3d2',
    },
    state: 'Gujarat',
    district: 'Surat',
    city: 'Umarpada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3d3',
    },
    state: 'Gujarat',
    district: 'Surendranagar',
    city: 'Chotila',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3d4',
    },
    state: 'Gujarat',
    district: 'Surendranagar',
    city: 'Chuda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3d5',
    },
    state: 'Gujarat',
    district: 'Surendranagar',
    city: 'Dasada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3d6',
    },
    state: 'Gujarat',
    district: 'Surendranagar',
    city: 'Dhrangadhra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3d7',
    },
    state: 'Gujarat',
    district: 'Surendranagar',
    city: 'Lakhtar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3d8',
    },
    state: 'Gujarat',
    district: 'Surat',
    city: 'Kamrej',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3d9',
    },
    state: 'Gujarat',
    district: 'Surendranagar',
    city: 'Limbdi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3da',
    },
    state: 'Gujarat',
    district: 'Surendranagar',
    city: 'Muli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3db',
    },
    state: 'Gujarat',
    district: 'Surendranagar',
    city: 'Sayla',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3dc',
    },
    state: 'Gujarat',
    district: 'Surendranagar',
    city: 'Thangadh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3dd',
    },
    state: 'Gujarat',
    district: 'Tapi',
    city: 'Dolvan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3de',
    },
    state: 'Gujarat',
    district: 'Tapi',
    city: 'Kukarmunda',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3df',
    },
    state: 'Gujarat',
    district: 'Tapi',
    city: 'Nizar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3e0',
    },
    state: 'Gujarat',
    district: 'Tapi',
    city: 'Songadh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3e1',
    },
    state: 'Gujarat',
    district: 'Surendranagar',
    city: 'Wadhwan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3e2',
    },
    state: 'Gujarat',
    district: 'Tapi',
    city: 'Uchchhal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3e3',
    },
    state: 'Gujarat',
    district: 'Tapi',
    city: 'Valod',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3e4',
    },
    state: 'Gujarat',
    district: 'Tapi',
    city: 'Vyara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3e5',
    },
    state: 'Gujarat',
    district: 'Vadodara',
    city: 'Dabhoi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3e6',
    },
    state: 'Gujarat',
    district: 'Vadodara',
    city: 'Karjan',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3e7',
    },
    state: 'Gujarat',
    district: 'Vadodara',
    city: 'Padra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3e8',
    },
    state: 'Gujarat',
    district: 'Vadodara',
    city: 'Savli',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3e9',
    },
    state: 'Gujarat',
    district: 'Vadodara',
    city: 'Shinor',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3ea',
    },
    state: 'Gujarat',
    district: 'Vadodara',
    city: 'Desar',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3eb',
    },
    state: 'Gujarat',
    district: 'Vadodara',
    city: 'Vadodara(City And Rural)',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3ec',
    },
    state: 'Gujarat',
    district: 'Vadodara',
    city: 'Waghodia',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3ed',
    },
    state: 'Gujarat',
    district: 'Valsad',
    city: 'Dharampur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3ee',
    },
    state: 'Gujarat',
    district: 'Valsad',
    city: 'Kaprada',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3ef',
    },
    state: 'Gujarat',
    district: 'Valsad',
    city: 'Pardi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3f0',
    },
    state: 'Gujarat',
    district: 'Valsad',
    city: 'Umbergaon',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3f1',
    },
    state: 'Gujarat',
    district: 'Valsad',
    city: 'Valsad',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3f2',
    },
    state: 'Gujarat',
    district: 'Valsad',
    city: 'Vapi',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3f3',
    },
    state: 'Haryana',
    district: 'Ambala',
    city: 'Ambala-I',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3f4',
    },
    state: 'Haryana',
    district: 'Ambala',
    city: 'Ambala-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3f5',
    },
    state: 'Haryana',
    district: 'Ambala',
    city: 'Barara',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3f6',
    },
    state: 'Haryana',
    district: 'Ambala',
    city: 'Naraingarh',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3f7',
    },
    state: 'Haryana',
    district: 'Ambala',
    city: 'Saha',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3f8',
    },
    state: 'Haryana',
    district: 'Ambala',
    city: 'Shahzadpur',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3f9',
    },
    state: 'Haryana',
    district: 'Bhiwani',
    city: 'Behal',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3fa',
    },
    state: 'Haryana',
    district: 'Bhiwani',
    city: 'Bhiwani',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3fb',
    },
    state: 'Haryana',
    district: 'Bhiwani',
    city: 'Kairu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3fc',
    },
    state: 'Haryana',
    district: 'Bhiwani',
    city: 'Loharu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3fd',
    },
    state: 'Haryana',
    district: 'Bhiwani',
    city: 'Siwani',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3fe',
    },
    state: 'Haryana',
    district: 'Bhiwani',
    city: 'Tosham',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e3ff',
    },
    state: 'Haryana',
    district: 'Charki Dadri',
    city: 'Badhra',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e400',
    },
    state: 'Haryana',
    district: 'Charki Dadri',
    city: 'Baund',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e401',
    },
    state: 'Haryana',
    district: 'Charki Dadri',
    city: 'Charkhi Dadri',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e402',
    },
    state: 'Haryana',
    district: 'Charki Dadri',
    city: 'Jhojhu',
  },
  {
    _id: {
      $oid: '60ab6e9486ed1c1a3559e403',
    },
    state: 'Haryana',
    district: 'Faridabad',
    city: 'Ballabgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e405',
    },
    state: 'Haryana',
    district: 'Bhiwani',
    city: 'Bawani Khera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e406',
    },
    state: 'Haryana',
    district: 'Faridabad',
    city: 'Faridabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e407',
    },
    state: 'Haryana',
    district: 'Faridabad',
    city: 'Tigaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e408',
    },
    state: 'Haryana',
    district: 'Fatehabad',
    city: 'Bhattu Kalan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e409',
    },
    state: 'Haryana',
    district: 'Fatehabad',
    city: 'Bhuna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e40a',
    },
    state: 'Haryana',
    district: 'Fatehabad',
    city: 'Jakhal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e40b',
    },
    state: 'Haryana',
    district: 'Fatehabad',
    city: 'Nagpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e40c',
    },
    state: 'Haryana',
    district: 'Fatehabad',
    city: 'Ratia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e40d',
    },
    state: 'Haryana',
    district: 'Fatehabad',
    city: 'Tohana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e40e',
    },
    state: 'Haryana',
    district: 'Gurugram',
    city: 'Farrukh Nagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e40f',
    },
    state: 'Haryana',
    district: 'Gurugram',
    city: 'Gurgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e410',
    },
    state: 'Haryana',
    district: 'Gurugram',
    city: 'Pataudi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e411',
    },
    state: 'Haryana',
    district: 'Gurugram',
    city: 'Sohna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e412',
    },
    state: 'Haryana',
    district: 'Hisar',
    city: 'Adampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e413',
    },
    state: 'Haryana',
    district: 'Hisar',
    city: 'Agroha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e414',
    },
    state: 'Haryana',
    district: 'Hisar',
    city: 'Barwala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e415',
    },
    state: 'Haryana',
    district: 'Hisar',
    city: 'Hansi-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e416',
    },
    state: 'Haryana',
    district: 'Hisar',
    city: 'Hansi-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e417',
    },
    state: 'Haryana',
    district: 'Fatehabad',
    city: 'Fatehabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e418',
    },
    state: 'Haryana',
    district: 'Hisar',
    city: 'Hisar-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e419',
    },
    state: 'Haryana',
    district: 'Hisar',
    city: 'Hisar-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e41a',
    },
    state: 'Haryana',
    district: 'Hisar',
    city: 'Narnaund',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e41b',
    },
    state: 'Haryana',
    district: 'Jhajjar',
    city: 'Badli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e41c',
    },
    state: 'Haryana',
    district: 'Jhajjar',
    city: 'Bahadurgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e41d',
    },
    state: 'Haryana',
    district: 'Jhajjar',
    city: 'Beri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e41e',
    },
    state: 'Haryana',
    district: 'Jhajjar',
    city: 'Jhajjar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e41f',
    },
    state: 'Haryana',
    district: 'Jhajjar',
    city: 'Machhrauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e420',
    },
    state: 'Haryana',
    district: 'Jhajjar',
    city: 'Matannail',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e421',
    },
    state: 'Haryana',
    district: 'Jhajjar',
    city: 'Salhawas',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e422',
    },
    state: 'Haryana',
    district: 'Jind',
    city: 'Alewa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e423',
    },
    state: 'Haryana',
    district: 'Jind',
    city: 'Jind',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e424',
    },
    state: 'Haryana',
    district: 'Jind',
    city: 'Julana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e425',
    },
    state: 'Haryana',
    district: 'Jind',
    city: 'Narwana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e426',
    },
    state: 'Haryana',
    district: 'Jind',
    city: 'Pillukhera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e427',
    },
    state: 'Haryana',
    district: 'Jind',
    city: 'Safidon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e428',
    },
    state: 'Haryana',
    district: 'Jind',
    city: 'Uchana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e429',
    },
    state: 'Haryana',
    district: 'Jind',
    city: 'Ujhana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e42a',
    },
    state: 'Haryana',
    district: 'Kaithal',
    city: 'Dhand',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e42b',
    },
    state: 'Haryana',
    district: 'Kaithal',
    city: 'Guhla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e42c',
    },
    state: 'Haryana',
    district: 'Kaithal',
    city: 'Kaithal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e42d',
    },
    state: 'Haryana',
    district: 'Hisar',
    city: 'Uklana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e42e',
    },
    state: 'Haryana',
    district: 'Kaithal',
    city: 'Kalayat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e42f',
    },
    state: 'Haryana',
    district: 'Kaithal',
    city: 'Pundri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e430',
    },
    state: 'Haryana',
    district: 'Kaithal',
    city: 'Rajound',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e431',
    },
    state: 'Haryana',
    district: 'Karnal',
    city: 'Assandh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e432',
    },
    state: 'Haryana',
    district: 'Karnal',
    city: 'Gharaunda (Part)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e433',
    },
    state: 'Haryana',
    district: 'Karnal',
    city: 'Indri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e434',
    },
    state: 'Haryana',
    district: 'Karnal',
    city: 'Karnal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e435',
    },
    state: 'Haryana',
    district: 'Karnal',
    city: 'Kunjpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e436',
    },
    state: 'Haryana',
    district: 'Karnal',
    city: 'Munak',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e437',
    },
    state: 'Haryana',
    district: 'Karnal',
    city: 'Nilokheri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e438',
    },
    state: 'Haryana',
    district: 'Karnal',
    city: 'Nissing At Chirao',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e439',
    },
    state: 'Haryana',
    district: 'Kurukshetra',
    city: 'Babain',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e43a',
    },
    state: 'Haryana',
    district: 'Kurukshetra',
    city: 'Ismailabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e43b',
    },
    state: 'Haryana',
    district: 'Kurukshetra',
    city: 'Ladwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e43c',
    },
    state: 'Haryana',
    district: 'Kurukshetra',
    city: 'Pehowa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e43d',
    },
    state: 'Haryana',
    district: 'Kurukshetra',
    city: 'Pipli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e43e',
    },
    state: 'Haryana',
    district: 'Kurukshetra',
    city: 'Shahbad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e43f',
    },
    state: 'Haryana',
    district: 'Kurukshetra',
    city: 'Thanesar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e440',
    },
    state: 'Haryana',
    district: 'Mahendragarh',
    city: 'Ateli Nangal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e441',
    },
    state: 'Haryana',
    district: 'Mahendragarh',
    city: 'Kanina',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e442',
    },
    state: 'Haryana',
    district: 'Mahendragarh',
    city: 'Mahendragarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e443',
    },
    state: 'Haryana',
    district: 'Kaithal',
    city: 'Siwan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e444',
    },
    state: 'Haryana',
    district: 'Mahendragarh',
    city: 'Nangal Chaudhry',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e445',
    },
    state: 'Haryana',
    district: 'Mahendragarh',
    city: 'Narnaul',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e446',
    },
    state: 'Haryana',
    district: 'Mahendragarh',
    city: 'Nizampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e447',
    },
    state: 'Haryana',
    district: 'Mahendragarh',
    city: 'Sihma',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e448',
    },
    state: 'Haryana',
    district: 'Nuh',
    city: 'Ferozepur Jhirka',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e449',
    },
    state: 'Haryana',
    district: 'Nuh',
    city: 'Indri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e44a',
    },
    state: 'Haryana',
    district: 'Nuh',
    city: 'Nagina',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e44b',
    },
    state: 'Haryana',
    district: 'Nuh',
    city: 'Nuh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e44c',
    },
    state: 'Haryana',
    district: 'Nuh',
    city: 'Pingwan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e44d',
    },
    state: 'Haryana',
    district: 'Nuh',
    city: 'Punahana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e44e',
    },
    state: 'Haryana',
    district: 'Nuh',
    city: 'Taoru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e44f',
    },
    state: 'Haryana',
    district: 'Palwal',
    city: 'Badoli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e450',
    },
    state: 'Haryana',
    district: 'Palwal',
    city: 'Hassanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e451',
    },
    state: 'Haryana',
    district: 'Palwal',
    city: 'Hathin',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e452',
    },
    state: 'Haryana',
    district: 'Palwal',
    city: 'Hodal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e453',
    },
    state: 'Haryana',
    district: 'Palwal',
    city: 'Palwal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e454',
    },
    state: 'Haryana',
    district: 'Palwal',
    city: 'Prithla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e455',
    },
    state: 'Haryana',
    district: 'Panchkula',
    city: 'Barwala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e456',
    },
    state: 'Haryana',
    district: 'Panchkula',
    city: 'Morni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e457',
    },
    state: 'Haryana',
    district: 'Panchkula',
    city: 'Pinjore',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e458',
    },
    state: 'Haryana',
    district: 'Panchkula',
    city: 'Raipur Rani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e459',
    },
    state: 'Haryana',
    district: 'Mahendragarh',
    city: 'Satnali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e45a',
    },
    state: 'Haryana',
    district: 'Panipat',
    city: 'Bapoli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e45b',
    },
    state: 'Haryana',
    district: 'Panipat',
    city: 'Israna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e45c',
    },
    state: 'Haryana',
    district: 'Panipat',
    city: 'Madlauda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e45d',
    },
    state: 'Haryana',
    district: 'Panipat',
    city: 'Samalkha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e45e',
    },
    state: 'Haryana',
    district: 'Panipat',
    city: 'Sanauli Khurd',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e45f',
    },
    state: 'Haryana',
    district: 'Rewari',
    city: 'Bawal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e460',
    },
    state: 'Haryana',
    district: 'Rewari',
    city: 'Dahina',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e461',
    },
    state: 'Haryana',
    district: 'Rewari',
    city: 'Dharuhera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e462',
    },
    state: 'Haryana',
    district: 'Rewari',
    city: 'Jatusana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e463',
    },
    state: 'Haryana',
    district: 'Rewari',
    city: 'Khol At Rewari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e464',
    },
    state: 'Haryana',
    district: 'Rewari',
    city: 'Nahar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e465',
    },
    state: 'Haryana',
    district: 'Rewari',
    city: 'Rewari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e466',
    },
    state: 'Haryana',
    district: 'Rohtak',
    city: 'Kalanaur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e467',
    },
    state: 'Haryana',
    district: 'Rohtak',
    city: 'Lakhan Majra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e468',
    },
    state: 'Haryana',
    district: 'Rohtak',
    city: 'Maham',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e469',
    },
    state: 'Haryana',
    district: 'Rohtak',
    city: 'Rohtak',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e46a',
    },
    state: 'Haryana',
    district: 'Rohtak',
    city: 'Sampla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e46b',
    },
    state: 'Haryana',
    district: 'Sirsa',
    city: 'Baragudha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e46c',
    },
    state: 'Haryana',
    district: 'Sirsa',
    city: 'Dabwali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e46d',
    },
    state: 'Haryana',
    district: 'Sirsa',
    city: 'Ellenabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e46e',
    },
    state: 'Haryana',
    district: 'Sirsa',
    city: 'Nathusari Chopta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e46f',
    },
    state: 'Haryana',
    district: 'Panipat',
    city: 'Panipat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e470',
    },
    state: 'Haryana',
    district: 'Sirsa',
    city: 'Odhan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e471',
    },
    state: 'Haryana',
    district: 'Sirsa',
    city: 'Rania',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e472',
    },
    state: 'Haryana',
    district: 'Sirsa',
    city: 'Sirsa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e473',
    },
    state: 'Haryana',
    district: 'Sonipat',
    city: 'Gohana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e474',
    },
    state: 'Haryana',
    district: 'Sonipat',
    city: 'Kathura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e475',
    },
    state: 'Haryana',
    district: 'Sonipat',
    city: 'Kharkhoda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e476',
    },
    state: 'Haryana',
    district: 'Sonipat',
    city: 'Mundlana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e477',
    },
    state: 'Haryana',
    district: 'Sonipat',
    city: 'Murthal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e478',
    },
    state: 'Haryana',
    district: 'Sonipat',
    city: 'Rai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e479',
    },
    state: 'Haryana',
    district: 'Sonipat',
    city: 'Sonipat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e47a',
    },
    state: 'Haryana',
    district: 'Yamunanagar',
    city: 'Bilaspur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e47b',
    },
    state: 'Haryana',
    district: 'Yamunanagar',
    city: 'Chhachhrauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e47c',
    },
    state: 'Haryana',
    district: 'Yamunanagar',
    city: 'Jagadhri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e47d',
    },
    state: 'Haryana',
    district: 'Yamunanagar',
    city: 'Partap Nagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e47e',
    },
    state: 'Haryana',
    district: 'Yamunanagar',
    city: 'Radaur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e47f',
    },
    state: 'Haryana',
    district: 'Yamunanagar',
    city: 'Sadaura (Part)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e480',
    },
    state: 'Haryana',
    district: 'Yamunanagar',
    city: 'Saraswati Nagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e481',
    },
    state: 'Himachal Pradesh',
    district: 'Bilaspur',
    city: 'Bilaspur Sadar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e482',
    },
    state: 'Himachal Pradesh',
    district: 'Bilaspur',
    city: 'Ghumarwin',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e483',
    },
    state: 'Himachal Pradesh',
    district: 'Bilaspur',
    city: 'Jhandutta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e484',
    },
    state: 'Himachal Pradesh',
    district: 'Bilaspur',
    city: 'Shree Naina Devi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e485',
    },
    state: 'Haryana',
    district: 'Sonipat',
    city: 'Ganaur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e486',
    },
    state: 'Himachal Pradesh',
    district: 'Chamba',
    city: 'Bharmour',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e487',
    },
    state: 'Himachal Pradesh',
    district: 'Chamba',
    city: 'Bhattiyat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e488',
    },
    state: 'Himachal Pradesh',
    district: 'Chamba',
    city: 'Chamba',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e489',
    },
    state: 'Himachal Pradesh',
    district: 'Chamba',
    city: 'Pangi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e48a',
    },
    state: 'Himachal Pradesh',
    district: 'Chamba',
    city: 'Saluni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e48b',
    },
    state: 'Himachal Pradesh',
    district: 'Chamba',
    city: 'Tisa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e48c',
    },
    state: 'Himachal Pradesh',
    district: 'Hamirpur',
    city: 'Bamson',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e48d',
    },
    state: 'Himachal Pradesh',
    district: 'Hamirpur',
    city: 'Bhoranj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e48e',
    },
    state: 'Himachal Pradesh',
    district: 'Hamirpur',
    city: 'Bijhri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e48f',
    },
    state: 'Himachal Pradesh',
    district: 'Hamirpur',
    city: 'Hamirpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e490',
    },
    state: 'Himachal Pradesh',
    district: 'Hamirpur',
    city: 'Nadaun',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e491',
    },
    state: 'Himachal Pradesh',
    district: 'Hamirpur',
    city: 'Tira Sujanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e492',
    },
    state: 'Himachal Pradesh',
    district: 'Kangra',
    city: 'Baijnath',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e493',
    },
    state: 'Himachal Pradesh',
    district: 'Kangra',
    city: 'Bhawarna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e494',
    },
    state: 'Himachal Pradesh',
    district: 'Kangra',
    city: 'Dehra Gopipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e495',
    },
    state: 'Himachal Pradesh',
    district: 'Kangra',
    city: 'Dharamshala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e496',
    },
    state: 'Himachal Pradesh',
    district: 'Kangra',
    city: 'Fatehpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e497',
    },
    state: 'Himachal Pradesh',
    district: 'Kangra',
    city: 'Indora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e498',
    },
    state: 'Himachal Pradesh',
    district: 'Kangra',
    city: 'Kangra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e499',
    },
    state: 'Himachal Pradesh',
    district: 'Kangra',
    city: 'Lambagaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e49a',
    },
    state: 'Himachal Pradesh',
    district: 'Kangra',
    city: 'Nagrota Bagwan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e49b',
    },
    state: 'Himachal Pradesh',
    district: 'Chamba',
    city: 'Mehla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e49c',
    },
    state: 'Himachal Pradesh',
    district: 'Kangra',
    city: 'Nagrota Surian',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e49d',
    },
    state: 'Himachal Pradesh',
    district: 'Kangra',
    city: 'Nurpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e49e',
    },
    state: 'Himachal Pradesh',
    district: 'Kangra',
    city: 'Panchrukhi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e49f',
    },
    state: 'Himachal Pradesh',
    district: 'Kangra',
    city: 'Rait',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4a0',
    },
    state: 'Himachal Pradesh',
    district: 'Kangra',
    city: 'Sulah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4a1',
    },
    state: 'Himachal Pradesh',
    district: 'Kinnaur',
    city: 'Kalpa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4a2',
    },
    state: 'Himachal Pradesh',
    district: 'Kinnaur',
    city: 'Nichar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4a3',
    },
    state: 'Himachal Pradesh',
    district: 'Kinnaur',
    city: 'Pooh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4a4',
    },
    state: 'Himachal Pradesh',
    district: 'Kullu',
    city: 'Anni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4a5',
    },
    state: 'Himachal Pradesh',
    district: 'Kullu',
    city: 'Banjar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4a6',
    },
    state: 'Himachal Pradesh',
    district: 'Kullu',
    city: 'Kullu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4a7',
    },
    state: 'Himachal Pradesh',
    district: 'Kullu',
    city: 'Naggar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4a8',
    },
    state: 'Himachal Pradesh',
    district: 'Kullu',
    city: 'Nirmand',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4a9',
    },
    state: 'Himachal Pradesh',
    district: 'Lahul And Spiti',
    city: 'Lahul',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4aa',
    },
    state: 'Himachal Pradesh',
    district: 'Lahul And Spiti',
    city: 'Spiti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4ab',
    },
    state: 'Himachal Pradesh',
    district: 'Mandi',
    city: 'Balh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4ac',
    },
    state: 'Himachal Pradesh',
    district: 'Mandi',
    city: 'Bali Chowki',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4ad',
    },
    state: 'Himachal Pradesh',
    district: 'Mandi',
    city: 'Chauntra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4ae',
    },
    state: 'Himachal Pradesh',
    district: 'Mandi',
    city: 'Dharmpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4af',
    },
    state: 'Himachal Pradesh',
    district: 'Mandi',
    city: 'Drang',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4b0',
    },
    state: 'Himachal Pradesh',
    district: 'Mandi',
    city: 'Gohar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4b1',
    },
    state: 'Himachal Pradesh',
    district: 'Kangra',
    city: 'Pragpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4b2',
    },
    state: 'Himachal Pradesh',
    district: 'Mandi',
    city: 'Gopalpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4b3',
    },
    state: 'Himachal Pradesh',
    district: 'Mandi',
    city: 'Karsog',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4b4',
    },
    state: 'Himachal Pradesh',
    district: 'Mandi',
    city: 'Mandi Sadar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4b5',
    },
    state: 'Himachal Pradesh',
    district: 'Mandi',
    city: 'Sundarnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4b6',
    },
    state: 'Himachal Pradesh',
    district: 'Shimla',
    city: 'Basantpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4b7',
    },
    state: 'Himachal Pradesh',
    district: 'Shimla',
    city: 'Chauhara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4b8',
    },
    state: 'Himachal Pradesh',
    district: 'Shimla',
    city: 'Chaupal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4b9',
    },
    state: 'Himachal Pradesh',
    district: 'Shimla',
    city: 'Jubbal Kotkhai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4ba',
    },
    state: 'Himachal Pradesh',
    district: 'Shimla',
    city: 'Kupvi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4bb',
    },
    state: 'Himachal Pradesh',
    district: 'Shimla',
    city: 'Mashobra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4bc',
    },
    state: 'Himachal Pradesh',
    district: 'Shimla',
    city: 'Nankhari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4bd',
    },
    state: 'Himachal Pradesh',
    district: 'Shimla',
    city: 'Narkanda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4be',
    },
    state: 'Himachal Pradesh',
    district: 'Shimla',
    city: 'Rampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4bf',
    },
    state: 'Himachal Pradesh',
    district: 'Shimla',
    city: 'Rohru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4c0',
    },
    state: 'Himachal Pradesh',
    district: 'Shimla',
    city: 'Theog',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4c1',
    },
    state: 'Himachal Pradesh',
    district: 'Shimla',
    city: 'Totu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4c2',
    },
    state: 'Himachal Pradesh',
    district: 'Sirmaur',
    city: 'Nahan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4c3',
    },
    state: 'Himachal Pradesh',
    district: 'Sirmaur',
    city: 'Pachhad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4c4',
    },
    state: 'Himachal Pradesh',
    district: 'Sirmaur',
    city: 'Paonta Sahib',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4c5',
    },
    state: 'Himachal Pradesh',
    district: 'Sirmaur',
    city: 'Rajgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4c6',
    },
    state: 'Himachal Pradesh',
    district: 'Sirmaur',
    city: 'Sangrah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4c7',
    },
    state: 'Himachal Pradesh',
    district: 'Mandi',
    city: 'Seraj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4c8',
    },
    state: 'Himachal Pradesh',
    district: 'Sirmaur',
    city: 'Shillai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4c9',
    },
    state: 'Himachal Pradesh',
    district: 'Solan',
    city: 'Dharampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4ca',
    },
    state: 'Himachal Pradesh',
    district: 'Solan',
    city: 'Kandaghat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4cb',
    },
    state: 'Himachal Pradesh',
    district: 'Solan',
    city: 'Nalagarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4cc',
    },
    state: 'Himachal Pradesh',
    district: 'Solan',
    city: 'Solan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4cd',
    },
    state: 'Himachal Pradesh',
    district: 'Una',
    city: 'Amb',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4ce',
    },
    state: 'Himachal Pradesh',
    district: 'Una',
    city: 'Bangana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4cf',
    },
    state: 'Himachal Pradesh',
    district: 'Una',
    city: 'Gagret',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4d0',
    },
    state: 'Himachal Pradesh',
    district: 'Una',
    city: 'Haroli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4d1',
    },
    state: 'Himachal Pradesh',
    district: 'Una',
    city: 'Una',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4d2',
    },
    state: 'Jammu And Kashmir',
    district: 'Anantnag',
    city: 'Achabal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4d3',
    },
    state: 'Jammu And Kashmir',
    district: 'Anantnag',
    city: 'Anantnag',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4d4',
    },
    state: 'Jammu And Kashmir',
    district: 'Anantnag',
    city: 'Bijibehara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4d5',
    },
    state: 'Jammu And Kashmir',
    district: 'Anantnag',
    city: 'Breng',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4d6',
    },
    state: 'Jammu And Kashmir',
    district: 'Anantnag',
    city: 'Chathergul',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4d7',
    },
    state: 'Jammu And Kashmir',
    district: 'Anantnag',
    city: 'Dachnipora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4d8',
    },
    state: 'Jammu And Kashmir',
    district: 'Anantnag',
    city: 'Hiller Shahabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4d9',
    },
    state: 'Jammu And Kashmir',
    district: 'Anantnag',
    city: 'Khoveripora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4da',
    },
    state: 'Jammu And Kashmir',
    district: 'Anantnag',
    city: 'Larnoo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4db',
    },
    state: 'Jammu And Kashmir',
    district: 'Anantnag',
    city: 'Pahalgam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4dc',
    },
    state: 'Jammu And Kashmir',
    district: 'Anantnag',
    city: 'Qazigund (Partly)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4dd',
    },
    state: 'Himachal Pradesh',
    district: 'Solan',
    city: 'Kunihar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4de',
    },
    state: 'Jammu And Kashmir',
    district: 'Anantnag',
    city: 'Quimoh Anantnag District Part',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4df',
    },
    state: 'Jammu And Kashmir',
    district: 'Anantnag',
    city: 'Sagam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4e0',
    },
    state: 'Jammu And Kashmir',
    district: 'Anantnag',
    city: 'Shahabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4e1',
    },
    state: 'Jammu And Kashmir',
    district: 'Anantnag',
    city: 'Verinag',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4e2',
    },
    state: 'Jammu And Kashmir',
    district: 'Anantnag',
    city: 'Vessu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4e3',
    },
    state: 'Jammu And Kashmir',
    district: 'Bandipora',
    city: 'Aloosa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4e4',
    },
    state: 'Jammu And Kashmir',
    district: 'Bandipora',
    city: 'Arin',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4e5',
    },
    state: 'Jammu And Kashmir',
    district: 'Bandipora',
    city: 'Baktoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4e6',
    },
    state: 'Jammu And Kashmir',
    district: 'Bandipora',
    city: 'Bandipora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4e7',
    },
    state: 'Jammu And Kashmir',
    district: 'Bandipora',
    city: 'Bonkoot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4e8',
    },
    state: 'Jammu And Kashmir',
    district: 'Bandipora',
    city: 'Ganstan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4e9',
    },
    state: 'Jammu And Kashmir',
    district: 'Bandipora',
    city: 'Gurez',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4ea',
    },
    state: 'Jammu And Kashmir',
    district: 'Bandipora',
    city: 'Hajin',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4eb',
    },
    state: 'Jammu And Kashmir',
    district: 'Bandipora',
    city: 'Naidkhay',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4ec',
    },
    state: 'Jammu And Kashmir',
    district: 'Bandipora',
    city: 'Nowgam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4ed',
    },
    state: 'Jammu And Kashmir',
    district: 'Bandipora',
    city: 'Sumbal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4ee',
    },
    state: 'Jammu And Kashmir',
    district: 'Bandipora',
    city: 'Tulail',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4ef',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Baramulla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4f0',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Bijhama',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4f1',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Boniyar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4f2',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Chandil Wangam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4f3',
    },
    state: 'Jammu And Kashmir',
    district: 'Anantnag',
    city: 'Shangus',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4f4',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Hardaboora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4f5',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Kandi Rafiabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4f6',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Khaipora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4f7',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Lalpora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4f8',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Nadihal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4f9',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Narwah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4fa',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Noorkah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4fb',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Paranpeela',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4fc',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Pattan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4fd',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Rafiabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4fe',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Rohama',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e4ff',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Sangrama',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e500',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Sherabad Khore',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e501',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Singhpora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e502',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Sopore',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e503',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Tangmarag',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e504',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Tujjar Sharief',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e505',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Uri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e506',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Wagoora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e507',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Wailoo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e508',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Zaingeer',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e509',
    },
    state: 'Jammu And Kashmir',
    district: 'Baramulla',
    city: 'Kunzer',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e50a',
    },
    state: 'Jammu And Kashmir',
    district: 'Budgam',
    city: 'Badgam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e50b',
    },
    state: 'Jammu And Kashmir',
    district: 'Budgam',
    city: 'Beerwah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e50c',
    },
    state: 'Jammu And Kashmir',
    district: 'Budgam',
    city: 'B.K.Pora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e50d',
    },
    state: 'Jammu And Kashmir',
    district: 'Budgam',
    city: 'Charisharief',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e50e',
    },
    state: 'Jammu And Kashmir',
    district: 'Budgam',
    city: 'Khag',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e50f',
    },
    state: 'Jammu And Kashmir',
    district: 'Budgam',
    city: 'Khan-Sahib',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e510',
    },
    state: 'Jammu And Kashmir',
    district: 'Budgam',
    city: 'Nagam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e511',
    },
    state: 'Jammu And Kashmir',
    district: 'Budgam',
    city: 'Narbal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e512',
    },
    state: 'Jammu And Kashmir',
    district: 'Budgam',
    city: 'Pakherpora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e513',
    },
    state: 'Jammu And Kashmir',
    district: 'Budgam',
    city: 'Parnewa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e514',
    },
    state: 'Jammu And Kashmir',
    district: 'Budgam',
    city: 'Rathsun',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e515',
    },
    state: 'Jammu And Kashmir',
    district: 'Budgam',
    city: 'S K Pora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e516',
    },
    state: 'Jammu And Kashmir',
    district: 'Budgam',
    city: 'Soibugh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e517',
    },
    state: 'Jammu And Kashmir',
    district: 'Budgam',
    city: 'Sukhnag Hard Panzoo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e518',
    },
    state: 'Jammu And Kashmir',
    district: 'Budgam',
    city: 'Surasyar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e519',
    },
    state: 'Jammu And Kashmir',
    district: 'Budgam',
    city: 'Waterhail',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e51a',
    },
    state: 'Jammu And Kashmir',
    district: 'Doda',
    city: 'Assar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e51b',
    },
    state: 'Jammu And Kashmir',
    district: 'Doda',
    city: 'Bhaderwah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e51c',
    },
    state: 'Jammu And Kashmir',
    district: 'Doda',
    city: 'Bhagwah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e51d',
    },
    state: 'Jammu And Kashmir',
    district: 'Doda',
    city: 'Bhalessa(Gandoh)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e51e',
    },
    state: 'Jammu And Kashmir',
    district: 'Doda',
    city: 'Bhalla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e51f',
    },
    state: 'Jammu And Kashmir',
    district: 'Budgam',
    city: 'Chadoora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e520',
    },
    state: 'Jammu And Kashmir',
    district: 'Doda',
    city: 'Changa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e521',
    },
    state: 'Jammu And Kashmir',
    district: 'Doda',
    city: 'Chilli Pingal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e522',
    },
    state: 'Jammu And Kashmir',
    district: 'Doda',
    city: 'Chiralla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e523',
    },
    state: 'Jammu And Kashmir',
    district: 'Doda',
    city: 'Doda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e524',
    },
    state: 'Jammu And Kashmir',
    district: 'Doda',
    city: 'Gundana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e525',
    },
    state: 'Jammu And Kashmir',
    district: 'Doda',
    city: 'Jakyas',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e526',
    },
    state: 'Jammu And Kashmir',
    district: 'Doda',
    city: 'Kahara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e527',
    },
    state: 'Jammu And Kashmir',
    district: 'Doda',
    city: 'Kastigarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e528',
    },
    state: 'Jammu And Kashmir',
    district: 'Doda',
    city: 'Khellani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e529',
    },
    state: 'Jammu And Kashmir',
    district: 'Doda',
    city: 'Marmat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e52a',
    },
    state: 'Jammu And Kashmir',
    district: 'Doda',
    city: 'Thathri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e52b',
    },
    state: 'Jammu And Kashmir',
    district: 'Ganderbal',
    city: 'Ganderbal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e52c',
    },
    state: 'Jammu And Kashmir',
    district: 'Ganderbal',
    city: 'Gund',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e52d',
    },
    state: 'Jammu And Kashmir',
    district: 'Ganderbal',
    city: 'Kangan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e52e',
    },
    state: 'Jammu And Kashmir',
    district: 'Ganderbal',
    city: 'Lar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e52f',
    },
    state: 'Jammu And Kashmir',
    district: 'Ganderbal',
    city: 'Safapora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e530',
    },
    state: 'Jammu And Kashmir',
    district: 'Ganderbal',
    city: 'Sherpathri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e531',
    },
    state: 'Jammu And Kashmir',
    district: 'Ganderbal',
    city: 'Wakoora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e532',
    },
    state: 'Jammu And Kashmir',
    district: 'Jammu',
    city: 'Akhnoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e533',
    },
    state: 'Jammu And Kashmir',
    district: 'Jammu',
    city: 'Arnia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e534',
    },
    state: 'Jammu And Kashmir',
    district: 'Jammu',
    city: 'Bhalwal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e535',
    },
    state: 'Jammu And Kashmir',
    district: 'Doda',
    city: 'Dali Udhyanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e536',
    },
    state: 'Jammu And Kashmir',
    district: 'Jammu',
    city: 'Bhalwal Brahmana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e537',
    },
    state: 'Jammu And Kashmir',
    district: 'Jammu',
    city: 'Bishnah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e538',
    },
    state: 'Jammu And Kashmir',
    district: 'Jammu',
    city: 'Chowki Choura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e539',
    },
    state: 'Jammu And Kashmir',
    district: 'Jammu',
    city: 'Kharah Balli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e53a',
    },
    state: 'Jammu And Kashmir',
    district: 'Jammu',
    city: 'Khour',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e53b',
    },
    state: 'Jammu And Kashmir',
    district: 'Jammu',
    city: 'Maira Mandrian',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e53c',
    },
    state: 'Jammu And Kashmir',
    district: 'Jammu',
    city: 'Mandal Phallain',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e53d',
    },
    state: 'Jammu And Kashmir',
    district: 'Jammu',
    city: 'Marh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e53e',
    },
    state: 'Jammu And Kashmir',
    district: 'Jammu',
    city: 'Mathwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e53f',
    },
    state: 'Jammu And Kashmir',
    district: 'Jammu',
    city: 'Miran Sahib',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e540',
    },
    state: 'Jammu And Kashmir',
    district: 'Jammu',
    city: 'Nagrota',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e541',
    },
    state: 'Jammu And Kashmir',
    district: 'Jammu',
    city: 'Pragwal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e542',
    },
    state: 'Jammu And Kashmir',
    district: 'Jammu',
    city: 'R.S.Pura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e543',
    },
    state: 'Jammu And Kashmir',
    district: 'Jammu',
    city: 'Samwan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e544',
    },
    state: 'Jammu And Kashmir',
    district: 'Jammu',
    city: 'Satwari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e545',
    },
    state: 'Jammu And Kashmir',
    district: 'Jammu',
    city: 'Suchetgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e546',
    },
    state: 'Jammu And Kashmir',
    district: 'Kathua',
    city: 'Baggan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e547',
    },
    state: 'Jammu And Kashmir',
    district: 'Kathua',
    city: 'Bani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e548',
    },
    state: 'Jammu And Kashmir',
    district: 'Kathua',
    city: 'Barnoti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e549',
    },
    state: 'Jammu And Kashmir',
    district: 'Kathua',
    city: 'Basohli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e54a',
    },
    state: 'Jammu And Kashmir',
    district: 'Kathua',
    city: 'Bhoond',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e54b',
    },
    state: 'Jammu And Kashmir',
    district: 'Jammu',
    city: 'Dansal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e54c',
    },
    state: 'Jammu And Kashmir',
    district: 'Kathua',
    city: 'Billawar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e54d',
    },
    state: 'Jammu And Kashmir',
    district: 'Kathua',
    city: 'Dhar Mahanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e54e',
    },
    state: 'Jammu And Kashmir',
    district: 'Kathua',
    city: 'Dinga Amb',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e54f',
    },
    state: 'Jammu And Kashmir',
    district: 'Kathua',
    city: 'Duggan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e550',
    },
    state: 'Jammu And Kashmir',
    district: 'Kathua',
    city: 'Hiranagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e551',
    },
    state: 'Jammu And Kashmir',
    district: 'Kathua',
    city: 'Kathua',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e552',
    },
    state: 'Jammu And Kashmir',
    district: 'Kathua',
    city: 'Keerian Gangyal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e553',
    },
    state: 'Jammu And Kashmir',
    district: 'Kathua',
    city: 'Lohai-Malhar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e554',
    },
    state: 'Jammu And Kashmir',
    district: 'Kathua',
    city: 'Mahanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e555',
    },
    state: 'Jammu And Kashmir',
    district: 'Kathua',
    city: 'Mandli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e556',
    },
    state: 'Jammu And Kashmir',
    district: 'Kathua',
    city: 'Marheen',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e557',
    },
    state: 'Jammu And Kashmir',
    district: 'Kathua',
    city: 'Nagri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e558',
    },
    state: 'Jammu And Kashmir',
    district: 'Kathua',
    city: 'Nagrota Gujroo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e559',
    },
    state: 'Jammu And Kashmir',
    district: 'Kishtwar',
    city: 'Bunjwah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e55a',
    },
    state: 'Jammu And Kashmir',
    district: 'Kishtwar',
    city: 'Dachhan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e55b',
    },
    state: 'Jammu And Kashmir',
    district: 'Kishtwar',
    city: 'Drabshalla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e55c',
    },
    state: 'Jammu And Kashmir',
    district: 'Kishtwar',
    city: 'Inderwal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e55d',
    },
    state: 'Jammu And Kashmir',
    district: 'Kishtwar',
    city: 'Kishtwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e55e',
    },
    state: 'Jammu And Kashmir',
    district: 'Kishtwar',
    city: 'Marwah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e55f',
    },
    state: 'Jammu And Kashmir',
    district: 'Kishtwar',
    city: 'Mughalmaidan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e560',
    },
    state: 'Jammu And Kashmir',
    district: 'Kishtwar',
    city: 'Nagseni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e561',
    },
    state: 'Jammu And Kashmir',
    district: 'Kathua',
    city: 'Duggain',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e562',
    },
    state: 'Jammu And Kashmir',
    district: 'Kishtwar',
    city: 'Padder',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e563',
    },
    state: 'Jammu And Kashmir',
    district: 'Kishtwar',
    city: 'Palmar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e564',
    },
    state: 'Jammu And Kashmir',
    district: 'Kishtwar',
    city: 'Thakraie',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e565',
    },
    state: 'Jammu And Kashmir',
    district: 'Kishtwar',
    city: 'Warwan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e566',
    },
    state: 'Jammu And Kashmir',
    district: 'Kulgam',
    city: 'Behibag',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e567',
    },
    state: 'Jammu And Kashmir',
    district: 'Kulgam',
    city: 'Devsar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e568',
    },
    state: 'Jammu And Kashmir',
    district: 'Kulgam',
    city: 'D.H. Pora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e569',
    },
    state: 'Jammu And Kashmir',
    district: 'Kulgam',
    city: 'D K Marg',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e56a',
    },
    state: 'Jammu And Kashmir',
    district: 'Kulgam',
    city: 'Frisal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e56b',
    },
    state: 'Jammu And Kashmir',
    district: 'Kulgam',
    city: 'Kulgam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e56c',
    },
    state: 'Jammu And Kashmir',
    district: 'Kulgam',
    city: 'Kund',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e56d',
    },
    state: 'Jammu And Kashmir',
    district: 'Kulgam',
    city: 'Manzgam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e56e',
    },
    state: 'Jammu And Kashmir',
    district: 'Kulgam',
    city: 'Pahloo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e56f',
    },
    state: 'Jammu And Kashmir',
    district: 'Kulgam',
    city: 'Pombay',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e570',
    },
    state: 'Jammu And Kashmir',
    district: 'Kulgam',
    city: 'Qaimoh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e571',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Drugmulla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e572',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Handwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e573',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Hayhama',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e574',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Kalarooch',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e575',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Keran',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e576',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Kralpora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e577',
    },
    state: 'Jammu And Kashmir',
    district: 'Kishtwar',
    city: 'Trigam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e578',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Kupwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e579',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Langate',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e57a',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Machil',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e57b',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Mawar Kalamabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e57c',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Meliyal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e57d',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Nutnoosa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e57e',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Qadirabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e57f',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Qaziabad Supernagama',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e580',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Rajwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e581',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Ramhal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e582',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Reddi Chowkibal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e583',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Sogam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e584',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Tangdar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e585',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Tarathpora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e586',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Teetwal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e587',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Trehgam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e588',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Wavoora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e589',
    },
    state: 'Jammu And Kashmir',
    district: 'Poonch',
    city: 'Balakote',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e58a',
    },
    state: 'Jammu And Kashmir',
    district: 'Poonch',
    city: 'Bufliaz',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e58b',
    },
    state: 'Jammu And Kashmir',
    district: 'Poonch',
    city: 'Lassana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e58c',
    },
    state: 'Jammu And Kashmir',
    district: 'Poonch',
    city: 'Loran',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e58d',
    },
    state: 'Jammu And Kashmir',
    district: 'Kupwara',
    city: 'Magam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e58e',
    },
    state: 'Jammu And Kashmir',
    district: 'Poonch',
    city: 'Mandi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e58f',
    },
    state: 'Jammu And Kashmir',
    district: 'Poonch',
    city: 'Mankote',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e590',
    },
    state: 'Jammu And Kashmir',
    district: 'Poonch',
    city: 'Mendhar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e591',
    },
    state: 'Jammu And Kashmir',
    district: 'Poonch',
    city: 'Poonch',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e592',
    },
    state: 'Jammu And Kashmir',
    district: 'Poonch',
    city: 'Sathra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e593',
    },
    state: 'Jammu And Kashmir',
    district: 'Poonch',
    city: 'Surankote',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e594',
    },
    state: 'Jammu And Kashmir',
    district: 'Pulwama',
    city: 'Aripal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e595',
    },
    state: 'Jammu And Kashmir',
    district: 'Pulwama',
    city: 'Awantipora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e596',
    },
    state: 'Jammu And Kashmir',
    district: 'Pulwama',
    city: 'Dadsara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e597',
    },
    state: 'Jammu And Kashmir',
    district: 'Pulwama',
    city: 'Ichegoza',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e598',
    },
    state: 'Jammu And Kashmir',
    district: 'Pulwama',
    city: 'Kakapora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e599',
    },
    state: 'Jammu And Kashmir',
    district: 'Pulwama',
    city: 'Litter',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e59a',
    },
    state: 'Jammu And Kashmir',
    district: 'Pulwama',
    city: 'Newa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e59b',
    },
    state: 'Jammu And Kashmir',
    district: 'Pulwama',
    city: 'Pampore',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e59c',
    },
    state: 'Jammu And Kashmir',
    district: 'Pulwama',
    city: 'Pulwama',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e59d',
    },
    state: 'Jammu And Kashmir',
    district: 'Pulwama',
    city: 'Shadimarg',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e59e',
    },
    state: 'Jammu And Kashmir',
    district: 'Pulwama',
    city: 'Tral',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e59f',
    },
    state: 'Jammu And Kashmir',
    district: 'Rajouri',
    city: 'Budhal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5a0',
    },
    state: 'Jammu And Kashmir',
    district: 'Rajouri',
    city: 'Budhal New',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5a1',
    },
    state: 'Jammu And Kashmir',
    district: 'Rajouri',
    city: 'Darhal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5a2',
    },
    state: 'Jammu And Kashmir',
    district: 'Rajouri',
    city: 'Dhangri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5a3',
    },
    state: 'Jammu And Kashmir',
    district: 'Poonch',
    city: 'Nangali Sahib Sain Baba',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5a4',
    },
    state: 'Jammu And Kashmir',
    district: 'Rajouri',
    city: 'Doongi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5a5',
    },
    state: 'Jammu And Kashmir',
    district: 'Rajouri',
    city: 'Kalakote',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5a6',
    },
    state: 'Jammu And Kashmir',
    district: 'Rajouri',
    city: 'Khawas',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5a7',
    },
    state: 'Jammu And Kashmir',
    district: 'Rajouri',
    city: 'Manjakote',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5a8',
    },
    state: 'Jammu And Kashmir',
    district: 'Rajouri',
    city: 'Moughla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5a9',
    },
    state: 'Jammu And Kashmir',
    district: 'Rajouri',
    city: 'Nowshera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5aa',
    },
    state: 'Jammu And Kashmir',
    district: 'Rajouri',
    city: 'Panjgrian',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5ab',
    },
    state: 'Jammu And Kashmir',
    district: 'Rajouri',
    city: 'Planger',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5ac',
    },
    state: 'Jammu And Kashmir',
    district: 'Rajouri',
    city: 'Qila Darhal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5ad',
    },
    state: 'Jammu And Kashmir',
    district: 'Rajouri',
    city: 'Rajouri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5ae',
    },
    state: 'Jammu And Kashmir',
    district: 'Rajouri',
    city: 'Seri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5af',
    },
    state: 'Jammu And Kashmir',
    district: 'Rajouri',
    city: 'Siot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5b0',
    },
    state: 'Jammu And Kashmir',
    district: 'Rajouri',
    city: 'Sunderbani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5b1',
    },
    state: 'Jammu And Kashmir',
    district: 'Rajouri',
    city: 'Thana Mandi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5b2',
    },
    state: 'Jammu And Kashmir',
    district: 'Ramban',
    city: 'Banihal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5b3',
    },
    state: 'Jammu And Kashmir',
    district: 'Ramban',
    city: 'Batote',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5b4',
    },
    state: 'Jammu And Kashmir',
    district: 'Ramban',
    city: 'Gandhri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5b5',
    },
    state: 'Jammu And Kashmir',
    district: 'Ramban',
    city: 'Gool',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5b6',
    },
    state: 'Jammu And Kashmir',
    district: 'Ramban',
    city: 'Gundi Dharam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5b7',
    },
    state: 'Jammu And Kashmir',
    district: 'Ramban',
    city: 'Khari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5b8',
    },
    state: 'Jammu And Kashmir',
    district: 'Ramban',
    city: 'Rajgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5b9',
    },
    state: 'Jammu And Kashmir',
    district: 'Rajouri',
    city: 'Lamberi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5ba',
    },
    state: 'Jammu And Kashmir',
    district: 'Ramban',
    city: 'Ramban',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5bb',
    },
    state: 'Jammu And Kashmir',
    district: 'Ramban',
    city: 'Ramsoo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5bc',
    },
    state: 'Jammu And Kashmir',
    district: 'Ramban',
    city: 'Sangaldan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5bd',
    },
    state: 'Jammu And Kashmir',
    district: 'Reasi',
    city: 'Arnas',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5be',
    },
    state: 'Jammu And Kashmir',
    district: 'Reasi',
    city: 'Bamagh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5bf',
    },
    state: 'Jammu And Kashmir',
    district: 'Reasi',
    city: 'Chassana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5c0',
    },
    state: 'Jammu And Kashmir',
    district: 'Reasi',
    city: 'Gulab Garh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5c1',
    },
    state: 'Jammu And Kashmir',
    district: 'Reasi',
    city: 'Jig Bagli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5c2',
    },
    state: 'Jammu And Kashmir',
    district: 'Reasi',
    city: 'Katra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5c3',
    },
    state: 'Jammu And Kashmir',
    district: 'Reasi',
    city: 'Mahore',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5c4',
    },
    state: 'Jammu And Kashmir',
    district: 'Reasi',
    city: 'Panthal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5c5',
    },
    state: 'Jammu And Kashmir',
    district: 'Reasi',
    city: 'Pouni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5c6',
    },
    state: 'Jammu And Kashmir',
    district: 'Reasi',
    city: 'Reasi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5c7',
    },
    state: 'Jammu And Kashmir',
    district: 'Reasi',
    city: 'Thakrakote',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5c8',
    },
    state: 'Jammu And Kashmir',
    district: 'Reasi',
    city: 'Thuroo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5c9',
    },
    state: 'Jammu And Kashmir',
    district: 'Samba',
    city: 'Ghagwal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5ca',
    },
    state: 'Jammu And Kashmir',
    district: 'Samba',
    city: 'Nud',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5cb',
    },
    state: 'Jammu And Kashmir',
    district: 'Samba',
    city: 'Purmandal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5cc',
    },
    state: 'Jammu And Kashmir',
    district: 'Samba',
    city: 'Purmandal Bari Brahamna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5cd',
    },
    state: 'Jammu And Kashmir',
    district: 'Samba',
    city: 'Rajpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5ce',
    },
    state: 'Jammu And Kashmir',
    district: 'Samba',
    city: 'Ramgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5cf',
    },
    state: 'Jammu And Kashmir',
    district: 'Ramban',
    city: 'Ukhral',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5d0',
    },
    state: 'Jammu And Kashmir',
    district: 'Samba',
    city: 'Samba',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5d1',
    },
    state: 'Jammu And Kashmir',
    district: 'Samba',
    city: 'Sumb',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5d2',
    },
    state: 'Jammu And Kashmir',
    district: 'Samba',
    city: 'Vijaypur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5d3',
    },
    state: 'Jammu And Kashmir',
    district: 'Shopian',
    city: 'Harman',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5d4',
    },
    state: 'Jammu And Kashmir',
    district: 'Shopian',
    city: 'Imamsahib',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5d5',
    },
    state: 'Jammu And Kashmir',
    district: 'Shopian',
    city: 'Kanji Ullar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5d6',
    },
    state: 'Jammu And Kashmir',
    district: 'Shopian',
    city: 'Kaprin',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5d7',
    },
    state: 'Jammu And Kashmir',
    district: 'Shopian',
    city: 'Keller',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5d8',
    },
    state: 'Jammu And Kashmir',
    district: 'Shopian',
    city: 'Ramnagri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5d9',
    },
    state: 'Jammu And Kashmir',
    district: 'Shopian',
    city: 'Shopian',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5da',
    },
    state: 'Jammu And Kashmir',
    district: 'Shopian',
    city: 'Zainpora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5db',
    },
    state: 'Jammu And Kashmir',
    district: 'Srinagar',
    city: 'Eidgah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5dc',
    },
    state: 'Jammu And Kashmir',
    district: 'Srinagar',
    city: 'Harwan Rural Area Dara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5dd',
    },
    state: 'Jammu And Kashmir',
    district: 'Srinagar',
    city: 'Khanmoh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5de',
    },
    state: 'Jammu And Kashmir',
    district: 'Srinagar',
    city: 'Qamarwari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5df',
    },
    state: 'Jammu And Kashmir',
    district: 'Srinagar',
    city: 'Srinagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5e0',
    },
    state: 'Jammu And Kashmir',
    district: 'Udhampur',
    city: 'Chanunta Dalsar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5e1',
    },
    state: 'Jammu And Kashmir',
    district: 'Udhampur',
    city: 'Chenani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5e2',
    },
    state: 'Jammu And Kashmir',
    district: 'Udhampur',
    city: 'Dudu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5e3',
    },
    state: 'Jammu And Kashmir',
    district: 'Udhampur',
    city: 'Ghordi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5e4',
    },
    state: 'Jammu And Kashmir',
    district: 'Udhampur',
    city: 'Jaganoo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5e5',
    },
    state: 'Jammu And Kashmir',
    district: 'Shopian',
    city: 'Chitragam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5e6',
    },
    state: 'Jammu And Kashmir',
    district: 'Udhampur',
    city: 'Khoon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5e7',
    },
    state: 'Jammu And Kashmir',
    district: 'Udhampur',
    city: 'Kulwanta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5e8',
    },
    state: 'Jammu And Kashmir',
    district: 'Udhampur',
    city: 'Latti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5e9',
    },
    state: 'Jammu And Kashmir',
    district: 'Udhampur',
    city: 'Moungri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5ea',
    },
    state: 'Jammu And Kashmir',
    district: 'Udhampur',
    city: 'Narsoo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5eb',
    },
    state: 'Jammu And Kashmir',
    district: 'Udhampur',
    city: 'Panchari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5ec',
    },
    state: 'Jammu And Kashmir',
    district: 'Udhampur',
    city: 'Parli Dhar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5ed',
    },
    state: 'Jammu And Kashmir',
    district: 'Udhampur',
    city: 'Ramnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5ee',
    },
    state: 'Jammu And Kashmir',
    district: 'Udhampur',
    city: 'Sewna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5ef',
    },
    state: 'Jammu And Kashmir',
    district: 'Udhampur',
    city: 'Tikri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5f0',
    },
    state: 'Jammu And Kashmir',
    district: 'Udhampur',
    city: 'Udhampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5f1',
    },
    state: 'Jharkhand',
    district: 'Bokaro',
    city: 'Bermo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5f2',
    },
    state: 'Jharkhand',
    district: 'Bokaro',
    city: 'Chandankiyari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5f3',
    },
    state: 'Jharkhand',
    district: 'Bokaro',
    city: 'Chandrapura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5f4',
    },
    state: 'Jharkhand',
    district: 'Bokaro',
    city: 'Chas',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5f5',
    },
    state: 'Jharkhand',
    district: 'Bokaro',
    city: 'Gomia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5f6',
    },
    state: 'Jharkhand',
    district: 'Bokaro',
    city: 'Jaridih',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5f7',
    },
    state: 'Jharkhand',
    district: 'Bokaro',
    city: 'Kasmar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5f8',
    },
    state: 'Jharkhand',
    district: 'Bokaro',
    city: 'Nawadih',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5f9',
    },
    state: 'Jharkhand',
    district: 'Bokaro',
    city: 'Peterwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5fa',
    },
    state: 'Jharkhand',
    district: 'Chatra',
    city: 'Chatra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5fb',
    },
    state: 'Jammu And Kashmir',
    district: 'Udhampur',
    city: 'Majalta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5fc',
    },
    state: 'Jharkhand',
    district: 'Chatra',
    city: 'Giddhor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5fd',
    },
    state: 'Jharkhand',
    district: 'Chatra',
    city: 'Itkhori',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5fe',
    },
    state: 'Jharkhand',
    district: 'Chatra',
    city: 'Kanhachatti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e5ff',
    },
    state: 'Jharkhand',
    district: 'Chatra',
    city: 'Lawalong',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e600',
    },
    state: 'Jharkhand',
    district: 'Chatra',
    city: 'Mayurhand',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e601',
    },
    state: 'Jharkhand',
    district: 'Chatra',
    city: 'Pathalgada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e602',
    },
    state: 'Jharkhand',
    district: 'Chatra',
    city: 'Pratappur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e603',
    },
    state: 'Jharkhand',
    district: 'Chatra',
    city: 'Shaligram Ram Narayanpur Alias Hunterganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e604',
    },
    state: 'Jharkhand',
    district: 'Chatra',
    city: 'Simaria',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e605',
    },
    state: 'Jharkhand',
    district: 'Chatra',
    city: 'Tandwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e606',
    },
    state: 'Jharkhand',
    district: 'Deoghar',
    city: 'Deoghar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e607',
    },
    state: 'Jharkhand',
    district: 'Deoghar',
    city: 'Devipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e608',
    },
    state: 'Jharkhand',
    district: 'Deoghar',
    city: 'Karown',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e609',
    },
    state: 'Jharkhand',
    district: 'Deoghar',
    city: 'Madhupur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e60a',
    },
    state: 'Jharkhand',
    district: 'Deoghar',
    city: 'Margomunda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e60b',
    },
    state: 'Jharkhand',
    district: 'Deoghar',
    city: 'Mohanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e60c',
    },
    state: 'Jharkhand',
    district: 'Deoghar',
    city: 'Palojori',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e60d',
    },
    state: 'Jharkhand',
    district: 'Deoghar',
    city: 'Sarath',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e60e',
    },
    state: 'Jharkhand',
    district: 'Deoghar',
    city: 'Sarwan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e60f',
    },
    state: 'Jharkhand',
    district: 'Deoghar',
    city: 'Sonaraithari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e610',
    },
    state: 'Jharkhand',
    district: 'Dhanbad',
    city: 'Baghmara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e611',
    },
    state: 'Jharkhand',
    district: 'Chatra',
    city: 'Kunda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e612',
    },
    state: 'Jharkhand',
    district: 'Dhanbad',
    city: 'Baliapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e613',
    },
    state: 'Jharkhand',
    district: 'Dhanbad',
    city: 'Dhanbad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e614',
    },
    state: 'Jharkhand',
    district: 'Dhanbad',
    city: 'Egarkund',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e615',
    },
    state: 'Jharkhand',
    district: 'Dhanbad',
    city: 'Kaliasol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e616',
    },
    state: 'Jharkhand',
    district: 'Dhanbad',
    city: 'Nirsa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e617',
    },
    state: 'Jharkhand',
    district: 'Dhanbad',
    city: 'Purvi Tundi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e618',
    },
    state: 'Jharkhand',
    district: 'Dhanbad',
    city: 'Topchanchi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e619',
    },
    state: 'Jharkhand',
    district: 'Dhanbad',
    city: 'Tundi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e61a',
    },
    state: 'Jharkhand',
    district: 'Dumka',
    city: 'Dumka',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e61b',
    },
    state: 'Jharkhand',
    district: 'Dumka',
    city: 'Gopikander',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e61c',
    },
    state: 'Jharkhand',
    district: 'Dumka',
    city: 'Jama',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e61d',
    },
    state: 'Jharkhand',
    district: 'Dumka',
    city: 'Jarmundi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e61e',
    },
    state: 'Jharkhand',
    district: 'Dumka',
    city: 'Kathikund',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e61f',
    },
    state: 'Jharkhand',
    district: 'Dumka',
    city: 'Masaliya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e620',
    },
    state: 'Jharkhand',
    district: 'Dumka',
    city: 'Ramgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e621',
    },
    state: 'Jharkhand',
    district: 'Dumka',
    city: 'Ranishwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e622',
    },
    state: 'Jharkhand',
    district: 'Dumka',
    city: 'Saraiyahat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e623',
    },
    state: 'Jharkhand',
    district: 'Dumka',
    city: 'Sikaripara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e624',
    },
    state: 'Jharkhand',
    district: 'East Singhbhum',
    city: 'Bahragora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e625',
    },
    state: 'Jharkhand',
    district: 'East Singhbhum',
    city: 'Boram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e626',
    },
    state: 'Jharkhand',
    district: 'East Singhbhum',
    city: 'Chakulia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e627',
    },
    state: 'Jharkhand',
    district: 'Dhanbad',
    city: 'Govindpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e628',
    },
    state: 'Jharkhand',
    district: 'East Singhbhum',
    city: 'Dhalbhumgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e629',
    },
    state: 'Jharkhand',
    district: 'East Singhbhum',
    city: 'Dumaria',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e62a',
    },
    state: 'Jharkhand',
    district: 'East Singhbhum',
    city: 'Ghatshila',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e62b',
    },
    state: 'Jharkhand',
    district: 'East Singhbhum',
    city: 'Gurabanda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e62c',
    },
    state: 'Jharkhand',
    district: 'East Singhbhum',
    city: 'Musabani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e62d',
    },
    state: 'Jharkhand',
    district: 'East Singhbhum',
    city: 'Patamda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e62e',
    },
    state: 'Jharkhand',
    district: 'East Singhbhum',
    city: 'Potka',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e62f',
    },
    state: 'Jharkhand',
    district: 'Garhwa',
    city: 'Bardiha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e630',
    },
    state: 'Jharkhand',
    district: 'Garhwa',
    city: 'Bargad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e631',
    },
    state: 'Jharkhand',
    district: 'Garhwa',
    city: 'Bhandaria',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e632',
    },
    state: 'Jharkhand',
    district: 'Garhwa',
    city: 'Bhawnathpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e633',
    },
    state: 'Jharkhand',
    district: 'Garhwa',
    city: 'Bishunpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e634',
    },
    state: 'Jharkhand',
    district: 'Garhwa',
    city: 'Chinia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e635',
    },
    state: 'Jharkhand',
    district: 'Garhwa',
    city: 'Danda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e636',
    },
    state: 'Jharkhand',
    district: 'Garhwa',
    city: 'Dandai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e637',
    },
    state: 'Jharkhand',
    district: 'Garhwa',
    city: 'Dhurki',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e638',
    },
    state: 'Jharkhand',
    district: 'Garhwa',
    city: 'Garhwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e639',
    },
    state: 'Jharkhand',
    district: 'Garhwa',
    city: 'Kandi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e63a',
    },
    state: 'Jharkhand',
    district: 'Garhwa',
    city: 'Ketar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e63b',
    },
    state: 'Jharkhand',
    district: 'Garhwa',
    city: 'Kharaundhi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e63c',
    },
    state: 'Jharkhand',
    district: 'Garhwa',
    city: 'Manjhiaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e63d',
    },
    state: 'Jharkhand',
    district: 'East Singhbhum',
    city: 'Golmuri Cum Jugsalai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e63e',
    },
    state: 'Jharkhand',
    district: 'Garhwa',
    city: 'Meral',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e63f',
    },
    state: 'Jharkhand',
    district: 'Garhwa',
    city: 'Nagar Untari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e640',
    },
    state: 'Jharkhand',
    district: 'Garhwa',
    city: 'Ramkanda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e641',
    },
    state: 'Jharkhand',
    district: 'Garhwa',
    city: 'Ranka',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e642',
    },
    state: 'Jharkhand',
    district: 'Garhwa',
    city: 'Sagma',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e643',
    },
    state: 'Jharkhand',
    district: 'Giridih',
    city: 'Bagodar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e644',
    },
    state: 'Jharkhand',
    district: 'Giridih',
    city: 'Bengabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e645',
    },
    state: 'Jharkhand',
    district: 'Giridih',
    city: 'Birni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e646',
    },
    state: 'Jharkhand',
    district: 'Giridih',
    city: 'Deori',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e647',
    },
    state: 'Jharkhand',
    district: 'Giridih',
    city: 'Dhanwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e648',
    },
    state: 'Jharkhand',
    district: 'Giridih',
    city: 'Dumri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e649',
    },
    state: 'Jharkhand',
    district: 'Giridih',
    city: 'Gandey',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e64a',
    },
    state: 'Jharkhand',
    district: 'Giridih',
    city: 'Gawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e64b',
    },
    state: 'Jharkhand',
    district: 'Giridih',
    city: 'Giridih',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e64c',
    },
    state: 'Jharkhand',
    district: 'Giridih',
    city: 'Jamua',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e64d',
    },
    state: 'Jharkhand',
    district: 'Giridih',
    city: 'Pirtand',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e64e',
    },
    state: 'Jharkhand',
    district: 'Giridih',
    city: 'Suriya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e64f',
    },
    state: 'Jharkhand',
    district: 'Giridih',
    city: 'Tisri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e650',
    },
    state: 'Jharkhand',
    district: 'Godda',
    city: 'Basantray',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e651',
    },
    state: 'Jharkhand',
    district: 'Godda',
    city: 'Boarijor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e652',
    },
    state: 'Jharkhand',
    district: 'Godda',
    city: 'Godda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e653',
    },
    state: 'Jharkhand',
    district: 'Garhwa',
    city: 'Ramna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e654',
    },
    state: 'Jharkhand',
    district: 'Godda',
    city: 'Mahagama',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e655',
    },
    state: 'Jharkhand',
    district: 'Godda',
    city: 'Meharma',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e656',
    },
    state: 'Jharkhand',
    district: 'Godda',
    city: 'Pathargama',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e657',
    },
    state: 'Jharkhand',
    district: 'Godda',
    city: 'Sundarpahari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e658',
    },
    state: 'Jharkhand',
    district: 'Godda',
    city: 'Thakurgangti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e659',
    },
    state: 'Jharkhand',
    district: 'Gumla',
    city: 'Albert Ekka',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e65a',
    },
    state: 'Jharkhand',
    district: 'Gumla',
    city: 'Basia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e65b',
    },
    state: 'Jharkhand',
    district: 'Gumla',
    city: 'Bharno',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e65c',
    },
    state: 'Jharkhand',
    district: 'Gumla',
    city: 'Bishunpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e65d',
    },
    state: 'Jharkhand',
    district: 'Gumla',
    city: 'Chainpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e65e',
    },
    state: 'Jharkhand',
    district: 'Gumla',
    city: 'Dumri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e65f',
    },
    state: 'Jharkhand',
    district: 'Gumla',
    city: 'Ghaghra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e660',
    },
    state: 'Jharkhand',
    district: 'Gumla',
    city: 'Gumla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e661',
    },
    state: 'Jharkhand',
    district: 'Gumla',
    city: 'Kamdara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e662',
    },
    state: 'Jharkhand',
    district: 'Gumla',
    city: 'Palkot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e663',
    },
    state: 'Jharkhand',
    district: 'Gumla',
    city: 'Raidih',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e664',
    },
    state: 'Jharkhand',
    district: 'Gumla',
    city: 'Sisai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e665',
    },
    state: 'Jharkhand',
    district: 'Hazaribagh',
    city: 'Barhi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e666',
    },
    state: 'Jharkhand',
    district: 'Hazaribagh',
    city: 'Barkagaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e667',
    },
    state: 'Jharkhand',
    district: 'Hazaribagh',
    city: 'Barkatha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e668',
    },
    state: 'Jharkhand',
    district: 'Hazaribagh',
    city: 'Bishnugarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e669',
    },
    state: 'Jharkhand',
    district: 'Godda',
    city: 'Poraiyahat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e66a',
    },
    state: 'Jharkhand',
    district: 'Hazaribagh',
    city: 'Chalkusha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e66b',
    },
    state: 'Jharkhand',
    district: 'Hazaribagh',
    city: 'Chouparan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e66c',
    },
    state: 'Jharkhand',
    district: 'Hazaribagh',
    city: 'Churchu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e66d',
    },
    state: 'Jharkhand',
    district: 'Hazaribagh',
    city: 'Daru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e66e',
    },
    state: 'Jharkhand',
    district: 'Hazaribagh',
    city: 'Ichak',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e66f',
    },
    state: 'Jharkhand',
    district: 'Hazaribagh',
    city: 'Katkamdag',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e670',
    },
    state: 'Jharkhand',
    district: 'Hazaribagh',
    city: 'Katkamsandi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e671',
    },
    state: 'Jharkhand',
    district: 'Hazaribagh',
    city: 'Keredari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e672',
    },
    state: 'Jharkhand',
    district: 'Hazaribagh',
    city: 'Padma',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e673',
    },
    state: 'Jharkhand',
    district: 'Hazaribagh',
    city: 'Sadar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e674',
    },
    state: 'Jharkhand',
    district: 'Hazaribagh',
    city: 'Tatijhariya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e675',
    },
    state: 'Jharkhand',
    district: 'Jamtara',
    city: 'Fatehpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e676',
    },
    state: 'Jharkhand',
    district: 'Jamtara',
    city: 'Jamtara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e677',
    },
    state: 'Jharkhand',
    district: 'Jamtara',
    city: 'Karmatanr Vidyasagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e678',
    },
    state: 'Jharkhand',
    district: 'Jamtara',
    city: 'Kundhit',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e679',
    },
    state: 'Jharkhand',
    district: 'Jamtara',
    city: 'Nala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e67a',
    },
    state: 'Jharkhand',
    district: 'Jamtara',
    city: 'Narayanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e67b',
    },
    state: 'Jharkhand',
    district: 'Khunti',
    city: 'Arki',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e67c',
    },
    state: 'Jharkhand',
    district: 'Khunti',
    city: 'Karra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e67d',
    },
    state: 'Jharkhand',
    district: 'Khunti',
    city: 'Khunti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e67e',
    },
    state: 'Jharkhand',
    district: 'Khunti',
    city: 'Murhu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e67f',
    },
    state: 'Jharkhand',
    district: 'Hazaribagh',
    city: 'Dadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e680',
    },
    state: 'Jharkhand',
    district: 'Khunti',
    city: 'Rania',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e681',
    },
    state: 'Jharkhand',
    district: 'Khunti',
    city: 'Torpa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e682',
    },
    state: 'Jharkhand',
    district: 'Koderma',
    city: 'Chandwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e683',
    },
    state: 'Jharkhand',
    district: 'Koderma',
    city: 'Jainagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e684',
    },
    state: 'Jharkhand',
    district: 'Koderma',
    city: 'Koderma',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e685',
    },
    state: 'Jharkhand',
    district: 'Koderma',
    city: 'Markacho',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e686',
    },
    state: 'Jharkhand',
    district: 'Koderma',
    city: 'Satgawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e687',
    },
    state: 'Jharkhand',
    district: 'Latehar',
    city: 'Balumath',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e688',
    },
    state: 'Jharkhand',
    district: 'Latehar',
    city: 'Bariyatu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e689',
    },
    state: 'Jharkhand',
    district: 'Latehar',
    city: 'Barwadih',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e68a',
    },
    state: 'Jharkhand',
    district: 'Latehar',
    city: 'Chandwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e68b',
    },
    state: 'Jharkhand',
    district: 'Latehar',
    city: 'Garu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e68c',
    },
    state: 'Jharkhand',
    district: 'Latehar',
    city: 'Herhanj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e68d',
    },
    state: 'Jharkhand',
    district: 'Latehar',
    city: 'Latehar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e68e',
    },
    state: 'Jharkhand',
    district: 'Latehar',
    city: 'Mahuadanr',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e68f',
    },
    state: 'Jharkhand',
    district: 'Latehar',
    city: 'Manika',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e690',
    },
    state: 'Jharkhand',
    district: 'Latehar',
    city: 'Saryu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e691',
    },
    state: 'Jharkhand',
    district: 'Lohardaga',
    city: 'Bhandra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e692',
    },
    state: 'Jharkhand',
    district: 'Lohardaga',
    city: 'Kairo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e693',
    },
    state: 'Jharkhand',
    district: 'Lohardaga',
    city: 'Kisko',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e694',
    },
    state: 'Jharkhand',
    district: 'Lohardaga',
    city: 'Kuru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e695',
    },
    state: 'Jharkhand',
    district: 'Koderma',
    city: 'Domchanch',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e696',
    },
    state: 'Jharkhand',
    district: 'Lohardaga',
    city: 'Lohardaga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e697',
    },
    state: 'Jharkhand',
    district: 'Lohardaga',
    city: 'Peshrar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e698',
    },
    state: 'Jharkhand',
    district: 'Lohardaga',
    city: 'Senha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e699',
    },
    state: 'Jharkhand',
    district: 'Pakur',
    city: 'Hiranpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e69a',
    },
    state: 'Jharkhand',
    district: 'Pakur',
    city: 'Littipara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e69b',
    },
    state: 'Jharkhand',
    district: 'Pakur',
    city: 'Maheshpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e69c',
    },
    state: 'Jharkhand',
    district: 'Pakur',
    city: 'Pakur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e69d',
    },
    state: 'Jharkhand',
    district: 'Pakur',
    city: 'Pakuria',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e69e',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Bishrampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e69f',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Chainpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6a0',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Chhatarpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6a1',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Haidernagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6a2',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Hariharganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6a3',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Hussainabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6a4',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Lesliganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6a5',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Manatu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6a6',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Medininagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6a7',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Mohamadganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6a8',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Nawa Bazar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6a9',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Nawdiha Bazar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6aa',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Padwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6ab',
    },
    state: 'Jharkhand',
    district: 'Pakur',
    city: 'Amrapara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6ac',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Pandu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6ad',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Panki',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6ae',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Patan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6af',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Ramgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6b0',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Satbarwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6b1',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Tarhasi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6b2',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Untari Road',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6b3',
    },
    state: 'Jharkhand',
    district: 'Ramgarh',
    city: 'Chitarpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6b4',
    },
    state: 'Jharkhand',
    district: 'Ramgarh',
    city: 'Dulmi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6b5',
    },
    state: 'Jharkhand',
    district: 'Ramgarh',
    city: 'Gola',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6b6',
    },
    state: 'Jharkhand',
    district: 'Ramgarh',
    city: 'Mandu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6b7',
    },
    state: 'Jharkhand',
    district: 'Ramgarh',
    city: 'Patratu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6b8',
    },
    state: 'Jharkhand',
    district: 'Ramgarh',
    city: 'Ramgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6b9',
    },
    state: 'Jharkhand',
    district: 'Ranchi',
    city: 'Angara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6ba',
    },
    state: 'Jharkhand',
    district: 'Ranchi',
    city: 'Bero',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6bb',
    },
    state: 'Jharkhand',
    district: 'Ranchi',
    city: 'Bundu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6bc',
    },
    state: 'Jharkhand',
    district: 'Ranchi',
    city: 'Burmu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6bd',
    },
    state: 'Jharkhand',
    district: 'Ranchi',
    city: 'Chanho',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6be',
    },
    state: 'Jharkhand',
    district: 'Ranchi',
    city: 'Itki',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6bf',
    },
    state: 'Jharkhand',
    district: 'Ranchi',
    city: 'Kanke',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6c0',
    },
    state: 'Jharkhand',
    district: 'Ranchi',
    city: 'Khelari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6c1',
    },
    state: 'Jharkhand',
    district: 'Palamu',
    city: 'Pipra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6c2',
    },
    state: 'Jharkhand',
    district: 'Ranchi',
    city: 'Lapung',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6c3',
    },
    state: 'Jharkhand',
    district: 'Ranchi',
    city: 'Mandar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6c4',
    },
    state: 'Jharkhand',
    district: 'Ranchi',
    city: 'Nagri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6c5',
    },
    state: 'Jharkhand',
    district: 'Ranchi',
    city: 'Ormanjhi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6c6',
    },
    state: 'Jharkhand',
    district: 'Ranchi',
    city: 'Rahe',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6c7',
    },
    state: 'Jharkhand',
    district: 'Ranchi',
    city: 'Ratu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6c8',
    },
    state: 'Jharkhand',
    district: 'Ranchi',
    city: 'Silli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6c9',
    },
    state: 'Jharkhand',
    district: 'Ranchi',
    city: 'Sonahatu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6ca',
    },
    state: 'Jharkhand',
    district: 'Ranchi',
    city: 'Tamar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6cb',
    },
    state: 'Jharkhand',
    district: 'Sahibganj',
    city: 'Barhait',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6cc',
    },
    state: 'Jharkhand',
    district: 'Sahibganj',
    city: 'Barharwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6cd',
    },
    state: 'Jharkhand',
    district: 'Sahibganj',
    city: 'Borio',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6ce',
    },
    state: 'Jharkhand',
    district: 'Sahibganj',
    city: 'Mandro',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6cf',
    },
    state: 'Jharkhand',
    district: 'Sahibganj',
    city: 'Pathna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6d0',
    },
    state: 'Jharkhand',
    district: 'Sahibganj',
    city: 'Rajmahal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6d1',
    },
    state: 'Jharkhand',
    district: 'Sahibganj',
    city: 'Sahibganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6d2',
    },
    state: 'Jharkhand',
    district: 'Sahibganj',
    city: 'Taljhari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6d3',
    },
    state: 'Jharkhand',
    district: 'Sahibganj',
    city: 'Udhwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6d4',
    },
    state: 'Jharkhand',
    district: 'Seraikela-Kharsawan',
    city: 'Chandil',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6d5',
    },
    state: 'Jharkhand',
    district: 'Seraikela-Kharsawan',
    city: 'Gamharia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6d6',
    },
    state: 'Jharkhand',
    district: 'Seraikela-Kharsawan',
    city: 'Ichagarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6d7',
    },
    state: 'Jharkhand',
    district: 'Ranchi',
    city: 'Namkum',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6d8',
    },
    state: 'Jharkhand',
    district: 'Seraikela-Kharsawan',
    city: 'Kharsawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6d9',
    },
    state: 'Jharkhand',
    district: 'Seraikela-Kharsawan',
    city: 'Kuchai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6da',
    },
    state: 'Jharkhand',
    district: 'Seraikela-Kharsawan',
    city: 'Kukru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6db',
    },
    state: 'Jharkhand',
    district: 'Seraikela-Kharsawan',
    city: 'Rajnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6dc',
    },
    state: 'Jharkhand',
    district: 'Seraikela-Kharsawan',
    city: 'Seraikella',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6dd',
    },
    state: 'Jharkhand',
    district: 'Simdega',
    city: 'Bano',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6de',
    },
    state: 'Jharkhand',
    district: 'Simdega',
    city: 'Bansjore',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6df',
    },
    state: 'Jharkhand',
    district: 'Simdega',
    city: 'Bolba',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6e0',
    },
    state: 'Jharkhand',
    district: 'Simdega',
    city: 'Jaldega',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6e1',
    },
    state: 'Jharkhand',
    district: 'Simdega',
    city: 'Kersai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6e2',
    },
    state: 'Jharkhand',
    district: 'Simdega',
    city: 'Kolebira',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6e3',
    },
    state: 'Jharkhand',
    district: 'Simdega',
    city: 'Kurdeg',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6e4',
    },
    state: 'Jharkhand',
    district: 'Simdega',
    city: 'Pakartanr',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6e5',
    },
    state: 'Jharkhand',
    district: 'Simdega',
    city: 'Simdega',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6e6',
    },
    state: 'Jharkhand',
    district: 'Simdega',
    city: 'Thethaitanger',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6e7',
    },
    state: 'Jharkhand',
    district: 'West Singhbhum',
    city: 'Anandpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6e8',
    },
    state: 'Jharkhand',
    district: 'West Singhbhum',
    city: 'Bandgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6e9',
    },
    state: 'Jharkhand',
    district: 'West Singhbhum',
    city: 'Chaibasa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6ea',
    },
    state: 'Jharkhand',
    district: 'West Singhbhum',
    city: 'Chakradharpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6eb',
    },
    state: 'Jharkhand',
    district: 'West Singhbhum',
    city: 'Goelkera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6ec',
    },
    state: 'Jharkhand',
    district: 'West Singhbhum',
    city: 'Gudri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6ed',
    },
    state: 'Jharkhand',
    district: 'Seraikela-Kharsawan',
    city: 'Nimdih',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6ee',
    },
    state: 'Jharkhand',
    district: 'West Singhbhum',
    city: 'Hatgamharia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6ef',
    },
    state: 'Jharkhand',
    district: 'West Singhbhum',
    city: 'Jagannathpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6f0',
    },
    state: 'Jharkhand',
    district: 'West Singhbhum',
    city: 'Jhinkpani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6f1',
    },
    state: 'Jharkhand',
    district: 'West Singhbhum',
    city: 'Kumardungi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6f2',
    },
    state: 'Jharkhand',
    district: 'West Singhbhum',
    city: 'Manjhari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6f3',
    },
    state: 'Jharkhand',
    district: 'West Singhbhum',
    city: 'Manjhgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6f4',
    },
    state: 'Jharkhand',
    district: 'West Singhbhum',
    city: 'Manoharpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6f5',
    },
    state: 'Jharkhand',
    district: 'West Singhbhum',
    city: 'Noamundi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6f6',
    },
    state: 'Jharkhand',
    district: 'West Singhbhum',
    city: 'Sonua',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6f7',
    },
    state: 'Jharkhand',
    district: 'West Singhbhum',
    city: 'Tantnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6f8',
    },
    state: 'Jharkhand',
    district: 'West Singhbhum',
    city: 'Tonto',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6f9',
    },
    state: 'Karnataka',
    district: 'Bagalkote',
    city: 'Badami',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6fa',
    },
    state: 'Karnataka',
    district: 'Bagalkote',
    city: 'Bagalkot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6fb',
    },
    state: 'Karnataka',
    district: 'Bagalkote',
    city: 'Bilagi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6fc',
    },
    state: 'Karnataka',
    district: 'Bagalkote',
    city: 'Guledagudda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6fd',
    },
    state: 'Karnataka',
    district: 'Bagalkote',
    city: 'Hungund',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6fe',
    },
    state: 'Karnataka',
    district: 'Bagalkote',
    city: 'Ilkal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e6ff',
    },
    state: 'Karnataka',
    district: 'Bagalkote',
    city: 'Jamkhandi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e700',
    },
    state: 'Karnataka',
    district: 'Bagalkote',
    city: 'Mudhol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e701',
    },
    state: 'Karnataka',
    district: 'Bagalkote',
    city: 'Rabakavi Banahatti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e702',
    },
    state: 'Karnataka',
    district: 'Ballari',
    city: 'Ballari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e703',
    },
    state: 'Jharkhand',
    district: 'West Singhbhum',
    city: 'Khuntpani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e704',
    },
    state: 'Karnataka',
    district: 'Ballari',
    city: 'Hadagalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e705',
    },
    state: 'Karnataka',
    district: 'Ballari',
    city: 'Hagaribommanahalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e706',
    },
    state: 'Karnataka',
    district: 'Ballari',
    city: 'Harappanahalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e707',
    },
    state: 'Karnataka',
    district: 'Ballari',
    city: 'Kampli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e708',
    },
    state: 'Karnataka',
    district: 'Ballari',
    city: 'Kottur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e709',
    },
    state: 'Karnataka',
    district: 'Ballari',
    city: 'Kudligi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e70a',
    },
    state: 'Karnataka',
    district: 'Ballari',
    city: 'Kurugodu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e70b',
    },
    state: 'Karnataka',
    district: 'Ballari',
    city: 'Sandur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e70c',
    },
    state: 'Karnataka',
    district: 'Ballari',
    city: 'Siruguppa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e70d',
    },
    state: 'Karnataka',
    district: 'Belagavi',
    city: 'Athni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e70e',
    },
    state: 'Karnataka',
    district: 'Belagavi',
    city: 'Belagavi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e70f',
    },
    state: 'Karnataka',
    district: 'Belagavi',
    city: 'Bylahongal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e710',
    },
    state: 'Karnataka',
    district: 'Belagavi',
    city: 'Chikodi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e711',
    },
    state: 'Karnataka',
    district: 'Belagavi',
    city: 'Gokak',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e712',
    },
    state: 'Karnataka',
    district: 'Belagavi',
    city: 'Hukeri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e713',
    },
    state: 'Karnataka',
    district: 'Belagavi',
    city: 'Kagawada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e714',
    },
    state: 'Karnataka',
    district: 'Belagavi',
    city: 'Khanapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e715',
    },
    state: 'Karnataka',
    district: 'Belagavi',
    city: 'Kittur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e716',
    },
    state: 'Karnataka',
    district: 'Belagavi',
    city: 'Mudalagi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e717',
    },
    state: 'Karnataka',
    district: 'Belagavi',
    city: 'Nippani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e718',
    },
    state: 'Karnataka',
    district: 'Belagavi',
    city: 'Ramdurg',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e719',
    },
    state: 'Karnataka',
    district: 'Ballari',
    city: 'Hosapete',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e71a',
    },
    state: 'Karnataka',
    district: 'Belagavi',
    city: 'Raybag',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e71b',
    },
    state: 'Karnataka',
    district: 'Belagavi',
    city: 'Savadathi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e71c',
    },
    state: 'Karnataka',
    district: 'Bengaluru Rural',
    city: 'Devanhalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e71d',
    },
    state: 'Karnataka',
    district: 'Bengaluru Rural',
    city: 'Hoskote',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e71e',
    },
    state: 'Karnataka',
    district: 'Bengaluru Rural',
    city: 'Nelamangala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e71f',
    },
    state: 'Karnataka',
    district: 'Bengaluru Urban',
    city: 'Anekal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e720',
    },
    state: 'Karnataka',
    district: 'Bengaluru Urban',
    city: 'Bengaluru East',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e721',
    },
    state: 'Karnataka',
    district: 'Bengaluru Urban',
    city: 'Bengaluru North',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e722',
    },
    state: 'Karnataka',
    district: 'Bengaluru Urban',
    city: 'Bengaluru South',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e723',
    },
    state: 'Karnataka',
    district: 'Bengaluru Urban',
    city: 'Yelahanka',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e724',
    },
    state: 'Karnataka',
    district: 'Bidar',
    city: 'Aurad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e725',
    },
    state: 'Karnataka',
    district: 'Bidar',
    city: 'Basavakalyan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e726',
    },
    state: 'Karnataka',
    district: 'Bidar',
    city: 'Bhalki',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e727',
    },
    state: 'Karnataka',
    district: 'Bidar',
    city: 'Bidar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e728',
    },
    state: 'Karnataka',
    district: 'Bidar',
    city: 'Chittaguppa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e729',
    },
    state: 'Karnataka',
    district: 'Bidar',
    city: 'Hulasuru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e72a',
    },
    state: 'Karnataka',
    district: 'Bidar',
    city: 'Humnabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e72b',
    },
    state: 'Karnataka',
    district: 'Bidar',
    city: 'Kamalanagara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e72c',
    },
    state: 'Karnataka',
    district: 'Chamarajanagara',
    city: 'Chamarajanagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e72d',
    },
    state: 'Karnataka',
    district: 'Chamarajanagara',
    city: 'Gundlupet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e72e',
    },
    state: 'Karnataka',
    district: 'Chamarajanagara',
    city: 'Hanuru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e72f',
    },
    state: 'Karnataka',
    district: 'Bengaluru Rural',
    city: 'Dodballapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e730',
    },
    state: 'Karnataka',
    district: 'Chamarajanagara',
    city: 'Kollegala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e731',
    },
    state: 'Karnataka',
    district: 'Chamarajanagara',
    city: 'Yelandur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e732',
    },
    state: 'Karnataka',
    district: 'Chikkaballapura',
    city: 'Bagepalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e733',
    },
    state: 'Karnataka',
    district: 'Chikkaballapura',
    city: 'Chintamani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e734',
    },
    state: 'Karnataka',
    district: 'Chikkaballapura',
    city: 'Gauribidanur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e735',
    },
    state: 'Karnataka',
    district: 'Chikkaballapura',
    city: 'Gudibanda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e736',
    },
    state: 'Karnataka',
    district: 'Chikkaballapura',
    city: 'Sidlaghatta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e737',
    },
    state: 'Karnataka',
    district: 'Chikkamagaluru',
    city: 'Ajjampura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e738',
    },
    state: 'Karnataka',
    district: 'Chikkamagaluru',
    city: 'Chikkamagaluru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e739',
    },
    state: 'Karnataka',
    district: 'Chikkamagaluru',
    city: 'Kadur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e73a',
    },
    state: 'Karnataka',
    district: 'Chikkamagaluru',
    city: 'Koppa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e73b',
    },
    state: 'Karnataka',
    district: 'Chikkamagaluru',
    city: 'Mudigere',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e73c',
    },
    state: 'Karnataka',
    district: 'Chikkamagaluru',
    city: 'Narasimharajapura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e73d',
    },
    state: 'Karnataka',
    district: 'Chikkamagaluru',
    city: 'Sringeri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e73e',
    },
    state: 'Karnataka',
    district: 'Chikkamagaluru',
    city: 'Tarikere',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e73f',
    },
    state: 'Karnataka',
    district: 'Chitradurga',
    city: 'Challakere',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e740',
    },
    state: 'Karnataka',
    district: 'Chitradurga',
    city: 'Chitradurga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e741',
    },
    state: 'Karnataka',
    district: 'Chitradurga',
    city: 'Hiriyur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e742',
    },
    state: 'Karnataka',
    district: 'Chitradurga',
    city: 'Holalkere',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e743',
    },
    state: 'Karnataka',
    district: 'Chitradurga',
    city: 'Hosdurga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e744',
    },
    state: 'Karnataka',
    district: 'Chitradurga',
    city: 'Molakalmuru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e745',
    },
    state: 'Karnataka',
    district: 'Chikkaballapura',
    city: 'Chikballapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e746',
    },
    state: 'Karnataka',
    district: 'Dakshina Kannada',
    city: 'Bantval',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e747',
    },
    state: 'Karnataka',
    district: 'Dakshina Kannada',
    city: 'Beltangadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e748',
    },
    state: 'Karnataka',
    district: 'Dakshina Kannada',
    city: 'Kadaba',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e749',
    },
    state: 'Karnataka',
    district: 'Dakshina Kannada',
    city: 'Mudubidare',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e74a',
    },
    state: 'Karnataka',
    district: 'Dakshina Kannada',
    city: 'Puttur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e74b',
    },
    state: 'Karnataka',
    district: 'Dakshina Kannada',
    city: 'Sulya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e74c',
    },
    state: 'Karnataka',
    district: 'Davangere',
    city: 'Channagiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e74d',
    },
    state: 'Karnataka',
    district: 'Davangere',
    city: 'Davanagere',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e74e',
    },
    state: 'Karnataka',
    district: 'Davangere',
    city: 'Harihara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e74f',
    },
    state: 'Karnataka',
    district: 'Davangere',
    city: 'Honnali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e750',
    },
    state: 'Karnataka',
    district: 'Davangere',
    city: 'Jagalur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e751',
    },
    state: 'Karnataka',
    district: 'Davangere',
    city: 'Nyamathi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e752',
    },
    state: 'Karnataka',
    district: 'Dharwad',
    city: 'Alnavar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e753',
    },
    state: 'Karnataka',
    district: 'Dharwad',
    city: 'Annigeri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e754',
    },
    state: 'Karnataka',
    district: 'Dharwad',
    city: 'Dharwad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e755',
    },
    state: 'Karnataka',
    district: 'Dharwad',
    city: 'Hubali Urban',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e756',
    },
    state: 'Karnataka',
    district: 'Dharwad',
    city: 'Hubballi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e757',
    },
    state: 'Karnataka',
    district: 'Dharwad',
    city: 'Kalghatgi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e758',
    },
    state: 'Karnataka',
    district: 'Dharwad',
    city: 'Kundgol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e759',
    },
    state: 'Karnataka',
    district: 'Dharwad',
    city: 'Navalgund',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e75a',
    },
    state: 'Karnataka',
    district: 'Gadag',
    city: 'Gadag',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e75b',
    },
    state: 'Karnataka',
    district: 'Dakshina Kannada',
    city: 'Mangaluru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e75c',
    },
    state: 'Karnataka',
    district: 'Gadag',
    city: 'Gajendragad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e75d',
    },
    state: 'Karnataka',
    district: 'Gadag',
    city: 'Laxmeshwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e75e',
    },
    state: 'Karnataka',
    district: 'Gadag',
    city: 'Mundaragi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e75f',
    },
    state: 'Karnataka',
    district: 'Gadag',
    city: 'Ron',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e760',
    },
    state: 'Karnataka',
    district: 'Gadag',
    city: 'Shirahatti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e761',
    },
    state: 'Karnataka',
    district: 'Hassan',
    city: 'Alur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e762',
    },
    state: 'Karnataka',
    district: 'Hassan',
    city: 'Arkalgud',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e763',
    },
    state: 'Karnataka',
    district: 'Hassan',
    city: 'Arsikere',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e764',
    },
    state: 'Karnataka',
    district: 'Hassan',
    city: 'Belur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e765',
    },
    state: 'Karnataka',
    district: 'Hassan',
    city: 'Channarayapatna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e766',
    },
    state: 'Karnataka',
    district: 'Hassan',
    city: 'Hassan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e767',
    },
    state: 'Karnataka',
    district: 'Hassan',
    city: 'Holenarsipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e768',
    },
    state: 'Karnataka',
    district: 'Hassan',
    city: 'Sakaleshpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e769',
    },
    state: 'Karnataka',
    district: 'Haveri',
    city: 'Byadgi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e76a',
    },
    state: 'Karnataka',
    district: 'Haveri',
    city: 'Hanagal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e76b',
    },
    state: 'Karnataka',
    district: 'Haveri',
    city: 'Haveri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e76c',
    },
    state: 'Karnataka',
    district: 'Haveri',
    city: 'Hirekerur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e76d',
    },
    state: 'Karnataka',
    district: 'Haveri',
    city: 'Ranebennur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e76e',
    },
    state: 'Karnataka',
    district: 'Haveri',
    city: 'Rattihalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e76f',
    },
    state: 'Karnataka',
    district: 'Haveri',
    city: 'Savanur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e770',
    },
    state: 'Karnataka',
    district: 'Haveri',
    city: 'Shiggaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e771',
    },
    state: 'Karnataka',
    district: 'Gadag',
    city: 'Naragund',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e772',
    },
    state: 'Karnataka',
    district: 'Kalaburagi',
    city: 'Afzalpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e773',
    },
    state: 'Karnataka',
    district: 'Kalaburagi',
    city: 'Aland',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e774',
    },
    state: 'Karnataka',
    district: 'Kalaburagi',
    city: 'Chincholi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e775',
    },
    state: 'Karnataka',
    district: 'Kalaburagi',
    city: 'Jevargi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e776',
    },
    state: 'Karnataka',
    district: 'Kalaburagi',
    city: 'Kalaburagi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e777',
    },
    state: 'Karnataka',
    district: 'Kalaburagi',
    city: 'Kalagi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e778',
    },
    state: 'Karnataka',
    district: 'Kalaburagi',
    city: 'Kamalapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e779',
    },
    state: 'Karnataka',
    district: 'Kalaburagi',
    city: 'Sedam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e77a',
    },
    state: 'Karnataka',
    district: 'Kalaburagi',
    city: 'Shabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e77b',
    },
    state: 'Karnataka',
    district: 'Kalaburagi',
    city: 'Yadrami',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e77c',
    },
    state: 'Karnataka',
    district: 'Kodagu',
    city: 'Madikeri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e77d',
    },
    state: 'Karnataka',
    district: 'Kodagu',
    city: 'Somvarpet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e77e',
    },
    state: 'Karnataka',
    district: 'Kodagu',
    city: 'Virajpet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e77f',
    },
    state: 'Karnataka',
    district: 'Kolar',
    city: 'Bangarapet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e780',
    },
    state: 'Karnataka',
    district: 'Kolar',
    city: 'K G F',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e781',
    },
    state: 'Karnataka',
    district: 'Kolar',
    city: 'Kolar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e782',
    },
    state: 'Karnataka',
    district: 'Kolar',
    city: 'Malur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e783',
    },
    state: 'Karnataka',
    district: 'Kolar',
    city: 'Mulbagal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e784',
    },
    state: 'Karnataka',
    district: 'Kolar',
    city: 'Srinivaspur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e785',
    },
    state: 'Karnataka',
    district: 'Koppal',
    city: 'Gangavathi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e786',
    },
    state: 'Karnataka',
    district: 'Koppal',
    city: 'Kanakagiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e787',
    },
    state: 'Karnataka',
    district: 'Kalaburagi',
    city: 'Chitapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e788',
    },
    state: 'Karnataka',
    district: 'Koppal',
    city: 'Karatagi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e789',
    },
    state: 'Karnataka',
    district: 'Koppal',
    city: 'Koppal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e78a',
    },
    state: 'Karnataka',
    district: 'Koppal',
    city: 'Kukunoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e78b',
    },
    state: 'Karnataka',
    district: 'Koppal',
    city: 'Yelburga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e78c',
    },
    state: 'Karnataka',
    district: 'Mandya',
    city: 'Krishnarajpet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e78d',
    },
    state: 'Karnataka',
    district: 'Mandya',
    city: 'Maddur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e78e',
    },
    state: 'Karnataka',
    district: 'Mandya',
    city: 'Malvalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e78f',
    },
    state: 'Karnataka',
    district: 'Mandya',
    city: 'Mandya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e790',
    },
    state: 'Karnataka',
    district: 'Mandya',
    city: 'Nagamangala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e791',
    },
    state: 'Karnataka',
    district: 'Mandya',
    city: 'Pandavapura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e792',
    },
    state: 'Karnataka',
    district: 'Mandya',
    city: 'Shrirangapattana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e793',
    },
    state: 'Karnataka',
    district: 'Mysuru',
    city: 'Heggadadevankote',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e794',
    },
    state: 'Karnataka',
    district: 'Mysuru',
    city: 'Hunsur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e795',
    },
    state: 'Karnataka',
    district: 'Mysuru',
    city: 'Krishnarajanagara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e796',
    },
    state: 'Karnataka',
    district: 'Mysuru',
    city: 'Mysuru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e797',
    },
    state: 'Karnataka',
    district: 'Mysuru',
    city: 'Nanjangud',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e798',
    },
    state: 'Karnataka',
    district: 'Mysuru',
    city: 'Piriyapatna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e799',
    },
    state: 'Karnataka',
    district: 'Mysuru',
    city: 'Saraguru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e79a',
    },
    state: 'Karnataka',
    district: 'Mysuru',
    city: 'Tirumakudal-Narsipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e79b',
    },
    state: 'Karnataka',
    district: 'Raichur',
    city: 'Devadurga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e79c',
    },
    state: 'Karnataka',
    district: 'Raichur',
    city: 'Lingsugur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e79d',
    },
    state: 'Karnataka',
    district: 'Koppal',
    city: 'Kushtagi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e79e',
    },
    state: 'Karnataka',
    district: 'Raichur',
    city: 'Manvi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e79f',
    },
    state: 'Karnataka',
    district: 'Raichur',
    city: 'Maski',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7a0',
    },
    state: 'Karnataka',
    district: 'Raichur',
    city: 'Raichur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7a1',
    },
    state: 'Karnataka',
    district: 'Raichur',
    city: 'Sirawara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7a2',
    },
    state: 'Karnataka',
    district: 'Ramanagara',
    city: 'Channapatna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7a3',
    },
    state: 'Karnataka',
    district: 'Ramanagara',
    city: 'Kanakapura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7a4',
    },
    state: 'Karnataka',
    district: 'Ramanagara',
    city: 'Magadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7a5',
    },
    state: 'Karnataka',
    district: 'Ramanagara',
    city: 'Ramanagara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7a6',
    },
    state: 'Karnataka',
    district: 'Shivamogga',
    city: 'Bhadravati',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7a7',
    },
    state: 'Karnataka',
    district: 'Shivamogga',
    city: 'Hosanagara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7a8',
    },
    state: 'Karnataka',
    district: 'Shivamogga',
    city: 'Sagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7a9',
    },
    state: 'Karnataka',
    district: 'Shivamogga',
    city: 'Shikarpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7aa',
    },
    state: 'Karnataka',
    district: 'Shivamogga',
    city: 'Shivamogga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7ab',
    },
    state: 'Karnataka',
    district: 'Shivamogga',
    city: 'Sorab',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7ac',
    },
    state: 'Karnataka',
    district: 'Shivamogga',
    city: 'Tirthahalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7ad',
    },
    state: 'Karnataka',
    district: 'Tumakuru',
    city: 'Chiknayakanhalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7ae',
    },
    state: 'Karnataka',
    district: 'Tumakuru',
    city: 'Gubbi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7af',
    },
    state: 'Karnataka',
    district: 'Tumakuru',
    city: 'Koratagere',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7b0',
    },
    state: 'Karnataka',
    district: 'Tumakuru',
    city: 'Kunigal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7b1',
    },
    state: 'Karnataka',
    district: 'Tumakuru',
    city: 'Madhugiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7b2',
    },
    state: 'Karnataka',
    district: 'Tumakuru',
    city: 'Pavagada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7b3',
    },
    state: 'Karnataka',
    district: 'Raichur',
    city: 'Sindhanur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7b4',
    },
    state: 'Karnataka',
    district: 'Tumakuru',
    city: 'Sira',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7b5',
    },
    state: 'Karnataka',
    district: 'Tumakuru',
    city: 'Tiptur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7b6',
    },
    state: 'Karnataka',
    district: 'Tumakuru',
    city: 'Tumakuru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7b7',
    },
    state: 'Karnataka',
    district: 'Udupi',
    city: 'Bainduru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7b8',
    },
    state: 'Karnataka',
    district: 'Udupi',
    city: 'Brahmavara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7b9',
    },
    state: 'Karnataka',
    district: 'Udupi',
    city: 'Hebri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7ba',
    },
    state: 'Karnataka',
    district: 'Udupi',
    city: 'Kapu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7bb',
    },
    state: 'Karnataka',
    district: 'Udupi',
    city: 'Karkal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7bc',
    },
    state: 'Karnataka',
    district: 'Udupi',
    city: 'Kundapura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7bd',
    },
    state: 'Karnataka',
    district: 'Udupi',
    city: 'Udupi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7be',
    },
    state: 'Karnataka',
    district: 'Uttara Kannada',
    city: 'Ankola',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7bf',
    },
    state: 'Karnataka',
    district: 'Uttara Kannada',
    city: 'Bhatkal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7c0',
    },
    state: 'Karnataka',
    district: 'Uttara Kannada',
    city: 'Dandeli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7c1',
    },
    state: 'Karnataka',
    district: 'Uttara Kannada',
    city: 'Haliyal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7c2',
    },
    state: 'Karnataka',
    district: 'Uttara Kannada',
    city: 'Honavar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7c3',
    },
    state: 'Karnataka',
    district: 'Uttara Kannada',
    city: 'Karwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7c4',
    },
    state: 'Karnataka',
    district: 'Uttara Kannada',
    city: 'Kumta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7c5',
    },
    state: 'Karnataka',
    district: 'Uttara Kannada',
    city: 'Mundgod',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7c6',
    },
    state: 'Karnataka',
    district: 'Uttara Kannada',
    city: 'Siddapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7c7',
    },
    state: 'Karnataka',
    district: 'Uttara Kannada',
    city: 'Sirsi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7c8',
    },
    state: 'Karnataka',
    district: 'Uttara Kannada',
    city: 'Supa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7c9',
    },
    state: 'Karnataka',
    district: 'Tumakuru',
    city: 'Turuvekere',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7ca',
    },
    state: 'Karnataka',
    district: 'Uttara Kannada',
    city: 'Yellapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7cb',
    },
    state: 'Karnataka',
    district: 'Vijayapura',
    city: 'Babaleshwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7cc',
    },
    state: 'Karnataka',
    district: 'Vijayapura',
    city: 'Basavana Bagewadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7cd',
    },
    state: 'Karnataka',
    district: 'Vijayapura',
    city: 'Devara Hipparagi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7ce',
    },
    state: 'Karnataka',
    district: 'Vijayapura',
    city: 'Indi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7cf',
    },
    state: 'Karnataka',
    district: 'Vijayapura',
    city: 'Kolhar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7d0',
    },
    state: 'Karnataka',
    district: 'Vijayapura',
    city: 'Muddebihal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7d1',
    },
    state: 'Karnataka',
    district: 'Vijayapura',
    city: 'Nidagundi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7d2',
    },
    state: 'Karnataka',
    district: 'Vijayapura',
    city: 'Sindagi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7d3',
    },
    state: 'Karnataka',
    district: 'Vijayapura',
    city: 'Thalikoti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7d4',
    },
    state: 'Karnataka',
    district: 'Vijayapura',
    city: 'Thikota',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7d5',
    },
    state: 'Karnataka',
    district: 'Vijayapura',
    city: 'Vijayapura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7d6',
    },
    state: 'Karnataka',
    district: 'Yadgir',
    city: 'Gurumitkal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7d7',
    },
    state: 'Karnataka',
    district: 'Yadgir',
    city: 'Hunasagi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7d8',
    },
    state: 'Karnataka',
    district: 'Yadgir',
    city: 'Shahapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7d9',
    },
    state: 'Karnataka',
    district: 'Yadgir',
    city: 'Shorapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7da',
    },
    state: 'Karnataka',
    district: 'Yadgir',
    city: 'Wadagera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7db',
    },
    state: 'Karnataka',
    district: 'Yadgir',
    city: 'Yadgir',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7dc',
    },
    state: 'Kerala',
    district: 'Alappuzha',
    city: 'Ambalappuzha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7dd',
    },
    state: 'Kerala',
    district: 'Alappuzha',
    city: 'Aryad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7de',
    },
    state: 'Kerala',
    district: 'Alappuzha',
    city: 'Bharanicavu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7df',
    },
    state: 'Karnataka',
    district: 'Vijayapura',
    city: 'Chadachan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7e0',
    },
    state: 'Kerala',
    district: 'Alappuzha',
    city: 'Champakulam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7e1',
    },
    state: 'Kerala',
    district: 'Alappuzha',
    city: 'Chengannur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7e2',
    },
    state: 'Kerala',
    district: 'Alappuzha',
    city: 'Harippad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7e3',
    },
    state: 'Kerala',
    district: 'Alappuzha',
    city: 'Mavelikkara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7e4',
    },
    state: 'Kerala',
    district: 'Alappuzha',
    city: 'Muthukulam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7e5',
    },
    state: 'Kerala',
    district: 'Alappuzha',
    city: 'Pattanakkad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7e6',
    },
    state: 'Kerala',
    district: 'Alappuzha',
    city: 'Thycattussery',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7e7',
    },
    state: 'Kerala',
    district: 'Alappuzha',
    city: 'Veliyanad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7e8',
    },
    state: 'Kerala',
    district: 'Ernakulam',
    city: 'Alangad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7e9',
    },
    state: 'Kerala',
    district: 'Ernakulam',
    city: 'Angamali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7ea',
    },
    state: 'Kerala',
    district: 'Ernakulam',
    city: 'Edappally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7eb',
    },
    state: 'Kerala',
    district: 'Ernakulam',
    city: 'Koovappady',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7ec',
    },
    state: 'Kerala',
    district: 'Ernakulam',
    city: 'Kothamangalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7ee',
    },
    state: 'Kerala',
    district: 'Ernakulam',
    city: 'Mulanthuruthy',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7ef',
    },
    state: 'Kerala',
    district: 'Ernakulam',
    city: 'Muvattupuzha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7f0',
    },
    state: 'Kerala',
    district: 'Ernakulam',
    city: 'Palluruthy',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7f1',
    },
    state: 'Kerala',
    district: 'Ernakulam',
    city: 'Pampakuda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7f2',
    },
    state: 'Kerala',
    district: 'Ernakulam',
    city: 'Parakkadav',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7f3',
    },
    state: 'Kerala',
    district: 'Ernakulam',
    city: 'Paravur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7f4',
    },
    state: 'Kerala',
    district: 'Ernakulam',
    city: 'Vadavucode',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7f5',
    },
    state: 'Kerala',
    district: 'Ernakulam',
    city: 'Vazhakkulam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7f6',
    },
    state: 'Kerala',
    district: 'Alappuzha',
    city: 'Kanjikkuzhy',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7f7',
    },
    state: 'Kerala',
    district: 'Ernakulam',
    city: 'Vypeen',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7f8',
    },
    state: 'Kerala',
    district: 'Idukki',
    city: 'Adimaly',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7f9',
    },
    state: 'Kerala',
    district: 'Idukki',
    city: 'Azhutha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7fa',
    },
    state: 'Kerala',
    district: 'Idukki',
    city: 'Devikulam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7fb',
    },
    state: 'Kerala',
    district: 'Idukki',
    city: 'Elemdesam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7fc',
    },
    state: 'Kerala',
    district: 'Idukki',
    city: 'Idukki',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7fd',
    },
    state: 'Kerala',
    district: 'Idukki',
    city: 'Kattappana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7fe',
    },
    state: 'Kerala',
    district: 'Idukki',
    city: 'Nedumkandom',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e7ff',
    },
    state: 'Kerala',
    district: 'Idukki',
    city: 'Thodupuzha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e800',
    },
    state: 'Kerala',
    district: 'Kannur',
    city: 'Edakkad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e801',
    },
    state: 'Kerala',
    district: 'Kannur',
    city: 'Irikkur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e802',
    },
    state: 'Kerala',
    district: 'Kannur',
    city: 'Iritty',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e803',
    },
    state: 'Kerala',
    district: 'Kannur',
    city: 'Kalliasseri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e804',
    },
    state: 'Kerala',
    district: 'Kannur',
    city: 'Kuthuparamba',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e805',
    },
    state: 'Kerala',
    district: 'Kannur',
    city: 'Kannur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e806',
    },
    state: 'Kerala',
    district: 'Kannur',
    city: 'Panoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e807',
    },
    state: 'Kerala',
    district: 'Kannur',
    city: 'Payyannur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e808',
    },
    state: 'Kerala',
    district: 'Kannur',
    city: 'Peravoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e809',
    },
    state: 'Kerala',
    district: 'Kannur',
    city: 'Thalassery',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e80a',
    },
    state: 'Kerala',
    district: 'Kasaragod',
    city: 'Kanhangad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e80b',
    },
    state: 'Kerala',
    district: 'Kasaragod',
    city: 'Karadka',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e80c',
    },
    state: 'Kerala',
    district: 'Kannur',
    city: 'Taliparamba',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e80d',
    },
    state: 'Kerala',
    district: 'Kasaragod',
    city: 'Kasargod',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e80e',
    },
    state: 'Kerala',
    district: 'Kasaragod',
    city: 'Manjeshwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e80f',
    },
    state: 'Kerala',
    district: 'Kasaragod',
    city: 'Parappa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e810',
    },
    state: 'Kerala',
    district: 'Kollam',
    city: 'Anchal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e811',
    },
    state: 'Kerala',
    district: 'Kasaragod',
    city: 'Nileshwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e812',
    },
    state: 'Kerala',
    district: 'Kollam',
    city: 'Chadayamangalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e813',
    },
    state: 'Kerala',
    district: 'Kollam',
    city: 'Chavara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e814',
    },
    state: 'Kerala',
    district: 'Kollam',
    city: 'Ithikkara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e815',
    },
    state: 'Kerala',
    district: 'Kollam',
    city: 'Kottarakkara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e816',
    },
    state: 'Kerala',
    district: 'Kollam',
    city: 'Chittumala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e817',
    },
    state: 'Kerala',
    district: 'Kollam',
    city: 'Mukhathala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e818',
    },
    state: 'Kerala',
    district: 'Kollam',
    city: 'Oachira',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e819',
    },
    state: 'Kerala',
    district: 'Kollam',
    city: 'Sasthamcottah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e81a',
    },
    state: 'Kerala',
    district: 'Kollam',
    city: 'Vettikkavala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e81b',
    },
    state: 'Kerala',
    district: 'Kottayam',
    city: 'Erattupetta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e81c',
    },
    state: 'Kerala',
    district: 'Kollam',
    city: 'Pathanapuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e81d',
    },
    state: 'Kerala',
    district: 'Kottayam',
    city: 'Ettumanoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e81e',
    },
    state: 'Kerala',
    district: 'Kottayam',
    city: 'Kanjirappally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e81f',
    },
    state: 'Kerala',
    district: 'Kottayam',
    city: 'Lalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e820',
    },
    state: 'Kerala',
    district: 'Kottayam',
    city: 'Madappally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e821',
    },
    state: 'Kerala',
    district: 'Kottayam',
    city: 'Pallom',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e822',
    },
    state: 'Kerala',
    district: 'Kottayam',
    city: 'Pampady',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e823',
    },
    state: 'Kerala',
    district: 'Kottayam',
    city: 'Uzhavoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e824',
    },
    state: 'Kerala',
    district: 'Kottayam',
    city: 'Vaikom',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e825',
    },
    state: 'Kerala',
    district: 'Kottayam',
    city: 'Vazhoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e826',
    },
    state: 'Kerala',
    district: 'Kozhikode',
    city: 'Balusseri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e827',
    },
    state: 'Kerala',
    district: 'Kozhikode',
    city: 'Chelannur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e828',
    },
    state: 'Kerala',
    district: 'Kozhikode',
    city: 'Koduvally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e829',
    },
    state: 'Kerala',
    district: 'Kozhikode',
    city: 'Kozhikode',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e82a',
    },
    state: 'Kerala',
    district: 'Kozhikode',
    city: 'Kunnamangalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e82b',
    },
    state: 'Kerala',
    district: 'Kozhikode',
    city: 'Kunnummal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e82c',
    },
    state: 'Kerala',
    district: 'Kozhikode',
    city: 'Melday',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e82d',
    },
    state: 'Kerala',
    district: 'Kottayam',
    city: 'Kaduthuruthy',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e82e',
    },
    state: 'Kerala',
    district: 'Kozhikode',
    city: 'Panthalayani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e82f',
    },
    state: 'Kerala',
    district: 'Kozhikode',
    city: 'Perambra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e830',
    },
    state: 'Kerala',
    district: 'Kozhikode',
    city: 'Thodannur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e831',
    },
    state: 'Kerala',
    district: 'Kozhikode',
    city: 'Thuneri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e832',
    },
    state: 'Kerala',
    district: 'Kozhikode',
    city: 'Vadakara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e833',
    },
    state: 'Kerala',
    district: 'Malappuram',
    city: 'Areakode',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e834',
    },
    state: 'Kerala',
    district: 'Malappuram',
    city: 'Kalikavu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e835',
    },
    state: 'Kerala',
    district: 'Malappuram',
    city: 'Kondotty',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e836',
    },
    state: 'Kerala',
    district: 'Malappuram',
    city: 'Kuttippuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e837',
    },
    state: 'Kerala',
    district: 'Malappuram',
    city: 'Malappuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e838',
    },
    state: 'Kerala',
    district: 'Malappuram',
    city: 'Mankada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e839',
    },
    state: 'Kerala',
    district: 'Malappuram',
    city: 'Nilambur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e83a',
    },
    state: 'Kerala',
    district: 'Malappuram',
    city: 'Perinthalmanna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e83b',
    },
    state: 'Kerala',
    district: 'Malappuram',
    city: 'Perumpadappu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e83c',
    },
    state: 'Kerala',
    district: 'Malappuram',
    city: 'Tanur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e83d',
    },
    state: 'Kerala',
    district: 'Malappuram',
    city: 'Tirur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e83e',
    },
    state: 'Kerala',
    district: 'Malappuram',
    city: 'Tirurangadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e83f',
    },
    state: 'Kerala',
    district: 'Malappuram',
    city: 'Vengara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e840',
    },
    state: 'Kerala',
    district: 'Malappuram',
    city: 'Wandoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e841',
    },
    state: 'Kerala',
    district: 'Palakkad',
    city: 'Alathur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e842',
    },
    state: 'Kerala',
    district: 'Palakkad',
    city: 'Attappadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e843',
    },
    state: 'Kerala',
    district: 'Palakkad',
    city: 'Chittur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e844',
    },
    state: 'Kerala',
    district: 'Palakkad',
    city: 'Kollengode',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e845',
    },
    state: 'Kerala',
    district: 'Palakkad',
    city: 'Kuzhalmannam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e846',
    },
    state: 'Kerala',
    district: 'Palakkad',
    city: 'Malampuzha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e847',
    },
    state: 'Kerala',
    district: 'Palakkad',
    city: 'Mannarkad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e848',
    },
    state: 'Kerala',
    district: 'Malappuram',
    city: 'Ponnani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e849',
    },
    state: 'Kerala',
    district: 'Palakkad',
    city: 'Ottappalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e84a',
    },
    state: 'Kerala',
    district: 'Palakkad',
    city: 'Palakkad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e84b',
    },
    state: 'Kerala',
    district: 'Palakkad',
    city: 'Pattambi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e84c',
    },
    state: 'Kerala',
    district: 'Palakkad',
    city: 'Nemmara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e84d',
    },
    state: 'Kerala',
    district: 'Palakkad',
    city: 'Trithala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e84e',
    },
    state: 'Kerala',
    district: 'Palakkad',
    city: 'Sreekrishnapuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e84f',
    },
    state: 'Kerala',
    district: 'Pathanamthitta',
    city: 'Elanthoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e850',
    },
    state: 'Kerala',
    district: 'Pathanamthitta',
    city: 'Koipuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e851',
    },
    state: 'Kerala',
    district: 'Pathanamthitta',
    city: 'Konni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e852',
    },
    state: 'Kerala',
    district: 'Pathanamthitta',
    city: 'Pandlam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e853',
    },
    state: 'Kerala',
    district: 'Pathanamthitta',
    city: 'Parakode',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e854',
    },
    state: 'Kerala',
    district: 'Pathanamthitta',
    city: 'Pulikeezhu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e855',
    },
    state: 'Kerala',
    district: 'Pathanamthitta',
    city: 'Ranni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e856',
    },
    state: 'Kerala',
    district: 'Pathanamthitta',
    city: 'Mallappally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e857',
    },
    state: 'Kerala',
    district: 'Thiruvananthapuram',
    city: 'Chirayinkeezhu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e858',
    },
    state: 'Kerala',
    district: 'Thiruvananthapuram',
    city: 'Athiyannoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e859',
    },
    state: 'Kerala',
    district: 'Thiruvananthapuram',
    city: 'Kilimanoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e85a',
    },
    state: 'Kerala',
    district: 'Thiruvananthapuram',
    city: 'Nedumangad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e85b',
    },
    state: 'Kerala',
    district: 'Thiruvananthapuram',
    city: 'Nemom',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e85c',
    },
    state: 'Kerala',
    district: 'Thiruvananthapuram',
    city: 'Parassala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e85d',
    },
    state: 'Kerala',
    district: 'Thiruvananthapuram',
    city: 'Pothencode',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e85e',
    },
    state: 'Kerala',
    district: 'Thiruvananthapuram',
    city: 'Perumkadavila',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e85f',
    },
    state: 'Kerala',
    district: 'Thiruvananthapuram',
    city: 'Vamanapuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e860',
    },
    state: 'Kerala',
    district: 'Thiruvananthapuram',
    city: 'Varkala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e861',
    },
    state: 'Kerala',
    district: 'Thiruvananthapuram',
    city: 'Vellanad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e862',
    },
    state: 'Kerala',
    district: 'Thrissur',
    city: 'Chalakkudy',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e863',
    },
    state: 'Kerala',
    district: 'Thrissur',
    city: 'Anthikkad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e864',
    },
    state: 'Kerala',
    district: 'Thrissur',
    city: 'Cherpu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e865',
    },
    state: 'Kerala',
    district: 'Thrissur',
    city: 'Chavakkad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e866',
    },
    state: 'Kerala',
    district: 'Thrissur',
    city: 'Irinjalakkuda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e867',
    },
    state: 'Kerala',
    district: 'Thrissur',
    city: 'Chowannur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e868',
    },
    state: 'Kerala',
    district: 'Thrissur',
    city: 'Kodakara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e869',
    },
    state: 'Kerala',
    district: 'Thrissur',
    city: 'Mala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e86a',
    },
    state: 'Kerala',
    district: 'Thrissur',
    city: 'Mathilakam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e86b',
    },
    state: 'Kerala',
    district: 'Thrissur',
    city: 'Mullassery',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e86c',
    },
    state: 'Kerala',
    district: 'Thrissur',
    city: 'Ollukkara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e86d',
    },
    state: 'Kerala',
    district: 'Thrissur',
    city: 'Pazhayannur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e86e',
    },
    state: 'Kerala',
    district: 'Thrissur',
    city: 'Puzhakkal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e86f',
    },
    state: 'Kerala',
    district: 'Thrissur',
    city: 'Thalikkulam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e870',
    },
    state: 'Kerala',
    district: 'Thrissur',
    city: 'Wadakkanchery',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e871',
    },
    state: 'Kerala',
    district: 'Wayanad',
    city: 'Kalpetta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e872',
    },
    state: 'Kerala',
    district: 'Wayanad',
    city: 'Mananthavady',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e873',
    },
    state: 'Kerala',
    district: 'Thrissur',
    city: 'Vellangallur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e874',
    },
    state: 'Kerala',
    district: 'Wayanad',
    city: 'Sulthan Bathery',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e875',
    },
    state: 'Ladakh',
    district: 'Kargil',
    city: 'Barsoo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e876',
    },
    state: 'Ladakh',
    district: 'Kargil',
    city: 'Bhimbat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e877',
    },
    state: 'Ladakh',
    district: 'Kargil',
    city: 'Drass',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e878',
    },
    state: 'Kerala',
    district: 'Wayanad',
    city: 'Panamaram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e879',
    },
    state: 'Ladakh',
    district: 'Kargil',
    city: 'Gm Pora (Trespone)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e87a',
    },
    state: 'Ladakh',
    district: 'Kargil',
    city: 'Karsha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e87b',
    },
    state: 'Ladakh',
    district: 'Kargil',
    city: 'Lotsum',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e87c',
    },
    state: 'Ladakh',
    district: 'Kargil',
    city: 'Kargil',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e87d',
    },
    state: 'Ladakh',
    district: 'Kargil',
    city: 'Pashkum',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e87e',
    },
    state: 'Ladakh',
    district: 'Kargil',
    city: 'Sankoo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e87f',
    },
    state: 'Ladakh',
    district: 'Kargil',
    city: 'Shaker Chaktan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e880',
    },
    state: 'Ladakh',
    district: 'Kargil',
    city: 'Lungnak',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e881',
    },
    state: 'Ladakh',
    district: 'Kargil',
    city: 'Shargol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e882',
    },
    state: 'Ladakh',
    district: 'Kargil',
    city: 'Soudh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e883',
    },
    state: 'Ladakh',
    district: 'Kargil',
    city: 'Taisuru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e884',
    },
    state: 'Ladakh',
    district: 'Leh Ladakh',
    city: 'Chuchot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e885',
    },
    state: 'Ladakh',
    district: 'Leh Ladakh',
    city: 'Deskit',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e886',
    },
    state: 'Ladakh',
    district: 'Kargil',
    city: 'Zansker',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e887',
    },
    state: 'Ladakh',
    district: 'Leh Ladakh',
    city: 'Khaltsi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e888',
    },
    state: 'Ladakh',
    district: 'Leh Ladakh',
    city: 'Kharu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e889',
    },
    state: 'Ladakh',
    district: 'Leh Ladakh',
    city: 'Leh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e88a',
    },
    state: 'Ladakh',
    district: 'Leh Ladakh',
    city: 'Nimoo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e88b',
    },
    state: 'Ladakh',
    district: 'Leh Ladakh',
    city: 'Nyoma',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e88c',
    },
    state: 'Ladakh',
    district: 'Leh Ladakh',
    city: 'Panamic',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e88d',
    },
    state: 'Ladakh',
    district: 'Leh Ladakh',
    city: 'Rong',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e88e',
    },
    state: 'Ladakh',
    district: 'Leh Ladakh',
    city: 'Rupsho',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e88f',
    },
    state: 'Ladakh',
    district: 'Leh Ladakh',
    city: 'Saspol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e890',
    },
    state: 'Ladakh',
    district: 'Leh Ladakh',
    city: 'Durbuk',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e891',
    },
    state: 'Ladakh',
    district: 'Leh Ladakh',
    city: 'Singay Lalok Wanla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e892',
    },
    state: 'Ladakh',
    district: 'Leh Ladakh',
    city: 'Sukerbachan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e893',
    },
    state: 'Ladakh',
    district: 'Leh Ladakh',
    city: 'Thiksay',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e894',
    },
    state: 'Ladakh',
    district: 'Leh Ladakh',
    city: 'Turtuk',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e895',
    },
    state: 'Lakshadweep',
    district: 'Lakshadweep District',
    city: 'Agatti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e896',
    },
    state: 'Lakshadweep',
    district: 'Lakshadweep District',
    city: 'Andrott',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e897',
    },
    state: 'Lakshadweep',
    district: 'Lakshadweep District',
    city: 'Bitra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e898',
    },
    state: 'Lakshadweep',
    district: 'Lakshadweep District',
    city: 'Chetlat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e899',
    },
    state: 'Lakshadweep',
    district: 'Lakshadweep District',
    city: 'Kadmat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e89a',
    },
    state: 'Lakshadweep',
    district: 'Lakshadweep District',
    city: 'Amini',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e89b',
    },
    state: 'Lakshadweep',
    district: 'Lakshadweep District',
    city: 'Kalpeni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e89c',
    },
    state: 'Lakshadweep',
    district: 'Lakshadweep District',
    city: 'Kavaratti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e89d',
    },
    state: 'Lakshadweep',
    district: 'Lakshadweep District',
    city: 'Kiltan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e89e',
    },
    state: 'Madhya Pradesh',
    district: 'Agar Malwa',
    city: 'Agar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e89f',
    },
    state: 'Madhya Pradesh',
    district: 'Agar Malwa',
    city: 'Barod',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8a0',
    },
    state: 'Madhya Pradesh',
    district: 'Agar Malwa',
    city: 'Nalkheda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8a1',
    },
    state: 'Lakshadweep',
    district: 'Lakshadweep District',
    city: 'Minicoy',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8a2',
    },
    state: 'Madhya Pradesh',
    district: 'Agar Malwa',
    city: 'Susner',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8a3',
    },
    state: 'Madhya Pradesh',
    district: 'Alirajpur',
    city: 'Alirajpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8a4',
    },
    state: 'Madhya Pradesh',
    district: 'Alirajpur',
    city: 'Bhabra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8a5',
    },
    state: 'Madhya Pradesh',
    district: 'Alirajpur',
    city: 'Jobat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8a6',
    },
    state: 'Madhya Pradesh',
    district: 'Alirajpur',
    city: 'Katthiwada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8a7',
    },
    state: 'Madhya Pradesh',
    district: 'Alirajpur',
    city: 'Sondwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8a8',
    },
    state: 'Madhya Pradesh',
    district: 'Anuppur',
    city: 'Anuppur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8a9',
    },
    state: 'Madhya Pradesh',
    district: 'Alirajpur',
    city: 'Udaigarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8aa',
    },
    state: 'Madhya Pradesh',
    district: 'Anuppur',
    city: 'Jaithari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8ab',
    },
    state: 'Madhya Pradesh',
    district: 'Anuppur',
    city: 'Kotma',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8ac',
    },
    state: 'Madhya Pradesh',
    district: 'Anuppur',
    city: 'Pushprajgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8ad',
    },
    state: 'Madhya Pradesh',
    district: 'Ashoknagar',
    city: 'Ashoknagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8ae',
    },
    state: 'Madhya Pradesh',
    district: 'Ashoknagar',
    city: 'Chanderi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8af',
    },
    state: 'Madhya Pradesh',
    district: 'Ashoknagar',
    city: 'Isagarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8b0',
    },
    state: 'Madhya Pradesh',
    district: 'Ashoknagar',
    city: 'Mungaoli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8b1',
    },
    state: 'Madhya Pradesh',
    district: 'Balaghat',
    city: 'Baihar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8b2',
    },
    state: 'Madhya Pradesh',
    district: 'Balaghat',
    city: 'Balaghat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8b3',
    },
    state: 'Madhya Pradesh',
    district: 'Balaghat',
    city: 'Birsa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8b4',
    },
    state: 'Madhya Pradesh',
    district: 'Balaghat',
    city: 'Katangi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8b5',
    },
    state: 'Madhya Pradesh',
    district: 'Balaghat',
    city: 'Khairlanji',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8b6',
    },
    state: 'Madhya Pradesh',
    district: 'Balaghat',
    city: 'Kirnapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8b7',
    },
    state: 'Madhya Pradesh',
    district: 'Balaghat',
    city: 'Lanji',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8b8',
    },
    state: 'Madhya Pradesh',
    district: 'Balaghat',
    city: 'Paraswada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8b9',
    },
    state: 'Madhya Pradesh',
    district: 'Balaghat',
    city: 'Waraseoni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8ba',
    },
    state: 'Madhya Pradesh',
    district: 'Barwani',
    city: 'Barwani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8bb',
    },
    state: 'Madhya Pradesh',
    district: 'Balaghat',
    city: 'Lalbarra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8bc',
    },
    state: 'Madhya Pradesh',
    district: 'Barwani',
    city: 'Newali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8bd',
    },
    state: 'Madhya Pradesh',
    district: 'Barwani',
    city: 'Pansemal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8be',
    },
    state: 'Madhya Pradesh',
    district: 'Barwani',
    city: 'Pati',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8bf',
    },
    state: 'Madhya Pradesh',
    district: 'Barwani',
    city: 'Rajpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8c0',
    },
    state: 'Madhya Pradesh',
    district: 'Barwani',
    city: 'Thikri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8c1',
    },
    state: 'Madhya Pradesh',
    district: 'Betul',
    city: 'Amla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8c2',
    },
    state: 'Madhya Pradesh',
    district: 'Betul',
    city: 'Athner',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8c3',
    },
    state: 'Madhya Pradesh',
    district: 'Betul',
    city: 'Betul',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8c4',
    },
    state: 'Madhya Pradesh',
    district: 'Betul',
    city: 'Bhainsdehi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8c5',
    },
    state: 'Madhya Pradesh',
    district: 'Betul',
    city: 'Bhimpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8c6',
    },
    state: 'Madhya Pradesh',
    district: 'Betul',
    city: 'Chicholi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8c7',
    },
    state: 'Madhya Pradesh',
    district: 'Barwani',
    city: 'Sendhawa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8c8',
    },
    state: 'Madhya Pradesh',
    district: 'Betul',
    city: 'Ghoradongri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8c9',
    },
    state: 'Madhya Pradesh',
    district: 'Betul',
    city: 'Multai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8ca',
    },
    state: 'Madhya Pradesh',
    district: 'Betul',
    city: 'Shahpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8cb',
    },
    state: 'Madhya Pradesh',
    district: 'Bhind',
    city: 'Ater',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8cc',
    },
    state: 'Madhya Pradesh',
    district: 'Bhind',
    city: 'Bhind',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8cd',
    },
    state: 'Madhya Pradesh',
    district: 'Bhind',
    city: 'Gohad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8ce',
    },
    state: 'Madhya Pradesh',
    district: 'Betul',
    city: 'Prabhat Pattan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8cf',
    },
    state: 'Madhya Pradesh',
    district: 'Bhind',
    city: 'Lahar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8d0',
    },
    state: 'Madhya Pradesh',
    district: 'Bhind',
    city: 'Mehgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8d1',
    },
    state: 'Madhya Pradesh',
    district: 'Bhind',
    city: 'Raon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8d2',
    },
    state: 'Madhya Pradesh',
    district: 'Bhopal',
    city: 'Berasia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8d3',
    },
    state: 'Madhya Pradesh',
    district: 'Burhanpur',
    city: 'Burhanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8d4',
    },
    state: 'Madhya Pradesh',
    district: 'Burhanpur',
    city: 'Khaknar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8d5',
    },
    state: 'Madhya Pradesh',
    district: 'Chhatarpur',
    city: 'Bada Malehara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8d6',
    },
    state: 'Madhya Pradesh',
    district: 'Chhatarpur',
    city: 'Barigarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8d7',
    },
    state: 'Madhya Pradesh',
    district: 'Chhatarpur',
    city: 'Bijawar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8d8',
    },
    state: 'Madhya Pradesh',
    district: 'Chhatarpur',
    city: 'Buxwaha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8d9',
    },
    state: 'Madhya Pradesh',
    district: 'Chhatarpur',
    city: 'Chhatarpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8da',
    },
    state: 'Madhya Pradesh',
    district: 'Chhatarpur',
    city: 'Laundi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8db',
    },
    state: 'Madhya Pradesh',
    district: 'Chhatarpur',
    city: 'Nowgong',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8dc',
    },
    state: 'Madhya Pradesh',
    district: 'Chhatarpur',
    city: 'Rajnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8dd',
    },
    state: 'Madhya Pradesh',
    district: 'Chhindwara',
    city: 'Amarwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8de',
    },
    state: 'Madhya Pradesh',
    district: 'Chhindwara',
    city: 'Bichhua',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8df',
    },
    state: 'Madhya Pradesh',
    district: 'Bhopal',
    city: 'Phanda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8e0',
    },
    state: 'Madhya Pradesh',
    district: 'Chhindwara',
    city: 'Chaurai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8e1',
    },
    state: 'Madhya Pradesh',
    district: 'Chhindwara',
    city: 'Chhindwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8e2',
    },
    state: 'Madhya Pradesh',
    district: 'Chhindwara',
    city: 'Jamai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8e3',
    },
    state: 'Madhya Pradesh',
    district: 'Chhindwara',
    city: 'Harrai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8e4',
    },
    state: 'Madhya Pradesh',
    district: 'Chhindwara',
    city: 'Mohkhed',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8e5',
    },
    state: 'Madhya Pradesh',
    district: 'Chhindwara',
    city: 'Parasia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8e6',
    },
    state: 'Madhya Pradesh',
    district: 'Chhindwara',
    city: 'Pandhurna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8e7',
    },
    state: 'Madhya Pradesh',
    district: 'Chhindwara',
    city: 'Sausar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8e8',
    },
    state: 'Madhya Pradesh',
    district: 'Chhindwara',
    city: 'Tamia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8e9',
    },
    state: 'Madhya Pradesh',
    district: 'Damoh',
    city: 'Damoh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8ea',
    },
    state: 'Madhya Pradesh',
    district: 'Damoh',
    city: 'Hatta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8eb',
    },
    state: 'Madhya Pradesh',
    district: 'Damoh',
    city: 'Jabera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8ec',
    },
    state: 'Madhya Pradesh',
    district: 'Damoh',
    city: 'Patera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8ed',
    },
    state: 'Madhya Pradesh',
    district: 'Damoh',
    city: 'Pathariya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8ee',
    },
    state: 'Madhya Pradesh',
    district: 'Damoh',
    city: 'Tendukheda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8ef',
    },
    state: 'Madhya Pradesh',
    district: 'Datia',
    city: 'Bhander',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8f0',
    },
    state: 'Madhya Pradesh',
    district: 'Datia',
    city: 'Datia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8f1',
    },
    state: 'Madhya Pradesh',
    district: 'Datia',
    city: 'Seondha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8f2',
    },
    state: 'Madhya Pradesh',
    district: 'Dewas',
    city: 'Bagli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8f3',
    },
    state: 'Madhya Pradesh',
    district: 'Dewas',
    city: 'Dewas',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8f4',
    },
    state: 'Madhya Pradesh',
    district: 'Dewas',
    city: 'Kannod',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8f5',
    },
    state: 'Madhya Pradesh',
    district: 'Dewas',
    city: 'Khategaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8f6',
    },
    state: 'Madhya Pradesh',
    district: 'Dewas',
    city: 'Sonkatch',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8f7',
    },
    state: 'Madhya Pradesh',
    district: 'Dewas',
    city: 'Tonk Khurd',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8f8',
    },
    state: 'Madhya Pradesh',
    district: 'Dhar',
    city: 'Badnawar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8f9',
    },
    state: 'Madhya Pradesh',
    district: 'Dhar',
    city: 'Bagh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8fa',
    },
    state: 'Madhya Pradesh',
    district: 'Dhar',
    city: 'Dahi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8fb',
    },
    state: 'Madhya Pradesh',
    district: 'Damoh',
    city: 'Batiyagarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8fc',
    },
    state: 'Madhya Pradesh',
    district: 'Dhar',
    city: 'Dhar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8fd',
    },
    state: 'Madhya Pradesh',
    district: 'Dhar',
    city: 'Dharampuri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8fe',
    },
    state: 'Madhya Pradesh',
    district: 'Dhar',
    city: 'Gandhwani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e8ff',
    },
    state: 'Madhya Pradesh',
    district: 'Dhar',
    city: 'Manawar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e900',
    },
    state: 'Madhya Pradesh',
    district: 'Dhar',
    city: 'Nalchha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e901',
    },
    state: 'Madhya Pradesh',
    district: 'Dhar',
    city: 'Nisarpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e902',
    },
    state: 'Madhya Pradesh',
    district: 'Dhar',
    city: 'Sardarpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e903',
    },
    state: 'Madhya Pradesh',
    district: 'Dhar',
    city: 'Kukshi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e904',
    },
    state: 'Madhya Pradesh',
    district: 'Dhar',
    city: 'Umarban',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e905',
    },
    state: 'Madhya Pradesh',
    district: 'Dhar',
    city: 'Tirla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e906',
    },
    state: 'Madhya Pradesh',
    district: 'Dindori',
    city: 'Amarpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e907',
    },
    state: 'Madhya Pradesh',
    district: 'Dindori',
    city: 'Bajag',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e908',
    },
    state: 'Madhya Pradesh',
    district: 'Dindori',
    city: 'Dindori',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e909',
    },
    state: 'Madhya Pradesh',
    district: 'Dindori',
    city: 'Mehandwani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e90a',
    },
    state: 'Madhya Pradesh',
    district: 'Dindori',
    city: 'Karanjiya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e90b',
    },
    state: 'Madhya Pradesh',
    district: 'Dindori',
    city: 'Samnapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e90c',
    },
    state: 'Madhya Pradesh',
    district: 'Dindori',
    city: 'Shahpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e90d',
    },
    state: 'Madhya Pradesh',
    district: 'East Nimar',
    city: 'Baladi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e90e',
    },
    state: 'Madhya Pradesh',
    district: 'East Nimar',
    city: 'Chhaigaon Makhan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e90f',
    },
    state: 'Madhya Pradesh',
    district: 'East Nimar',
    city: 'Harsud',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e910',
    },
    state: 'Madhya Pradesh',
    district: 'East Nimar',
    city: 'Khalwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e911',
    },
    state: 'Madhya Pradesh',
    district: 'East Nimar',
    city: 'Khandwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e912',
    },
    state: 'Madhya Pradesh',
    district: 'East Nimar',
    city: 'Pandhana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e913',
    },
    state: 'Madhya Pradesh',
    district: 'East Nimar',
    city: 'Punasa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e914',
    },
    state: 'Madhya Pradesh',
    district: 'Guna',
    city: 'Aron',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e915',
    },
    state: 'Madhya Pradesh',
    district: 'Guna',
    city: 'Bamori',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e916',
    },
    state: 'Madhya Pradesh',
    district: 'Guna',
    city: 'Chanchoda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e917',
    },
    state: 'Madhya Pradesh',
    district: 'Guna',
    city: 'Guna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e918',
    },
    state: 'Madhya Pradesh',
    district: 'Gwalior',
    city: 'Bhitarwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e919',
    },
    state: 'Madhya Pradesh',
    district: 'Gwalior',
    city: 'Dabra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e91a',
    },
    state: 'Madhya Pradesh',
    district: 'Gwalior',
    city: 'Ghatigaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e91b',
    },
    state: 'Madhya Pradesh',
    district: 'Gwalior',
    city: 'Morar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e91c',
    },
    state: 'Madhya Pradesh',
    district: 'Harda',
    city: 'Harda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e91d',
    },
    state: 'Madhya Pradesh',
    district: 'Guna',
    city: 'Raghogarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e91e',
    },
    state: 'Madhya Pradesh',
    district: 'Harda',
    city: 'Khirkiya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e91f',
    },
    state: 'Madhya Pradesh',
    district: 'Harda',
    city: 'Timarni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e920',
    },
    state: 'Madhya Pradesh',
    district: 'Hoshangabad',
    city: 'Babai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e921',
    },
    state: 'Madhya Pradesh',
    district: 'Hoshangabad',
    city: 'Bankhedi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e922',
    },
    state: 'Madhya Pradesh',
    district: 'Hoshangabad',
    city: 'Hoshangabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e923',
    },
    state: 'Madhya Pradesh',
    district: 'Hoshangabad',
    city: 'Kesla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e924',
    },
    state: 'Madhya Pradesh',
    district: 'Hoshangabad',
    city: 'Pipariya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e925',
    },
    state: 'Madhya Pradesh',
    district: 'Hoshangabad',
    city: 'Seoni Malwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e926',
    },
    state: 'Madhya Pradesh',
    district: 'Hoshangabad',
    city: 'Sohagpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e927',
    },
    state: 'Madhya Pradesh',
    district: 'Indore',
    city: 'Depalpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e928',
    },
    state: 'Madhya Pradesh',
    district: 'Indore',
    city: 'Indore',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e929',
    },
    state: 'Madhya Pradesh',
    district: 'Indore',
    city: 'Mhow',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e92a',
    },
    state: 'Madhya Pradesh',
    district: 'Indore',
    city: 'Sanwer',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e92b',
    },
    state: 'Madhya Pradesh',
    district: 'Jabalpur',
    city: 'Kundam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e92c',
    },
    state: 'Madhya Pradesh',
    district: 'Jabalpur',
    city: 'Majhouli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e92d',
    },
    state: 'Madhya Pradesh',
    district: 'Jabalpur',
    city: 'Panagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e92e',
    },
    state: 'Madhya Pradesh',
    district: 'Jabalpur',
    city: 'Patan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e92f',
    },
    state: 'Madhya Pradesh',
    district: 'Jabalpur',
    city: 'Jabalpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e930',
    },
    state: 'Madhya Pradesh',
    district: 'Jabalpur',
    city: 'Shahpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e931',
    },
    state: 'Madhya Pradesh',
    district: 'Jabalpur',
    city: 'Sihora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e932',
    },
    state: 'Madhya Pradesh',
    district: 'Jhabua',
    city: 'Jhabua',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e933',
    },
    state: 'Madhya Pradesh',
    district: 'Jhabua',
    city: 'Meghnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e934',
    },
    state: 'Madhya Pradesh',
    district: 'Jhabua',
    city: 'Petlawad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e935',
    },
    state: 'Madhya Pradesh',
    district: 'Jhabua',
    city: 'Rama',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e936',
    },
    state: 'Madhya Pradesh',
    district: 'Jhabua',
    city: 'Ranapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e937',
    },
    state: 'Madhya Pradesh',
    district: 'Jhabua',
    city: 'Thandla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e938',
    },
    state: 'Madhya Pradesh',
    district: 'Katni',
    city: 'Badwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e939',
    },
    state: 'Madhya Pradesh',
    district: 'Katni',
    city: 'Dheemerkheda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e93a',
    },
    state: 'Madhya Pradesh',
    district: 'Katni',
    city: 'Katni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e93b',
    },
    state: 'Madhya Pradesh',
    district: 'Katni',
    city: 'Rithi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e93c',
    },
    state: 'Madhya Pradesh',
    district: 'Katni',
    city: 'Vijayraghavgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e93d',
    },
    state: 'Madhya Pradesh',
    district: 'Khargone',
    city: 'Barwah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e93e',
    },
    state: 'Madhya Pradesh',
    district: 'Katni',
    city: 'Bahoriband',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e93f',
    },
    state: 'Madhya Pradesh',
    district: 'Khargone',
    city: 'Bhagvanpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e940',
    },
    state: 'Madhya Pradesh',
    district: 'Khargone',
    city: 'Bhikangaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e941',
    },
    state: 'Madhya Pradesh',
    district: 'Khargone',
    city: 'Gogawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e942',
    },
    state: 'Madhya Pradesh',
    district: 'Khargone',
    city: 'Khargone',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e943',
    },
    state: 'Madhya Pradesh',
    district: 'Khargone',
    city: 'Kasrawad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e944',
    },
    state: 'Madhya Pradesh',
    district: 'Khargone',
    city: 'Maheshwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e945',
    },
    state: 'Madhya Pradesh',
    district: 'Khargone',
    city: 'Segaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e946',
    },
    state: 'Madhya Pradesh',
    district: 'Khargone',
    city: 'Ziranya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e947',
    },
    state: 'Madhya Pradesh',
    district: 'Mandla',
    city: 'Bijadandi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e948',
    },
    state: 'Madhya Pradesh',
    district: 'Mandla',
    city: 'Ghughri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e949',
    },
    state: 'Madhya Pradesh',
    district: 'Mandla',
    city: 'Mandla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e94a',
    },
    state: 'Madhya Pradesh',
    district: 'Mandla',
    city: 'Mawai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e94b',
    },
    state: 'Madhya Pradesh',
    district: 'Mandla',
    city: 'Mohgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e94c',
    },
    state: 'Madhya Pradesh',
    district: 'Mandla',
    city: 'Nainpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e94d',
    },
    state: 'Madhya Pradesh',
    district: 'Mandla',
    city: 'Narayanganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e94e',
    },
    state: 'Madhya Pradesh',
    district: 'Mandla',
    city: 'Niwas',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e94f',
    },
    state: 'Madhya Pradesh',
    district: 'Mandla',
    city: 'Bichhiya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e950',
    },
    state: 'Madhya Pradesh',
    district: 'Mandsaur',
    city: 'Garoth',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e951',
    },
    state: 'Madhya Pradesh',
    district: 'Mandsaur',
    city: 'Bhanpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e952',
    },
    state: 'Madhya Pradesh',
    district: 'Mandsaur',
    city: 'Malhargarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e953',
    },
    state: 'Madhya Pradesh',
    district: 'Mandsaur',
    city: 'Mandsaur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e954',
    },
    state: 'Madhya Pradesh',
    district: 'Mandsaur',
    city: 'Sitamau',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e955',
    },
    state: 'Madhya Pradesh',
    district: 'Morena',
    city: 'Joura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e956',
    },
    state: 'Madhya Pradesh',
    district: 'Morena',
    city: 'Kailaras',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e957',
    },
    state: 'Madhya Pradesh',
    district: 'Morena',
    city: 'Morena',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e958',
    },
    state: 'Madhya Pradesh',
    district: 'Morena',
    city: 'Ambah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e959',
    },
    state: 'Madhya Pradesh',
    district: 'Morena',
    city: 'Pahadgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e95a',
    },
    state: 'Madhya Pradesh',
    district: 'Morena',
    city: 'Porsa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e95b',
    },
    state: 'Madhya Pradesh',
    district: 'Narsinghpur',
    city: 'Babai Chichali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e95c',
    },
    state: 'Madhya Pradesh',
    district: 'Narsinghpur',
    city: 'Chawarpatha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e95d',
    },
    state: 'Madhya Pradesh',
    district: 'Narsinghpur',
    city: 'Gotegaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e95e',
    },
    state: 'Madhya Pradesh',
    district: 'Morena',
    city: 'Sabalgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e95f',
    },
    state: 'Madhya Pradesh',
    district: 'Narsinghpur',
    city: 'Kareli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e960',
    },
    state: 'Madhya Pradesh',
    district: 'Narsinghpur',
    city: 'Narsimhapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e961',
    },
    state: 'Madhya Pradesh',
    district: 'Narsinghpur',
    city: 'Sainkheda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e962',
    },
    state: 'Madhya Pradesh',
    district: 'Neemuch',
    city: 'Jawad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e963',
    },
    state: 'Madhya Pradesh',
    district: 'Neemuch',
    city: 'Neemuch',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e964',
    },
    state: 'Madhya Pradesh',
    district: 'Niwari',
    city: 'Niwari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e965',
    },
    state: 'Madhya Pradesh',
    district: 'Neemuch',
    city: 'Manasa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e966',
    },
    state: 'Madhya Pradesh',
    district: 'Niwari',
    city: 'Prithvipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e967',
    },
    state: 'Madhya Pradesh',
    district: 'Panna',
    city: 'Ajaigarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e968',
    },
    state: 'Madhya Pradesh',
    district: 'Panna',
    city: 'Panna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e969',
    },
    state: 'Madhya Pradesh',
    district: 'Panna',
    city: 'Gunour',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e96a',
    },
    state: 'Madhya Pradesh',
    district: 'Panna',
    city: 'Pawai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e96b',
    },
    state: 'Madhya Pradesh',
    district: 'Panna',
    city: 'Shahnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e96c',
    },
    state: 'Madhya Pradesh',
    district: 'Raisen',
    city: 'Badi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e96d',
    },
    state: 'Madhya Pradesh',
    district: 'Raisen',
    city: 'Begamganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e96e',
    },
    state: 'Madhya Pradesh',
    district: 'Raisen',
    city: 'Obaidallaganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e96f',
    },
    state: 'Madhya Pradesh',
    district: 'Raisen',
    city: 'Sanchi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e970',
    },
    state: 'Madhya Pradesh',
    district: 'Raisen',
    city: 'Silwani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e971',
    },
    state: 'Madhya Pradesh',
    district: 'Raisen',
    city: 'Udaipura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e972',
    },
    state: 'Madhya Pradesh',
    district: 'Rajgarh',
    city: 'Biaora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e973',
    },
    state: 'Madhya Pradesh',
    district: 'Rajgarh',
    city: 'Khilchipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e974',
    },
    state: 'Madhya Pradesh',
    district: 'Rajgarh',
    city: 'Narsinghgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e975',
    },
    state: 'Madhya Pradesh',
    district: 'Rajgarh',
    city: 'Rajgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e976',
    },
    state: 'Madhya Pradesh',
    district: 'Rajgarh',
    city: 'Sarangpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e977',
    },
    state: 'Madhya Pradesh',
    district: 'Rajgarh',
    city: 'Zirapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e978',
    },
    state: 'Madhya Pradesh',
    district: 'Ratlam',
    city: 'Alot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e979',
    },
    state: 'Madhya Pradesh',
    district: 'Ratlam',
    city: 'Bajna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e97a',
    },
    state: 'Madhya Pradesh',
    district: 'Raisen',
    city: 'Gairatganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e97b',
    },
    state: 'Madhya Pradesh',
    district: 'Ratlam',
    city: 'Piploda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e97c',
    },
    state: 'Madhya Pradesh',
    district: 'Ratlam',
    city: 'Ratlam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e97d',
    },
    state: 'Madhya Pradesh',
    district: 'Ratlam',
    city: 'Jaora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e97e',
    },
    state: 'Madhya Pradesh',
    district: 'Ratlam',
    city: 'Sailana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e97f',
    },
    state: 'Madhya Pradesh',
    district: 'Rewa',
    city: 'Gangev',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e980',
    },
    state: 'Madhya Pradesh',
    district: 'Rewa',
    city: 'Hanumana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e981',
    },
    state: 'Madhya Pradesh',
    district: 'Rewa',
    city: 'Jawa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e982',
    },
    state: 'Madhya Pradesh',
    district: 'Rewa',
    city: 'Mauganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e983',
    },
    state: 'Madhya Pradesh',
    district: 'Rewa',
    city: 'Naigarhi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e984',
    },
    state: 'Madhya Pradesh',
    district: 'Rewa',
    city: 'Raipur Karchuliyan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e985',
    },
    state: 'Madhya Pradesh',
    district: 'Rewa',
    city: 'Sirmour',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e986',
    },
    state: 'Madhya Pradesh',
    district: 'Rewa',
    city: 'Teonthar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e987',
    },
    state: 'Madhya Pradesh',
    district: 'Sagar',
    city: 'Banda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e988',
    },
    state: 'Madhya Pradesh',
    district: 'Sagar',
    city: 'Bina',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e989',
    },
    state: 'Madhya Pradesh',
    district: 'Sagar',
    city: 'Deori',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e98a',
    },
    state: 'Madhya Pradesh',
    district: 'Sagar',
    city: 'Jaisinagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e98b',
    },
    state: 'Madhya Pradesh',
    district: 'Sagar',
    city: 'Kesli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e98c',
    },
    state: 'Madhya Pradesh',
    district: 'Sagar',
    city: 'Khurai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e98d',
    },
    state: 'Madhya Pradesh',
    district: 'Sagar',
    city: 'Malthone',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e98e',
    },
    state: 'Madhya Pradesh',
    district: 'Sagar',
    city: 'Rahatgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e98f',
    },
    state: 'Madhya Pradesh',
    district: 'Sagar',
    city: 'Rehli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e990',
    },
    state: 'Madhya Pradesh',
    district: 'Sagar',
    city: 'Sagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e991',
    },
    state: 'Madhya Pradesh',
    district: 'Rewa',
    city: 'Rewa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e992',
    },
    state: 'Madhya Pradesh',
    district: 'Satna',
    city: 'Amarpatan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e993',
    },
    state: 'Madhya Pradesh',
    district: 'Satna',
    city: 'Maihar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e994',
    },
    state: 'Madhya Pradesh',
    district: 'Satna',
    city: 'Majhgawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e995',
    },
    state: 'Madhya Pradesh',
    district: 'Satna',
    city: 'Nagod',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e996',
    },
    state: 'Madhya Pradesh',
    district: 'Sagar',
    city: 'Shahgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e997',
    },
    state: 'Madhya Pradesh',
    district: 'Satna',
    city: 'Ramnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e998',
    },
    state: 'Madhya Pradesh',
    district: 'Satna',
    city: 'Sohawal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e999',
    },
    state: 'Madhya Pradesh',
    district: 'Satna',
    city: 'Rampur Baghelan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e99a',
    },
    state: 'Madhya Pradesh',
    district: 'Satna',
    city: 'Unchahara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e99b',
    },
    state: 'Madhya Pradesh',
    district: 'Sehore',
    city: 'Ashta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e99c',
    },
    state: 'Madhya Pradesh',
    district: 'Sehore',
    city: 'Ichhawar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e99d',
    },
    state: 'Madhya Pradesh',
    district: 'Sehore',
    city: 'Budni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e99e',
    },
    state: 'Madhya Pradesh',
    district: 'Sehore',
    city: 'Sehore',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e99f',
    },
    state: 'Madhya Pradesh',
    district: 'Seoni',
    city: 'Barghat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9a0',
    },
    state: 'Madhya Pradesh',
    district: 'Sehore',
    city: 'Nasrullaganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9a1',
    },
    state: 'Madhya Pradesh',
    district: 'Seoni',
    city: 'Dhanaura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9a2',
    },
    state: 'Madhya Pradesh',
    district: 'Seoni',
    city: 'Kahnapas(Ghansaur)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9a3',
    },
    state: 'Madhya Pradesh',
    district: 'Seoni',
    city: 'Keolari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9a4',
    },
    state: 'Madhya Pradesh',
    district: 'Seoni',
    city: 'Kurai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9a5',
    },
    state: 'Madhya Pradesh',
    district: 'Seoni',
    city: 'Lakhnadon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9a6',
    },
    state: 'Madhya Pradesh',
    district: 'Seoni',
    city: 'Seoni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9a7',
    },
    state: 'Madhya Pradesh',
    district: 'Shahdol',
    city: 'Beohari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9a8',
    },
    state: 'Madhya Pradesh',
    district: 'Shahdol',
    city: 'Burhar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9a9',
    },
    state: 'Madhya Pradesh',
    district: 'Shahdol',
    city: 'Gohparu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9aa',
    },
    state: 'Madhya Pradesh',
    district: 'Shahdol',
    city: 'Jaisinghnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9ab',
    },
    state: 'Madhya Pradesh',
    district: 'Seoni',
    city: 'Chhapara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9ac',
    },
    state: 'Madhya Pradesh',
    district: 'Shahdol',
    city: 'Sohagpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9ad',
    },
    state: 'Madhya Pradesh',
    district: 'Shajapur',
    city: 'Kalapipal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9ae',
    },
    state: 'Madhya Pradesh',
    district: 'Shajapur',
    city: 'Moman Badodia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9af',
    },
    state: 'Madhya Pradesh',
    district: 'Shajapur',
    city: 'Shajapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9b0',
    },
    state: 'Madhya Pradesh',
    district: 'Sheopur',
    city: 'Karahal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9b1',
    },
    state: 'Madhya Pradesh',
    district: 'Sheopur',
    city: 'Sheopur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9b2',
    },
    state: 'Madhya Pradesh',
    district: 'Sheopur',
    city: 'Vijaypur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9b3',
    },
    state: 'Madhya Pradesh',
    district: 'Shivpuri',
    city: 'Badarwas',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9b4',
    },
    state: 'Madhya Pradesh',
    district: 'Shajapur',
    city: 'Shujalpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9b5',
    },
    state: 'Madhya Pradesh',
    district: 'Shivpuri',
    city: 'Karera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9b6',
    },
    state: 'Madhya Pradesh',
    district: 'Shivpuri',
    city: 'Khaniadhana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9b7',
    },
    state: 'Madhya Pradesh',
    district: 'Shivpuri',
    city: 'Kolaras',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9b8',
    },
    state: 'Madhya Pradesh',
    district: 'Shivpuri',
    city: 'Narwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9b9',
    },
    state: 'Madhya Pradesh',
    district: 'Shivpuri',
    city: 'Pichhore',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9ba',
    },
    state: 'Madhya Pradesh',
    district: 'Shivpuri',
    city: 'Shivpuri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9bb',
    },
    state: 'Madhya Pradesh',
    district: 'Sidhi',
    city: 'Kusmi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9bc',
    },
    state: 'Madhya Pradesh',
    district: 'Sidhi',
    city: 'Majhauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9bd',
    },
    state: 'Madhya Pradesh',
    district: 'Sidhi',
    city: 'Rampur Naikin',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9be',
    },
    state: 'Madhya Pradesh',
    district: 'Shivpuri',
    city: 'Pohri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9bf',
    },
    state: 'Madhya Pradesh',
    district: 'Sidhi',
    city: 'Sihawal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9c0',
    },
    state: 'Madhya Pradesh',
    district: 'Singrauli',
    city: 'Baidhan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9c1',
    },
    state: 'Madhya Pradesh',
    district: 'Singrauli',
    city: 'Chitrangi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9c2',
    },
    state: 'Madhya Pradesh',
    district: 'Sidhi',
    city: 'Sidhi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9c3',
    },
    state: 'Madhya Pradesh',
    district: 'Singrauli',
    city: 'Devsar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9c4',
    },
    state: 'Madhya Pradesh',
    district: 'Tikamgarh',
    city: 'Baldeogarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9c5',
    },
    state: 'Madhya Pradesh',
    district: 'Tikamgarh',
    city: 'Jatara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9c6',
    },
    state: 'Madhya Pradesh',
    district: 'Tikamgarh',
    city: 'Tikamgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9c7',
    },
    state: 'Madhya Pradesh',
    district: 'Ujjain',
    city: 'Badnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9c8',
    },
    state: 'Madhya Pradesh',
    district: 'Ujjain',
    city: 'Ghatiya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9c9',
    },
    state: 'Madhya Pradesh',
    district: 'Tikamgarh',
    city: 'Palera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9ca',
    },
    state: 'Madhya Pradesh',
    district: 'Ujjain',
    city: 'Mahidpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9cb',
    },
    state: 'Madhya Pradesh',
    district: 'Ujjain',
    city: 'Tarana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9cc',
    },
    state: 'Madhya Pradesh',
    district: 'Ujjain',
    city: 'Ujjain',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9cd',
    },
    state: 'Madhya Pradesh',
    district: 'Ujjain',
    city: 'Khacharod',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9ce',
    },
    state: 'Madhya Pradesh',
    district: 'Umaria',
    city: 'Karkeli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9cf',
    },
    state: 'Madhya Pradesh',
    district: 'Umaria',
    city: 'Pali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9d0',
    },
    state: 'Madhya Pradesh',
    district: 'Umaria',
    city: 'Manpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9d1',
    },
    state: 'Madhya Pradesh',
    district: 'Vidisha',
    city: 'Basoda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9d2',
    },
    state: 'Madhya Pradesh',
    district: 'Vidisha',
    city: 'Gyaraspur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9d3',
    },
    state: 'Madhya Pradesh',
    district: 'Vidisha',
    city: 'Kurwai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9d4',
    },
    state: 'Madhya Pradesh',
    district: 'Vidisha',
    city: 'Lateri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9d5',
    },
    state: 'Madhya Pradesh',
    district: 'Vidisha',
    city: 'Nateran',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9d6',
    },
    state: 'Madhya Pradesh',
    district: 'Vidisha',
    city: 'Sironj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9d7',
    },
    state: 'Madhya Pradesh',
    district: 'Vidisha',
    city: 'Vidisha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9d8',
    },
    state: 'Maharashtra',
    district: 'Ahmednagar',
    city: 'Akole',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9d9',
    },
    state: 'Maharashtra',
    district: 'Ahmednagar',
    city: 'Karjat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9da',
    },
    state: 'Maharashtra',
    district: 'Ahmednagar',
    city: 'Jamkhed',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9db',
    },
    state: 'Maharashtra',
    district: 'Ahmednagar',
    city: 'Nagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9dc',
    },
    state: 'Maharashtra',
    district: 'Ahmednagar',
    city: 'Nevasa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9dd',
    },
    state: 'Maharashtra',
    district: 'Ahmednagar',
    city: 'Kopargaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9de',
    },
    state: 'Maharashtra',
    district: 'Ahmednagar',
    city: 'Pathardi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9df',
    },
    state: 'Maharashtra',
    district: 'Ahmednagar',
    city: 'Rahata',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9e0',
    },
    state: 'Maharashtra',
    district: 'Ahmednagar',
    city: 'Rahuri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9e1',
    },
    state: 'Maharashtra',
    district: 'Ahmednagar',
    city: 'Sangamner',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9e2',
    },
    state: 'Maharashtra',
    district: 'Ahmednagar',
    city: 'Shevgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9e3',
    },
    state: 'Maharashtra',
    district: 'Ahmednagar',
    city: 'Shrigonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9e4',
    },
    state: 'Maharashtra',
    district: 'Ahmednagar',
    city: 'Shrirampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9e5',
    },
    state: 'Maharashtra',
    district: 'Akola',
    city: 'Akola',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9e6',
    },
    state: 'Maharashtra',
    district: 'Akola',
    city: 'Akot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9e7',
    },
    state: 'Maharashtra',
    district: 'Akola',
    city: 'Balapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9e8',
    },
    state: 'Maharashtra',
    district: 'Akola',
    city: 'Barshitakli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9e9',
    },
    state: 'Maharashtra',
    district: 'Akola',
    city: 'Murtijapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9ea',
    },
    state: 'Maharashtra',
    district: 'Ahmednagar',
    city: 'Parner',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9eb',
    },
    state: 'Maharashtra',
    district: 'Akola',
    city: 'Patur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9ec',
    },
    state: 'Maharashtra',
    district: 'Akola',
    city: 'Telhara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9ed',
    },
    state: 'Maharashtra',
    district: 'Amravati',
    city: 'Achalpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9ee',
    },
    state: 'Maharashtra',
    district: 'Amravati',
    city: 'Anjangaon S',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9ef',
    },
    state: 'Maharashtra',
    district: 'Amravati',
    city: 'Bhatkuli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9f0',
    },
    state: 'Maharashtra',
    district: 'Amravati',
    city: 'Chandur Bz',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9f1',
    },
    state: 'Maharashtra',
    district: 'Amravati',
    city: 'Chandur Ril',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9f2',
    },
    state: 'Maharashtra',
    district: 'Amravati',
    city: 'Chikhaldara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9f3',
    },
    state: 'Maharashtra',
    district: 'Amravati',
    city: 'Daryapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9f4',
    },
    state: 'Maharashtra',
    district: 'Amravati',
    city: 'Amravati',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9f5',
    },
    state: 'Maharashtra',
    district: 'Amravati',
    city: 'Dhamangaon Ril',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9f6',
    },
    state: 'Maharashtra',
    district: 'Amravati',
    city: 'Dharni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9f7',
    },
    state: 'Maharashtra',
    district: 'Amravati',
    city: 'Nandgaon Kh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9f8',
    },
    state: 'Maharashtra',
    district: 'Amravati',
    city: 'Tiwsa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9f9',
    },
    state: 'Maharashtra',
    district: 'Amravati',
    city: 'Warud',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9fb',
    },
    state: 'Maharashtra',
    district: 'Amravati',
    city: 'Morshi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9fc',
    },
    state: 'Maharashtra',
    district: 'Aurangabad',
    city: 'Kanand',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9fd',
    },
    state: 'Maharashtra',
    district: 'Aurangabad',
    city: 'Gangapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9fe',
    },
    state: 'Maharashtra',
    district: 'Aurangabad',
    city: 'Khultabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559e9ff',
    },
    state: 'Maharashtra',
    district: 'Aurangabad',
    city: 'Paithan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea00',
    },
    state: 'Maharashtra',
    district: 'Aurangabad',
    city: 'Phulambri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea01',
    },
    state: 'Maharashtra',
    district: 'Aurangabad',
    city: 'Sillod',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea02',
    },
    state: 'Maharashtra',
    district: 'Aurangabad',
    city: 'Soegaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea03',
    },
    state: 'Maharashtra',
    district: 'Aurangabad',
    city: 'Vaijapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea04',
    },
    state: 'Maharashtra',
    district: 'Beed',
    city: 'Ambajogai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea05',
    },
    state: 'Maharashtra',
    district: 'Beed',
    city: 'Beed',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea06',
    },
    state: 'Maharashtra',
    district: 'Beed',
    city: 'Dharur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea07',
    },
    state: 'Maharashtra',
    district: 'Beed',
    city: 'Georai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea08',
    },
    state: 'Maharashtra',
    district: 'Beed',
    city: 'Kaij',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea09',
    },
    state: 'Maharashtra',
    district: 'Beed',
    city: 'Ashti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea0a',
    },
    state: 'Maharashtra',
    district: 'Beed',
    city: 'Parali V .',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea0b',
    },
    state: 'Maharashtra',
    district: 'Beed',
    city: 'Majalgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea0c',
    },
    state: 'Maharashtra',
    district: 'Beed',
    city: 'Patoda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea0d',
    },
    state: 'Maharashtra',
    district: 'Beed',
    city: 'Shirur ( Ka )',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea0e',
    },
    state: 'Maharashtra',
    district: 'Beed',
    city: 'Wadwani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea0f',
    },
    state: 'Maharashtra',
    district: 'Bhandara',
    city: 'Bhandara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea10',
    },
    state: 'Maharashtra',
    district: 'Bhandara',
    city: 'Lakhandur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea11',
    },
    state: 'Maharashtra',
    district: 'Bhandara',
    city: 'Lakhani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea12',
    },
    state: 'Maharashtra',
    district: 'Bhandara',
    city: 'Mohadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea13',
    },
    state: 'Maharashtra',
    district: 'Bhandara',
    city: 'Pauni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea14',
    },
    state: 'Maharashtra',
    district: 'Bhandara',
    city: 'Sakoli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea15',
    },
    state: 'Maharashtra',
    district: 'Bhandara',
    city: 'Tumsar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea16',
    },
    state: 'Maharashtra',
    district: 'Buldana',
    city: 'Buldana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea17',
    },
    state: 'Maharashtra',
    district: 'Buldana',
    city: 'Chikhli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea18',
    },
    state: 'Maharashtra',
    district: 'Buldana',
    city: 'D. Raja',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea19',
    },
    state: 'Maharashtra',
    district: 'Buldana',
    city: 'Khamgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea1a',
    },
    state: 'Maharashtra',
    district: 'Buldana',
    city: 'Lonar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea1b',
    },
    state: 'Maharashtra',
    district: 'Buldana',
    city: 'Malkapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea1c',
    },
    state: 'Maharashtra',
    district: 'Buldana',
    city: 'Mehkar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea1d',
    },
    state: 'Maharashtra',
    district: 'Buldana',
    city: 'Motala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea1e',
    },
    state: 'Maharashtra',
    district: 'Buldana',
    city: 'Nandura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea1f',
    },
    state: 'Maharashtra',
    district: 'Buldana',
    city: 'Sangrampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea20',
    },
    state: 'Maharashtra',
    district: 'Buldana',
    city: 'Shegaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea21',
    },
    state: 'Maharashtra',
    district: 'Buldana',
    city: 'Sindkhedraja',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea22',
    },
    state: 'Maharashtra',
    district: 'Chandrapur',
    city: 'Ballarpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea23',
    },
    state: 'Maharashtra',
    district: 'Buldana',
    city: 'Jalgaonjamod',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea24',
    },
    state: 'Maharashtra',
    district: 'Chandrapur',
    city: 'Bhadrawati',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea25',
    },
    state: 'Maharashtra',
    district: 'Chandrapur',
    city: 'Brahmapuri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea26',
    },
    state: 'Maharashtra',
    district: 'Chandrapur',
    city: 'Chandrapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea27',
    },
    state: 'Maharashtra',
    district: 'Chandrapur',
    city: 'Chimur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea28',
    },
    state: 'Maharashtra',
    district: 'Chandrapur',
    city: 'Jiwati',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea29',
    },
    state: 'Maharashtra',
    district: 'Chandrapur',
    city: 'Korpana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea2a',
    },
    state: 'Maharashtra',
    district: 'Chandrapur',
    city: 'Mul',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea2b',
    },
    state: 'Maharashtra',
    district: 'Chandrapur',
    city: 'Nagbhid',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea2c',
    },
    state: 'Maharashtra',
    district: 'Chandrapur',
    city: 'Gondpipri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea2d',
    },
    state: 'Maharashtra',
    district: 'Chandrapur',
    city: 'Rajura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea2e',
    },
    state: 'Maharashtra',
    district: 'Chandrapur',
    city: 'Saoli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea2f',
    },
    state: 'Maharashtra',
    district: 'Chandrapur',
    city: 'Sindewahi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea30',
    },
    state: 'Maharashtra',
    district: 'Chandrapur',
    city: 'Warora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea31',
    },
    state: 'Maharashtra',
    district: 'Dhule',
    city: 'Dhule',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea32',
    },
    state: 'Maharashtra',
    district: 'Dhule',
    city: 'Sakri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea33',
    },
    state: 'Maharashtra',
    district: 'Dhule',
    city: 'Shindkhede',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea34',
    },
    state: 'Maharashtra',
    district: 'Dhule',
    city: 'Shirpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea35',
    },
    state: 'Maharashtra',
    district: 'Chandrapur',
    city: 'Pombhurna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea36',
    },
    state: 'Maharashtra',
    district: 'Gadchiroli',
    city: 'Armori',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea37',
    },
    state: 'Maharashtra',
    district: 'Gadchiroli',
    city: 'Bhamaragad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea38',
    },
    state: 'Maharashtra',
    district: 'Gadchiroli',
    city: 'Aheri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea39',
    },
    state: 'Maharashtra',
    district: 'Gadchiroli',
    city: 'Chamorshi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea3a',
    },
    state: 'Maharashtra',
    district: 'Gadchiroli',
    city: 'Desaiganj (Wadsa)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea3b',
    },
    state: 'Maharashtra',
    district: 'Gadchiroli',
    city: 'Etapalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea3c',
    },
    state: 'Maharashtra',
    district: 'Gadchiroli',
    city: 'Gadchiroli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea3d',
    },
    state: 'Maharashtra',
    district: 'Gadchiroli',
    city: 'Korchi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea3e',
    },
    state: 'Maharashtra',
    district: 'Gadchiroli',
    city: 'Kurkheda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea3f',
    },
    state: 'Maharashtra',
    district: 'Gadchiroli',
    city: 'Mulchera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea40',
    },
    state: 'Maharashtra',
    district: 'Gadchiroli',
    city: 'Sironcha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea41',
    },
    state: 'Maharashtra',
    district: 'Gadchiroli',
    city: 'Dhanora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea42',
    },
    state: 'Maharashtra',
    district: 'Gondia',
    city: 'Amgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea43',
    },
    state: 'Maharashtra',
    district: 'Gondia',
    city: 'Arjuni Morgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea44',
    },
    state: 'Maharashtra',
    district: 'Gondia',
    city: 'Gondia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea45',
    },
    state: 'Maharashtra',
    district: 'Gondia',
    city: 'Goregaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea46',
    },
    state: 'Maharashtra',
    district: 'Gondia',
    city: 'Sadak Arjuni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea47',
    },
    state: 'Maharashtra',
    district: 'Gondia',
    city: 'Salekasa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea48',
    },
    state: 'Maharashtra',
    district: 'Gondia',
    city: 'Deori',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea49',
    },
    state: 'Maharashtra',
    district: 'Hingoli',
    city: 'Aundha Nagnath',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea4a',
    },
    state: 'Maharashtra',
    district: 'Hingoli',
    city: 'Basmat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea4b',
    },
    state: 'Maharashtra',
    district: 'Hingoli',
    city: 'Hingoli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea4c',
    },
    state: 'Maharashtra',
    district: 'Hingoli',
    city: 'Kalamnuri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea4d',
    },
    state: 'Maharashtra',
    district: 'Hingoli',
    city: 'Sengaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea4e',
    },
    state: 'Maharashtra',
    district: 'Jalgaon',
    city: 'Amalner',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea4f',
    },
    state: 'Maharashtra',
    district: 'Jalgaon',
    city: 'Bhadgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea50',
    },
    state: 'Maharashtra',
    district: 'Gondia',
    city: 'Tirora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea51',
    },
    state: 'Maharashtra',
    district: 'Jalgaon',
    city: 'Bhusawal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea52',
    },
    state: 'Maharashtra',
    district: 'Jalgaon',
    city: 'Chalisgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea53',
    },
    state: 'Maharashtra',
    district: 'Jalgaon',
    city: 'Bodwad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea54',
    },
    state: 'Maharashtra',
    district: 'Jalgaon',
    city: 'Chopda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea55',
    },
    state: 'Maharashtra',
    district: 'Jalgaon',
    city: 'Dharangaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea56',
    },
    state: 'Maharashtra',
    district: 'Jalgaon',
    city: 'Erandol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea57',
    },
    state: 'Maharashtra',
    district: 'Jalgaon',
    city: 'Jalgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea58',
    },
    state: 'Maharashtra',
    district: 'Jalgaon',
    city: 'Jamner',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea59',
    },
    state: 'Maharashtra',
    district: 'Jalgaon',
    city: 'Muktainagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea5a',
    },
    state: 'Maharashtra',
    district: 'Jalgaon',
    city: 'Pachora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea5b',
    },
    state: 'Maharashtra',
    district: 'Jalgaon',
    city: 'Parola',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea5c',
    },
    state: 'Maharashtra',
    district: 'Jalgaon',
    city: 'Raver',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea5d',
    },
    state: 'Maharashtra',
    district: 'Jalna',
    city: 'Ambad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea5e',
    },
    state: 'Maharashtra',
    district: 'Jalna',
    city: 'Badnapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea5f',
    },
    state: 'Maharashtra',
    district: 'Jalna',
    city: 'Bhokardan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea60',
    },
    state: 'Maharashtra',
    district: 'Jalgaon',
    city: 'Yawal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea61',
    },
    state: 'Maharashtra',
    district: 'Jalna',
    city: 'Ghansawangi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea62',
    },
    state: 'Maharashtra',
    district: 'Jalna',
    city: 'Jafrabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea63',
    },
    state: 'Maharashtra',
    district: 'Jalna',
    city: 'Jalna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea64',
    },
    state: 'Maharashtra',
    district: 'Jalna',
    city: 'Mantha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea65',
    },
    state: 'Maharashtra',
    district: 'Kolhapur',
    city: 'Ajara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea66',
    },
    state: 'Maharashtra',
    district: 'Jalna',
    city: 'Partur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea67',
    },
    state: 'Maharashtra',
    district: 'Kolhapur',
    city: 'Bhudargad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea68',
    },
    state: 'Maharashtra',
    district: 'Kolhapur',
    city: 'Gadhinglaj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea69',
    },
    state: 'Maharashtra',
    district: 'Kolhapur',
    city: 'Chandgad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea6a',
    },
    state: 'Maharashtra',
    district: 'Kolhapur',
    city: 'Hatkanangale',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea6b',
    },
    state: 'Maharashtra',
    district: 'Kolhapur',
    city: 'Gagan Bavada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea6c',
    },
    state: 'Maharashtra',
    district: 'Kolhapur',
    city: 'Kagal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea6d',
    },
    state: 'Maharashtra',
    district: 'Kolhapur',
    city: 'Karveer',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea6e',
    },
    state: 'Maharashtra',
    district: 'Kolhapur',
    city: 'Panhala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea6f',
    },
    state: 'Maharashtra',
    district: 'Kolhapur',
    city: 'Shahuwadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea70',
    },
    state: 'Maharashtra',
    district: 'Kolhapur',
    city: 'Radhanagari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea71',
    },
    state: 'Maharashtra',
    district: 'Latur',
    city: 'Ahemadpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea72',
    },
    state: 'Maharashtra',
    district: 'Kolhapur',
    city: 'Shirol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea73',
    },
    state: 'Maharashtra',
    district: 'Latur',
    city: 'Ausa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea74',
    },
    state: 'Maharashtra',
    district: 'Latur',
    city: 'Deoni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea75',
    },
    state: 'Maharashtra',
    district: 'Latur',
    city: 'Jalkot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea76',
    },
    state: 'Maharashtra',
    district: 'Latur',
    city: 'Chakur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea77',
    },
    state: 'Maharashtra',
    district: 'Latur',
    city: 'Nilanga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea78',
    },
    state: 'Maharashtra',
    district: 'Latur',
    city: 'Latur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea79',
    },
    state: 'Maharashtra',
    district: 'Latur',
    city: 'Renapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea7a',
    },
    state: 'Maharashtra',
    district: 'Latur',
    city: 'Shirur Anantpal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea7b',
    },
    state: 'Maharashtra',
    district: 'Latur',
    city: 'Udgir',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea7c',
    },
    state: 'Maharashtra',
    district: 'Mumbai City',
    city: 'Mumbai City',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea7d',
    },
    state: 'Maharashtra',
    district: 'Mumbai Suburban',
    city: 'Andheri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea7e',
    },
    state: 'Maharashtra',
    district: 'Mumbai Suburban',
    city: 'Borivali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea7f',
    },
    state: 'Maharashtra',
    district: 'Mumbai Suburban',
    city: 'Kurla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea80',
    },
    state: 'Maharashtra',
    district: 'Nagpur',
    city: 'Bhivapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea81',
    },
    state: 'Maharashtra',
    district: 'Nagpur',
    city: 'Hingna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea82',
    },
    state: 'Maharashtra',
    district: 'Nagpur',
    city: 'Kamptee',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea83',
    },
    state: 'Maharashtra',
    district: 'Nagpur',
    city: 'Katol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea84',
    },
    state: 'Maharashtra',
    district: 'Nagpur',
    city: 'Kuhi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea85',
    },
    state: 'Maharashtra',
    district: 'Nagpur',
    city: 'Mouda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea86',
    },
    state: 'Maharashtra',
    district: 'Nagpur',
    city: 'Kalmeshwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea87',
    },
    state: 'Maharashtra',
    district: 'Nagpur',
    city: 'Narkhed',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea88',
    },
    state: 'Maharashtra',
    district: 'Nagpur',
    city: 'Nagpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea89',
    },
    state: 'Maharashtra',
    district: 'Nagpur',
    city: 'Parseoni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea8a',
    },
    state: 'Maharashtra',
    district: 'Nagpur',
    city: 'Ramtek',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea8b',
    },
    state: 'Maharashtra',
    district: 'Nagpur',
    city: 'Saoner',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea8c',
    },
    state: 'Maharashtra',
    district: 'Nanded',
    city: 'Ardhapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea8d',
    },
    state: 'Maharashtra',
    district: 'Nagpur',
    city: 'Umred',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea8e',
    },
    state: 'Maharashtra',
    district: 'Nanded',
    city: 'Bhokar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea8f',
    },
    state: 'Maharashtra',
    district: 'Nanded',
    city: 'Biloli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea90',
    },
    state: 'Maharashtra',
    district: 'Nanded',
    city: 'Deglur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea91',
    },
    state: 'Maharashtra',
    district: 'Nanded',
    city: 'Dharmabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea92',
    },
    state: 'Maharashtra',
    district: 'Nanded',
    city: 'Hadgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea93',
    },
    state: 'Maharashtra',
    district: 'Nanded',
    city: 'Himayatnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea94',
    },
    state: 'Maharashtra',
    district: 'Nanded',
    city: 'Kandhar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea95',
    },
    state: 'Maharashtra',
    district: 'Nanded',
    city: 'Kinwat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea96',
    },
    state: 'Maharashtra',
    district: 'Nanded',
    city: 'Mahur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea97',
    },
    state: 'Maharashtra',
    district: 'Nanded',
    city: 'Modkhed',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea98',
    },
    state: 'Maharashtra',
    district: 'Nanded',
    city: 'Mokhed',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea99',
    },
    state: 'Maharashtra',
    district: 'Nanded',
    city: 'Naigaon (Kh)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea9a',
    },
    state: 'Maharashtra',
    district: 'Nanded',
    city: 'Nanded',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea9b',
    },
    state: 'Maharashtra',
    district: 'Nanded',
    city: 'Umri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea9c',
    },
    state: 'Maharashtra',
    district: 'Nandurbar',
    city: 'Akarani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea9d',
    },
    state: 'Maharashtra',
    district: 'Nandurbar',
    city: 'Akkalkuwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea9e',
    },
    state: 'Maharashtra',
    district: 'Nandurbar',
    city: 'Nandurbar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ea9f',
    },
    state: 'Maharashtra',
    district: 'Nandurbar',
    city: 'Navapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaa0',
    },
    state: 'Maharashtra',
    district: 'Nandurbar',
    city: 'Shahada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaa1',
    },
    state: 'Maharashtra',
    district: 'Nanded',
    city: 'Loha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaa2',
    },
    state: 'Maharashtra',
    district: 'Nashik',
    city: 'Baglan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaa3',
    },
    state: 'Maharashtra',
    district: 'Nandurbar',
    city: 'Taloda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaa4',
    },
    state: 'Maharashtra',
    district: 'Nashik',
    city: 'Chandwad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaa5',
    },
    state: 'Maharashtra',
    district: 'Nashik',
    city: 'Dindori',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaa6',
    },
    state: 'Maharashtra',
    district: 'Nashik',
    city: 'Igatpuri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaa7',
    },
    state: 'Maharashtra',
    district: 'Nashik',
    city: 'Kalwan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaa8',
    },
    state: 'Maharashtra',
    district: 'Nashik',
    city: 'Malegaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaa9',
    },
    state: 'Maharashtra',
    district: 'Nashik',
    city: 'Deola',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaaa',
    },
    state: 'Maharashtra',
    district: 'Nashik',
    city: 'Nandgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaab',
    },
    state: 'Maharashtra',
    district: 'Nashik',
    city: 'Nashik',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaac',
    },
    state: 'Maharashtra',
    district: 'Nashik',
    city: 'Niphad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaad',
    },
    state: 'Maharashtra',
    district: 'Nashik',
    city: 'Peth',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaae',
    },
    state: 'Maharashtra',
    district: 'Nashik',
    city: 'Sinnar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaaf',
    },
    state: 'Maharashtra',
    district: 'Nashik',
    city: 'Trimbak',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eab0',
    },
    state: 'Maharashtra',
    district: 'Nashik',
    city: 'Yeola',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eab1',
    },
    state: 'Maharashtra',
    district: 'Osmanabad',
    city: 'Bhoom',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eab2',
    },
    state: 'Maharashtra',
    district: 'Osmanabad',
    city: 'Kalamb',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eab3',
    },
    state: 'Maharashtra',
    district: 'Osmanabad',
    city: 'Lohara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eab4',
    },
    state: 'Maharashtra',
    district: 'Osmanabad',
    city: 'Omerga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eab5',
    },
    state: 'Maharashtra',
    district: 'Osmanabad',
    city: 'Osmanabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eab6',
    },
    state: 'Maharashtra',
    district: 'Osmanabad',
    city: 'Paranda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eab7',
    },
    state: 'Maharashtra',
    district: 'Osmanabad',
    city: 'Tuljapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eab8',
    },
    state: 'Maharashtra',
    district: 'Osmanabad',
    city: 'Washi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eab9',
    },
    state: 'Maharashtra',
    district: 'Palghar',
    city: 'Dahanu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaba',
    },
    state: 'Maharashtra',
    district: 'Palghar',
    city: 'Jawhar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eabb',
    },
    state: 'Maharashtra',
    district: 'Nashik',
    city: 'Surgana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eabc',
    },
    state: 'Maharashtra',
    district: 'Palghar',
    city: 'Palghar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eabd',
    },
    state: 'Maharashtra',
    district: 'Palghar',
    city: 'Talasari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eabe',
    },
    state: 'Maharashtra',
    district: 'Palghar',
    city: 'Vasai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eabf',
    },
    state: 'Maharashtra',
    district: 'Palghar',
    city: 'Vikramgad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eac0',
    },
    state: 'Maharashtra',
    district: 'Palghar',
    city: 'Mokhada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eac1',
    },
    state: 'Maharashtra',
    district: 'Palghar',
    city: 'Wada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eac2',
    },
    state: 'Maharashtra',
    district: 'Parbhani',
    city: 'Gangakhed',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eac3',
    },
    state: 'Maharashtra',
    district: 'Parbhani',
    city: 'Jintur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eac4',
    },
    state: 'Maharashtra',
    district: 'Parbhani',
    city: 'Manwat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eac5',
    },
    state: 'Maharashtra',
    district: 'Parbhani',
    city: 'Palam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eac6',
    },
    state: 'Maharashtra',
    district: 'Parbhani',
    city: 'Parbhani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eac7',
    },
    state: 'Maharashtra',
    district: 'Parbhani',
    city: 'Purna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eac8',
    },
    state: 'Maharashtra',
    district: 'Parbhani',
    city: 'Sailu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eac9',
    },
    state: 'Maharashtra',
    district: 'Parbhani',
    city: 'Pathri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaca',
    },
    state: 'Maharashtra',
    district: 'Pune',
    city: 'Ambegaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eacb',
    },
    state: 'Maharashtra',
    district: 'Pune',
    city: 'Baramati',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eacc',
    },
    state: 'Maharashtra',
    district: 'Pune',
    city: 'Bhor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eacd',
    },
    state: 'Maharashtra',
    district: 'Pune',
    city: 'Daund',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eace',
    },
    state: 'Maharashtra',
    district: 'Pune',
    city: 'Haveli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eacf',
    },
    state: 'Maharashtra',
    district: 'Pune',
    city: 'Indapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ead0',
    },
    state: 'Maharashtra',
    district: 'Pune',
    city: 'Junnar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ead1',
    },
    state: 'Maharashtra',
    district: 'Pune',
    city: 'Khed',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ead2',
    },
    state: 'Maharashtra',
    district: 'Pune',
    city: 'Maval',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ead3',
    },
    state: 'Maharashtra',
    district: 'Pune',
    city: 'Mulshi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ead4',
    },
    state: 'Maharashtra',
    district: 'Pune',
    city: 'Pune City',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ead5',
    },
    state: 'Maharashtra',
    district: 'Parbhani',
    city: 'Sonpeth',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ead6',
    },
    state: 'Maharashtra',
    district: 'Pune',
    city: 'Purandar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ead7',
    },
    state: 'Maharashtra',
    district: 'Pune',
    city: 'Shirur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ead8',
    },
    state: 'Maharashtra',
    district: 'Pune',
    city: 'Velhe',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ead9',
    },
    state: 'Maharashtra',
    district: 'Raigad',
    city: 'Karjat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eada',
    },
    state: 'Maharashtra',
    district: 'Raigad',
    city: 'Khalapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eadb',
    },
    state: 'Maharashtra',
    district: 'Raigad',
    city: 'Mahad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eadc',
    },
    state: 'Maharashtra',
    district: 'Raigad',
    city: 'Mangaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eadd',
    },
    state: 'Maharashtra',
    district: 'Raigad',
    city: 'Alibag',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eade',
    },
    state: 'Maharashtra',
    district: 'Raigad',
    city: 'Mhasala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eadf',
    },
    state: 'Maharashtra',
    district: 'Raigad',
    city: 'Murud',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eae0',
    },
    state: 'Maharashtra',
    district: 'Raigad',
    city: 'Panvel',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eae1',
    },
    state: 'Maharashtra',
    district: 'Raigad',
    city: 'Pen',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eae2',
    },
    state: 'Maharashtra',
    district: 'Raigad',
    city: 'Poladpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eae3',
    },
    state: 'Maharashtra',
    district: 'Raigad',
    city: 'Shrivardhan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eae4',
    },
    state: 'Maharashtra',
    district: 'Raigad',
    city: 'Sudhagad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eae5',
    },
    state: 'Maharashtra',
    district: 'Raigad',
    city: 'Tala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eae6',
    },
    state: 'Maharashtra',
    district: 'Raigad',
    city: 'Uran',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eae7',
    },
    state: 'Maharashtra',
    district: 'Raigad',
    city: 'Roha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eae8',
    },
    state: 'Maharashtra',
    district: 'Ratnagiri',
    city: 'Chipalun',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eae9',
    },
    state: 'Maharashtra',
    district: 'Ratnagiri',
    city: 'Dapoli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaea',
    },
    state: 'Maharashtra',
    district: 'Ratnagiri',
    city: 'Guhagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaeb',
    },
    state: 'Maharashtra',
    district: 'Ratnagiri',
    city: 'Khed',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaec',
    },
    state: 'Maharashtra',
    district: 'Ratnagiri',
    city: 'Lanja',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaed',
    },
    state: 'Maharashtra',
    district: 'Ratnagiri',
    city: 'Mandangad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaee',
    },
    state: 'Maharashtra',
    district: 'Ratnagiri',
    city: 'Rajapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaef',
    },
    state: 'Maharashtra',
    district: 'Ratnagiri',
    city: 'Ratnagiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaf0',
    },
    state: 'Maharashtra',
    district: 'Ratnagiri',
    city: 'Sangmeshwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaf1',
    },
    state: 'Maharashtra',
    district: 'Sangli',
    city: 'Atpadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaf2',
    },
    state: 'Maharashtra',
    district: 'Sangli',
    city: 'Jath',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaf3',
    },
    state: 'Maharashtra',
    district: 'Sangli',
    city: 'Kavathemahankal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaf4',
    },
    state: 'Maharashtra',
    district: 'Sangli',
    city: 'Khanapur-Vita',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaf5',
    },
    state: 'Maharashtra',
    district: 'Sangli',
    city: 'Kadegaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaf6',
    },
    state: 'Maharashtra',
    district: 'Sangli',
    city: 'Palus',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaf7',
    },
    state: 'Maharashtra',
    district: 'Sangli',
    city: 'Shirala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaf8',
    },
    state: 'Maharashtra',
    district: 'Sangli',
    city: 'Tasgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaf9',
    },
    state: 'Maharashtra',
    district: 'Sangli',
    city: 'Valva-Islampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eafa',
    },
    state: 'Maharashtra',
    district: 'Satara',
    city: 'Jawali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eafb',
    },
    state: 'Maharashtra',
    district: 'Satara',
    city: 'Karad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eafc',
    },
    state: 'Maharashtra',
    district: 'Satara',
    city: 'Khandala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eafd',
    },
    state: 'Maharashtra',
    district: 'Satara',
    city: 'Khatav',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eafe',
    },
    state: 'Maharashtra',
    district: 'Satara',
    city: 'Koregaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eaff',
    },
    state: 'Maharashtra',
    district: 'Satara',
    city: 'Mahabaleshwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb00',
    },
    state: 'Maharashtra',
    district: 'Satara',
    city: 'Man',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb01',
    },
    state: 'Maharashtra',
    district: 'Satara',
    city: 'Patan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb02',
    },
    state: 'Maharashtra',
    district: 'Sangli',
    city: 'Miraj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb03',
    },
    state: 'Maharashtra',
    district: 'Satara',
    city: 'Satara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb04',
    },
    state: 'Maharashtra',
    district: 'Satara',
    city: 'Wai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb05',
    },
    state: 'Maharashtra',
    district: 'Sindhudurg',
    city: 'Deogad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb06',
    },
    state: 'Maharashtra',
    district: 'Satara',
    city: 'Phaltan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb07',
    },
    state: 'Maharashtra',
    district: 'Sindhudurg',
    city: 'Dodamarg',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb08',
    },
    state: 'Maharashtra',
    district: 'Sindhudurg',
    city: 'Kankavali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb09',
    },
    state: 'Maharashtra',
    district: 'Sindhudurg',
    city: 'Kudal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb0a',
    },
    state: 'Maharashtra',
    district: 'Sindhudurg',
    city: 'Malvan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb0b',
    },
    state: 'Maharashtra',
    district: 'Sindhudurg',
    city: 'Sawantwadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb0c',
    },
    state: 'Maharashtra',
    district: 'Sindhudurg',
    city: 'Vaibhavawadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb0d',
    },
    state: 'Maharashtra',
    district: 'Solapur',
    city: 'Akkalkot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb0e',
    },
    state: 'Maharashtra',
    district: 'Solapur',
    city: 'Barshi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb0f',
    },
    state: 'Maharashtra',
    district: 'Sindhudurg',
    city: 'Vengurla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb10',
    },
    state: 'Maharashtra',
    district: 'Solapur',
    city: 'Karmala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb11',
    },
    state: 'Maharashtra',
    district: 'Solapur',
    city: 'Madha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb12',
    },
    state: 'Maharashtra',
    district: 'Solapur',
    city: 'Malshiras',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb13',
    },
    state: 'Maharashtra',
    district: 'Solapur',
    city: 'Mangalvedhe',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb14',
    },
    state: 'Maharashtra',
    district: 'Solapur',
    city: 'Mohol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb15',
    },
    state: 'Maharashtra',
    district: 'Solapur',
    city: 'Pandharpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb16',
    },
    state: 'Maharashtra',
    district: 'Solapur',
    city: 'Sangola',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb17',
    },
    state: 'Maharashtra',
    district: 'Solapur',
    city: 'Solapur North',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb18',
    },
    state: 'Maharashtra',
    district: 'Thane',
    city: 'Ambernath',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb19',
    },
    state: 'Maharashtra',
    district: 'Solapur',
    city: 'South Solapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb1a',
    },
    state: 'Maharashtra',
    district: 'Thane',
    city: 'Kalyan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb1b',
    },
    state: 'Maharashtra',
    district: 'Thane',
    city: 'Murbad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb1c',
    },
    state: 'Maharashtra',
    district: 'Thane',
    city: 'Shahapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb1d',
    },
    state: 'Maharashtra',
    district: 'Thane',
    city: 'Bhiwandi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb1e',
    },
    state: 'Maharashtra',
    district: 'Wardha',
    city: 'Arvi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb1f',
    },
    state: 'Maharashtra',
    district: 'Wardha',
    city: 'Deoli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb20',
    },
    state: 'Maharashtra',
    district: 'Wardha',
    city: 'Ashti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb21',
    },
    state: 'Maharashtra',
    district: 'Wardha',
    city: 'Hinganghat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb22',
    },
    state: 'Maharashtra',
    district: 'Wardha',
    city: 'Karanja',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb23',
    },
    state: 'Maharashtra',
    district: 'Wardha',
    city: 'Samudrapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb24',
    },
    state: 'Maharashtra',
    district: 'Wardha',
    city: 'Seloo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb25',
    },
    state: 'Maharashtra',
    district: 'Wardha',
    city: 'Wardha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb26',
    },
    state: 'Maharashtra',
    district: 'Washim',
    city: 'Karanja',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb27',
    },
    state: 'Maharashtra',
    district: 'Washim',
    city: 'Malegaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb28',
    },
    state: 'Maharashtra',
    district: 'Washim',
    city: 'Mangrulpir',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb29',
    },
    state: 'Maharashtra',
    district: 'Washim',
    city: 'Manora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb2a',
    },
    state: 'Maharashtra',
    district: 'Washim',
    city: 'Risod',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb2b',
    },
    state: 'Maharashtra',
    district: 'Washim',
    city: 'Washim',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb2c',
    },
    state: 'Maharashtra',
    district: 'Yavatmal',
    city: 'Babhulgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb2d',
    },
    state: 'Maharashtra',
    district: 'Yavatmal',
    city: 'Darwha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb2e',
    },
    state: 'Maharashtra',
    district: 'Yavatmal',
    city: 'Arni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb2f',
    },
    state: 'Maharashtra',
    district: 'Yavatmal',
    city: 'Digras',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb30',
    },
    state: 'Maharashtra',
    district: 'Yavatmal',
    city: 'Ghatanji',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb31',
    },
    state: 'Maharashtra',
    district: 'Yavatmal',
    city: 'Kalamb',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb32',
    },
    state: 'Maharashtra',
    district: 'Yavatmal',
    city: 'Mahagaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb33',
    },
    state: 'Maharashtra',
    district: 'Yavatmal',
    city: 'Maregaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb34',
    },
    state: 'Maharashtra',
    district: 'Yavatmal',
    city: 'Kelapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb35',
    },
    state: 'Maharashtra',
    district: 'Yavatmal',
    city: 'Ner',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb36',
    },
    state: 'Maharashtra',
    district: 'Yavatmal',
    city: 'Pusad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb37',
    },
    state: 'Maharashtra',
    district: 'Yavatmal',
    city: 'Ralegaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb38',
    },
    state: 'Maharashtra',
    district: 'Yavatmal',
    city: 'Umarkhed',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb39',
    },
    state: 'Maharashtra',
    district: 'Yavatmal',
    city: 'Wani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb3a',
    },
    state: 'Maharashtra',
    district: 'Yavatmal',
    city: 'Yavatmal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb3b',
    },
    state: 'Maharashtra',
    district: 'Yavatmal',
    city: 'Zari Jamni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb3c',
    },
    state: 'Manipur',
    district: 'Bishnupur',
    city: 'Moirang',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb3d',
    },
    state: 'Manipur',
    district: 'Bishnupur',
    city: 'Nambol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb3e',
    },
    state: 'Manipur',
    district: 'Chandel',
    city: 'Chakpikarong',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb3f',
    },
    state: 'Manipur',
    district: 'Chandel',
    city: 'Chandel',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb40',
    },
    state: 'Manipur',
    district: 'Bishnupur',
    city: 'Bishnupur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb41',
    },
    state: 'Manipur',
    district: 'Chandel',
    city: 'Khengjoy',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb42',
    },
    state: 'Manipur',
    district: 'Chandel',
    city: 'Khangbarol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb43',
    },
    state: 'Manipur',
    district: 'Churachandpur',
    city: 'Henglep',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb44',
    },
    state: 'Manipur',
    district: 'Churachandpur',
    city: 'Kangvai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb45',
    },
    state: 'Manipur',
    district: 'Churachandpur',
    city: 'Lamka',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb46',
    },
    state: 'Manipur',
    district: 'Churachandpur',
    city: 'Lanva',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb47',
    },
    state: 'Manipur',
    district: 'Churachandpur',
    city: 'Mualnuam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb48',
    },
    state: 'Manipur',
    district: 'Churachandpur',
    city: 'Saikot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb49',
    },
    state: 'Manipur',
    district: 'Churachandpur',
    city: 'Samulamlan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb4a',
    },
    state: 'Manipur',
    district: 'Churachandpur',
    city: 'Sangaikot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb4b',
    },
    state: 'Manipur',
    district: 'Churachandpur',
    city: 'Singngat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb4c',
    },
    state: 'Manipur',
    district: 'Churachandpur',
    city: 'Suangdoh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb4d',
    },
    state: 'Manipur',
    district: 'Churachandpur',
    city: 'Tuibong',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb4e',
    },
    state: 'Manipur',
    district: 'Imphal East',
    city: 'Heingang Cd Block',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb4f',
    },
    state: 'Manipur',
    district: 'Imphal East',
    city: 'Keirao Cd Block',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb50',
    },
    state: 'Manipur',
    district: 'Imphal East',
    city: 'Kshetrigao Cd Block',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb51',
    },
    state: 'Manipur',
    district: 'Imphal East',
    city: 'Sawombung Cd Block',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb52',
    },
    state: 'Manipur',
    district: 'Churachandpur',
    city: 'Lamka South',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb53',
    },
    state: 'Manipur',
    district: 'Imphal West',
    city: 'Hiyangthang',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb54',
    },
    state: 'Manipur',
    district: 'Imphal West',
    city: 'Patsoi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb55',
    },
    state: 'Manipur',
    district: 'Imphal West',
    city: 'Wangoi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb56',
    },
    state: 'Manipur',
    district: 'Jiribam',
    city: 'Borobekra Cd Block',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb57',
    },
    state: 'Manipur',
    district: 'Imphal West',
    city: 'Haorangsabal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb58',
    },
    state: 'Manipur',
    district: 'Kakching',
    city: 'Kakching',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb59',
    },
    state: 'Manipur',
    district: 'Kakching',
    city: 'Langmeidong',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb5a',
    },
    state: 'Manipur',
    district: 'Kamjong',
    city: 'Kamjong',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb5b',
    },
    state: 'Manipur',
    district: 'Kamjong',
    city: 'Kasom Khullen',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb5c',
    },
    state: 'Manipur',
    district: 'Kamjong',
    city: 'Phungyar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb5d',
    },
    state: 'Manipur',
    district: 'Kamjong',
    city: 'Sahamphung Td Block',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb5e',
    },
    state: 'Manipur',
    district: 'Kangpokpi',
    city: 'Bungte Chiru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb5f',
    },
    state: 'Manipur',
    district: 'Kangpokpi',
    city: 'Champhai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb60',
    },
    state: 'Manipur',
    district: 'Kangpokpi',
    city: 'Island',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb61',
    },
    state: 'Manipur',
    district: 'Jiribam',
    city: 'Jiribam C D Block',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb62',
    },
    state: 'Manipur',
    district: 'Kangpokpi',
    city: 'Kangchup Geljang',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb63',
    },
    state: 'Manipur',
    district: 'Kangpokpi',
    city: 'Kangpokpi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb64',
    },
    state: 'Manipur',
    district: 'Kangpokpi',
    city: 'Lhungtin',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb65',
    },
    state: 'Manipur',
    district: 'Kangpokpi',
    city: 'Saitu Gamphazol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb66',
    },
    state: 'Manipur',
    district: 'Kangpokpi',
    city: 'T Vaichong',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb67',
    },
    state: 'Manipur',
    district: 'Noney',
    city: 'Haochong',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb68',
    },
    state: 'Manipur',
    district: 'Noney',
    city: 'Khoupum',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb69',
    },
    state: 'Manipur',
    district: 'Kangpokpi',
    city: 'Saikul',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb6a',
    },
    state: 'Manipur',
    district: 'Noney',
    city: 'Longmai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb6b',
    },
    state: 'Manipur',
    district: 'Noney',
    city: 'Nungba',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb6c',
    },
    state: 'Manipur',
    district: 'Pherzawl',
    city: 'Thanlon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb6d',
    },
    state: 'Manipur',
    district: 'Pherzawl',
    city: 'Tipaimukh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb6e',
    },
    state: 'Manipur',
    district: 'Pherzawl',
    city: 'Vangai Range',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb6f',
    },
    state: 'Manipur',
    district: 'Senapati',
    city: 'Phaibung Khullen',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb70',
    },
    state: 'Manipur',
    district: 'Senapati',
    city: 'Paomata',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb71',
    },
    state: 'Manipur',
    district: 'Senapati',
    city: 'Purul',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb72',
    },
    state: 'Manipur',
    district: 'Senapati',
    city: 'Song Song',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb73',
    },
    state: 'Manipur',
    district: 'Senapati',
    city: 'Tadubi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb74',
    },
    state: 'Manipur',
    district: 'Tamenglong',
    city: 'Tamei',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb75',
    },
    state: 'Manipur',
    district: 'Senapati',
    city: 'Willong',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb76',
    },
    state: 'Manipur',
    district: 'Tamenglong',
    city: 'Tousem',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb77',
    },
    state: 'Manipur',
    district: 'Tengnoupal',
    city: 'Machi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb78',
    },
    state: 'Manipur',
    district: 'Tamenglong',
    city: 'Tamenglong',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb79',
    },
    state: 'Manipur',
    district: 'Tengnoupal',
    city: 'Tengnoupal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb7a',
    },
    state: 'Manipur',
    district: 'Thoubal',
    city: 'Lilong Cd Block',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb7b',
    },
    state: 'Manipur',
    district: 'Thoubal',
    city: 'Thoubal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb7c',
    },
    state: 'Manipur',
    district: 'Thoubal',
    city: 'Wangjing Cd Block',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb7d',
    },
    state: 'Manipur',
    district: 'Ukhrul',
    city: 'Chingai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb7e',
    },
    state: 'Manipur',
    district: 'Ukhrul',
    city: 'Jessami Td',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb7f',
    },
    state: 'Manipur',
    district: 'Ukhrul',
    city: 'Lungchong Meiphai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb80',
    },
    state: 'Manipur',
    district: 'Ukhrul',
    city: 'Ukhrul',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb81',
    },
    state: 'Meghalaya',
    district: 'East Garo Hills',
    city: 'Dambo Rongjeng',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb82',
    },
    state: 'Meghalaya',
    district: 'East Garo Hills',
    city: 'Samanda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb83',
    },
    state: 'Meghalaya',
    district: 'East Garo Hills',
    city: 'Songsak',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb84',
    },
    state: 'Meghalaya',
    district: 'East Jaintia Hills',
    city: 'Khliehriat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb85',
    },
    state: 'Manipur',
    district: 'Tengnoupal',
    city: 'Moreh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb86',
    },
    state: 'Meghalaya',
    district: 'East Khasi Hills',
    city: 'Khadarshnong-Laitkroh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb87',
    },
    state: 'Meghalaya',
    district: 'East Khasi Hills',
    city: 'Mawkynrew',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb88',
    },
    state: 'Meghalaya',
    district: 'East Khasi Hills',
    city: 'Mawlai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb89',
    },
    state: 'Meghalaya',
    district: 'East Khasi Hills',
    city: 'Mawpat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb8a',
    },
    state: 'Meghalaya',
    district: 'East Jaintia Hills',
    city: 'Saipung',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb8b',
    },
    state: 'Meghalaya',
    district: 'East Khasi Hills',
    city: 'Mawphlang',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb8c',
    },
    state: 'Meghalaya',
    district: 'East Khasi Hills',
    city: 'Mawryngkneng',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb8d',
    },
    state: 'Meghalaya',
    district: 'East Khasi Hills',
    city: 'Mawsynram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb8e',
    },
    state: 'Meghalaya',
    district: 'East Khasi Hills',
    city: 'Mylliem',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb8f',
    },
    state: 'Meghalaya',
    district: 'East Khasi Hills',
    city: 'Pynursla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb90',
    },
    state: 'Meghalaya',
    district: 'East Khasi Hills',
    city: 'Sohiong',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb91',
    },
    state: 'Meghalaya',
    district: 'North Garo Hills',
    city: 'Bajengdoba',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb92',
    },
    state: 'Meghalaya',
    district: 'North Garo Hills',
    city: 'Kharkutta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb93',
    },
    state: 'Meghalaya',
    district: 'East Khasi Hills',
    city: 'Shella Bholaganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb94',
    },
    state: 'Meghalaya',
    district: 'Ri Bhoi',
    city: 'Bhoirymbong',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb95',
    },
    state: 'Meghalaya',
    district: 'North Garo Hills',
    city: 'Resubelpara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb96',
    },
    state: 'Meghalaya',
    district: 'Ri Bhoi',
    city: 'Jirang',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb97',
    },
    state: 'Meghalaya',
    district: 'Ri Bhoi',
    city: 'Umling',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb98',
    },
    state: 'Meghalaya',
    district: 'Ri Bhoi',
    city: 'Umsning',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb99',
    },
    state: 'Meghalaya',
    district: 'South Garo Hills',
    city: 'Chokpot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb9a',
    },
    state: 'Meghalaya',
    district: 'South Garo Hills',
    city: 'Gasuapara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb9b',
    },
    state: 'Meghalaya',
    district: 'South Garo Hills',
    city: 'Baghmara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb9c',
    },
    state: 'Meghalaya',
    district: 'South Garo Hills',
    city: 'Rongara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb9d',
    },
    state: 'Meghalaya',
    district: 'South West Garo Hills',
    city: 'Betasing',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb9e',
    },
    state: 'Meghalaya',
    district: 'South West Garo Hills',
    city: 'Damalgre',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eb9f',
    },
    state: 'Meghalaya',
    district: 'South West Garo Hills',
    city: 'Zikzak',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eba0',
    },
    state: 'Meghalaya',
    district: 'South West Khasi Hills',
    city: 'Mawkyrwat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eba1',
    },
    state: 'Meghalaya',
    district: 'South West Khasi Hills',
    city: 'Ranikor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eba2',
    },
    state: 'Meghalaya',
    district: 'West Garo Hills',
    city: 'Dadenggiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eba3',
    },
    state: 'Meghalaya',
    district: 'West Garo Hills',
    city: 'Dalu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eba4',
    },
    state: 'Meghalaya',
    district: 'West Garo Hills',
    city: 'Demdema',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eba5',
    },
    state: 'Meghalaya',
    district: 'West Garo Hills',
    city: 'Gambegre',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eba6',
    },
    state: 'Meghalaya',
    district: 'West Garo Hills',
    city: 'Salsella',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eba7',
    },
    state: 'Meghalaya',
    district: 'West Garo Hills',
    city: 'Tikrikilla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eba8',
    },
    state: 'Meghalaya',
    district: 'West Jaintia Hills',
    city: 'Amlarem',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eba9',
    },
    state: 'Meghalaya',
    district: 'West Garo Hills',
    city: 'Rongram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebaa',
    },
    state: 'Meghalaya',
    district: 'West Jaintia Hills',
    city: 'Laskein',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebab',
    },
    state: 'Meghalaya',
    district: 'West Jaintia Hills',
    city: 'Thadlaskein',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebac',
    },
    state: 'Meghalaya',
    district: 'West Khasi Hills',
    city: 'Mawshynrut',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebad',
    },
    state: 'Meghalaya',
    district: 'West Khasi Hills',
    city: 'Mawthadraishan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebae',
    },
    state: 'Meghalaya',
    district: 'West Khasi Hills',
    city: 'Nongstoin',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebaf',
    },
    state: 'Meghalaya',
    district: 'West Khasi Hills',
    city: 'Mairang',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebb0',
    },
    state: 'Mizoram',
    district: 'Aizawl',
    city: 'Aibawk',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebb1',
    },
    state: 'Mizoram',
    district: 'Aizawl',
    city: 'Thingsulthliah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebb2',
    },
    state: 'Mizoram',
    district: 'Aizawl',
    city: 'Darlawn',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebb3',
    },
    state: 'Mizoram',
    district: 'Aizawl',
    city: 'Tlangnuam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebb4',
    },
    state: 'Mizoram',
    district: 'Champhai',
    city: 'Champhai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebb5',
    },
    state: 'Mizoram',
    district: 'Champhai',
    city: 'Khawbung',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebb6',
    },
    state: 'Mizoram',
    district: 'Hnahthial',
    city: 'Hnahthial',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebb7',
    },
    state: 'Mizoram',
    district: 'Khawzawl',
    city: 'Khawzawl',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebb8',
    },
    state: 'Mizoram',
    district: 'Kolasib',
    city: 'Thingdawl',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebb9',
    },
    state: 'Mizoram',
    district: 'Kolasib',
    city: 'Bilkhawthlir',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebba',
    },
    state: 'Mizoram',
    district: 'Lawngtlai',
    city: 'Bungtlang South',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebbb',
    },
    state: 'Mizoram',
    district: 'Lawngtlai',
    city: 'Chawngte',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebbc',
    },
    state: 'Mizoram',
    district: 'Lawngtlai',
    city: 'Sangau',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebbd',
    },
    state: 'Mizoram',
    district: 'Lunglei',
    city: 'Bunghmun',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebbe',
    },
    state: 'Mizoram',
    district: 'Lunglei',
    city: 'Lunglei',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebbf',
    },
    state: 'Mizoram',
    district: 'Lawngtlai',
    city: 'Lawngtlai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebc0',
    },
    state: 'Mizoram',
    district: 'Mamit',
    city: 'Reiek',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebc1',
    },
    state: 'Mizoram',
    district: 'Mamit',
    city: 'West Phaileng',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebc2',
    },
    state: 'Mizoram',
    district: 'Mamit',
    city: 'Zawlnuam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebc3',
    },
    state: 'Mizoram',
    district: 'Saiha',
    city: 'Saiha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebc4',
    },
    state: 'Mizoram',
    district: 'Saiha',
    city: 'Tuipang',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebc5',
    },
    state: 'Mizoram',
    district: 'Lunglei',
    city: 'Lungsen',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebc6',
    },
    state: 'Mizoram',
    district: 'Saitual',
    city: 'Ngopa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebc7',
    },
    state: 'Mizoram',
    district: 'Saitual',
    city: 'Phullen',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebc8',
    },
    state: 'Mizoram',
    district: 'Serchhip',
    city: 'East Lungdar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebc9',
    },
    state: 'Mizoram',
    district: 'Serchhip',
    city: 'Serchhip',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebca',
    },
    state: 'Nagaland',
    district: 'Dimapur',
    city: 'Chumukedima',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebcb',
    },
    state: 'Nagaland',
    district: 'Dimapur',
    city: 'Dhansiripar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebcc',
    },
    state: 'Nagaland',
    district: 'Dimapur',
    city: 'Kuhuboto',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebcd',
    },
    state: 'Nagaland',
    district: 'Dimapur',
    city: 'Medziphema',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebce',
    },
    state: 'Nagaland',
    district: 'Dimapur',
    city: 'Niuland',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebcf',
    },
    state: 'Nagaland',
    district: 'Kiphire',
    city: 'Khonsa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebd0',
    },
    state: 'Nagaland',
    district: 'Kiphire',
    city: 'Kiphire',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebd1',
    },
    state: 'Nagaland',
    district: 'Kiphire',
    city: 'Longmatra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebd2',
    },
    state: 'Nagaland',
    district: 'Kiphire',
    city: 'Pungro',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebd3',
    },
    state: 'Nagaland',
    district: 'Dimapur',
    city: 'Aghunaqa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebd4',
    },
    state: 'Nagaland',
    district: 'Kiphire',
    city: 'Sitimi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebd5',
    },
    state: 'Nagaland',
    district: 'Kohima',
    city: 'Botsa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebd7',
    },
    state: 'Nagaland',
    district: 'Kohima',
    city: 'Chiephobozou',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebd8',
    },
    state: 'Nagaland',
    district: 'Kohima',
    city: 'Jakhama',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebd9',
    },
    state: 'Nagaland',
    district: 'Kohima',
    city: 'Kohima',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebda',
    },
    state: 'Nagaland',
    district: 'Kohima',
    city: 'Sechu Zubza',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebdb',
    },
    state: 'Nagaland',
    district: 'Kohima',
    city: 'Tseminyu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebdc',
    },
    state: 'Nagaland',
    district: 'Longleng',
    city: 'Longleng',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebdd',
    },
    state: 'Nagaland',
    district: 'Longleng',
    city: 'Sakshi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebde',
    },
    state: 'Nagaland',
    district: 'Longleng',
    city: 'Tamlu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebdf',
    },
    state: 'Nagaland',
    district: 'Mokokchung',
    city: 'Changtongya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebe0',
    },
    state: 'Nagaland',
    district: 'Mokokchung',
    city: 'Chuchuyimlang',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebe1',
    },
    state: 'Nagaland',
    district: 'Mokokchung',
    city: 'Kubolong',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebe2',
    },
    state: 'Nagaland',
    district: 'Mokokchung',
    city: 'Longchem',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebe3',
    },
    state: 'Nagaland',
    district: 'Mokokchung',
    city: 'Mangkolemba',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebe4',
    },
    state: 'Nagaland',
    district: 'Mokokchung',
    city: 'Ongpangkong(North)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebe5',
    },
    state: 'Nagaland',
    district: 'Mokokchung',
    city: 'Ongpangkong(South)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebe6',
    },
    state: 'Nagaland',
    district: 'Mokokchung',
    city: 'Tsurangkong',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebe7',
    },
    state: 'Nagaland',
    district: 'Mokokchung',
    city: 'Tuli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebe8',
    },
    state: 'Nagaland',
    district: 'Mon',
    city: 'Aboi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebe9',
    },
    state: 'Nagaland',
    district: 'Kohima',
    city: 'Chunlikha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebea',
    },
    state: 'Nagaland',
    district: 'Mon',
    city: 'Chen',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebeb',
    },
    state: 'Nagaland',
    district: 'Mon',
    city: 'Mon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebec',
    },
    state: 'Nagaland',
    district: 'Mon',
    city: 'Angjangyang',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebed',
    },
    state: 'Nagaland',
    district: 'Mon',
    city: 'Tizit',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebee',
    },
    state: 'Nagaland',
    district: 'Mon',
    city: 'Tobu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebef',
    },
    state: 'Nagaland',
    district: 'Mon',
    city: 'Wakching',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebf0',
    },
    state: 'Nagaland',
    district: 'Peren',
    city: 'Athibung',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebf1',
    },
    state: 'Nagaland',
    district: 'Peren',
    city: 'Jalukie',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebf2',
    },
    state: 'Nagaland',
    district: 'Peren',
    city: 'Peren',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebf3',
    },
    state: 'Nagaland',
    district: 'Peren',
    city: 'Tenning',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebf4',
    },
    state: 'Nagaland',
    district: 'Mon',
    city: 'Phomching',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebf5',
    },
    state: 'Nagaland',
    district: 'Phek',
    city: 'Chetheba',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebf6',
    },
    state: 'Nagaland',
    district: 'Phek',
    city: 'Kikruma',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebf7',
    },
    state: 'Nagaland',
    district: 'Phek',
    city: 'Meluri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebf8',
    },
    state: 'Nagaland',
    district: 'Phek',
    city: 'Chizami',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebf9',
    },
    state: 'Nagaland',
    district: 'Phek',
    city: 'Pfutsero',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebfa',
    },
    state: 'Nagaland',
    district: 'Phek',
    city: 'Phek',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebfb',
    },
    state: 'Nagaland',
    district: 'Phek',
    city: 'Weziho',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebfc',
    },
    state: 'Nagaland',
    district: 'Tuensang',
    city: 'Chare',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebfd',
    },
    state: 'Nagaland',
    district: 'Tuensang',
    city: 'Chessore',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebfe',
    },
    state: 'Nagaland',
    district: 'Tuensang',
    city: 'Longkhim',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ebff',
    },
    state: 'Nagaland',
    district: 'Tuensang',
    city: 'Noklak',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec00',
    },
    state: 'Nagaland',
    district: 'Tuensang',
    city: 'Noksen',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec01',
    },
    state: 'Nagaland',
    district: 'Phek',
    city: 'Sekruzu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec02',
    },
    state: 'Nagaland',
    district: 'Tuensang',
    city: 'Panso',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec03',
    },
    state: 'Nagaland',
    district: 'Tuensang',
    city: 'Sangsangyu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec04',
    },
    state: 'Nagaland',
    district: 'Tuensang',
    city: 'Thonoknyu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec05',
    },
    state: 'Nagaland',
    district: 'Wokha',
    city: 'Bhandari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec06',
    },
    state: 'Nagaland',
    district: 'Wokha',
    city: 'Changpang',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec07',
    },
    state: 'Nagaland',
    district: 'Wokha',
    city: 'Chukitong',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec08',
    },
    state: 'Nagaland',
    district: 'Wokha',
    city: 'Ralan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec09',
    },
    state: 'Nagaland',
    district: 'Wokha',
    city: 'Sanis',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec0a',
    },
    state: 'Nagaland',
    district: 'Wokha',
    city: 'Wokha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec0b',
    },
    state: 'Nagaland',
    district: 'Wokha',
    city: 'Wozhuro-Ralan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec0c',
    },
    state: 'Nagaland',
    district: 'Zunheboto',
    city: 'Akuhaito',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec0d',
    },
    state: 'Nagaland',
    district: 'Tuensang',
    city: 'Shamator',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec0e',
    },
    state: 'Nagaland',
    district: 'Zunheboto',
    city: 'Akuluto',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec0f',
    },
    state: 'Nagaland',
    district: 'Zunheboto',
    city: 'Ghathashi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec10',
    },
    state: 'Nagaland',
    district: 'Zunheboto',
    city: 'Satakha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec11',
    },
    state: 'Nagaland',
    district: 'Zunheboto',
    city: 'Suruhoto',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec12',
    },
    state: 'Nagaland',
    district: 'Zunheboto',
    city: 'Tokiye',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec13',
    },
    state: 'Nagaland',
    district: 'Zunheboto',
    city: 'Zunheboto',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec14',
    },
    state: 'Nagaland',
    district: 'Zunheboto',
    city: 'Satoi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec15',
    },
    state: 'Odisha',
    district: 'Anugul',
    city: 'Anugul',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec16',
    },
    state: 'Odisha',
    district: 'Anugul',
    city: 'Athmallik',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec17',
    },
    state: 'Odisha',
    district: 'Anugul',
    city: 'Banarpal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec18',
    },
    state: 'Odisha',
    district: 'Anugul',
    city: 'Kaniha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec19',
    },
    state: 'Odisha',
    district: 'Anugul',
    city: 'Chhendipada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec1a',
    },
    state: 'Odisha',
    district: 'Anugul',
    city: 'Kishorenagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec1b',
    },
    state: 'Odisha',
    district: 'Anugul',
    city: 'Palalahada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec1c',
    },
    state: 'Odisha',
    district: 'Balangir',
    city: 'Agalpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec1d',
    },
    state: 'Odisha',
    district: 'Anugul',
    city: 'Talacher',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec1e',
    },
    state: 'Odisha',
    district: 'Balangir',
    city: 'Balangir',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec1f',
    },
    state: 'Odisha',
    district: 'Balangir',
    city: 'Bangomunda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec20',
    },
    state: 'Odisha',
    district: 'Balangir',
    city: 'Deogaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec21',
    },
    state: 'Odisha',
    district: 'Balangir',
    city: 'Gudvella',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec22',
    },
    state: 'Odisha',
    district: 'Balangir',
    city: 'Khaprakhol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec23',
    },
    state: 'Odisha',
    district: 'Balangir',
    city: 'Loisinga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec24',
    },
    state: 'Odisha',
    district: 'Balangir',
    city: 'Belpara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec25',
    },
    state: 'Odisha',
    district: 'Balangir',
    city: 'Muribahal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec26',
    },
    state: 'Odisha',
    district: 'Balangir',
    city: 'Patnagarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec27',
    },
    state: 'Odisha',
    district: 'Balangir',
    city: 'Puintala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec28',
    },
    state: 'Odisha',
    district: 'Balangir',
    city: 'Titlagarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec29',
    },
    state: 'Odisha',
    district: 'Balangir',
    city: 'Saintala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec2a',
    },
    state: 'Odisha',
    district: 'Baleshwar',
    city: 'Bahanaga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec2b',
    },
    state: 'Odisha',
    district: 'Balangir',
    city: 'Turekela',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec2c',
    },
    state: 'Odisha',
    district: 'Baleshwar',
    city: 'Baleshwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec2d',
    },
    state: 'Odisha',
    district: 'Baleshwar',
    city: 'Baliapal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec2e',
    },
    state: 'Odisha',
    district: 'Baleshwar',
    city: 'Basta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec2f',
    },
    state: 'Odisha',
    district: 'Baleshwar',
    city: 'Bhograi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec30',
    },
    state: 'Odisha',
    district: 'Baleshwar',
    city: 'Khaira',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec31',
    },
    state: 'Odisha',
    district: 'Baleshwar',
    city: 'Nilgiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec32',
    },
    state: 'Odisha',
    district: 'Baleshwar',
    city: 'Jaleswar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec33',
    },
    state: 'Odisha',
    district: 'Baleshwar',
    city: 'Oupada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec34',
    },
    state: 'Odisha',
    district: 'Baleshwar',
    city: 'Simulia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec35',
    },
    state: 'Odisha',
    district: 'Baleshwar',
    city: 'Remuna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec36',
    },
    state: 'Odisha',
    district: 'Bargarh',
    city: 'Ambabhona',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec37',
    },
    state: 'Odisha',
    district: 'Bargarh',
    city: 'Attabira',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec38',
    },
    state: 'Odisha',
    district: 'Baleshwar',
    city: 'Soro',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec39',
    },
    state: 'Odisha',
    district: 'Bargarh',
    city: 'Bargarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec3a',
    },
    state: 'Odisha',
    district: 'Bargarh',
    city: 'Barpali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec3b',
    },
    state: 'Odisha',
    district: 'Bargarh',
    city: 'Bheden',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec3c',
    },
    state: 'Odisha',
    district: 'Bargarh',
    city: 'Bijepur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec3d',
    },
    state: 'Odisha',
    district: 'Bargarh',
    city: 'Gaisilet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec3e',
    },
    state: 'Odisha',
    district: 'Bargarh',
    city: 'Jharbandh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec3f',
    },
    state: 'Odisha',
    district: 'Bargarh',
    city: 'Padampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec40',
    },
    state: 'Odisha',
    district: 'Bargarh',
    city: 'Paikmal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec41',
    },
    state: 'Odisha',
    district: 'Bargarh',
    city: 'Sohella',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec42',
    },
    state: 'Odisha',
    district: 'Bhadrak',
    city: 'Basudevpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec43',
    },
    state: 'Odisha',
    district: 'Bhadrak',
    city: 'Bhadrak',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec44',
    },
    state: 'Odisha',
    district: 'Bhadrak',
    city: 'Bhandaripokhari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec45',
    },
    state: 'Odisha',
    district: 'Bhadrak',
    city: 'Bonth',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec46',
    },
    state: 'Odisha',
    district: 'Bhadrak',
    city: 'Chandabali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec47',
    },
    state: 'Odisha',
    district: 'Bhadrak',
    city: 'Dhamanagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec48',
    },
    state: 'Odisha',
    district: 'Bhadrak',
    city: 'Tihidi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec49',
    },
    state: 'Odisha',
    district: 'Baudh',
    city: 'Boudh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec4a',
    },
    state: 'Odisha',
    district: 'Bargarh',
    city: 'Bhatli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec4b',
    },
    state: 'Odisha',
    district: 'Baudh',
    city: 'Harabhanga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec4c',
    },
    state: 'Odisha',
    district: 'Cuttack',
    city: 'Athagad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec4d',
    },
    state: 'Odisha',
    district: 'Cuttack',
    city: 'Badamba',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec4e',
    },
    state: 'Odisha',
    district: 'Cuttack',
    city: 'Banki',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec4f',
    },
    state: 'Odisha',
    district: 'Cuttack',
    city: 'Banki- Dampara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec50',
    },
    state: 'Odisha',
    district: 'Baudh',
    city: 'Kantamal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec51',
    },
    state: 'Odisha',
    district: 'Cuttack',
    city: 'Baranga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec52',
    },
    state: 'Odisha',
    district: 'Cuttack',
    city: 'Kantapada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec53',
    },
    state: 'Odisha',
    district: 'Cuttack',
    city: 'Cuttacksadar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec54',
    },
    state: 'Odisha',
    district: 'Cuttack',
    city: 'Mahanga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec55',
    },
    state: 'Odisha',
    district: 'Cuttack',
    city: 'Niali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec56',
    },
    state: 'Odisha',
    district: 'Cuttack',
    city: 'Narasinghpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec57',
    },
    state: 'Odisha',
    district: 'Cuttack',
    city: 'Nischinta Koili',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec58',
    },
    state: 'Odisha',
    district: 'Cuttack',
    city: 'Salepur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec59',
    },
    state: 'Odisha',
    district: 'Cuttack',
    city: 'Tangi Choudwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec5a',
    },
    state: 'Odisha',
    district: 'Debagarh',
    city: 'Barkote',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec5b',
    },
    state: 'Odisha',
    district: 'Cuttack',
    city: 'Tigiria',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec5c',
    },
    state: 'Odisha',
    district: 'Debagarh',
    city: 'Reamal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec5d',
    },
    state: 'Odisha',
    district: 'Debagarh',
    city: 'Tileibani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec5e',
    },
    state: 'Odisha',
    district: 'Dhenkanal',
    city: 'Bhuban',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec5f',
    },
    state: 'Odisha',
    district: 'Dhenkanal',
    city: 'Dhenkanal Sadar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec60',
    },
    state: 'Odisha',
    district: 'Dhenkanal',
    city: 'Gondia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec61',
    },
    state: 'Odisha',
    district: 'Dhenkanal',
    city: 'Hindol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec62',
    },
    state: 'Odisha',
    district: 'Dhenkanal',
    city: 'Kamakhyanagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec63',
    },
    state: 'Odisha',
    district: 'Dhenkanal',
    city: 'Kankada Had',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec64',
    },
    state: 'Odisha',
    district: 'Dhenkanal',
    city: 'Parjang',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec65',
    },
    state: 'Odisha',
    district: 'Gajapati',
    city: 'Gosani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec66',
    },
    state: 'Odisha',
    district: 'Gajapati',
    city: 'Gumma',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec67',
    },
    state: 'Odisha',
    district: 'Gajapati',
    city: 'Kasinagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec68',
    },
    state: 'Odisha',
    district: 'Gajapati',
    city: 'Mohana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec69',
    },
    state: 'Odisha',
    district: 'Gajapati',
    city: 'Nuagada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec6a',
    },
    state: 'Odisha',
    district: 'Gajapati',
    city: 'Rayagada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec6b',
    },
    state: 'Odisha',
    district: 'Gajapati',
    city: 'R.Udayagiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec6c',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Aska',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec6d',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Beguniapada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec6e',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Bellaguntha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec6f',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Bhanjanagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec70',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Buguda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec71',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Chatrapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec72',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Chikiti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec73',
    },
    state: 'Odisha',
    district: 'Dhenkanal',
    city: 'Odapada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec74',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Dharakote',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec75',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Ganjam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec76',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Hinjilicut',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec77',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Jagannathprasad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec78',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Kabisuryanagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec79',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Khallikote',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec7a',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Digapahandi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec7b',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Kukudakhandi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec7c',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Patrapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec7d',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Polosara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec7e',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Purushottampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec7f',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Rangeilunda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec80',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Sanakhemundi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec81',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Sheragada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec82',
    },
    state: 'Odisha',
    district: 'Ganjam',
    city: 'Surada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec83',
    },
    state: 'Odisha',
    district: 'Jagatsinghpur',
    city: 'Balikuda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec84',
    },
    state: 'Odisha',
    district: 'Jagatsinghpur',
    city: 'Biridi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec85',
    },
    state: 'Odisha',
    district: 'Jagatsinghpur',
    city: 'Erasama',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec86',
    },
    state: 'Odisha',
    district: 'Jagatsinghpur',
    city: 'Jagatsinghpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec87',
    },
    state: 'Odisha',
    district: 'Jagatsinghpur',
    city: 'Kujang',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec88',
    },
    state: 'Odisha',
    district: 'Jagatsinghpur',
    city: 'Naugaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec89',
    },
    state: 'Odisha',
    district: 'Jagatsinghpur',
    city: 'Raghunathpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec8a',
    },
    state: 'Odisha',
    district: 'Jagatsinghpur',
    city: 'Tirtol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec8b',
    },
    state: 'Odisha',
    district: 'Jajapur',
    city: 'Badchana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec8c',
    },
    state: 'Odisha',
    district: 'Jajapur',
    city: 'Bari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec8d',
    },
    state: 'Odisha',
    district: 'Jajapur',
    city: 'Binjharpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec8e',
    },
    state: 'Odisha',
    district: 'Jajapur',
    city: 'Dahrmasala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec8f',
    },
    state: 'Odisha',
    district: 'Jajapur',
    city: 'Danagadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec90',
    },
    state: 'Odisha',
    district: 'Jajapur',
    city: 'Dasarathapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec91',
    },
    state: 'Odisha',
    district: 'Jajapur',
    city: 'Jajpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec92',
    },
    state: 'Odisha',
    district: 'Jajapur',
    city: 'Korei',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec93',
    },
    state: 'Odisha',
    district: 'Jajapur',
    city: 'Rasulpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec94',
    },
    state: 'Odisha',
    district: 'Jajapur',
    city: 'Sukinda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec95',
    },
    state: 'Odisha',
    district: 'Jharsuguda',
    city: 'Jharsuguda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec96',
    },
    state: 'Odisha',
    district: 'Jharsuguda',
    city: 'Kirmira',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec97',
    },
    state: 'Odisha',
    district: 'Jharsuguda',
    city: 'Kolabira',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec98',
    },
    state: 'Odisha',
    district: 'Jharsuguda',
    city: 'Laikera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec99',
    },
    state: 'Odisha',
    district: 'Jharsuguda',
    city: 'Lakhanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec9a',
    },
    state: 'Odisha',
    district: 'Kalahandi',
    city: 'Bhawanipatna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec9b',
    },
    state: 'Odisha',
    district: 'Kalahandi',
    city: 'Dharamagarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec9c',
    },
    state: 'Odisha',
    district: 'Kalahandi',
    city: 'Golamunda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec9d',
    },
    state: 'Odisha',
    district: 'Kalahandi',
    city: 'Jayapatna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec9e',
    },
    state: 'Odisha',
    district: 'Kalahandi',
    city: 'Junagarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ec9f',
    },
    state: 'Odisha',
    district: 'Kalahandi',
    city: 'Kalampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eca0',
    },
    state: 'Odisha',
    district: 'Kalahandi',
    city: 'Karlamunda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eca1',
    },
    state: 'Odisha',
    district: 'Kalahandi',
    city: 'Kesinga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eca2',
    },
    state: 'Odisha',
    district: 'Kalahandi',
    city: 'Kokasara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eca3',
    },
    state: 'Odisha',
    district: 'Kalahandi',
    city: 'Lanjigarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eca4',
    },
    state: 'Odisha',
    district: 'Kalahandi',
    city: 'Madanpur Rampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eca5',
    },
    state: 'Odisha',
    district: 'Kalahandi',
    city: 'Narala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eca6',
    },
    state: 'Odisha',
    district: 'Kalahandi',
    city: 'Thuamul Ram Pur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eca7',
    },
    state: 'Odisha',
    district: 'Kandhamal',
    city: 'Baliguda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eca8',
    },
    state: 'Odisha',
    district: 'Kandhamal',
    city: 'Chakapad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eca9',
    },
    state: 'Odisha',
    district: 'Kandhamal',
    city: 'Daringibadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecaa',
    },
    state: 'Odisha',
    district: 'Kandhamal',
    city: 'G.Udayagiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecab',
    },
    state: 'Odisha',
    district: 'Kandhamal',
    city: 'Khajuripada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecac',
    },
    state: 'Odisha',
    district: 'Kandhamal',
    city: 'Kotagarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecad',
    },
    state: 'Odisha',
    district: 'Kandhamal',
    city: 'K.Nuagan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecae',
    },
    state: 'Odisha',
    district: 'Kandhamal',
    city: 'Phiringia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecaf',
    },
    state: 'Odisha',
    district: 'Kandhamal',
    city: 'Phulbani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecb0',
    },
    state: 'Odisha',
    district: 'Kandhamal',
    city: 'Raikia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecb1',
    },
    state: 'Odisha',
    district: 'Kandhamal',
    city: 'Tikabali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecb2',
    },
    state: 'Odisha',
    district: 'Kendrapara',
    city: 'Aul',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecb3',
    },
    state: 'Odisha',
    district: 'Kandhamal',
    city: 'Tumudibandh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecb4',
    },
    state: 'Odisha',
    district: 'Kendrapara',
    city: 'Derabish',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecb5',
    },
    state: 'Odisha',
    district: 'Kendrapara',
    city: 'Garadapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecb6',
    },
    state: 'Odisha',
    district: 'Kendrapara',
    city: 'Kendrapada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecb7',
    },
    state: 'Odisha',
    district: 'Kendrapara',
    city: 'Mahakalapada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecb8',
    },
    state: 'Odisha',
    district: 'Kendrapara',
    city: 'Marsaghai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecb9',
    },
    state: 'Odisha',
    district: 'Kendrapara',
    city: 'Pattamundai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecba',
    },
    state: 'Odisha',
    district: 'Kendrapara',
    city: 'Rajkanika',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecbb',
    },
    state: 'Odisha',
    district: 'Kendrapara',
    city: 'Rajnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecbc',
    },
    state: 'Odisha',
    district: 'Kendujhar',
    city: 'Bansapal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecbd',
    },
    state: 'Odisha',
    district: 'Kendujhar',
    city: 'Champua',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecbe',
    },
    state: 'Odisha',
    district: 'Kendujhar',
    city: 'Ghasipura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecbf',
    },
    state: 'Odisha',
    district: 'Kendujhar',
    city: 'Ghatgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecc0',
    },
    state: 'Odisha',
    district: 'Kendujhar',
    city: 'Harichadanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecc1',
    },
    state: 'Odisha',
    district: 'Kendujhar',
    city: 'Hatadihi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecc2',
    },
    state: 'Odisha',
    district: 'Kendujhar',
    city: 'Jhumpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecc3',
    },
    state: 'Odisha',
    district: 'Kendujhar',
    city: 'Joda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecc4',
    },
    state: 'Odisha',
    district: 'Kendujhar',
    city: 'Kendujhar Sadar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecc5',
    },
    state: 'Odisha',
    district: 'Kendujhar',
    city: 'Patana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecc6',
    },
    state: 'Odisha',
    district: 'Kendujhar',
    city: 'Saharapada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecc7',
    },
    state: 'Odisha',
    district: 'Kendujhar',
    city: 'Telkoi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecc8',
    },
    state: 'Odisha',
    district: 'Khordha',
    city: 'Balianta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecc9',
    },
    state: 'Odisha',
    district: 'Khordha',
    city: 'Balipatna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecca',
    },
    state: 'Odisha',
    district: 'Khordha',
    city: 'Banapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eccb',
    },
    state: 'Odisha',
    district: 'Khordha',
    city: 'Begunia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eccc',
    },
    state: 'Odisha',
    district: 'Khordha',
    city: 'Bhubaneswar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eccd',
    },
    state: 'Odisha',
    district: 'Kendujhar',
    city: 'Anandapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecce',
    },
    state: 'Odisha',
    district: 'Khordha',
    city: 'Bolagarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eccf',
    },
    state: 'Odisha',
    district: 'Khordha',
    city: 'Chilika',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecd0',
    },
    state: 'Odisha',
    district: 'Khordha',
    city: 'Jatni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecd1',
    },
    state: 'Odisha',
    district: 'Khordha',
    city: 'Khordha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecd2',
    },
    state: 'Odisha',
    district: 'Khordha',
    city: 'Tangi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecd3',
    },
    state: 'Odisha',
    district: 'Koraput',
    city: 'Bandhugaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecd4',
    },
    state: 'Odisha',
    district: 'Koraput',
    city: 'Boipariguda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecd5',
    },
    state: 'Odisha',
    district: 'Koraput',
    city: 'Borigumma',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecd6',
    },
    state: 'Odisha',
    district: 'Koraput',
    city: 'Dasamantapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecd7',
    },
    state: 'Odisha',
    district: 'Koraput',
    city: 'Koraput',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecd8',
    },
    state: 'Odisha',
    district: 'Koraput',
    city: 'Kotpad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecd9',
    },
    state: 'Odisha',
    district: 'Koraput',
    city: 'Kundura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecda',
    },
    state: 'Odisha',
    district: 'Koraput',
    city: 'Jeypore',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecdb',
    },
    state: 'Odisha',
    district: 'Koraput',
    city: 'Laxmipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecdc',
    },
    state: 'Odisha',
    district: 'Koraput',
    city: 'Nandapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecdd',
    },
    state: 'Odisha',
    district: 'Koraput',
    city: 'Lamtaput',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecde',
    },
    state: 'Odisha',
    district: 'Koraput',
    city: 'Narayan Patana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecdf',
    },
    state: 'Odisha',
    district: 'Koraput',
    city: 'Pottangi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ece0',
    },
    state: 'Odisha',
    district: 'Malkangiri',
    city: 'Chitrakonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ece1',
    },
    state: 'Odisha',
    district: 'Malkangiri',
    city: 'Kalimela',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ece2',
    },
    state: 'Odisha',
    district: 'Malkangiri',
    city: 'Khairaput',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ece3',
    },
    state: 'Odisha',
    district: 'Malkangiri',
    city: 'Korukonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ece4',
    },
    state: 'Odisha',
    district: 'Malkangiri',
    city: 'Malkangiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ece5',
    },
    state: 'Odisha',
    district: 'Malkangiri',
    city: 'Mathili',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ece6',
    },
    state: 'Odisha',
    district: 'Malkangiri',
    city: 'Podia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ece7',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Badasahi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ece8',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Bahalda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ece9',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Bangriposi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecea',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Baripada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eceb',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Betnoti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecec',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Bijatala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eced',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Bisoi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecee',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Gopabandhunagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecef',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Jamda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecf0',
    },
    state: 'Odisha',
    district: 'Koraput',
    city: 'Semiliguda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecf1',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Kaptipada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecf2',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Karanjia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecf3',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Khunta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecf4',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Kuliana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecf5',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Kusumi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecf6',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Joshipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecf7',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Morada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecf8',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Rairangpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecf9',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Raruan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecfa',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Rasgovindpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecfb',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Samakhunta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecfc',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Saraskana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecfd',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Sukruli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecfe',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Suliapada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ecff',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Thakurmunda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed00',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Tiring',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed01',
    },
    state: 'Odisha',
    district: 'Mayurbhanj',
    city: 'Udala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed02',
    },
    state: 'Odisha',
    district: 'Nabarangapur',
    city: 'Chandahandi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed03',
    },
    state: 'Odisha',
    district: 'Nabarangapur',
    city: 'Dabugam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed04',
    },
    state: 'Odisha',
    district: 'Nabarangapur',
    city: 'Jharigam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed05',
    },
    state: 'Odisha',
    district: 'Nabarangapur',
    city: 'Nabarangpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed06',
    },
    state: 'Odisha',
    district: 'Nabarangapur',
    city: 'Kosagumuda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed07',
    },
    state: 'Odisha',
    district: 'Nabarangapur',
    city: 'Nandahandi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed08',
    },
    state: 'Odisha',
    district: 'Nabarangapur',
    city: 'Papadahandi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed09',
    },
    state: 'Odisha',
    district: 'Nabarangapur',
    city: 'Tentulikhunti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed0a',
    },
    state: 'Odisha',
    district: 'Nabarangapur',
    city: 'Raighar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed0b',
    },
    state: 'Odisha',
    district: 'Nabarangapur',
    city: 'Umerkote',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed0c',
    },
    state: 'Odisha',
    district: 'Nayagarh',
    city: 'Bhapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed0d',
    },
    state: 'Odisha',
    district: 'Nayagarh',
    city: 'Dasapalla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed0e',
    },
    state: 'Odisha',
    district: 'Nayagarh',
    city: 'Gania',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed0f',
    },
    state: 'Odisha',
    district: 'Nayagarh',
    city: 'Khandapara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed10',
    },
    state: 'Odisha',
    district: 'Nayagarh',
    city: 'Nayagarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed11',
    },
    state: 'Odisha',
    district: 'Nayagarh',
    city: 'Nuagaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed12',
    },
    state: 'Odisha',
    district: 'Nayagarh',
    city: 'Odagaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed13',
    },
    state: 'Odisha',
    district: 'Nuapada',
    city: 'Boden',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed14',
    },
    state: 'Odisha',
    district: 'Nuapada',
    city: 'Khariar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed15',
    },
    state: 'Odisha',
    district: 'Nuapada',
    city: 'Komna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed16',
    },
    state: 'Odisha',
    district: 'Nuapada',
    city: 'Nuapada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed17',
    },
    state: 'Odisha',
    district: 'Nuapada',
    city: 'Sinapali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed18',
    },
    state: 'Odisha',
    district: 'Puri',
    city: 'Astaranga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed19',
    },
    state: 'Odisha',
    district: 'Puri',
    city: 'Brahmagiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed1a',
    },
    state: 'Odisha',
    district: 'Puri',
    city: 'Delanga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed1b',
    },
    state: 'Odisha',
    district: 'Puri',
    city: 'Gop',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed1c',
    },
    state: 'Odisha',
    district: 'Puri',
    city: 'Kakat Pur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed1d',
    },
    state: 'Odisha',
    district: 'Puri',
    city: 'Kanas',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed1e',
    },
    state: 'Odisha',
    district: 'Puri',
    city: 'Krushnaprasad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed1f',
    },
    state: 'Odisha',
    district: 'Puri',
    city: 'Nimapada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed20',
    },
    state: 'Odisha',
    district: 'Puri',
    city: 'Pipili',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed21',
    },
    state: 'Odisha',
    district: 'Puri',
    city: 'Sadar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed22',
    },
    state: 'Odisha',
    district: 'Nayagarh',
    city: 'Ranapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed23',
    },
    state: 'Odisha',
    district: 'Puri',
    city: 'Satyabadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed24',
    },
    state: 'Odisha',
    district: 'Rayagada',
    city: 'Chandrapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed25',
    },
    state: 'Odisha',
    district: 'Rayagada',
    city: 'Gudari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed26',
    },
    state: 'Odisha',
    district: 'Rayagada',
    city: 'Gunupur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed27',
    },
    state: 'Odisha',
    district: 'Rayagada',
    city: 'Kalyansingpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed28',
    },
    state: 'Odisha',
    district: 'Rayagada',
    city: 'Kasipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed29',
    },
    state: 'Odisha',
    district: 'Rayagada',
    city: 'Kolnara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed2a',
    },
    state: 'Odisha',
    district: 'Rayagada',
    city: 'Muniguda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed2b',
    },
    state: 'Odisha',
    district: 'Rayagada',
    city: 'Padmapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed2c',
    },
    state: 'Odisha',
    district: 'Rayagada',
    city: 'Bissamcuttack',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed2d',
    },
    state: 'Odisha',
    district: 'Rayagada',
    city: 'Ramanaguda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed2e',
    },
    state: 'Odisha',
    district: 'Rayagada',
    city: 'Rayagada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed2f',
    },
    state: 'Odisha',
    district: 'Sambalpur',
    city: 'Bamra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed30',
    },
    state: 'Odisha',
    district: 'Sambalpur',
    city: 'Dhankauda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed31',
    },
    state: 'Odisha',
    district: 'Sambalpur',
    city: 'Jujomura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed32',
    },
    state: 'Odisha',
    district: 'Sambalpur',
    city: 'Kuchinda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed33',
    },
    state: 'Odisha',
    district: 'Sambalpur',
    city: 'Maneswar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed34',
    },
    state: 'Odisha',
    district: 'Sambalpur',
    city: 'Jamankira',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed35',
    },
    state: 'Odisha',
    district: 'Sambalpur',
    city: 'Naktideul',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed36',
    },
    state: 'Odisha',
    district: 'Sambalpur',
    city: 'Rairakhol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed37',
    },
    state: 'Odisha',
    district: 'Sambalpur',
    city: 'Rengali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed38',
    },
    state: 'Odisha',
    district: 'Subarnapur',
    city: 'Binika',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed39',
    },
    state: 'Odisha',
    district: 'Subarnapur',
    city: 'Birmaharajpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed3a',
    },
    state: 'Odisha',
    district: 'Subarnapur',
    city: 'Dunguripali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed3b',
    },
    state: 'Odisha',
    district: 'Subarnapur',
    city: 'Tarbha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed3c',
    },
    state: 'Odisha',
    district: 'Subarnapur',
    city: 'Ullunda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed3d',
    },
    state: 'Odisha',
    district: 'Sundargarh',
    city: 'Balisankara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed3e',
    },
    state: 'Odisha',
    district: 'Sundargarh',
    city: 'Bargaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed3f',
    },
    state: 'Odisha',
    district: 'Sundargarh',
    city: 'Bisra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed40',
    },
    state: 'Odisha',
    district: 'Sundargarh',
    city: 'Bonaigarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed41',
    },
    state: 'Odisha',
    district: 'Sundargarh',
    city: 'Gurundia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed42',
    },
    state: 'Odisha',
    district: 'Sundargarh',
    city: 'Hemgir',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed43',
    },
    state: 'Odisha',
    district: 'Sundargarh',
    city: 'Koida',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed44',
    },
    state: 'Odisha',
    district: 'Sundargarh',
    city: 'Kuarmunda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed45',
    },
    state: 'Odisha',
    district: 'Sundargarh',
    city: 'Kutra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed46',
    },
    state: 'Odisha',
    district: 'Sundargarh',
    city: 'Lahunipara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed47',
    },
    state: 'Odisha',
    district: 'Sundargarh',
    city: 'Lathikata',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed48',
    },
    state: 'Odisha',
    district: 'Sundargarh',
    city: 'Lephripara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed49',
    },
    state: 'Odisha',
    district: 'Subarnapur',
    city: 'Sonepur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed4a',
    },
    state: 'Odisha',
    district: 'Sundargarh',
    city: 'Nuagaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed4b',
    },
    state: 'Odisha',
    district: 'Sundargarh',
    city: 'Rajgangpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed4c',
    },
    state: 'Odisha',
    district: 'Sundargarh',
    city: 'Sundargarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed4d',
    },
    state: 'Odisha',
    district: 'Sundargarh',
    city: 'Tangarpali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed4e',
    },
    state: 'Puducherry',
    district: 'Karaikal',
    city: 'Karaikal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed4f',
    },
    state: 'Puducherry',
    district: 'Mahe',
    city: 'Mahe',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed50',
    },
    state: 'Puducherry',
    district: 'Pondicherry',
    city: 'Ariankuppam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed51',
    },
    state: 'Odisha',
    district: 'Sundargarh',
    city: 'Subdega',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed52',
    },
    state: 'Puducherry',
    district: 'Pondicherry',
    city: 'Ozhukarai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed53',
    },
    state: 'Puducherry',
    district: 'Pondicherry',
    city: 'Villianur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed54',
    },
    state: 'Puducherry',
    district: 'Yanam',
    city: 'Yanam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed55',
    },
    state: 'Punjab',
    district: 'Amritsar',
    city: 'Ajnala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed56',
    },
    state: 'Punjab',
    district: 'Amritsar',
    city: 'Chogawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed57',
    },
    state: 'Punjab',
    district: 'Amritsar',
    city: 'Attari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed58',
    },
    state: 'Punjab',
    district: 'Amritsar',
    city: 'Harshe Chhina',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed59',
    },
    state: 'Punjab',
    district: 'Amritsar',
    city: 'Jandiala Guru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed5a',
    },
    state: 'Punjab',
    district: 'Amritsar',
    city: 'Rayya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed5b',
    },
    state: 'Punjab',
    district: 'Amritsar',
    city: 'Majitha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed5c',
    },
    state: 'Punjab',
    district: 'Amritsar',
    city: 'Tarsikka',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed5d',
    },
    state: 'Punjab',
    district: 'Amritsar',
    city: 'Verka',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed5e',
    },
    state: 'Punjab',
    district: 'Barnala',
    city: 'Barnala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed5f',
    },
    state: 'Punjab',
    district: 'Barnala',
    city: 'Mehal Kalan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed60',
    },
    state: 'Punjab',
    district: 'Barnala',
    city: 'Sehna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed61',
    },
    state: 'Punjab',
    district: 'Bathinda',
    city: 'Bathinda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed62',
    },
    state: 'Punjab',
    district: 'Bathinda',
    city: 'Bhagta Bhaika',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed63',
    },
    state: 'Punjab',
    district: 'Bathinda',
    city: 'Goniana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed64',
    },
    state: 'Punjab',
    district: 'Bathinda',
    city: 'Maur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed65',
    },
    state: 'Punjab',
    district: 'Bathinda',
    city: 'Nathana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed66',
    },
    state: 'Punjab',
    district: 'Bathinda',
    city: 'Phul',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed67',
    },
    state: 'Punjab',
    district: 'Bathinda',
    city: 'Rampura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed68',
    },
    state: 'Punjab',
    district: 'Bathinda',
    city: 'Sangat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed69',
    },
    state: 'Punjab',
    district: 'Bathinda',
    city: 'Talwandi Sabo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed6a',
    },
    state: 'Punjab',
    district: 'Faridkot',
    city: 'Faridkot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed6b',
    },
    state: 'Punjab',
    district: 'Faridkot',
    city: 'Jaitu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed6c',
    },
    state: 'Punjab',
    district: 'Faridkot',
    city: 'Kot Kapura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed6d',
    },
    state: 'Punjab',
    district: 'Fatehgarh Sahib',
    city: 'Amloh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed6e',
    },
    state: 'Punjab',
    district: 'Fatehgarh Sahib',
    city: 'Bassi Pathana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed6f',
    },
    state: 'Punjab',
    district: 'Fatehgarh Sahib',
    city: 'Khamano',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed70',
    },
    state: 'Punjab',
    district: 'Fatehgarh Sahib',
    city: 'Khera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed71',
    },
    state: 'Punjab',
    district: 'Fatehgarh Sahib',
    city: 'Sirhind',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed72',
    },
    state: 'Punjab',
    district: 'Fazilka',
    city: 'Abohar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed73',
    },
    state: 'Punjab',
    district: 'Fazilka',
    city: 'Arniwala Shiekh Subhan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed74',
    },
    state: 'Punjab',
    district: 'Fazilka',
    city: 'Fazilka',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed75',
    },
    state: 'Punjab',
    district: 'Fazilka',
    city: 'Jalalabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed76',
    },
    state: 'Punjab',
    district: 'Fazilka',
    city: 'Khuian Sarwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed77',
    },
    state: 'Punjab',
    district: 'Firozepur',
    city: 'Firozpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed78',
    },
    state: 'Punjab',
    district: 'Firozepur',
    city: 'Ghall Khurd',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed79',
    },
    state: 'Punjab',
    district: 'Firozepur',
    city: 'Guru Har Sahai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed7a',
    },
    state: 'Punjab',
    district: 'Firozepur',
    city: 'Makhu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed7b',
    },
    state: 'Punjab',
    district: 'Firozepur',
    city: 'Mamdot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed7c',
    },
    state: 'Punjab',
    district: 'Firozepur',
    city: 'Zira',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed7d',
    },
    state: 'Punjab',
    district: 'Gurdaspur',
    city: 'Dera Baba Nanak',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed7e',
    },
    state: 'Punjab',
    district: 'Gurdaspur',
    city: 'Batala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed7f',
    },
    state: 'Punjab',
    district: 'Gurdaspur',
    city: 'Dhariwal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed80',
    },
    state: 'Punjab',
    district: 'Gurdaspur',
    city: 'Dinanagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed81',
    },
    state: 'Punjab',
    district: 'Gurdaspur',
    city: 'Dorangla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed82',
    },
    state: 'Punjab',
    district: 'Gurdaspur',
    city: 'Fatehgarh Churian',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed83',
    },
    state: 'Punjab',
    district: 'Gurdaspur',
    city: 'Kahnuwan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed84',
    },
    state: 'Punjab',
    district: 'Gurdaspur',
    city: 'Gurdaspur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed85',
    },
    state: 'Punjab',
    district: 'Gurdaspur',
    city: 'Kalanaur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed86',
    },
    state: 'Punjab',
    district: 'Gurdaspur',
    city: 'Qadian',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed87',
    },
    state: 'Punjab',
    district: 'Hoshiarpur',
    city: 'Bhunga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed88',
    },
    state: 'Punjab',
    district: 'Gurdaspur',
    city: 'Sri Hargobindpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed89',
    },
    state: 'Punjab',
    district: 'Hoshiarpur',
    city: 'Dasuya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed8a',
    },
    state: 'Punjab',
    district: 'Hoshiarpur',
    city: 'Garhshankar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed8b',
    },
    state: 'Punjab',
    district: 'Hoshiarpur',
    city: 'Hajipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed8c',
    },
    state: 'Punjab',
    district: 'Hoshiarpur',
    city: 'Hoshiarpur-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed8d',
    },
    state: 'Punjab',
    district: 'Hoshiarpur',
    city: 'Mahilpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed8e',
    },
    state: 'Punjab',
    district: 'Hoshiarpur',
    city: 'Mukerian',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed8f',
    },
    state: 'Punjab',
    district: 'Hoshiarpur',
    city: 'Talwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed90',
    },
    state: 'Punjab',
    district: 'Hoshiarpur',
    city: 'Tanda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed91',
    },
    state: 'Punjab',
    district: 'Jalandhar',
    city: 'Adampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed92',
    },
    state: 'Punjab',
    district: 'Jalandhar',
    city: 'Bhogpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed93',
    },
    state: 'Punjab',
    district: 'Jalandhar',
    city: 'Jalandhar-East',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed94',
    },
    state: 'Punjab',
    district: 'Jalandhar',
    city: 'Jalandhar - West',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed95',
    },
    state: 'Punjab',
    district: 'Jalandhar',
    city: 'Lohian',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed96',
    },
    state: 'Punjab',
    district: 'Jalandhar',
    city: 'Mehatpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed97',
    },
    state: 'Punjab',
    district: 'Jalandhar',
    city: 'Nakodar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed98',
    },
    state: 'Punjab',
    district: 'Jalandhar',
    city: 'Nurmahal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed99',
    },
    state: 'Punjab',
    district: 'Jalandhar',
    city: 'Phillaur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed9a',
    },
    state: 'Punjab',
    district: 'Jalandhar',
    city: 'Rurka Kalan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed9b',
    },
    state: 'Punjab',
    district: 'Jalandhar',
    city: 'Shahkot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed9c',
    },
    state: 'Punjab',
    district: 'Hoshiarpur',
    city: 'Hoshiarpur-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed9d',
    },
    state: 'Punjab',
    district: 'Kapurthala',
    city: 'Kapurthala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed9e',
    },
    state: 'Punjab',
    district: 'Kapurthala',
    city: 'Dhilwan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ed9f',
    },
    state: 'Punjab',
    district: 'Kapurthala',
    city: 'Nadala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eda0',
    },
    state: 'Punjab',
    district: 'Kapurthala',
    city: 'Phagwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eda1',
    },
    state: 'Punjab',
    district: 'Kapurthala',
    city: 'Sultanpur Lodhi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eda2',
    },
    state: 'Punjab',
    district: 'Ludhiana',
    city: 'Doraha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eda3',
    },
    state: 'Punjab',
    district: 'Ludhiana',
    city: 'Jagraon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eda4',
    },
    state: 'Punjab',
    district: 'Ludhiana',
    city: 'Khanna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eda5',
    },
    state: 'Punjab',
    district: 'Ludhiana',
    city: 'Ludhiana-1',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eda6',
    },
    state: 'Punjab',
    district: 'Ludhiana',
    city: 'Ludhiana-2',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eda7',
    },
    state: 'Punjab',
    district: 'Ludhiana',
    city: 'Dehlon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eda8',
    },
    state: 'Punjab',
    district: 'Ludhiana',
    city: 'Machhiwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eda9',
    },
    state: 'Punjab',
    district: 'Ludhiana',
    city: 'Maloud',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edaa',
    },
    state: 'Punjab',
    district: 'Ludhiana',
    city: 'Pakhowal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edab',
    },
    state: 'Punjab',
    district: 'Ludhiana',
    city: 'Samrala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edac',
    },
    state: 'Punjab',
    district: 'Ludhiana',
    city: 'Sidhwan Bet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edad',
    },
    state: 'Punjab',
    district: 'Ludhiana',
    city: 'Raikot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edae',
    },
    state: 'Punjab',
    district: 'Ludhiana',
    city: 'Sudhar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edaf',
    },
    state: 'Punjab',
    district: 'Mansa',
    city: 'Bhikhi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edb0',
    },
    state: 'Punjab',
    district: 'Mansa',
    city: 'Budhlada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edb1',
    },
    state: 'Punjab',
    district: 'Mansa',
    city: 'Jhunir',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edb2',
    },
    state: 'Punjab',
    district: 'Mansa',
    city: 'Mansa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edb3',
    },
    state: 'Punjab',
    district: 'Mansa',
    city: 'Sardulgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edb4',
    },
    state: 'Punjab',
    district: 'Moga',
    city: 'Baghapurana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edb5',
    },
    state: 'Punjab',
    district: 'Moga',
    city: 'Moga-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edb6',
    },
    state: 'Punjab',
    district: 'Moga',
    city: 'Moga-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edb7',
    },
    state: 'Punjab',
    district: 'Moga',
    city: 'Nihal Singh Wala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edb8',
    },
    state: 'Punjab',
    district: 'Moga',
    city: 'Kot-Ise-Khan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edb9',
    },
    state: 'Punjab',
    district: 'Pathankot',
    city: 'Bamial',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edba',
    },
    state: 'Punjab',
    district: 'Pathankot',
    city: 'Dharkalan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edbb',
    },
    state: 'Punjab',
    district: 'Pathankot',
    city: 'Gharota',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edbc',
    },
    state: 'Punjab',
    district: 'Pathankot',
    city: 'Narot Jaimal Singh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edbd',
    },
    state: 'Punjab',
    district: 'Pathankot',
    city: 'Pathankot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edbe',
    },
    state: 'Punjab',
    district: 'Pathankot',
    city: 'Sujanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edbf',
    },
    state: 'Punjab',
    district: 'Patiala',
    city: 'Bhuner Heri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edc0',
    },
    state: 'Punjab',
    district: 'Patiala',
    city: 'Ghanaur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edc1',
    },
    state: 'Punjab',
    district: 'Patiala',
    city: 'Nabha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edc2',
    },
    state: 'Punjab',
    district: 'Patiala',
    city: 'Patiala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edc3',
    },
    state: 'Punjab',
    district: 'Patiala',
    city: 'Patiala Rural',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edc4',
    },
    state: 'Punjab',
    district: 'Patiala',
    city: 'Patran',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edc5',
    },
    state: 'Punjab',
    district: 'Patiala',
    city: 'Rajpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edc6',
    },
    state: 'Punjab',
    district: 'Patiala',
    city: 'Sanour',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edc7',
    },
    state: 'Punjab',
    district: 'Patiala',
    city: 'Shambu Kalan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edc8',
    },
    state: 'Punjab',
    district: 'Patiala',
    city: 'Samana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edc9',
    },
    state: 'Punjab',
    district: 'Rupnagar',
    city: 'Chamkaur Sahib',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edca',
    },
    state: 'Punjab',
    district: 'Rupnagar',
    city: 'Morinda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edcb',
    },
    state: 'Punjab',
    district: 'Rupnagar',
    city: 'Nurpur Bedi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edcc',
    },
    state: 'Punjab',
    district: 'Rupnagar',
    city: 'Rupnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edcd',
    },
    state: 'Punjab',
    district: 'Sangrur',
    city: 'Ahmedgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edce',
    },
    state: 'Punjab',
    district: 'Sangrur',
    city: 'Andana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edcf',
    },
    state: 'Punjab',
    district: 'Sangrur',
    city: 'Bhawani Garh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edd0',
    },
    state: 'Punjab',
    district: 'Sangrur',
    city: 'Dhuri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edd1',
    },
    state: 'Punjab',
    district: 'Sangrur',
    city: 'Dirba',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edd2',
    },
    state: 'Punjab',
    district: 'Sangrur',
    city: 'Lehragaga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edd3',
    },
    state: 'Punjab',
    district: 'Rupnagar',
    city: 'Anandpur Sahib',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edd4',
    },
    state: 'Punjab',
    district: 'Sangrur',
    city: 'Sangrur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edd5',
    },
    state: 'Punjab',
    district: 'Sangrur',
    city: 'Malerkotla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edd6',
    },
    state: 'Punjab',
    district: 'Sangrur',
    city: 'Sherpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edd7',
    },
    state: 'Punjab',
    district: 'Sangrur',
    city: 'Sunam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edd8',
    },
    state: 'Punjab',
    district: 'S.A.S Nagar',
    city: 'Kharar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edd9',
    },
    state: 'Punjab',
    district: 'S.A.S Nagar',
    city: 'Majri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edda',
    },
    state: 'Punjab',
    district: 'Shahid Bhagat Singh Nagar',
    city: 'Aur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eddb',
    },
    state: 'Punjab',
    district: 'Shahid Bhagat Singh Nagar',
    city: 'Balachaur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eddc',
    },
    state: 'Punjab',
    district: 'S.A.S Nagar',
    city: 'Dera Bassi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eddd',
    },
    state: 'Punjab',
    district: 'Shahid Bhagat Singh Nagar',
    city: 'Banga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edde',
    },
    state: 'Punjab',
    district: 'Shahid Bhagat Singh Nagar',
    city: 'Nawanshahr',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eddf',
    },
    state: 'Punjab',
    district: 'Shahid Bhagat Singh Nagar',
    city: 'Saroya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ede0',
    },
    state: 'Punjab',
    district: 'Sri Muktsar Sahib',
    city: 'Gidderbaha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ede1',
    },
    state: 'Punjab',
    district: 'Sri Muktsar Sahib',
    city: 'Malout',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ede2',
    },
    state: 'Punjab',
    district: 'Sri Muktsar Sahib',
    city: 'Muktsar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ede3',
    },
    state: 'Punjab',
    district: 'Tarn Taran',
    city: 'Bhikhi Wind-13',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ede4',
    },
    state: 'Punjab',
    district: 'Tarn Taran',
    city: 'Chohla Sahib-8',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ede5',
    },
    state: 'Punjab',
    district: 'Tarn Taran',
    city: 'Gandiwind-9',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ede6',
    },
    state: 'Punjab',
    district: 'Tarn Taran',
    city: 'Khadur-Sahib-10',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ede7',
    },
    state: 'Punjab',
    district: 'Tarn Taran',
    city: 'Naushehra Pannuan-11',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ede8',
    },
    state: 'Punjab',
    district: 'Tarn Taran',
    city: 'Patti-14',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ede9',
    },
    state: 'Punjab',
    district: 'Tarn Taran',
    city: 'Tarn Taran-12',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edea',
    },
    state: 'Punjab',
    district: 'Tarn Taran',
    city: 'Valtoha-15',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edeb',
    },
    state: 'Rajasthan',
    district: 'Ajmer',
    city: 'Ajmer Rural',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edec',
    },
    state: 'Rajasthan',
    district: 'Ajmer',
    city: 'Arain',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eded',
    },
    state: 'Punjab',
    district: 'Sri Muktsar Sahib',
    city: 'Lambi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edee',
    },
    state: 'Rajasthan',
    district: 'Ajmer',
    city: 'Jawaja',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edef',
    },
    state: 'Rajasthan',
    district: 'Ajmer',
    city: 'Kekri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edf0',
    },
    state: 'Rajasthan',
    district: 'Ajmer',
    city: 'Kishangarh Silora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edf1',
    },
    state: 'Rajasthan',
    district: 'Ajmer',
    city: 'Masooda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edf2',
    },
    state: 'Rajasthan',
    district: 'Ajmer',
    city: 'Pisangan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edf3',
    },
    state: 'Rajasthan',
    district: 'Ajmer',
    city: 'Sarwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edf4',
    },
    state: 'Rajasthan',
    district: 'Ajmer',
    city: 'Bhinay',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edf5',
    },
    state: 'Rajasthan',
    district: 'Ajmer',
    city: 'Sawar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edf6',
    },
    state: 'Rajasthan',
    district: 'Ajmer',
    city: 'Srinagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edf7',
    },
    state: 'Rajasthan',
    district: 'Alwar',
    city: 'Bansur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edf8',
    },
    state: 'Rajasthan',
    district: 'Alwar',
    city: 'Govindgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edf9',
    },
    state: 'Rajasthan',
    district: 'Alwar',
    city: 'Behror',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edfa',
    },
    state: 'Rajasthan',
    district: 'Alwar',
    city: 'Kishangarh Bas',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edfb',
    },
    state: 'Rajasthan',
    district: 'Alwar',
    city: 'Kathumar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edfc',
    },
    state: 'Rajasthan',
    district: 'Alwar',
    city: 'Kotkasim',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edfd',
    },
    state: 'Rajasthan',
    district: 'Alwar',
    city: 'Malkheda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edfe',
    },
    state: 'Rajasthan',
    district: 'Alwar',
    city: 'Mandawar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559edff',
    },
    state: 'Rajasthan',
    district: 'Alwar',
    city: 'Laxmangarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee00',
    },
    state: 'Rajasthan',
    district: 'Alwar',
    city: 'Neemrana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee01',
    },
    state: 'Rajasthan',
    district: 'Alwar',
    city: 'Rajgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee02',
    },
    state: 'Rajasthan',
    district: 'Alwar',
    city: 'Ramgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee03',
    },
    state: 'Rajasthan',
    district: 'Alwar',
    city: 'Thanagazi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee04',
    },
    state: 'Rajasthan',
    district: 'Alwar',
    city: 'Tijara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee05',
    },
    state: 'Rajasthan',
    district: 'Alwar',
    city: 'Reni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee06',
    },
    state: 'Rajasthan',
    district: 'Alwar',
    city: 'Umren',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee07',
    },
    state: 'Rajasthan',
    district: 'Banswara',
    city: 'Anandpuri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee08',
    },
    state: 'Rajasthan',
    district: 'Banswara',
    city: 'Arthuna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee09',
    },
    state: 'Rajasthan',
    district: 'Banswara',
    city: 'Bagidora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee0a',
    },
    state: 'Rajasthan',
    district: 'Banswara',
    city: 'Banswara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee0b',
    },
    state: 'Rajasthan',
    district: 'Banswara',
    city: 'Chhoti Sarwan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee0c',
    },
    state: 'Rajasthan',
    district: 'Banswara',
    city: 'Gangar Talai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee0d',
    },
    state: 'Rajasthan',
    district: 'Banswara',
    city: 'Ghatol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee0e',
    },
    state: 'Rajasthan',
    district: 'Banswara',
    city: 'Garhi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee0f',
    },
    state: 'Rajasthan',
    district: 'Banswara',
    city: 'Kushalgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee10',
    },
    state: 'Rajasthan',
    district: 'Banswara',
    city: 'Sajjangarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee11',
    },
    state: 'Rajasthan',
    district: 'Banswara',
    city: 'Talwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee12',
    },
    state: 'Rajasthan',
    district: 'Baran',
    city: 'Anta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee13',
    },
    state: 'Rajasthan',
    district: 'Baran',
    city: 'Baran (Full)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee14',
    },
    state: 'Rajasthan',
    district: 'Baran',
    city: 'Atru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee15',
    },
    state: 'Rajasthan',
    district: 'Baran',
    city: 'Chhabra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee16',
    },
    state: 'Rajasthan',
    district: 'Baran',
    city: 'Chhipabarod',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee17',
    },
    state: 'Rajasthan',
    district: 'Baran',
    city: 'Kishanganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee18',
    },
    state: 'Rajasthan',
    district: 'Baran',
    city: 'Mangrol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee19',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Aadel',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee1a',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Baltora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee1b',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Barmer',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee1c',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Barmer Rural',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee1d',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Baytoo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee1e',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Chohtan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee1f',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Dhanau',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee20',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Dhorimanna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee21',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Fagliya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee22',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Gadra Road',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee23',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Gira',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee24',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Gudhamalani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee25',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Kalyanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee26',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Panyla Kla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee27',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Patodi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee28',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Ramsar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee29',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Samdari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee2a',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Sedwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee2b',
    },
    state: 'Rajasthan',
    district: 'Baran',
    city: 'Shahbad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee2c',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Sindhari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee2d',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Sheo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee2e',
    },
    state: 'Rajasthan',
    district: 'Barmer',
    city: 'Siwana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee2f',
    },
    state: 'Rajasthan',
    district: 'Bharatpur',
    city: 'Bayana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee30',
    },
    state: 'Rajasthan',
    district: 'Bharatpur',
    city: 'Bhusawar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee31',
    },
    state: 'Rajasthan',
    district: 'Bharatpur',
    city: 'Kaman',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee32',
    },
    state: 'Rajasthan',
    district: 'Bharatpur',
    city: 'Kumher',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee33',
    },
    state: 'Rajasthan',
    district: 'Bharatpur',
    city: 'Nadbai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee34',
    },
    state: 'Rajasthan',
    district: 'Bharatpur',
    city: 'Nagar Pahari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee35',
    },
    state: 'Rajasthan',
    district: 'Bharatpur',
    city: 'Pahari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee36',
    },
    state: 'Rajasthan',
    district: 'Bharatpur',
    city: 'Deeg',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee37',
    },
    state: 'Rajasthan',
    district: 'Bharatpur',
    city: 'Rupbas',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee38',
    },
    state: 'Rajasthan',
    district: 'Bharatpur',
    city: 'Sewar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee39',
    },
    state: 'Rajasthan',
    district: 'Bharatpur',
    city: 'Uchain',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee3a',
    },
    state: 'Rajasthan',
    district: 'Bhilwara',
    city: 'Asind',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee3b',
    },
    state: 'Rajasthan',
    district: 'Bhilwara',
    city: 'Badnor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee3c',
    },
    state: 'Rajasthan',
    district: 'Bharatpur',
    city: 'Weir',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee3d',
    },
    state: 'Rajasthan',
    district: 'Bhilwara',
    city: 'Banera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee3e',
    },
    state: 'Rajasthan',
    district: 'Bhilwara',
    city: 'Bijoliya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee3f',
    },
    state: 'Rajasthan',
    district: 'Bhilwara',
    city: 'Jahazpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee40',
    },
    state: 'Rajasthan',
    district: 'Bhilwara',
    city: 'Hurda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee41',
    },
    state: 'Rajasthan',
    district: 'Bhilwara',
    city: 'Kareda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee42',
    },
    state: 'Rajasthan',
    district: 'Bhilwara',
    city: 'Kotri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee43',
    },
    state: 'Rajasthan',
    district: 'Bhilwara',
    city: 'Mandal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee44',
    },
    state: 'Rajasthan',
    district: 'Bhilwara',
    city: 'Raipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee45',
    },
    state: 'Rajasthan',
    district: 'Bhilwara',
    city: 'Mandalgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee46',
    },
    state: 'Rajasthan',
    district: 'Bhilwara',
    city: 'Sahara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee47',
    },
    state: 'Rajasthan',
    district: 'Bhilwara',
    city: 'Shahpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee48',
    },
    state: 'Rajasthan',
    district: 'Bhilwara',
    city: 'Suwana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee49',
    },
    state: 'Rajasthan',
    district: 'Bikaner',
    city: 'Bikaner',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee4a',
    },
    state: 'Rajasthan',
    district: 'Bikaner',
    city: 'Bajju Khalsa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee4b',
    },
    state: 'Rajasthan',
    district: 'Bikaner',
    city: 'Khajuwala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee4c',
    },
    state: 'Rajasthan',
    district: 'Bikaner',
    city: 'Kolayat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee4d',
    },
    state: 'Rajasthan',
    district: 'Bikaner',
    city: 'Lunkaransar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee4e',
    },
    state: 'Rajasthan',
    district: 'Bikaner',
    city: 'Nokha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee4f',
    },
    state: 'Rajasthan',
    district: 'Bikaner',
    city: 'Poogal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee50',
    },
    state: 'Rajasthan',
    district: 'Bikaner',
    city: 'Sri Dungargarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee51',
    },
    state: 'Rajasthan',
    district: 'Bikaner',
    city: 'Panchoo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee52',
    },
    state: 'Rajasthan',
    district: 'Bundi',
    city: 'Hindoli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee53',
    },
    state: 'Rajasthan',
    district: 'Bundi',
    city: 'Bundi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee54',
    },
    state: 'Rajasthan',
    district: 'Bundi',
    city: 'Keshoraipatan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee55',
    },
    state: 'Rajasthan',
    district: 'Bundi',
    city: 'Nainwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee56',
    },
    state: 'Rajasthan',
    district: 'Bundi',
    city: 'Talera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee57',
    },
    state: 'Rajasthan',
    district: 'Chittorgarh',
    city: 'Bari Sadri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee58',
    },
    state: 'Rajasthan',
    district: 'Chittorgarh',
    city: 'Begun',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee59',
    },
    state: 'Rajasthan',
    district: 'Chittorgarh',
    city: 'Bhadesar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee5a',
    },
    state: 'Rajasthan',
    district: 'Chittorgarh',
    city: 'Bhainsrorgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee5b',
    },
    state: 'Rajasthan',
    district: 'Chittorgarh',
    city: 'Bhopalsagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee5c',
    },
    state: 'Rajasthan',
    district: 'Chittorgarh',
    city: 'Chittorgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee5d',
    },
    state: 'Rajasthan',
    district: 'Chittorgarh',
    city: 'Gangrar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee5e',
    },
    state: 'Rajasthan',
    district: 'Chittorgarh',
    city: 'Dungla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee5f',
    },
    state: 'Rajasthan',
    district: 'Chittorgarh',
    city: 'Kapasan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee60',
    },
    state: 'Rajasthan',
    district: 'Chittorgarh',
    city: 'Nimbahera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee61',
    },
    state: 'Rajasthan',
    district: 'Chittorgarh',
    city: 'Rashmi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee62',
    },
    state: 'Rajasthan',
    district: 'Churu',
    city: 'Bidasar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee63',
    },
    state: 'Rajasthan',
    district: 'Churu',
    city: 'Rajgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee64',
    },
    state: 'Rajasthan',
    district: 'Churu',
    city: 'Churu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee65',
    },
    state: 'Rajasthan',
    district: 'Churu',
    city: 'Ratangarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee66',
    },
    state: 'Rajasthan',
    district: 'Churu',
    city: 'Sujangarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee67',
    },
    state: 'Rajasthan',
    district: 'Churu',
    city: 'Sardarshahar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee68',
    },
    state: 'Rajasthan',
    district: 'Churu',
    city: 'Taranagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee69',
    },
    state: 'Rajasthan',
    district: 'Dausa',
    city: 'Baijupada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee6a',
    },
    state: 'Rajasthan',
    district: 'Dausa',
    city: 'Bandikui',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee6b',
    },
    state: 'Rajasthan',
    district: 'Dausa',
    city: 'Baswa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee6c',
    },
    state: 'Rajasthan',
    district: 'Dausa',
    city: 'Lalsot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee6d',
    },
    state: 'Rajasthan',
    district: 'Dausa',
    city: 'Lawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee6e',
    },
    state: 'Rajasthan',
    district: 'Dausa',
    city: 'Mahwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee6f',
    },
    state: 'Rajasthan',
    district: 'Dausa',
    city: 'Dausa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee70',
    },
    state: 'Rajasthan',
    district: 'Dausa',
    city: 'Ramgarh Pachwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee71',
    },
    state: 'Rajasthan',
    district: 'Dausa',
    city: 'Nangal Rajawatan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee72',
    },
    state: 'Rajasthan',
    district: 'Dausa',
    city: 'Sikandara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee73',
    },
    state: 'Rajasthan',
    district: 'Dausa',
    city: 'Sikrai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee74',
    },
    state: 'Rajasthan',
    district: 'Dholpur',
    city: 'Bari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee75',
    },
    state: 'Rajasthan',
    district: 'Dholpur',
    city: 'Baseri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee76',
    },
    state: 'Rajasthan',
    district: 'Dholpur',
    city: 'Dhaulpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee77',
    },
    state: 'Rajasthan',
    district: 'Dholpur',
    city: 'Rajakhera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee78',
    },
    state: 'Rajasthan',
    district: 'Dholpur',
    city: 'Saipau',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee79',
    },
    state: 'Rajasthan',
    district: 'Dholpur',
    city: 'Sarmathura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee7a',
    },
    state: 'Rajasthan',
    district: 'Dungarpur',
    city: 'Aspur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee7b',
    },
    state: 'Rajasthan',
    district: 'Dungarpur',
    city: 'Bichiwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee7c',
    },
    state: 'Rajasthan',
    district: 'Dungarpur',
    city: 'Chikhali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee7d',
    },
    state: 'Rajasthan',
    district: 'Dungarpur',
    city: 'Dovada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee7e',
    },
    state: 'Rajasthan',
    district: 'Dungarpur',
    city: 'Dungarpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee7f',
    },
    state: 'Rajasthan',
    district: 'Dungarpur',
    city: 'Jothari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee80',
    },
    state: 'Rajasthan',
    district: 'Dungarpur',
    city: 'Sabla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee81',
    },
    state: 'Rajasthan',
    district: 'Dungarpur',
    city: 'Galiyakoat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee82',
    },
    state: 'Rajasthan',
    district: 'Dungarpur',
    city: 'Sagwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee83',
    },
    state: 'Rajasthan',
    district: 'Dungarpur',
    city: 'Simalwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee84',
    },
    state: 'Rajasthan',
    district: 'Ganganagar',
    city: 'Anupgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee85',
    },
    state: 'Rajasthan',
    district: 'Ganganagar',
    city: 'Ganganagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee86',
    },
    state: 'Rajasthan',
    district: 'Ganganagar',
    city: 'Gharsana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee87',
    },
    state: 'Rajasthan',
    district: 'Ganganagar',
    city: 'Karanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee88',
    },
    state: 'Rajasthan',
    district: 'Ganganagar',
    city: 'Padampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee89',
    },
    state: 'Rajasthan',
    district: 'Ganganagar',
    city: 'Raisinghnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee8a',
    },
    state: 'Rajasthan',
    district: 'Ganganagar',
    city: 'Sadulshahar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee8b',
    },
    state: 'Rajasthan',
    district: 'Ganganagar',
    city: 'Suratgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee8c',
    },
    state: 'Rajasthan',
    district: 'Ganganagar',
    city: 'Vijainagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee8d',
    },
    state: 'Rajasthan',
    district: 'Hanumangarh',
    city: 'Bhadra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee8e',
    },
    state: 'Rajasthan',
    district: 'Hanumangarh',
    city: 'Nohar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee8f',
    },
    state: 'Rajasthan',
    district: 'Hanumangarh',
    city: 'Pilibanga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee90',
    },
    state: 'Rajasthan',
    district: 'Hanumangarh',
    city: 'Rawatsar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee91',
    },
    state: 'Rajasthan',
    district: 'Hanumangarh',
    city: 'Sangaria',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee92',
    },
    state: 'Rajasthan',
    district: 'Hanumangarh',
    city: 'Tibbi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee93',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Amber',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee94',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Andhi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee95',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Bassi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee96',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Chaksu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee97',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Dudu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee98',
    },
    state: 'Rajasthan',
    district: 'Hanumangarh',
    city: 'Hanumangarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee99',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Govindgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee9a',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Jalsu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee9b',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Jamwa Ramgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee9c',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Jhotwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee9d',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Kishangarh Renwal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee9e',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Kotkhawda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ee9f',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Kotputli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eea0',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Madhorajpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eea1',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Jobner',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eea2',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Mauzamabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eea3',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Phagi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eea4',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Sambhar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eea5',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Sanganer',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eea6',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Shahpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eea7',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Tunga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eea8',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Viratnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eea9',
    },
    state: 'Rajasthan',
    district: 'Jaisalmer',
    city: 'Bhaniyana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeaa',
    },
    state: 'Rajasthan',
    district: 'Jaisalmer',
    city: 'Fatehgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeab',
    },
    state: 'Rajasthan',
    district: 'Jaipur',
    city: 'Paota',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeac',
    },
    state: 'Rajasthan',
    district: 'Jaisalmer',
    city: 'Mohangarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eead',
    },
    state: 'Rajasthan',
    district: 'Jaisalmer',
    city: 'Jaisalmer',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeae',
    },
    state: 'Rajasthan',
    district: 'Jaisalmer',
    city: 'Nachana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeaf',
    },
    state: 'Rajasthan',
    district: 'Jaisalmer',
    city: 'Sam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeb0',
    },
    state: 'Rajasthan',
    district: 'Jaisalmer',
    city: 'Sankra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeb1',
    },
    state: 'Rajasthan',
    district: 'Jalore',
    city: 'Bagoda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeb2',
    },
    state: 'Rajasthan',
    district: 'Jalore',
    city: 'Bhinmal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeb3',
    },
    state: 'Rajasthan',
    district: 'Jalore',
    city: 'Chitalwana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeb4',
    },
    state: 'Rajasthan',
    district: 'Jalore',
    city: 'Ahore',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeb5',
    },
    state: 'Rajasthan',
    district: 'Jalore',
    city: 'Jalore',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeb6',
    },
    state: 'Rajasthan',
    district: 'Jalore',
    city: 'Raniwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeb7',
    },
    state: 'Rajasthan',
    district: 'Jalore',
    city: 'Sanchore',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeb8',
    },
    state: 'Rajasthan',
    district: 'Jalore',
    city: 'Sarnau',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeb9',
    },
    state: 'Rajasthan',
    district: 'Jalore',
    city: 'Sayla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeba',
    },
    state: 'Rajasthan',
    district: 'Jalore',
    city: 'Jaswantpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eebb',
    },
    state: 'Rajasthan',
    district: 'Jhalawar',
    city: 'Aklera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eebc',
    },
    state: 'Rajasthan',
    district: 'Jhalawar',
    city: 'Bakani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eebd',
    },
    state: 'Rajasthan',
    district: 'Jhalawar',
    city: 'Bhawani Mandi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eebe',
    },
    state: 'Rajasthan',
    district: 'Jhalawar',
    city: 'Dag',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eebf',
    },
    state: 'Rajasthan',
    district: 'Jhalawar',
    city: 'Khanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eec0',
    },
    state: 'Rajasthan',
    district: 'Jhalawar',
    city: 'Jhalrapatan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eec1',
    },
    state: 'Rajasthan',
    district: 'Jhalawar',
    city: 'Manoharthana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eec2',
    },
    state: 'Rajasthan',
    district: 'Jhalawar',
    city: 'Pirawa (Sunel)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eec3',
    },
    state: 'Rajasthan',
    district: 'Jhunjhunu',
    city: 'Alsisar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eec4',
    },
    state: 'Rajasthan',
    district: 'Jhunjhunu',
    city: 'Buhana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eec5',
    },
    state: 'Rajasthan',
    district: 'Jhunjhunu',
    city: 'Chirawa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eec6',
    },
    state: 'Rajasthan',
    district: 'Jhunjhunu',
    city: 'Jhunjhunu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eec7',
    },
    state: 'Rajasthan',
    district: 'Jhunjhunu',
    city: 'Khetri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eec8',
    },
    state: 'Rajasthan',
    district: 'Jhunjhunu',
    city: 'Mandawa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eec9',
    },
    state: 'Rajasthan',
    district: 'Jhunjhunu',
    city: 'Pilani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeca',
    },
    state: 'Rajasthan',
    district: 'Jhunjhunu',
    city: 'Singhana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eecb',
    },
    state: 'Rajasthan',
    district: 'Jhunjhunu',
    city: 'Surajgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eecc',
    },
    state: 'Rajasthan',
    district: 'Jhunjhunu',
    city: 'Udaipurwati',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eecd',
    },
    state: 'Rajasthan',
    district: 'Jhunjhunu',
    city: 'Nawalgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eece',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Aau',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eecf',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Balesar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eed0',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Bap',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eed1',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Bapini',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eed2',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Bawadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eed3',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Bhopalgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eed4',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Bilara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eed5',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Chamu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eed6',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Dechu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eed7',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Ghantiyali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eed8',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Keru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eed9',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Lohawat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeda',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Luni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eedb',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Mandor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eedc',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Osian',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eedd',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Peepad Sahar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eede',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Phalodi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eedf',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Dhawa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eee0',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Shergarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eee1',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Tinwari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eee2',
    },
    state: 'Rajasthan',
    district: 'Jodhpur',
    city: 'Sekhala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eee3',
    },
    state: 'Rajasthan',
    district: 'Karauli',
    city: 'Hindaun',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eee4',
    },
    state: 'Rajasthan',
    district: 'Karauli',
    city: 'Karauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eee5',
    },
    state: 'Rajasthan',
    district: 'Karauli',
    city: 'Masalpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eee6',
    },
    state: 'Rajasthan',
    district: 'Karauli',
    city: 'Nadauti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eee7',
    },
    state: 'Rajasthan',
    district: 'Karauli',
    city: 'Mandrayal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eee8',
    },
    state: 'Rajasthan',
    district: 'Karauli',
    city: 'Sri Mahaveer Ji',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eee9',
    },
    state: 'Rajasthan',
    district: 'Karauli',
    city: 'Sapotra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeea',
    },
    state: 'Rajasthan',
    district: 'Kota',
    city: 'Itawa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeeb',
    },
    state: 'Rajasthan',
    district: 'Kota',
    city: 'Khairabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeec',
    },
    state: 'Rajasthan',
    district: 'Kota',
    city: 'Ladpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeed',
    },
    state: 'Rajasthan',
    district: 'Kota',
    city: 'Sangod',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeee',
    },
    state: 'Rajasthan',
    district: 'Kota',
    city: 'Sultanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeef',
    },
    state: 'Rajasthan',
    district: 'Nagaur',
    city: 'Bherunda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eef0',
    },
    state: 'Rajasthan',
    district: 'Nagaur',
    city: 'Degana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eef1',
    },
    state: 'Rajasthan',
    district: 'Nagaur',
    city: 'Didwana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eef2',
    },
    state: 'Rajasthan',
    district: 'Nagaur',
    city: 'Jayal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eef3',
    },
    state: 'Rajasthan',
    district: 'Nagaur',
    city: 'Khinvsar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eef4',
    },
    state: 'Rajasthan',
    district: 'Nagaur',
    city: 'Kuchaman',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eef5',
    },
    state: 'Rajasthan',
    district: 'Karauli',
    city: 'Todabhim',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eef6',
    },
    state: 'Rajasthan',
    district: 'Nagaur',
    city: 'Ladnu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eef7',
    },
    state: 'Rajasthan',
    district: 'Nagaur',
    city: 'Makrana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eef8',
    },
    state: 'Rajasthan',
    district: 'Nagaur',
    city: 'Merta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eef9',
    },
    state: 'Rajasthan',
    district: 'Nagaur',
    city: 'Mundwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eefa',
    },
    state: 'Rajasthan',
    district: 'Nagaur',
    city: 'Nagaur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eefb',
    },
    state: 'Rajasthan',
    district: 'Nagaur',
    city: 'Nawa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eefc',
    },
    state: 'Rajasthan',
    district: 'Nagaur',
    city: 'Parbatsar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eefd',
    },
    state: 'Rajasthan',
    district: 'Nagaur',
    city: 'Molasar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eefe',
    },
    state: 'Rajasthan',
    district: 'Nagaur',
    city: 'Riyan Badi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eeff',
    },
    state: 'Rajasthan',
    district: 'Pali',
    city: 'Bali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef00',
    },
    state: 'Rajasthan',
    district: 'Pali',
    city: 'Desuri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef01',
    },
    state: 'Rajasthan',
    district: 'Pali',
    city: 'Jaitaran',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef02',
    },
    state: 'Rajasthan',
    district: 'Pali',
    city: 'Kharchi(Mar.Jun)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef03',
    },
    state: 'Rajasthan',
    district: 'Pali',
    city: 'Raipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef04',
    },
    state: 'Rajasthan',
    district: 'Pali',
    city: 'Rani Station',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef05',
    },
    state: 'Rajasthan',
    district: 'Pali',
    city: 'Rohat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef06',
    },
    state: 'Rajasthan',
    district: 'Pali',
    city: 'Sojat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef07',
    },
    state: 'Rajasthan',
    district: 'Pali',
    city: 'Pali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef08',
    },
    state: 'Rajasthan',
    district: 'Pali',
    city: 'Sumerpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef09',
    },
    state: 'Rajasthan',
    district: 'Pratapgarh',
    city: 'Arnod',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef0a',
    },
    state: 'Rajasthan',
    district: 'Pratapgarh',
    city: 'Chhoti Sadri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef0b',
    },
    state: 'Rajasthan',
    district: 'Pratapgarh',
    city: 'Dalot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef0c',
    },
    state: 'Rajasthan',
    district: 'Pratapgarh',
    city: 'Dhamotar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef0d',
    },
    state: 'Rajasthan',
    district: 'Pratapgarh',
    city: 'Dhariawad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef0e',
    },
    state: 'Rajasthan',
    district: 'Pratapgarh',
    city: 'Peepal Khoont',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef0f',
    },
    state: 'Rajasthan',
    district: 'Pratapgarh',
    city: 'Pratapgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef10',
    },
    state: 'Rajasthan',
    district: 'Pratapgarh',
    city: 'Suhagpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef11',
    },
    state: 'Rajasthan',
    district: 'Rajsamand',
    city: 'Amet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef12',
    },
    state: 'Rajasthan',
    district: 'Rajsamand',
    city: 'Bhim',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef13',
    },
    state: 'Rajasthan',
    district: 'Rajsamand',
    city: 'Delwada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef14',
    },
    state: 'Rajasthan',
    district: 'Rajsamand',
    city: 'Deogarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef15',
    },
    state: 'Rajasthan',
    district: 'Rajsamand',
    city: 'Khamnor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef16',
    },
    state: 'Rajasthan',
    district: 'Rajsamand',
    city: 'Railmagra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef17',
    },
    state: 'Rajasthan',
    district: 'Rajsamand',
    city: 'Rajsamand',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef18',
    },
    state: 'Rajasthan',
    district: 'Sawai Madhopur',
    city: 'Bamanwas',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef19',
    },
    state: 'Rajasthan',
    district: 'Sawai Madhopur',
    city: 'Bonli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef1a',
    },
    state: 'Rajasthan',
    district: 'Sawai Madhopur',
    city: 'Chauth Ka Barwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef1b',
    },
    state: 'Rajasthan',
    district: 'Sawai Madhopur',
    city: 'Gangapur City',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef1c',
    },
    state: 'Rajasthan',
    district: 'Sawai Madhopur',
    city: 'Khandar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef1d',
    },
    state: 'Rajasthan',
    district: 'Sawai Madhopur',
    city: 'Malarna Doongar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef1e',
    },
    state: 'Rajasthan',
    district: 'Sawai Madhopur',
    city: 'Sawai Madhopur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef1f',
    },
    state: 'Rajasthan',
    district: 'Sikar',
    city: 'Ajeetgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef20',
    },
    state: 'Rajasthan',
    district: 'Rajsamand',
    city: 'Kumbhalgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef21',
    },
    state: 'Rajasthan',
    district: 'Sikar',
    city: 'Danta Ramgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef22',
    },
    state: 'Rajasthan',
    district: 'Sikar',
    city: 'Dhod',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef23',
    },
    state: 'Rajasthan',
    district: 'Sikar',
    city: 'Fatehpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef24',
    },
    state: 'Rajasthan',
    district: 'Sikar',
    city: 'Khandela',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef25',
    },
    state: 'Rajasthan',
    district: 'Sikar',
    city: 'Nechwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef26',
    },
    state: 'Rajasthan',
    district: 'Sikar',
    city: 'Neem Ka Thana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef27',
    },
    state: 'Rajasthan',
    district: 'Sikar',
    city: 'Palsana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef28',
    },
    state: 'Rajasthan',
    district: 'Sikar',
    city: 'Patan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef29',
    },
    state: 'Rajasthan',
    district: 'Sikar',
    city: 'Lachhmangarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef2a',
    },
    state: 'Rajasthan',
    district: 'Sikar',
    city: 'Sri Madhopur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef2b',
    },
    state: 'Rajasthan',
    district: 'Sirohi',
    city: 'Abu Road',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef2c',
    },
    state: 'Rajasthan',
    district: 'Sirohi',
    city: 'Pindwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef2d',
    },
    state: 'Rajasthan',
    district: 'Sirohi',
    city: 'Reodar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef2e',
    },
    state: 'Rajasthan',
    district: 'Sirohi',
    city: 'Sheoganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef2f',
    },
    state: 'Rajasthan',
    district: 'Sirohi',
    city: 'Sirohi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef30',
    },
    state: 'Rajasthan',
    district: 'Tonk',
    city: 'Deoli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef31',
    },
    state: 'Rajasthan',
    district: 'Tonk',
    city: 'Malpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef32',
    },
    state: 'Rajasthan',
    district: 'Tonk',
    city: 'Newai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef33',
    },
    state: 'Rajasthan',
    district: 'Sikar',
    city: 'Piprali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef34',
    },
    state: 'Rajasthan',
    district: 'Tonk',
    city: 'Peeplu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef35',
    },
    state: 'Rajasthan',
    district: 'Tonk',
    city: 'Todaraisingh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef36',
    },
    state: 'Rajasthan',
    district: 'Tonk',
    city: 'Tonk',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef37',
    },
    state: 'Rajasthan',
    district: 'Udaipur',
    city: 'Bargaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef38',
    },
    state: 'Rajasthan',
    district: 'Udaipur',
    city: 'Bhinder',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef39',
    },
    state: 'Rajasthan',
    district: 'Udaipur',
    city: 'Girwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef3a',
    },
    state: 'Rajasthan',
    district: 'Udaipur',
    city: 'Gogunda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef3b',
    },
    state: 'Rajasthan',
    district: 'Tonk',
    city: 'Uniara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef3c',
    },
    state: 'Rajasthan',
    district: 'Udaipur',
    city: 'Jaisamand',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef3d',
    },
    state: 'Rajasthan',
    district: 'Udaipur',
    city: 'Jhadol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef3e',
    },
    state: 'Rajasthan',
    district: 'Udaipur',
    city: 'Jhallara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef3f',
    },
    state: 'Rajasthan',
    district: 'Udaipur',
    city: 'Kherwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef40',
    },
    state: 'Rajasthan',
    district: 'Udaipur',
    city: 'Kotra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef41',
    },
    state: 'Rajasthan',
    district: 'Udaipur',
    city: 'Kurabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef42',
    },
    state: 'Rajasthan',
    district: 'Udaipur',
    city: 'Mavli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef43',
    },
    state: 'Rajasthan',
    district: 'Udaipur',
    city: 'Nayagaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef44',
    },
    state: 'Rajasthan',
    district: 'Udaipur',
    city: 'Phalasiya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef45',
    },
    state: 'Rajasthan',
    district: 'Udaipur',
    city: 'Rishbhdeo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef46',
    },
    state: 'Rajasthan',
    district: 'Udaipur',
    city: 'Salumbar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef47',
    },
    state: 'Rajasthan',
    district: 'Udaipur',
    city: 'Sarada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef48',
    },
    state: 'Rajasthan',
    district: 'Udaipur',
    city: 'Sayra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef49',
    },
    state: 'Rajasthan',
    district: 'Udaipur',
    city: 'Semari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef4a',
    },
    state: 'Rajasthan',
    district: 'Udaipur',
    city: 'Vallabhnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef4b',
    },
    state: 'Sikkim',
    district: 'East District',
    city: 'Duga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef4c',
    },
    state: 'Sikkim',
    district: 'East District',
    city: 'Khamdong',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef4d',
    },
    state: 'Rajasthan',
    district: 'Udaipur',
    city: 'Lasadiya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef4e',
    },
    state: 'Sikkim',
    district: 'East District',
    city: 'Martam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef4f',
    },
    state: 'Sikkim',
    district: 'East District',
    city: 'Pakyong',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef50',
    },
    state: 'Sikkim',
    district: 'East District',
    city: 'Parkha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef51',
    },
    state: 'Sikkim',
    district: 'East District',
    city: 'Nandok',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef52',
    },
    state: 'Sikkim',
    district: 'East District',
    city: 'Rakdong Tintek',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef53',
    },
    state: 'Sikkim',
    district: 'East District',
    city: 'Ranka',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef54',
    },
    state: 'Sikkim',
    district: 'East District',
    city: 'Rhenock',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef55',
    },
    state: 'Sikkim',
    district: 'North District',
    city: 'Chungthang',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef56',
    },
    state: 'Sikkim',
    district: 'North District',
    city: 'Kabi Tingda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef57',
    },
    state: 'Sikkim',
    district: 'North District',
    city: 'Mangan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef58',
    },
    state: 'Sikkim',
    district: 'North District',
    city: 'Passingdang',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef59',
    },
    state: 'Sikkim',
    district: 'South District',
    city: 'Jorethang',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef5a',
    },
    state: 'Sikkim',
    district: 'South District',
    city: 'Namchi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef5b',
    },
    state: 'Sikkim',
    district: 'South District',
    city: 'Namthang',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef5c',
    },
    state: 'Sikkim',
    district: 'South District',
    city: 'Ravangla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef5d',
    },
    state: 'Sikkim',
    district: 'South District',
    city: 'Sumbuk',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef5e',
    },
    state: 'Sikkim',
    district: 'South District',
    city: 'Temi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef5f',
    },
    state: 'Sikkim',
    district: 'East District',
    city: 'Reghu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef60',
    },
    state: 'Sikkim',
    district: 'South District',
    city: 'Wok',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef61',
    },
    state: 'Sikkim',
    district: 'West District',
    city: 'Arithang Chongrang',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef62',
    },
    state: 'Sikkim',
    district: 'West District',
    city: 'Chumbong Chakung',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef63',
    },
    state: 'Sikkim',
    district: 'West District',
    city: 'Daramdin',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef64',
    },
    state: 'Sikkim',
    district: 'South District',
    city: 'Yangang',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef65',
    },
    state: 'Sikkim',
    district: 'West District',
    city: 'Dentam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef66',
    },
    state: 'Sikkim',
    district: 'West District',
    city: 'Gyalshing',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef67',
    },
    state: 'Sikkim',
    district: 'West District',
    city: 'Kaluk',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef68',
    },
    state: 'Sikkim',
    district: 'West District',
    city: 'Hee Martam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef69',
    },
    state: 'Sikkim',
    district: 'West District',
    city: 'Soreng',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef6a',
    },
    state: 'Sikkim',
    district: 'West District',
    city: 'Mangalbarey',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef6b',
    },
    state: 'Tamil Nadu',
    district: 'Ariyalur',
    city: 'Andimadam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef6c',
    },
    state: 'Tamil Nadu',
    district: 'Ariyalur',
    city: 'Ariyalur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef6d',
    },
    state: 'Tamil Nadu',
    district: 'Ariyalur',
    city: 'Jayamkondam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef6e',
    },
    state: 'Tamil Nadu',
    district: 'Ariyalur',
    city: 'Sendurai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef6f',
    },
    state: 'Sikkim',
    district: 'West District',
    city: 'Yuksom',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef70',
    },
    state: 'Tamil Nadu',
    district: 'Ariyalur',
    city: 'Thirumanur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef71',
    },
    state: 'Tamil Nadu',
    district: 'Ariyalur',
    city: 'T. Palur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef72',
    },
    state: 'Tamil Nadu',
    district: 'Chengalpattu',
    city: 'Acharapakkam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef73',
    },
    state: 'Tamil Nadu',
    district: 'Chengalpattu',
    city: 'Chithamur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef74',
    },
    state: 'Tamil Nadu',
    district: 'Chengalpattu',
    city: 'Kattankolathur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef75',
    },
    state: 'Tamil Nadu',
    district: 'Chengalpattu',
    city: 'Lathur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef76',
    },
    state: 'Tamil Nadu',
    district: 'Chengalpattu',
    city: 'Madurantakam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef77',
    },
    state: 'Tamil Nadu',
    district: 'Chengalpattu',
    city: 'St.Thomas Mount',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef78',
    },
    state: 'Tamil Nadu',
    district: 'Chengalpattu',
    city: 'Thiruporur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef79',
    },
    state: 'Tamil Nadu',
    district: 'Chengalpattu',
    city: 'Tirukkalukunram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef7a',
    },
    state: 'Tamil Nadu',
    district: 'Coimbatore',
    city: 'Anamalai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef7b',
    },
    state: 'Tamil Nadu',
    district: 'Coimbatore',
    city: 'Karamadai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef7c',
    },
    state: 'Tamil Nadu',
    district: 'Coimbatore',
    city: 'Kinathukadavu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef7d',
    },
    state: 'Tamil Nadu',
    district: 'Coimbatore',
    city: 'Annur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef7e',
    },
    state: 'Tamil Nadu',
    district: 'Coimbatore',
    city: 'Periyanayakkanpalayam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef7f',
    },
    state: 'Tamil Nadu',
    district: 'Coimbatore',
    city: 'Pollachi North',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef80',
    },
    state: 'Tamil Nadu',
    district: 'Coimbatore',
    city: 'Pollachi South',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef81',
    },
    state: 'Tamil Nadu',
    district: 'Coimbatore',
    city: 'Sarcarsamakulam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef82',
    },
    state: 'Tamil Nadu',
    district: 'Coimbatore',
    city: 'Sultanpet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef83',
    },
    state: 'Tamil Nadu',
    district: 'Coimbatore',
    city: 'Sulur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef84',
    },
    state: 'Tamil Nadu',
    district: 'Coimbatore',
    city: 'Thondamuthur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef85',
    },
    state: 'Tamil Nadu',
    district: 'Cuddalore',
    city: 'Annagramam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef86',
    },
    state: 'Tamil Nadu',
    district: 'Cuddalore',
    city: 'Cuddalore',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef87',
    },
    state: 'Tamil Nadu',
    district: 'Cuddalore',
    city: 'Kammapuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef88',
    },
    state: 'Tamil Nadu',
    district: 'Cuddalore',
    city: 'Kattumannarkoil',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef89',
    },
    state: 'Tamil Nadu',
    district: 'Cuddalore',
    city: 'Keerapalayam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef8a',
    },
    state: 'Tamil Nadu',
    district: 'Cuddalore',
    city: 'Komaratchi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef8b',
    },
    state: 'Tamil Nadu',
    district: 'Coimbatore',
    city: 'Madukkarai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef8c',
    },
    state: 'Tamil Nadu',
    district: 'Cuddalore',
    city: 'Kurinjipadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef8d',
    },
    state: 'Tamil Nadu',
    district: 'Cuddalore',
    city: 'Mangalur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef8e',
    },
    state: 'Tamil Nadu',
    district: 'Cuddalore',
    city: 'Melbhuvanagiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef8f',
    },
    state: 'Tamil Nadu',
    district: 'Cuddalore',
    city: 'Panruti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef90',
    },
    state: 'Tamil Nadu',
    district: 'Cuddalore',
    city: 'Parangipettai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef91',
    },
    state: 'Tamil Nadu',
    district: 'Cuddalore',
    city: 'Srimushnam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef92',
    },
    state: 'Tamil Nadu',
    district: 'Cuddalore',
    city: 'Vriddhachalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef93',
    },
    state: 'Tamil Nadu',
    district: 'Cuddalore',
    city: 'Nallur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef94',
    },
    state: 'Tamil Nadu',
    district: 'Dharmapuri',
    city: 'Dharmapuri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef95',
    },
    state: 'Tamil Nadu',
    district: 'Dharmapuri',
    city: 'Eriyur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef96',
    },
    state: 'Tamil Nadu',
    district: 'Dharmapuri',
    city: 'Harur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef97',
    },
    state: 'Tamil Nadu',
    district: 'Dharmapuri',
    city: 'Kadathur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef98',
    },
    state: 'Tamil Nadu',
    district: 'Dharmapuri',
    city: 'Karimangalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef99',
    },
    state: 'Tamil Nadu',
    district: 'Dharmapuri',
    city: 'Nallampalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef9a',
    },
    state: 'Tamil Nadu',
    district: 'Dharmapuri',
    city: 'Palakkodu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef9b',
    },
    state: 'Tamil Nadu',
    district: 'Dharmapuri',
    city: 'Pappireddipatty',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef9c',
    },
    state: 'Tamil Nadu',
    district: 'Dharmapuri',
    city: 'Pennagaram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef9d',
    },
    state: 'Tamil Nadu',
    district: 'Dindigul',
    city: 'Athoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef9e',
    },
    state: 'Tamil Nadu',
    district: 'Dindigul',
    city: 'Dindigul',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559ef9f',
    },
    state: 'Tamil Nadu',
    district: 'Dindigul',
    city: 'Guziliamparai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efa0',
    },
    state: 'Tamil Nadu',
    district: 'Dindigul',
    city: 'Kodaikanal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efa1',
    },
    state: 'Tamil Nadu',
    district: 'Dindigul',
    city: 'Nattam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efa2',
    },
    state: 'Tamil Nadu',
    district: 'Dindigul',
    city: 'Nilakottai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efa3',
    },
    state: 'Tamil Nadu',
    district: 'Dindigul',
    city: 'Oddanchatram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efa4',
    },
    state: 'Tamil Nadu',
    district: 'Dharmapuri',
    city: 'Morappur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efa5',
    },
    state: 'Tamil Nadu',
    district: 'Dindigul',
    city: 'Palani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efa6',
    },
    state: 'Tamil Nadu',
    district: 'Dindigul',
    city: 'Shanarpatti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efa7',
    },
    state: 'Tamil Nadu',
    district: 'Dindigul',
    city: 'Reddiyarchatiram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efa8',
    },
    state: 'Tamil Nadu',
    district: 'Dindigul',
    city: 'Thoppampatti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efa9',
    },
    state: 'Tamil Nadu',
    district: 'Dindigul',
    city: 'Vadamadurai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efaa',
    },
    state: 'Tamil Nadu',
    district: 'Dindigul',
    city: 'Vattalkundu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efab',
    },
    state: 'Tamil Nadu',
    district: 'Dindigul',
    city: 'Vedasandur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efac',
    },
    state: 'Tamil Nadu',
    district: 'Erode',
    city: 'Ammapet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efad',
    },
    state: 'Tamil Nadu',
    district: 'Erode',
    city: 'Andiyur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efae',
    },
    state: 'Tamil Nadu',
    district: 'Erode',
    city: 'Bhavani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efaf',
    },
    state: 'Tamil Nadu',
    district: 'Erode',
    city: 'Bhavanisagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efb0',
    },
    state: 'Tamil Nadu',
    district: 'Erode',
    city: 'Erode',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efb1',
    },
    state: 'Tamil Nadu',
    district: 'Erode',
    city: 'Chennimalai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efb2',
    },
    state: 'Tamil Nadu',
    district: 'Erode',
    city: 'Gopichettipalaiyam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efb3',
    },
    state: 'Tamil Nadu',
    district: 'Erode',
    city: 'Modakurichi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efb4',
    },
    state: 'Tamil Nadu',
    district: 'Erode',
    city: 'Nambiyur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efb5',
    },
    state: 'Tamil Nadu',
    district: 'Erode',
    city: 'Kodumudi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efb6',
    },
    state: 'Tamil Nadu',
    district: 'Erode',
    city: 'Perundurai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efb7',
    },
    state: 'Tamil Nadu',
    district: 'Erode',
    city: 'Satyamangalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efb8',
    },
    state: 'Tamil Nadu',
    district: 'Erode',
    city: 'Talavadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efb9',
    },
    state: 'Tamil Nadu',
    district: 'Kallakurichi',
    city: 'Chinnasalem',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efba',
    },
    state: 'Tamil Nadu',
    district: 'Kallakurichi',
    city: 'Kallakkurichi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efbb',
    },
    state: 'Tamil Nadu',
    district: 'Erode',
    city: 'Thoockanaickenpalaiyam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efbc',
    },
    state: 'Tamil Nadu',
    district: 'Kallakurichi',
    city: 'Kalrayanhills',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efbd',
    },
    state: 'Tamil Nadu',
    district: 'Kallakurichi',
    city: 'Rishivandiam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efbe',
    },
    state: 'Tamil Nadu',
    district: 'Kallakurichi',
    city: 'Thiagadurgam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efc0',
    },
    state: 'Tamil Nadu',
    district: 'Kallakurichi',
    city: 'Sankarapuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efc1',
    },
    state: 'Tamil Nadu',
    district: 'Kallakurichi',
    city: 'Tirukkoyilur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efc2',
    },
    state: 'Tamil Nadu',
    district: 'Kallakurichi',
    city: 'Tirunavalur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efc3',
    },
    state: 'Tamil Nadu',
    district: 'Kallakurichi',
    city: 'Ulundurpet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efc4',
    },
    state: 'Tamil Nadu',
    district: 'Kanchipuram',
    city: 'Kunnattur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efc5',
    },
    state: 'Tamil Nadu',
    district: 'Kanchipuram',
    city: 'Sriperumbudur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efc6',
    },
    state: 'Tamil Nadu',
    district: 'Kanchipuram',
    city: 'Uttiramerur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efc7',
    },
    state: 'Tamil Nadu',
    district: 'Kanchipuram',
    city: 'Walajabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efc8',
    },
    state: 'Tamil Nadu',
    district: 'Kanniyakumari',
    city: 'Agastiswaram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efc9',
    },
    state: 'Tamil Nadu',
    district: 'Kanniyakumari',
    city: 'Killiyoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efca',
    },
    state: 'Tamil Nadu',
    district: 'Kanniyakumari',
    city: 'Kurunthancode',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efcb',
    },
    state: 'Tamil Nadu',
    district: 'Kanniyakumari',
    city: 'Melpuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efcc',
    },
    state: 'Tamil Nadu',
    district: 'Kanniyakumari',
    city: 'Munchira',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efcd',
    },
    state: 'Tamil Nadu',
    district: 'Kanniyakumari',
    city: 'Rajakkamangalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efce',
    },
    state: 'Tamil Nadu',
    district: 'Kanniyakumari',
    city: 'Thackalai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efcf',
    },
    state: 'Tamil Nadu',
    district: 'Kanniyakumari',
    city: 'Thiruvattar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efd0',
    },
    state: 'Tamil Nadu',
    district: 'Kanniyakumari',
    city: 'Thovala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efd1',
    },
    state: 'Tamil Nadu',
    district: 'Karur',
    city: 'Aravakurichi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efd2',
    },
    state: 'Tamil Nadu',
    district: 'Kanchipuram',
    city: 'Kanchipuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efd3',
    },
    state: 'Tamil Nadu',
    district: 'Karur',
    city: 'Kadavur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efd4',
    },
    state: 'Tamil Nadu',
    district: 'Karur',
    city: 'Karur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efd5',
    },
    state: 'Tamil Nadu',
    district: 'Karur',
    city: 'K.Paramathy',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efd6',
    },
    state: 'Tamil Nadu',
    district: 'Karur',
    city: 'Krishnarayapuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efd7',
    },
    state: 'Tamil Nadu',
    district: 'Karur',
    city: 'Kulittalai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efd8',
    },
    state: 'Tamil Nadu',
    district: 'Karur',
    city: 'Thanthoni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efd9',
    },
    state: 'Tamil Nadu',
    district: 'Karur',
    city: 'Thogaimalai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efda',
    },
    state: 'Tamil Nadu',
    district: 'Krishnagiri',
    city: 'Bargur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efdb',
    },
    state: 'Tamil Nadu',
    district: 'Krishnagiri',
    city: 'Kaveripattinam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efdc',
    },
    state: 'Tamil Nadu',
    district: 'Krishnagiri',
    city: 'Kelamangalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efdd',
    },
    state: 'Tamil Nadu',
    district: 'Krishnagiri',
    city: 'Krishnagiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efde',
    },
    state: 'Tamil Nadu',
    district: 'Krishnagiri',
    city: 'Mathur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efdf',
    },
    state: 'Tamil Nadu',
    district: 'Krishnagiri',
    city: 'Shoolagiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efe0',
    },
    state: 'Tamil Nadu',
    district: 'Krishnagiri',
    city: 'Thally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efe1',
    },
    state: 'Tamil Nadu',
    district: 'Krishnagiri',
    city: 'Uthangarai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efe2',
    },
    state: 'Tamil Nadu',
    district: 'Krishnagiri',
    city: 'Veppanapalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efe3',
    },
    state: 'Tamil Nadu',
    district: 'Madurai',
    city: 'Alanganallur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efe4',
    },
    state: 'Tamil Nadu',
    district: 'Krishnagiri',
    city: 'Hosur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efe5',
    },
    state: 'Tamil Nadu',
    district: 'Madurai',
    city: 'Kallikudi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efe6',
    },
    state: 'Tamil Nadu',
    district: 'Madurai',
    city: 'Kottampatti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efe7',
    },
    state: 'Tamil Nadu',
    district: 'Madurai',
    city: 'Chellampatti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efe8',
    },
    state: 'Tamil Nadu',
    district: 'Madurai',
    city: 'Madurai West',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efe9',
    },
    state: 'Tamil Nadu',
    district: 'Madurai',
    city: 'Melur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efea',
    },
    state: 'Tamil Nadu',
    district: 'Madurai',
    city: 'Madurai East',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efeb',
    },
    state: 'Tamil Nadu',
    district: 'Madurai',
    city: 'Sedapatti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efec',
    },
    state: 'Tamil Nadu',
    district: 'Madurai',
    city: 'Tirumangalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efed',
    },
    state: 'Tamil Nadu',
    district: 'Madurai',
    city: 'T.Kallupatti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efee',
    },
    state: 'Tamil Nadu',
    district: 'Madurai',
    city: 'Usilampatti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efef',
    },
    state: 'Tamil Nadu',
    district: 'Madurai',
    city: 'Vadipatti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eff0',
    },
    state: 'Tamil Nadu',
    district: 'Nagapattinam',
    city: 'Keelaiyur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eff1',
    },
    state: 'Tamil Nadu',
    district: 'Nagapattinam',
    city: 'Kilvelur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eff2',
    },
    state: 'Tamil Nadu',
    district: 'Nagapattinam',
    city: 'Kollidam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eff3',
    },
    state: 'Tamil Nadu',
    district: 'Nagapattinam',
    city: 'Kuttalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eff4',
    },
    state: 'Tamil Nadu',
    district: 'Nagapattinam',
    city: 'Mayiladuthurai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eff5',
    },
    state: 'Tamil Nadu',
    district: 'Nagapattinam',
    city: 'Nagappattinam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eff6',
    },
    state: 'Tamil Nadu',
    district: 'Nagapattinam',
    city: 'Sembanar Koil',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eff7',
    },
    state: 'Tamil Nadu',
    district: 'Madurai',
    city: 'Tirupparangunram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eff8',
    },
    state: 'Tamil Nadu',
    district: 'Nagapattinam',
    city: 'Thalanayar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559eff9',
    },
    state: 'Tamil Nadu',
    district: 'Nagapattinam',
    city: 'Sirkazhi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559effa',
    },
    state: 'Tamil Nadu',
    district: 'Nagapattinam',
    city: 'Vedaranyam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559effb',
    },
    state: 'Tamil Nadu',
    district: 'Namakkal',
    city: 'Elacipalayam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559effc',
    },
    state: 'Tamil Nadu',
    district: 'Namakkal',
    city: 'Erumapatty',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559effd',
    },
    state: 'Tamil Nadu',
    district: 'Namakkal',
    city: 'Kabilamalai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559effe',
    },
    state: 'Tamil Nadu',
    district: 'Namakkal',
    city: 'Kolli Hills',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559efff',
    },
    state: 'Tamil Nadu',
    district: 'Namakkal',
    city: 'Mallasamudram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f000',
    },
    state: 'Tamil Nadu',
    district: 'Namakkal',
    city: 'Mohanur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f001',
    },
    state: 'Tamil Nadu',
    district: 'Namakkal',
    city: 'Namagiripet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f002',
    },
    state: 'Tamil Nadu',
    district: 'Nagapattinam',
    city: 'Thirumarugal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f003',
    },
    state: 'Tamil Nadu',
    district: 'Namakkal',
    city: 'Pallipalayam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f004',
    },
    state: 'Tamil Nadu',
    district: 'Namakkal',
    city: 'Namakkal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f005',
    },
    state: 'Tamil Nadu',
    district: 'Namakkal',
    city: 'Paramathy',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f006',
    },
    state: 'Tamil Nadu',
    district: 'Namakkal',
    city: 'Puduchatram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f007',
    },
    state: 'Tamil Nadu',
    district: 'Namakkal',
    city: 'Rasipuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f008',
    },
    state: 'Tamil Nadu',
    district: 'Namakkal',
    city: 'Tiruchengodu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f009',
    },
    state: 'Tamil Nadu',
    district: 'Namakkal',
    city: 'Vennandur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f00a',
    },
    state: 'Tamil Nadu',
    district: 'Perambalur',
    city: 'Alathur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f00b',
    },
    state: 'Tamil Nadu',
    district: 'Perambalur',
    city: 'Perambalur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f00c',
    },
    state: 'Tamil Nadu',
    district: 'Namakkal',
    city: 'Sendamangalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f00d',
    },
    state: 'Tamil Nadu',
    district: 'Perambalur',
    city: 'Veppanthattai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f00e',
    },
    state: 'Tamil Nadu',
    district: 'Pudukkottai',
    city: 'Annavasal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f00f',
    },
    state: 'Tamil Nadu',
    district: 'Pudukkottai',
    city: 'Arantangi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f010',
    },
    state: 'Tamil Nadu',
    district: 'Perambalur',
    city: 'Veppur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f011',
    },
    state: 'Tamil Nadu',
    district: 'Pudukkottai',
    city: 'Avadaiyarkovil',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f012',
    },
    state: 'Tamil Nadu',
    district: 'Pudukkottai',
    city: 'Gandaravakottai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f013',
    },
    state: 'Tamil Nadu',
    district: 'Pudukkottai',
    city: 'Karambakudi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f014',
    },
    state: 'Tamil Nadu',
    district: 'Pudukkottai',
    city: 'Kunnandarkoil',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f015',
    },
    state: 'Tamil Nadu',
    district: 'Pudukkottai',
    city: 'Manalmelkudi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f016',
    },
    state: 'Tamil Nadu',
    district: 'Pudukkottai',
    city: 'Ponnamaravati',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f017',
    },
    state: 'Tamil Nadu',
    district: 'Pudukkottai',
    city: 'Pudukkottai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f018',
    },
    state: 'Tamil Nadu',
    district: 'Pudukkottai',
    city: 'Thiruvarankulam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f019',
    },
    state: 'Tamil Nadu',
    district: 'Pudukkottai',
    city: 'Tirumayam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f01a',
    },
    state: 'Tamil Nadu',
    district: 'Pudukkottai',
    city: 'Viralimalai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f01b',
    },
    state: 'Tamil Nadu',
    district: 'Ramanathapuram',
    city: 'Bogalur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f01c',
    },
    state: 'Tamil Nadu',
    district: 'Ramanathapuram',
    city: 'Kadaladi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f01d',
    },
    state: 'Tamil Nadu',
    district: 'Pudukkottai',
    city: 'Arimalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f01e',
    },
    state: 'Tamil Nadu',
    district: 'Ramanathapuram',
    city: 'Mandapam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f01f',
    },
    state: 'Tamil Nadu',
    district: 'Ramanathapuram',
    city: 'Mudukulathur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f020',
    },
    state: 'Tamil Nadu',
    district: 'Ramanathapuram',
    city: 'Nainarkoil',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f021',
    },
    state: 'Tamil Nadu',
    district: 'Ramanathapuram',
    city: 'Paramakkudi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f022',
    },
    state: 'Tamil Nadu',
    district: 'Ramanathapuram',
    city: 'Kamudi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f023',
    },
    state: 'Tamil Nadu',
    district: 'Ramanathapuram',
    city: 'Ramanathapuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f024',
    },
    state: 'Tamil Nadu',
    district: 'Ramanathapuram',
    city: 'Tiruppullani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f025',
    },
    state: 'Tamil Nadu',
    district: 'Ramanathapuram',
    city: 'Tiruvadanai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f026',
    },
    state: 'Tamil Nadu',
    district: 'Ranipet',
    city: 'Arakkonam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f027',
    },
    state: 'Tamil Nadu',
    district: 'Ranipet',
    city: 'Arcot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f028',
    },
    state: 'Tamil Nadu',
    district: 'Ranipet',
    city: 'Kaveripakkam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f029',
    },
    state: 'Tamil Nadu',
    district: 'Ranipet',
    city: 'Nemili',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f02a',
    },
    state: 'Tamil Nadu',
    district: 'Ranipet',
    city: 'Sholinghur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f02b',
    },
    state: 'Tamil Nadu',
    district: 'Ramanathapuram',
    city: 'Rajasingamangalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f02c',
    },
    state: 'Tamil Nadu',
    district: 'Ranipet',
    city: 'Timiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f02d',
    },
    state: 'Tamil Nadu',
    district: 'Ranipet',
    city: 'Walajapet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f02e',
    },
    state: 'Tamil Nadu',
    district: 'Salem',
    city: 'Attur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f02f',
    },
    state: 'Tamil Nadu',
    district: 'Salem',
    city: 'Ayodhiyapattinam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f030',
    },
    state: 'Tamil Nadu',
    district: 'Salem',
    city: 'Idappadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f031',
    },
    state: 'Tamil Nadu',
    district: 'Salem',
    city: 'Kadaiyampatty',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f032',
    },
    state: 'Tamil Nadu',
    district: 'Salem',
    city: 'Gangavalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f033',
    },
    state: 'Tamil Nadu',
    district: 'Salem',
    city: 'Konganapuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f034',
    },
    state: 'Tamil Nadu',
    district: 'Salem',
    city: 'Kolathur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f035',
    },
    state: 'Tamil Nadu',
    district: 'Salem',
    city: 'Mecheri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f036',
    },
    state: 'Tamil Nadu',
    district: 'Salem',
    city: 'Nangavalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f037',
    },
    state: 'Tamil Nadu',
    district: 'Salem',
    city: 'Omalur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f038',
    },
    state: 'Tamil Nadu',
    district: 'Salem',
    city: 'Panamarathupatti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f039',
    },
    state: 'Tamil Nadu',
    district: 'Salem',
    city: 'Macdonalds Choultry',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f03a',
    },
    state: 'Tamil Nadu',
    district: 'Salem',
    city: 'Salem',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f03b',
    },
    state: 'Tamil Nadu',
    district: 'Salem',
    city: 'Peddanaickenpalayam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f03c',
    },
    state: 'Tamil Nadu',
    district: 'Salem',
    city: 'Sankari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f03d',
    },
    state: 'Tamil Nadu',
    district: 'Salem',
    city: 'Talavasal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f03e',
    },
    state: 'Tamil Nadu',
    district: 'Salem',
    city: 'Valapady',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f03f',
    },
    state: 'Tamil Nadu',
    district: 'Salem',
    city: 'Veerapandi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f040',
    },
    state: 'Tamil Nadu',
    district: 'Salem',
    city: 'Taramangalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f041',
    },
    state: 'Tamil Nadu',
    district: 'Salem',
    city: 'Yercaud',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f042',
    },
    state: 'Tamil Nadu',
    district: 'Sivaganga',
    city: 'Devakottai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f043',
    },
    state: 'Tamil Nadu',
    district: 'Sivaganga',
    city: 'Kalaiyarkoil',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f044',
    },
    state: 'Tamil Nadu',
    district: 'Sivaganga',
    city: 'Kallal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f045',
    },
    state: 'Tamil Nadu',
    district: 'Sivaganga',
    city: 'Kannankudi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f046',
    },
    state: 'Tamil Nadu',
    district: 'Sivaganga',
    city: 'Manamadurai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f047',
    },
    state: 'Tamil Nadu',
    district: 'Sivaganga',
    city: 'Ilayankudi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f048',
    },
    state: 'Tamil Nadu',
    district: 'Sivaganga',
    city: 'Singampunari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f049',
    },
    state: 'Tamil Nadu',
    district: 'Sivaganga',
    city: 'Sivaganga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f04a',
    },
    state: 'Tamil Nadu',
    district: 'Sivaganga',
    city: 'S. Pudur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f04b',
    },
    state: 'Tamil Nadu',
    district: 'Sivaganga',
    city: 'Tiruppathur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f04c',
    },
    state: 'Tamil Nadu',
    district: 'Sivaganga',
    city: 'Tiruppuvanam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f04d',
    },
    state: 'Tamil Nadu',
    district: 'Tenkasi',
    city: 'Alangulam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f04e',
    },
    state: 'Tamil Nadu',
    district: 'Tenkasi',
    city: 'Kadaiyanallur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f04f',
    },
    state: 'Tamil Nadu',
    district: 'Tenkasi',
    city: 'Kadayam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f050',
    },
    state: 'Tamil Nadu',
    district: 'Tenkasi',
    city: 'Keelapavoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f051',
    },
    state: 'Tamil Nadu',
    district: 'Tenkasi',
    city: 'Kuruvikulam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f052',
    },
    state: 'Tamil Nadu',
    district: 'Tenkasi',
    city: 'Melaneelithanallur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f053',
    },
    state: 'Tamil Nadu',
    district: 'Tenkasi',
    city: 'Sankarankovil',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f054',
    },
    state: 'Tamil Nadu',
    district: 'Tenkasi',
    city: 'Shencottah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f055',
    },
    state: 'Tamil Nadu',
    district: 'Tenkasi',
    city: 'Tenkasi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f056',
    },
    state: 'Tamil Nadu',
    district: 'Sivaganga',
    city: 'Sakkottai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f057',
    },
    state: 'Tamil Nadu',
    district: 'Tenkasi',
    city: 'Vasudevanallur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f058',
    },
    state: 'Tamil Nadu',
    district: 'Thanjavur',
    city: 'Ammapettai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f059',
    },
    state: 'Tamil Nadu',
    district: 'Thanjavur',
    city: 'Budalur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f05a',
    },
    state: 'Tamil Nadu',
    district: 'Thanjavur',
    city: 'Madukkur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f05b',
    },
    state: 'Tamil Nadu',
    district: 'Thanjavur',
    city: 'Orattanadu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f05c',
    },
    state: 'Tamil Nadu',
    district: 'Thanjavur',
    city: 'Papanasam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f05d',
    },
    state: 'Tamil Nadu',
    district: 'Thanjavur',
    city: 'Pattukkottai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f05e',
    },
    state: 'Tamil Nadu',
    district: 'Thanjavur',
    city: 'Kumbakonam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f05f',
    },
    state: 'Tamil Nadu',
    district: 'Thanjavur',
    city: 'Sethubhavachatram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f060',
    },
    state: 'Tamil Nadu',
    district: 'Thanjavur',
    city: 'Thanjavur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f061',
    },
    state: 'Tamil Nadu',
    district: 'Thanjavur',
    city: 'Thiruppanandal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f062',
    },
    state: 'Tamil Nadu',
    district: 'Thanjavur',
    city: 'Thiruvaiyaru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f063',
    },
    state: 'Tamil Nadu',
    district: 'Thanjavur',
    city: 'Thiruvonam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f064',
    },
    state: 'Tamil Nadu',
    district: 'Thanjavur',
    city: 'Tiruvidaimarudur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f065',
    },
    state: 'Tamil Nadu',
    district: 'Theni',
    city: 'Andipatti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f066',
    },
    state: 'Tamil Nadu',
    district: 'Theni',
    city: 'Bodinayakkanur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f067',
    },
    state: 'Tamil Nadu',
    district: 'Theni',
    city: 'Chinnamanur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f068',
    },
    state: 'Tamil Nadu',
    district: 'Theni',
    city: 'Kadamalaikundru Myladumparai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f069',
    },
    state: 'Tamil Nadu',
    district: 'Theni',
    city: 'Kambam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f06a',
    },
    state: 'Tamil Nadu',
    district: 'Theni',
    city: 'Periyakulam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f06b',
    },
    state: 'Tamil Nadu',
    district: 'Theni',
    city: 'Theni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f06c',
    },
    state: 'Tamil Nadu',
    district: 'Theni',
    city: 'Uttamapalaiyam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f06d',
    },
    state: 'Tamil Nadu',
    district: 'The Nilgiris',
    city: 'Coonoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f06e',
    },
    state: 'Tamil Nadu',
    district: 'The Nilgiris',
    city: 'Gudalur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f06f',
    },
    state: 'Tamil Nadu',
    district: 'Thanjavur',
    city: 'Peravurani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f070',
    },
    state: 'Tamil Nadu',
    district: 'The Nilgiris',
    city: 'Udhagamandalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f071',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvallur',
    city: 'Ellapuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f072',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvallur',
    city: 'Gummidipundi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f073',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvallur',
    city: 'Kadambathur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f074',
    },
    state: 'Tamil Nadu',
    district: 'The Nilgiris',
    city: 'Kotagiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f075',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvallur',
    city: 'Minjur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f076',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvallur',
    city: 'Pallipattu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f077',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvallur',
    city: 'Poonamallee',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f078',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvallur',
    city: 'Poondi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f079',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvallur',
    city: 'Puzhal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f07a',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvallur',
    city: 'R.K.Pet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f07b',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvallur',
    city: 'Sholavaram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f07c',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvallur',
    city: 'Tiruttani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f07d',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvallur',
    city: 'Tiruvallur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f07e',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvallur',
    city: 'Tiruvelangadu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f07f',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvallur',
    city: 'Villivakkam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f080',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvarur',
    city: 'Kodavasal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f081',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvarur',
    city: 'Koradacherry',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f082',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvarur',
    city: 'Kottur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f083',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvarur',
    city: 'Mannargudi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f084',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvarur',
    city: 'Muthupettai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f085',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvarur',
    city: 'Nannilam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f086',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvarur',
    city: 'Nidamangalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f087',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvarur',
    city: 'Thiruvarur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f088',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvarur',
    city: 'Tirutturaippundi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f089',
    },
    state: 'Tamil Nadu',
    district: 'Thiruvarur',
    city: 'Valangaiman',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f08a',
    },
    state: 'Tamil Nadu',
    district: 'Tiruchirappalli',
    city: 'Andanallur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f08b',
    },
    state: 'Tamil Nadu',
    district: 'Tiruchirappalli',
    city: 'Lalgudi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f08c',
    },
    state: 'Tamil Nadu',
    district: 'Tiruchirappalli',
    city: 'Manachanellur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f08d',
    },
    state: 'Tamil Nadu',
    district: 'Tiruchirappalli',
    city: 'Manapparai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f08e',
    },
    state: 'Tamil Nadu',
    district: 'Tiruchirappalli',
    city: 'Manikandam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f08f',
    },
    state: 'Tamil Nadu',
    district: 'Tiruchirappalli',
    city: 'Marungapuri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f090',
    },
    state: 'Tamil Nadu',
    district: 'Tiruchirappalli',
    city: 'Musiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f091',
    },
    state: 'Tamil Nadu',
    district: 'Tiruchirappalli',
    city: 'Pullambadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f092',
    },
    state: 'Tamil Nadu',
    district: 'Tiruchirappalli',
    city: 'Tattayyangarpettai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f093',
    },
    state: 'Tamil Nadu',
    district: 'Tiruchirappalli',
    city: 'Thiruverambur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f094',
    },
    state: 'Tamil Nadu',
    district: 'Tiruchirappalli',
    city: 'Thottiam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f095',
    },
    state: 'Tamil Nadu',
    district: 'Tiruchirappalli',
    city: 'Turaiyur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f096',
    },
    state: 'Tamil Nadu',
    district: 'Tiruchirappalli',
    city: 'Uppiliapuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f097',
    },
    state: 'Tamil Nadu',
    district: 'Tiruchirappalli',
    city: 'Vaiyampatti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f098',
    },
    state: 'Tamil Nadu',
    district: 'Tirunelveli',
    city: 'Ambasamudram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f099',
    },
    state: 'Tamil Nadu',
    district: 'Tirunelveli',
    city: 'Cheranmahadevi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f09a',
    },
    state: 'Tamil Nadu',
    district: 'Tirunelveli',
    city: 'Kalakadu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f09b',
    },
    state: 'Tamil Nadu',
    district: 'Tirunelveli',
    city: 'Manur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f09c',
    },
    state: 'Tamil Nadu',
    district: 'Tirunelveli',
    city: 'Nanguneri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f09d',
    },
    state: 'Tamil Nadu',
    district: 'Tirunelveli',
    city: 'Palayamkottai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f09e',
    },
    state: 'Tamil Nadu',
    district: 'Tirunelveli',
    city: 'Pappakudi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f09f',
    },
    state: 'Tamil Nadu',
    district: 'Tirunelveli',
    city: 'Radhapuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0a0',
    },
    state: 'Tamil Nadu',
    district: 'Tirunelveli',
    city: 'Valliyoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0a1',
    },
    state: 'Tamil Nadu',
    district: 'Tirupathur',
    city: 'Alangayan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0a2',
    },
    state: 'Tamil Nadu',
    district: 'Tirupathur',
    city: 'Jolarpet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0a3',
    },
    state: 'Tamil Nadu',
    district: 'Tirupathur',
    city: 'Kandili',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0a4',
    },
    state: 'Tamil Nadu',
    district: 'Tirupathur',
    city: 'Madhanur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0a5',
    },
    state: 'Tamil Nadu',
    district: 'Tirupathur',
    city: 'Natrampalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0a6',
    },
    state: 'Tamil Nadu',
    district: 'Tirupathur',
    city: 'Tiruppattur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0a7',
    },
    state: 'Tamil Nadu',
    district: 'Tiruppur',
    city: 'Avanashi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0a8',
    },
    state: 'Tamil Nadu',
    district: 'Tiruppur',
    city: 'Dharapuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0a9',
    },
    state: 'Tamil Nadu',
    district: 'Tiruppur',
    city: 'Gudimangalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0aa',
    },
    state: 'Tamil Nadu',
    district: 'Tiruppur',
    city: 'Kangayam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0ab',
    },
    state: 'Tamil Nadu',
    district: 'Tiruppur',
    city: 'Kundadam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0ac',
    },
    state: 'Tamil Nadu',
    district: 'Tiruppur',
    city: 'Madathukulam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0ad',
    },
    state: 'Tamil Nadu',
    district: 'Tiruppur',
    city: 'Mulanur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0ae',
    },
    state: 'Tamil Nadu',
    district: 'Tiruppur',
    city: 'Palladam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0af',
    },
    state: 'Tamil Nadu',
    district: 'Tiruppur',
    city: 'Pongalur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0b0',
    },
    state: 'Tamil Nadu',
    district: 'Tiruppur',
    city: 'Tiruppur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0b1',
    },
    state: 'Tamil Nadu',
    district: 'Tiruppur',
    city: 'Uttukkuli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0b2',
    },
    state: 'Tamil Nadu',
    district: 'Tiruppur',
    city: 'Vellakoil',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0b3',
    },
    state: 'Tamil Nadu',
    district: 'Tiruvannamalai',
    city: 'Anakkavur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0b4',
    },
    state: 'Tamil Nadu',
    district: 'Tiruvannamalai',
    city: 'Arani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0b5',
    },
    state: 'Tamil Nadu',
    district: 'Tiruvannamalai',
    city: 'Chengam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0b6',
    },
    state: 'Tamil Nadu',
    district: 'Tiruvannamalai',
    city: 'Chetput',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0b7',
    },
    state: 'Tamil Nadu',
    district: 'Tiruvannamalai',
    city: 'Cheyyar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0b8',
    },
    state: 'Tamil Nadu',
    district: 'Tiruvannamalai',
    city: 'Jawathu Hills',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0b9',
    },
    state: 'Tamil Nadu',
    district: 'Tiruvannamalai',
    city: 'Kalasapakkam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0ba',
    },
    state: 'Tamil Nadu',
    district: 'Tiruvannamalai',
    city: 'Keelpennathur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0bb',
    },
    state: 'Tamil Nadu',
    district: 'Tiruvannamalai',
    city: 'Pernamallur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0bc',
    },
    state: 'Tamil Nadu',
    district: 'Tiruvannamalai',
    city: 'Polur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0bd',
    },
    state: 'Tamil Nadu',
    district: 'Tiruvannamalai',
    city: 'Pudupalayam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0be',
    },
    state: 'Tamil Nadu',
    district: 'Tiruvannamalai',
    city: 'Thandrampet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0bf',
    },
    state: 'Tamil Nadu',
    district: 'Tiruvannamalai',
    city: 'Thellar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0c0',
    },
    state: 'Tamil Nadu',
    district: 'Tiruvannamalai',
    city: 'Thurinjapuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0c1',
    },
    state: 'Tamil Nadu',
    district: 'Tiruppur',
    city: 'Udumalpet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0c2',
    },
    state: 'Tamil Nadu',
    district: 'Tiruvannamalai',
    city: 'Vandavasi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0c3',
    },
    state: 'Tamil Nadu',
    district: 'Tiruvannamalai',
    city: 'Vembakkam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0c4',
    },
    state: 'Tamil Nadu',
    district: 'Tiruvannamalai',
    city: 'West Arani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0c5',
    },
    state: 'Tamil Nadu',
    district: 'Tuticorin',
    city: 'Alwarthirunagari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0c6',
    },
    state: 'Tamil Nadu',
    district: 'Tuticorin',
    city: 'Karungulam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0c7',
    },
    state: 'Tamil Nadu',
    district: 'Tiruvannamalai',
    city: 'Tiruvannamalai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0c8',
    },
    state: 'Tamil Nadu',
    district: 'Tuticorin',
    city: 'Kayathar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0c9',
    },
    state: 'Tamil Nadu',
    district: 'Tuticorin',
    city: 'Kovilpatti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0ca',
    },
    state: 'Tamil Nadu',
    district: 'Tuticorin',
    city: 'Ottapidaram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0cb',
    },
    state: 'Tamil Nadu',
    district: 'Tuticorin',
    city: 'Sattankulam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0cc',
    },
    state: 'Tamil Nadu',
    district: 'Tuticorin',
    city: 'Pudur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0cd',
    },
    state: 'Tamil Nadu',
    district: 'Tuticorin',
    city: 'Srivaikundam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0ce',
    },
    state: 'Tamil Nadu',
    district: 'Tuticorin',
    city: 'Thoothukkudi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0cf',
    },
    state: 'Tamil Nadu',
    district: 'Tuticorin',
    city: 'Tiruchendur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0d0',
    },
    state: 'Tamil Nadu',
    district: 'Tuticorin',
    city: 'Udangudi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0d1',
    },
    state: 'Tamil Nadu',
    district: 'Tuticorin',
    city: 'Vilathikulam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0d2',
    },
    state: 'Tamil Nadu',
    district: 'Vellore',
    city: 'Anaicut',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0d3',
    },
    state: 'Tamil Nadu',
    district: 'Vellore',
    city: 'Gudiyattam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0d4',
    },
    state: 'Tamil Nadu',
    district: 'Vellore',
    city: 'Kaniyambadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0d5',
    },
    state: 'Tamil Nadu',
    district: 'Vellore',
    city: 'Katpadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0d6',
    },
    state: 'Tamil Nadu',
    district: 'Vellore',
    city: 'K.V.Kuppam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0d7',
    },
    state: 'Tamil Nadu',
    district: 'Vellore',
    city: 'Peranambattu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0d8',
    },
    state: 'Tamil Nadu',
    district: 'Vellore',
    city: 'Vellore',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0d9',
    },
    state: 'Tamil Nadu',
    district: 'Villupuram',
    city: 'Gingee',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0da',
    },
    state: 'Tamil Nadu',
    district: 'Villupuram',
    city: 'Kanai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0db',
    },
    state: 'Tamil Nadu',
    district: 'Villupuram',
    city: 'Kandamangalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0dc',
    },
    state: 'Tamil Nadu',
    district: 'Villupuram',
    city: 'Koliyanur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0dd',
    },
    state: 'Tamil Nadu',
    district: 'Villupuram',
    city: 'Marakkanam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0de',
    },
    state: 'Tamil Nadu',
    district: 'Villupuram',
    city: 'Mailam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0df',
    },
    state: 'Tamil Nadu',
    district: 'Villupuram',
    city: 'Mugaiyur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0e0',
    },
    state: 'Tamil Nadu',
    district: 'Villupuram',
    city: 'Melmalayanur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0e1',
    },
    state: 'Tamil Nadu',
    district: 'Villupuram',
    city: 'Thiruvennainallur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0e2',
    },
    state: 'Tamil Nadu',
    district: 'Villupuram',
    city: 'Olakkur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0e3',
    },
    state: 'Tamil Nadu',
    district: 'Villupuram',
    city: 'Vallam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0e4',
    },
    state: 'Tamil Nadu',
    district: 'Villupuram',
    city: 'Vikravandi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0e5',
    },
    state: 'Tamil Nadu',
    district: 'Villupuram',
    city: 'Vanur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0e6',
    },
    state: 'Tamil Nadu',
    district: 'Virudhunagar',
    city: 'Aruppukottai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0e7',
    },
    state: 'Tamil Nadu',
    district: 'Virudhunagar',
    city: 'Kariapatti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0e8',
    },
    state: 'Tamil Nadu',
    district: 'Virudhunagar',
    city: 'Narikudi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0e9',
    },
    state: 'Tamil Nadu',
    district: 'Virudhunagar',
    city: 'Rajapalaiyam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0ea',
    },
    state: 'Tamil Nadu',
    district: 'Virudhunagar',
    city: 'Sattur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0eb',
    },
    state: 'Tamil Nadu',
    district: 'Virudhunagar',
    city: 'Sivakasi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0ec',
    },
    state: 'Tamil Nadu',
    district: 'Virudhunagar',
    city: 'Srivilliputtur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0ed',
    },
    state: 'Tamil Nadu',
    district: 'Virudhunagar',
    city: 'Tiruchuli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0ee',
    },
    state: 'Tamil Nadu',
    district: 'Virudhunagar',
    city: 'Vembakottai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0ef',
    },
    state: 'Tamil Nadu',
    district: 'Virudhunagar',
    city: 'Virudhunagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0f0',
    },
    state: 'Tamil Nadu',
    district: 'Virudhunagar',
    city: 'Watrap',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0f1',
    },
    state: 'Telangana',
    district: 'Adilabad',
    city: 'Adilabad Urban',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0f2',
    },
    state: 'Telangana',
    district: 'Adilabad',
    city: 'Aidilabad Rural',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0f3',
    },
    state: 'Telangana',
    district: 'Adilabad',
    city: 'Bazarhatnoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0f4',
    },
    state: 'Telangana',
    district: 'Adilabad',
    city: 'Bela',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0f5',
    },
    state: 'Telangana',
    district: 'Adilabad',
    city: 'Bheempur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0f6',
    },
    state: 'Telangana',
    district: 'Adilabad',
    city: 'Boath',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0f7',
    },
    state: 'Telangana',
    district: 'Adilabad',
    city: 'Gadiguda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0f8',
    },
    state: 'Telangana',
    district: 'Adilabad',
    city: 'Gudihatnur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0f9',
    },
    state: 'Telangana',
    district: 'Adilabad',
    city: 'Ichoda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0fa',
    },
    state: 'Telangana',
    district: 'Adilabad',
    city: 'Jainath',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0fb',
    },
    state: 'Telangana',
    district: 'Adilabad',
    city: 'Indervelly',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0fc',
    },
    state: 'Telangana',
    district: 'Adilabad',
    city: 'Mavala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0fd',
    },
    state: 'Telangana',
    district: 'Adilabad',
    city: 'Neradigonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0fe',
    },
    state: 'Telangana',
    district: 'Adilabad',
    city: 'Narnoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f0ff',
    },
    state: 'Telangana',
    district: 'Adilabad',
    city: 'Sirikonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f100',
    },
    state: 'Telangana',
    district: 'Adilabad',
    city: 'Talamadugu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f101',
    },
    state: 'Telangana',
    district: 'Adilabad',
    city: 'Tamsi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f102',
    },
    state: 'Telangana',
    district: 'Adilabad',
    city: 'Utnoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f103',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Allapalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f104',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Annapureddypalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f105',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Aswapuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f106',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Aswaraopeta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f107',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Bhadrachalam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f108',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Chandrugonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f109',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Burgampahad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f10a',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Cherla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f10b',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Chunchupalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f10c',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Dammapeta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f10d',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Dummugudem',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f10e',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Gundala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f10f',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Julurupadu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f110',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Karakagudem',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f111',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Kothagudem',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f112',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Laxmidevipalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f113',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Manugur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f114',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Mulakalapally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f115',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Palwancha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f116',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Pinapaka',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f117',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Sujathanagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f118',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Tekulapally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f119',
    },
    state: 'Telangana',
    district: 'Bhadradri Kothagudem',
    city: 'Yellandu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f11a',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Amberpet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f11b',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Asifnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f11c',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Bahadurpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f11d',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Bandlaguda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f11e',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Charminar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f11f',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Golconda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f120',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Himayathnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f121',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Khairthabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f122',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Marredpally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f123',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Musheerabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f124',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Nampally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f125',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Saidabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f126',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Secunderabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f127',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Shaikpet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f128',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Tirumalagiry',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f129',
    },
    state: 'Telangana',
    district: 'Jagitial',
    city: 'Beerpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f12a',
    },
    state: 'Telangana',
    district: 'Jagitial',
    city: 'Buggaram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f12b',
    },
    state: 'Telangana',
    district: 'Jagitial',
    city: 'Dharmapuri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f12c',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Ameerpet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f12d',
    },
    state: 'Telangana',
    district: 'Jagitial',
    city: 'Gollapalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f12e',
    },
    state: 'Telangana',
    district: 'Jagitial',
    city: 'Ibrahimpatnam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f12f',
    },
    state: 'Telangana',
    district: 'Jagitial',
    city: 'Jagitial Rural',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f130',
    },
    state: 'Telangana',
    district: 'Jagitial',
    city: 'Jagtial',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f131',
    },
    state: 'Telangana',
    district: 'Jagitial',
    city: 'Kodimial',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f132',
    },
    state: 'Telangana',
    district: 'Jagitial',
    city: 'Korutla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f133',
    },
    state: 'Telangana',
    district: 'Jagitial',
    city: 'Mallapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f134',
    },
    state: 'Telangana',
    district: 'Jagitial',
    city: 'Mallial',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f135',
    },
    state: 'Telangana',
    district: 'Jagitial',
    city: 'Kathalapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f136',
    },
    state: 'Telangana',
    district: 'Jagitial',
    city: 'Metpalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f137',
    },
    state: 'Telangana',
    district: 'Jagitial',
    city: 'Medipalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f138',
    },
    state: 'Telangana',
    district: 'Jagitial',
    city: 'Pegadapalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f139',
    },
    state: 'Telangana',
    district: 'Jagitial',
    city: 'Raikal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f13a',
    },
    state: 'Telangana',
    district: 'Jagitial',
    city: 'Sarangapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f13b',
    },
    state: 'Telangana',
    district: 'Jagitial',
    city: 'Velgatoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f13c',
    },
    state: 'Telangana',
    district: 'Jangaon',
    city: 'Bachannapeta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f13d',
    },
    state: 'Telangana',
    district: 'Jangaon',
    city: 'Chilpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f13e',
    },
    state: 'Telangana',
    district: 'Jangaon',
    city: 'Devaruppula',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f13f',
    },
    state: 'Telangana',
    district: 'Jangaon',
    city: 'Ghanpur(Station)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f140',
    },
    state: 'Telangana',
    district: 'Jangaon',
    city: 'Jangaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f141',
    },
    state: 'Telangana',
    district: 'Jangaon',
    city: 'Kodakandla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f142',
    },
    state: 'Telangana',
    district: 'Jangaon',
    city: 'Lingalaghanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f143',
    },
    state: 'Telangana',
    district: 'Jangaon',
    city: 'Palakurthi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f144',
    },
    state: 'Telangana',
    district: 'Jangaon',
    city: 'Raghunathpalle',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f145',
    },
    state: 'Telangana',
    district: 'Jangaon',
    city: 'Tharigoppula',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f146',
    },
    state: 'Telangana',
    district: 'Jangaon',
    city: 'Zaffergadh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f147',
    },
    state: 'Telangana',
    district: 'Jayashankar Bhupalapally',
    city: 'Bhupalpalle',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f148',
    },
    state: 'Telangana',
    district: 'Jangaon',
    city: 'Narmetta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f149',
    },
    state: 'Telangana',
    district: 'Jayashankar Bhupalapally',
    city: 'Ghanapur (Mulug)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f14a',
    },
    state: 'Telangana',
    district: 'Jayashankar Bhupalapally',
    city: 'Kataram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f14b',
    },
    state: 'Telangana',
    district: 'Jayashankar Bhupalapally',
    city: 'Chityal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f14c',
    },
    state: 'Telangana',
    district: 'Jayashankar Bhupalapally',
    city: 'Mahadevpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f14d',
    },
    state: 'Telangana',
    district: 'Jayashankar Bhupalapally',
    city: 'Malhar Rao',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f14e',
    },
    state: 'Telangana',
    district: 'Jayashankar Bhupalapally',
    city: 'Mogullapalle',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f14f',
    },
    state: 'Telangana',
    district: 'Jayashankar Bhupalapally',
    city: 'Palimela',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f150',
    },
    state: 'Telangana',
    district: 'Jayashankar Bhupalapally',
    city: 'Regonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f151',
    },
    state: 'Telangana',
    district: 'Jayashankar Bhupalapally',
    city: 'Tekumatla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f152',
    },
    state: 'Telangana',
    district: 'Jogulamba Gadwal',
    city: 'Alampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f153',
    },
    state: 'Telangana',
    district: 'Jayashankar Bhupalapally',
    city: 'Mutharam (Mahadevpur)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f154',
    },
    state: 'Telangana',
    district: 'Jogulamba Gadwal',
    city: 'Gadwal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f155',
    },
    state: 'Telangana',
    district: 'Jogulamba Gadwal',
    city: 'Ghattu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f156',
    },
    state: 'Telangana',
    district: 'Jogulamba Gadwal',
    city: 'Ieeja',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f157',
    },
    state: 'Telangana',
    district: 'Jogulamba Gadwal',
    city: 'Itikyal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f158',
    },
    state: 'Telangana',
    district: 'Jogulamba Gadwal',
    city: 'Kaloor Thimmandoddi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f159',
    },
    state: 'Telangana',
    district: 'Jogulamba Gadwal',
    city: 'Maldakal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f15a',
    },
    state: 'Telangana',
    district: 'Jogulamba Gadwal',
    city: 'Manopadu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f15b',
    },
    state: 'Telangana',
    district: 'Jogulamba Gadwal',
    city: 'Rajoli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f15c',
    },
    state: 'Telangana',
    district: 'Jogulamba Gadwal',
    city: 'Undavelly',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f15d',
    },
    state: 'Telangana',
    district: 'Jogulamba Gadwal',
    city: 'Waddepalle',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f15e',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Banswada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f15f',
    },
    state: 'Telangana',
    district: 'Jogulamba Gadwal',
    city: 'Dharur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f160',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Bhiknur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f161',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Bichkunda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f162',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Birkoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f163',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Domakonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f164',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Gandhari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f165',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Jukkal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f166',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Kamareddy',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f167',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Lingampet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f168',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Machareddy',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f169',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Madnur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f16a',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Nagireddypet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f16b',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Nasurullabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f16c',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Nizamsagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f16d',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Pedda Kodapgal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f16e',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Pitlam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f16f',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Bibipet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f170',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Rajampet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f171',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Ramareddy',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f172',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Tadwai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f173',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Yellareddy',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f174',
    },
    state: 'Telangana',
    district: 'Karimnagar',
    city: 'Chigurumamidi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f175',
    },
    state: 'Telangana',
    district: 'Karimnagar',
    city: 'Choppadandi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f176',
    },
    state: 'Telangana',
    district: 'Kamareddy',
    city: 'Sadasivanagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f177',
    },
    state: 'Telangana',
    district: 'Karimnagar',
    city: 'Ellandakunta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f178',
    },
    state: 'Telangana',
    district: 'Karimnagar',
    city: 'Gangadhara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f179',
    },
    state: 'Telangana',
    district: 'Karimnagar',
    city: 'Ganneruvaram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f17a',
    },
    state: 'Telangana',
    district: 'Karimnagar',
    city: 'Huzurabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f17b',
    },
    state: 'Telangana',
    district: 'Karimnagar',
    city: 'Karimnagar Rural',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f17c',
    },
    state: 'Telangana',
    district: 'Karimnagar',
    city: 'Jammikunta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f17d',
    },
    state: 'Telangana',
    district: 'Karimnagar',
    city: 'Karimnagar Rural I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f17e',
    },
    state: 'Telangana',
    district: 'Karimnagar',
    city: 'Kothapally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f17f',
    },
    state: 'Telangana',
    district: 'Karimnagar',
    city: 'Manakondur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f180',
    },
    state: 'Telangana',
    district: 'Karimnagar',
    city: 'Ramadugu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f181',
    },
    state: 'Telangana',
    district: 'Karimnagar',
    city: 'Shankarapatnam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f182',
    },
    state: 'Telangana',
    district: 'Karimnagar',
    city: 'Thimmapur (L.M.D.)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f183',
    },
    state: 'Telangana',
    district: 'Karimnagar',
    city: 'Veenavanka',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f184',
    },
    state: 'Telangana',
    district: 'Karimnagar',
    city: 'V. Saidapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f185',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Bonakal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f186',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Enkoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f187',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Chinthakani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f188',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Kallur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f189',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Kamepally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f18a',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Khammam (Rural)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f18b',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Khammam (Urban)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f18c',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Konijerla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f18d',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Kusumanchi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f18e',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Madhira',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f18f',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Mudigonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f190',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Nelakondapally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f191',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Penubally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f192',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Raghunadhapalem',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f193',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Sathupally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f194',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Singareni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f195',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Thallada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f196',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Thirumalayapalem',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f197',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Vemsoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f198',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Wyra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f199',
    },
    state: 'Telangana',
    district: 'Khammam',
    city: 'Yerrupalem',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f19a',
    },
    state: 'Telangana',
    district: 'Komaram Bheem',
    city: 'Asifabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f19b',
    },
    state: 'Telangana',
    district: 'Komaram Bheem',
    city: 'Bejjur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f19c',
    },
    state: 'Telangana',
    district: 'Komaram Bheem',
    city: 'Chintalamanepally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f19d',
    },
    state: 'Telangana',
    district: 'Komaram Bheem',
    city: 'Dahegaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f19e',
    },
    state: 'Telangana',
    district: 'Komaram Bheem',
    city: 'Jainoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f19f',
    },
    state: 'Telangana',
    district: 'Komaram Bheem',
    city: 'Kagaznagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1a0',
    },
    state: 'Telangana',
    district: 'Komaram Bheem',
    city: 'Kouthala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1a1',
    },
    state: 'Telangana',
    district: 'Komaram Bheem',
    city: 'Kerameri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1a2',
    },
    state: 'Telangana',
    district: 'Komaram Bheem',
    city: 'Lingapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1a3',
    },
    state: 'Telangana',
    district: 'Komaram Bheem',
    city: 'Penchicalpet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1a4',
    },
    state: 'Telangana',
    district: 'Komaram Bheem',
    city: 'Sirpur (T)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1a5',
    },
    state: 'Telangana',
    district: 'Komaram Bheem',
    city: 'Rebbena',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1a6',
    },
    state: 'Telangana',
    district: 'Komaram Bheem',
    city: 'Sirpur (U)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1a7',
    },
    state: 'Telangana',
    district: 'Komaram Bheem',
    city: 'Tiryani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1a8',
    },
    state: 'Telangana',
    district: 'Komaram Bheem',
    city: 'Wankidi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1a9',
    },
    state: 'Telangana',
    district: 'Mahabubabad',
    city: 'Bayyaram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1aa',
    },
    state: 'Telangana',
    district: 'Mahabubabad',
    city: 'Chinnagudur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1ab',
    },
    state: 'Telangana',
    district: 'Mahabubabad',
    city: 'Danthalapalle',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1ac',
    },
    state: 'Telangana',
    district: 'Mahabubabad',
    city: 'Dornakal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1ad',
    },
    state: 'Telangana',
    district: 'Mahabubabad',
    city: 'Gangaram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1ae',
    },
    state: 'Telangana',
    district: 'Mahabubabad',
    city: 'Garla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1af',
    },
    state: 'Telangana',
    district: 'Mahabubabad',
    city: 'Gudur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1b0',
    },
    state: 'Telangana',
    district: 'Mahabubabad',
    city: 'Kesamudram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1b1',
    },
    state: 'Telangana',
    district: 'Mahabubabad',
    city: 'Kothagudem',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1b2',
    },
    state: 'Telangana',
    district: 'Mahabubabad',
    city: 'Kuravi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1b3',
    },
    state: 'Telangana',
    district: 'Mahabubabad',
    city: 'Mahbubabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1b4',
    },
    state: 'Telangana',
    district: 'Mahabubabad',
    city: 'Nallikudur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1b5',
    },
    state: 'Telangana',
    district: 'Mahabubabad',
    city: 'Maripeda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1b6',
    },
    state: 'Telangana',
    district: 'Mahabubabad',
    city: 'Narsimhulapet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1b7',
    },
    state: 'Telangana',
    district: 'Mahabubabad',
    city: 'Peddavangara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1b8',
    },
    state: 'Telangana',
    district: 'Mahbubnagar',
    city: 'Addakal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1b9',
    },
    state: 'Telangana',
    district: 'Mahabubabad',
    city: 'Thorrur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1ba',
    },
    state: 'Telangana',
    district: 'Mahbubnagar',
    city: 'Balanagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1bb',
    },
    state: 'Telangana',
    district: 'Mahbubnagar',
    city: 'Bhoothpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1bc',
    },
    state: 'Telangana',
    district: 'Mahbubnagar',
    city: 'Chinnachintakunta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1bd',
    },
    state: 'Telangana',
    district: 'Mahbubnagar',
    city: 'Devarakadara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1be',
    },
    state: 'Telangana',
    district: 'Mahbubnagar',
    city: 'Gandeed',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1bf',
    },
    state: 'Telangana',
    district: 'Mahbubnagar',
    city: 'Hanwada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1c0',
    },
    state: 'Telangana',
    district: 'Mahbubnagar',
    city: 'Jadcherla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1c1',
    },
    state: 'Telangana',
    district: 'Mahbubnagar',
    city: 'Koilkonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1c2',
    },
    state: 'Telangana',
    district: 'Mahbubnagar',
    city: 'Mahbubnagar Rural',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1c3',
    },
    state: 'Telangana',
    district: 'Mahbubnagar',
    city: 'Mahabubnagar Urban',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1c4',
    },
    state: 'Telangana',
    district: 'Mahbubnagar',
    city: 'Midjil',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1c5',
    },
    state: 'Telangana',
    district: 'Mahbubnagar',
    city: 'Moosapet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1c6',
    },
    state: 'Telangana',
    district: 'Mahbubnagar',
    city: 'Nawabpet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1c7',
    },
    state: 'Telangana',
    district: 'Mahbubnagar',
    city: 'Rajapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1c8',
    },
    state: 'Telangana',
    district: 'Mancherial',
    city: 'Bellampally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1c9',
    },
    state: 'Telangana',
    district: 'Mancherial',
    city: 'Bheemini',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1ca',
    },
    state: 'Telangana',
    district: 'Mancherial',
    city: 'Chennur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1cb',
    },
    state: 'Telangana',
    district: 'Mancherial',
    city: 'Bheemaram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1cc',
    },
    state: 'Telangana',
    district: 'Mancherial',
    city: 'Hajipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1cd',
    },
    state: 'Telangana',
    district: 'Mancherial',
    city: 'Jaipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1ce',
    },
    state: 'Telangana',
    district: 'Mancherial',
    city: 'Jannaram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1cf',
    },
    state: 'Telangana',
    district: 'Mancherial',
    city: 'Kannepally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1d0',
    },
    state: 'Telangana',
    district: 'Mancherial',
    city: 'Kasipet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1d1',
    },
    state: 'Telangana',
    district: 'Mancherial',
    city: 'Kotapally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1d2',
    },
    state: 'Telangana',
    district: 'Mancherial',
    city: 'Luxettipet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1d3',
    },
    state: 'Telangana',
    district: 'Mancherial',
    city: 'Mancherial',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1d4',
    },
    state: 'Telangana',
    district: 'Mancherial',
    city: 'Mandamarri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1d5',
    },
    state: 'Telangana',
    district: 'Mancherial',
    city: 'Dandepally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1d6',
    },
    state: 'Telangana',
    district: 'Mancherial',
    city: 'Naspur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1d7',
    },
    state: 'Telangana',
    district: 'Mancherial',
    city: 'Nennel',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1d8',
    },
    state: 'Telangana',
    district: 'Mancherial',
    city: 'Tandur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1d9',
    },
    state: 'Telangana',
    district: 'Medak',
    city: 'Alladurg',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1da',
    },
    state: 'Telangana',
    district: 'Medak',
    city: 'Chegunta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1db',
    },
    state: 'Telangana',
    district: 'Medak',
    city: 'Chilpched',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1dc',
    },
    state: 'Telangana',
    district: 'Mancherial',
    city: 'Vemanpally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1dd',
    },
    state: 'Telangana',
    district: 'Medak',
    city: 'Havelighanapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1de',
    },
    state: 'Telangana',
    district: 'Medak',
    city: 'Kowdipalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1df',
    },
    state: 'Telangana',
    district: 'Medak',
    city: 'Kulcharam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1e0',
    },
    state: 'Telangana',
    district: 'Medak',
    city: 'Medak',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1e1',
    },
    state: 'Telangana',
    district: 'Medak',
    city: 'Manoharabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1e2',
    },
    state: 'Telangana',
    district: 'Medak',
    city: 'Narsapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1e3',
    },
    state: 'Telangana',
    district: 'Medak',
    city: 'Nizampet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1e4',
    },
    state: 'Telangana',
    district: 'Medak',
    city: 'Papannapet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1e5',
    },
    state: 'Telangana',
    district: 'Medak',
    city: 'Narsingi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1e6',
    },
    state: 'Telangana',
    district: 'Medak',
    city: 'Ramayampet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1e7',
    },
    state: 'Telangana',
    district: 'Medak',
    city: 'Regode',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1e8',
    },
    state: 'Telangana',
    district: 'Medak',
    city: 'Shankarampet[A]',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1e9',
    },
    state: 'Telangana',
    district: 'Medak',
    city: 'Shivampet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1ea',
    },
    state: 'Telangana',
    district: 'Medak',
    city: 'Tekmal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1eb',
    },
    state: 'Telangana',
    district: 'Medak',
    city: 'Shankarampet[R]',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1ec',
    },
    state: 'Telangana',
    district: 'Medak',
    city: 'Tupran',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1ed',
    },
    state: 'Telangana',
    district: 'Medak',
    city: 'Yeldurthy',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1ee',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Alwal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1ef',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Balanagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1f0',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Bachupally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1f1',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Dundigal Gandimaisamma',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1f2',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Ghatkesar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1f3',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Kapra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1f4',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Keesara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1f5',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Kukatpally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1f6',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Malkajgiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1f7',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Medchal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1f8',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Medipally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1f9',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Quthbullapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1fa',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Muduchinthalapally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1fb',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Shamirpet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1fc',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Uppal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1fd',
    },
    state: 'Telangana',
    district: 'Mulugu',
    city: 'Eturnagaram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1fe',
    },
    state: 'Telangana',
    district: 'Mulugu',
    city: 'Govindaraopet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f1ff',
    },
    state: 'Telangana',
    district: 'Mulugu',
    city: 'Kannaigudem',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f200',
    },
    state: 'Telangana',
    district: 'Mulugu',
    city: 'Mangapet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f201',
    },
    state: 'Telangana',
    district: 'Mulugu',
    city: 'Mulug',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f202',
    },
    state: 'Telangana',
    district: 'Mulugu',
    city: 'Tadvai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f203',
    },
    state: 'Telangana',
    district: 'Mulugu',
    city: 'Venkatapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f204',
    },
    state: 'Telangana',
    district: 'Mulugu',
    city: 'Wazeed',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f205',
    },
    state: 'Telangana',
    district: 'Mulugu',
    city: 'Venkatapuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f206',
    },
    state: 'Telangana',
    district: 'Nagarkurnool',
    city: 'Achampeta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f207',
    },
    state: 'Telangana',
    district: 'Nagarkurnool',
    city: 'Amrabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f208',
    },
    state: 'Telangana',
    district: 'Nagarkurnool',
    city: 'Balmoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f209',
    },
    state: 'Telangana',
    district: 'Nagarkurnool',
    city: 'Bijinapalle',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f20a',
    },
    state: 'Telangana',
    district: 'Nagarkurnool',
    city: 'Charakonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f20b',
    },
    state: 'Telangana',
    district: 'Nagarkurnool',
    city: 'Kalwakurthy',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f20c',
    },
    state: 'Telangana',
    district: 'Nagarkurnool',
    city: 'Kodair',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f20d',
    },
    state: 'Telangana',
    district: 'Nagarkurnool',
    city: 'Kollapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f20e',
    },
    state: 'Telangana',
    district: 'Nagarkurnool',
    city: 'Lingal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f20f',
    },
    state: 'Telangana',
    district: 'Nagarkurnool',
    city: 'Nagarkurnool',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f210',
    },
    state: 'Telangana',
    district: 'Nagarkurnool',
    city: 'Padara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f211',
    },
    state: 'Telangana',
    district: 'Nagarkurnool',
    city: 'Pentlavelli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f212',
    },
    state: 'Telangana',
    district: 'Nagarkurnool',
    city: 'Peddakothapalle',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f213',
    },
    state: 'Telangana',
    district: 'Nagarkurnool',
    city: 'Telkapalle',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f214',
    },
    state: 'Telangana',
    district: 'Nagarkurnool',
    city: 'Tadoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f215',
    },
    state: 'Telangana',
    district: 'Nagarkurnool',
    city: 'Thimmajipeta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f216',
    },
    state: 'Telangana',
    district: 'Nagarkurnool',
    city: 'Uppununthala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f217',
    },
    state: 'Telangana',
    district: 'Nagarkurnool',
    city: 'Urkonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f218',
    },
    state: 'Telangana',
    district: 'Nagarkurnool',
    city: 'Vangoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f219',
    },
    state: 'Telangana',
    district: 'Nagarkurnool',
    city: 'Veldanda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f21a',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Adavidevulapalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f21b',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Anumula',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f21c',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Chandampet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f21d',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Chandur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f21e',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Chintha Pally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f21f',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Chityala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f220',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Dameracherla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f221',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Devarakonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f222',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Gundlapally (Dindi)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f223',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Gurrampode',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f224',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Kanagal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f225',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Kattangoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f226',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Kethe Pally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f227',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Konda Mallepally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f228',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Madugulapally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f229',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Marriguda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f22a',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Miryalaguda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f22b',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Munugode',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f22c',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Nakrekal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f22d',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Nalgonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f22e',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Nampally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f22f',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Narketpally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f230',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Neredugommu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f231',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Nidamanoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f232',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Pedda Adiserlapally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f233',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Peddavoora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f234',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Shali Gouraram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f235',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Thipparthi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f236',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Tirumalagiri Sagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f237',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Tripuraram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f238',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Vemula Pally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f239',
    },
    state: 'Telangana',
    district: 'Narayanpet',
    city: 'Damaragidda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f23a',
    },
    state: 'Telangana',
    district: 'Narayanpet',
    city: 'Dhanwada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f23b',
    },
    state: 'Telangana',
    district: 'Narayanpet',
    city: 'Kosgi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f23c',
    },
    state: 'Telangana',
    district: 'Narayanpet',
    city: 'Krishna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f23d',
    },
    state: 'Telangana',
    district: 'Narayanpet',
    city: 'Maddur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f23e',
    },
    state: 'Telangana',
    district: 'Narayanpet',
    city: 'Maganoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f23f',
    },
    state: 'Telangana',
    district: 'Narayanpet',
    city: 'Makthal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f240',
    },
    state: 'Telangana',
    district: 'Narayanpet',
    city: 'Marikal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f241',
    },
    state: 'Telangana',
    district: 'Narayanpet',
    city: 'Narayanpet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f242',
    },
    state: 'Telangana',
    district: 'Narayanpet',
    city: 'Narva',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f243',
    },
    state: 'Telangana',
    district: 'Narayanpet',
    city: 'Utkoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f244',
    },
    state: 'Telangana',
    district: 'Nirmal',
    city: 'Basar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f245',
    },
    state: 'Telangana',
    district: 'Nirmal',
    city: 'Dasturabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f246',
    },
    state: 'Telangana',
    district: 'Nirmal',
    city: 'Bhainsa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f247',
    },
    state: 'Telangana',
    district: 'Nirmal',
    city: 'Dilawarpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f248',
    },
    state: 'Telangana',
    district: 'Nirmal',
    city: 'Kaddam (Peddur)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f249',
    },
    state: 'Telangana',
    district: 'Nirmal',
    city: 'Khanapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f24a',
    },
    state: 'Telangana',
    district: 'Nirmal',
    city: 'Kubeer',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f24b',
    },
    state: 'Telangana',
    district: 'Nirmal',
    city: 'Kuntala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f24c',
    },
    state: 'Telangana',
    district: 'Nirmal',
    city: 'Laxmanchanda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f24d',
    },
    state: 'Telangana',
    district: 'Nirmal',
    city: 'Lokeswaram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f24e',
    },
    state: 'Telangana',
    district: 'Nirmal',
    city: 'Mamda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f24f',
    },
    state: 'Telangana',
    district: 'Nirmal',
    city: 'Mudhole',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f250',
    },
    state: 'Telangana',
    district: 'Nirmal',
    city: 'Narsapur G',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f251',
    },
    state: 'Telangana',
    district: 'Nirmal',
    city: 'Nirmal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f252',
    },
    state: 'Telangana',
    district: 'Nirmal',
    city: 'Nirmal Rural',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f253',
    },
    state: 'Telangana',
    district: 'Nirmal',
    city: 'Pembi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f254',
    },
    state: 'Telangana',
    district: 'Nirmal',
    city: 'Sarangapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f255',
    },
    state: 'Telangana',
    district: 'Nirmal',
    city: 'Soan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f256',
    },
    state: 'Telangana',
    district: 'Nirmal',
    city: 'Tanur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f257',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Armur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f258',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Balkonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f259',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Bheemgal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f25a',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Bodhan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f25b',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Chandur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f25c',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Dharpalle',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f25d',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Dichpalle',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f25e',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Jakranpalle',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f25f',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Indalwai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f260',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Kotgiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f261',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Kammarapalle',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f262',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Makloor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f263',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Mortad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f264',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Mendora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f265',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Mosara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f266',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Mugpal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f267',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Mupkal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f268',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Nandipet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f269',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Navipet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f26a',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Nizamabad North',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f26b',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Nizamabad Rural',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f26c',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Nizamabad South',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f26d',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Renjal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f26e',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Rudrur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f26f',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Sirkonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f270',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Varni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f271',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Velpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f272',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Yedapalle',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f273',
    },
    state: 'Telangana',
    district: 'Nizamabad',
    city: 'Yergatla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f274',
    },
    state: 'Telangana',
    district: 'Peddapalli',
    city: 'Anthergaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f275',
    },
    state: 'Telangana',
    district: 'Peddapalli',
    city: 'Dharmaram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f276',
    },
    state: 'Telangana',
    district: 'Peddapalli',
    city: 'Eligaid',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f277',
    },
    state: 'Telangana',
    district: 'Peddapalli',
    city: 'Julapalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f278',
    },
    state: 'Telangana',
    district: 'Peddapalli',
    city: 'Kamanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f279',
    },
    state: 'Telangana',
    district: 'Peddapalli',
    city: 'Manthani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f27a',
    },
    state: 'Telangana',
    district: 'Peddapalli',
    city: 'Mutharam (Manthani)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f27b',
    },
    state: 'Telangana',
    district: 'Peddapalli',
    city: 'Odela',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f27c',
    },
    state: 'Telangana',
    district: 'Peddapalli',
    city: 'Peddapalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f27d',
    },
    state: 'Telangana',
    district: 'Peddapalli',
    city: 'Ramagiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f27e',
    },
    state: 'Telangana',
    district: 'Peddapalli',
    city: 'Ramagundam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f27f',
    },
    state: 'Telangana',
    district: 'Peddapalli',
    city: 'Srirampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f280',
    },
    state: 'Telangana',
    district: 'Peddapalli',
    city: 'Sulthanabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f281',
    },
    state: 'Telangana',
    district: 'Rajanna Sircilla',
    city: 'Boinpalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f282',
    },
    state: 'Telangana',
    district: 'Rajanna Sircilla',
    city: 'Chandurthi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f283',
    },
    state: 'Telangana',
    district: 'Rajanna Sircilla',
    city: 'Ellanthakunta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f284',
    },
    state: 'Telangana',
    district: 'Rajanna Sircilla',
    city: 'Gambhiraopet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f285',
    },
    state: 'Telangana',
    district: 'Rajanna Sircilla',
    city: 'Konaraopet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f286',
    },
    state: 'Telangana',
    district: 'Rajanna Sircilla',
    city: 'Mustabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f287',
    },
    state: 'Telangana',
    district: 'Rajanna Sircilla',
    city: 'Rudrangi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f288',
    },
    state: 'Telangana',
    district: 'Rajanna Sircilla',
    city: 'Sircilla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f289',
    },
    state: 'Telangana',
    district: 'Rajanna Sircilla',
    city: 'Thangallapalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f28a',
    },
    state: 'Telangana',
    district: 'Rajanna Sircilla',
    city: 'Veernapalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f28b',
    },
    state: 'Telangana',
    district: 'Rajanna Sircilla',
    city: 'Vemulawada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f28c',
    },
    state: 'Telangana',
    district: 'Peddapalli',
    city: 'Palakurthy',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f28d',
    },
    state: 'Telangana',
    district: 'Rajanna Sircilla',
    city: 'Vemulawada Rural',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f28e',
    },
    state: 'Telangana',
    district: 'Rajanna Sircilla',
    city: 'Yellareddipet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f28f',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Abdullapurmet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f290',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Amangal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f291',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Balapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f292',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Chevella',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f293',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Farooqnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f294',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Gandipet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f295',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Hayathnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f296',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Jilled Chowdergudem',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f297',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Kadthal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f298',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Kandukur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f299',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Keshampeta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f29a',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Kondurg',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f29b',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Kothur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f29c',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Madgul',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f29d',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Maheshwaram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f29e',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Manchal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f29f',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Moinabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2a0',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Ibrahimpatnam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2a1',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Nandigama',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2a2',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Rajendranagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2a3',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Serilingampally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2a4',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Shabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2a5',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Shamshabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2a6',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Shankarpally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2a7',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Saroornagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2a8',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Talakondapalle',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2a9',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Yacharam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2aa',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Ameenpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2ab',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Andole',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2ac',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Gummadidala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2ad',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Hathnoora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2ae',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Jharasangam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2af',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Jinnaram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2b0',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Kalher',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2b1',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Kangti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2b2',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Kandi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2b3',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Kondapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2b4',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Kohir',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2b5',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Manoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2b6',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Munipally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2b7',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Nagalgidda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2b8',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Narayankhed',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2b9',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Nyalkal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2ba',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Patancheru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2bb',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Pulkal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2bc',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Raikode',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2bd',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Ramchandrapuram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2be',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Mogudampally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2bf',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Sadasivpet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2c0',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Sangareddy',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2c1',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Sirgapoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2c2',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Vatpally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2c3',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Zahirabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2c4',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Akkannapet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2c5',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Bejjanki',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2c6',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Cheriyal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2c7',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Chinnakodur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2c8',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Doultabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2c9',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Gajwel',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2ca',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Husnabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2cb',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Jagdevpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2cc',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Koheda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2cd',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Komuravelli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2ce',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Kondapak',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2cf',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Dubbak',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2d0',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Maddur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2d1',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Markook',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2d2',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Mirdoddi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2d3',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Mulug',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2d4',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Nanganoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2d5',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Raipole',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2d6',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Narayanaraopet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2d7',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Siddipet Urban',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2d8',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Siddipet Rural',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2d9',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Thoguta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2da',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Ananthagiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2db',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Atmakur(S)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2dc',
    },
    state: 'Telangana',
    district: 'Siddipet',
    city: 'Wargal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2dd',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Chilkur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2de',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Chinthalapalem Mallareddygudem',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2df',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Chivvemla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2e0',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Huzurnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2e1',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Jaji Reddi Gudem (Arvapally)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2e2',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Kodad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2e3',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Maddirala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2e4',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Mattam Pally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2e5',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Mella Chervu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2e6',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Mothey',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2e7',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Munagala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2e8',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Garide Pally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2e9',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Nagaram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2ea',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Nadigudem',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2eb',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Nereducherla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2ec',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Nuthankal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2ed',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Palakeedu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2ee',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Penpahad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2ef',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Suryapet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2f0',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Thirumalagiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2f1',
    },
    state: 'Telangana',
    district: 'Suryapet',
    city: 'Thungathurthi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2f2',
    },
    state: 'Telangana',
    district: 'Vikarabad',
    city: 'Bantwaram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2f3',
    },
    state: 'Telangana',
    district: 'Vikarabad',
    city: 'Basheerabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2f4',
    },
    state: 'Telangana',
    district: 'Vikarabad',
    city: 'Bomraspeta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2f5',
    },
    state: 'Telangana',
    district: 'Vikarabad',
    city: 'Dharur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2f6',
    },
    state: 'Telangana',
    district: 'Vikarabad',
    city: 'Doma',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2f7',
    },
    state: 'Telangana',
    district: 'Vikarabad',
    city: 'Doulatabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2f8',
    },
    state: 'Telangana',
    district: 'Vikarabad',
    city: 'Kotepally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2f9',
    },
    state: 'Telangana',
    district: 'Vikarabad',
    city: 'Kodangal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2fa',
    },
    state: 'Telangana',
    district: 'Vikarabad',
    city: 'Kulkacharla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2fb',
    },
    state: 'Telangana',
    district: 'Vikarabad',
    city: 'Marpally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2fc',
    },
    state: 'Telangana',
    district: 'Vikarabad',
    city: 'Mominpet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2fd',
    },
    state: 'Telangana',
    district: 'Vikarabad',
    city: 'Nawabpet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2fe',
    },
    state: 'Telangana',
    district: 'Vikarabad',
    city: 'Peddemul',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f2ff',
    },
    state: 'Telangana',
    district: 'Vikarabad',
    city: 'Pargi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f300',
    },
    state: 'Telangana',
    district: 'Vikarabad',
    city: 'Pudur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f301',
    },
    state: 'Telangana',
    district: 'Vikarabad',
    city: 'Tandur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f302',
    },
    state: 'Telangana',
    district: 'Vikarabad',
    city: 'Vikarabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f303',
    },
    state: 'Telangana',
    district: 'Wanaparthy',
    city: 'Amarachintha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f304',
    },
    state: 'Telangana',
    district: 'Wanaparthy',
    city: 'Atmakur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f305',
    },
    state: 'Telangana',
    district: 'Wanaparthy',
    city: 'Chinnambavi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f306',
    },
    state: 'Telangana',
    district: 'Wanaparthy',
    city: 'Ghanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f307',
    },
    state: 'Telangana',
    district: 'Wanaparthy',
    city: 'Gopalpeta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f308',
    },
    state: 'Telangana',
    district: 'Vikarabad',
    city: 'Yalal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f309',
    },
    state: 'Telangana',
    district: 'Wanaparthy',
    city: 'Madanapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f30a',
    },
    state: 'Telangana',
    district: 'Wanaparthy',
    city: 'Kothakota',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f30b',
    },
    state: 'Telangana',
    district: 'Wanaparthy',
    city: 'Pangal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f30c',
    },
    state: 'Telangana',
    district: 'Wanaparthy',
    city: 'Peddamandadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f30d',
    },
    state: 'Telangana',
    district: 'Wanaparthy',
    city: 'Pebbair',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f30e',
    },
    state: 'Telangana',
    district: 'Wanaparthy',
    city: 'Revally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f30f',
    },
    state: 'Telangana',
    district: 'Wanaparthy',
    city: 'Srirangapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f310',
    },
    state: 'Telangana',
    district: 'Wanaparthy',
    city: 'Wanaparthy',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f311',
    },
    state: 'Telangana',
    district: 'Wanaparthy',
    city: 'Weepangandla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f312',
    },
    state: 'Telangana',
    district: 'Warangal Rural',
    city: 'Atmakur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f313',
    },
    state: 'Telangana',
    district: 'Warangal Rural',
    city: 'Damera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f314',
    },
    state: 'Telangana',
    district: 'Warangal Rural',
    city: 'Duggondi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f315',
    },
    state: 'Telangana',
    district: 'Warangal Rural',
    city: 'Chennaraopet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f316',
    },
    state: 'Telangana',
    district: 'Warangal Rural',
    city: 'Khanapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f317',
    },
    state: 'Telangana',
    district: 'Warangal Rural',
    city: 'Nadikuda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f318',
    },
    state: 'Telangana',
    district: 'Warangal Rural',
    city: 'Nalla Belli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f319',
    },
    state: 'Telangana',
    district: 'Warangal Rural',
    city: 'Narsampet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f31a',
    },
    state: 'Telangana',
    district: 'Warangal Rural',
    city: 'Nekkonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f31b',
    },
    state: 'Telangana',
    district: 'Warangal Rural',
    city: 'Parkal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f31c',
    },
    state: 'Telangana',
    district: 'Warangal Rural',
    city: 'Parvathagiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f31d',
    },
    state: 'Telangana',
    district: 'Warangal Rural',
    city: 'Raiparthy',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f31e',
    },
    state: 'Telangana',
    district: 'Warangal Rural',
    city: 'Sangem',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f31f',
    },
    state: 'Telangana',
    district: 'Warangal Rural',
    city: 'Shayampet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f320',
    },
    state: 'Telangana',
    district: 'Warangal Rural',
    city: 'Wardhanna Pet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f321',
    },
    state: 'Telangana',
    district: 'Warangal Urban',
    city: 'Bheemadevarapalli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f322',
    },
    state: 'Telangana',
    district: 'Warangal Rural',
    city: 'Geesugonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f323',
    },
    state: 'Telangana',
    district: 'Warangal Urban',
    city: 'Dharmasagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f324',
    },
    state: 'Telangana',
    district: 'Warangal Urban',
    city: 'Elkathurthi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f325',
    },
    state: 'Telangana',
    district: 'Warangal Urban',
    city: 'Hanamkonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f326',
    },
    state: 'Telangana',
    district: 'Warangal Urban',
    city: 'Hasanparthy',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f327',
    },
    state: 'Telangana',
    district: 'Warangal Urban',
    city: 'Kamalapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f328',
    },
    state: 'Telangana',
    district: 'Warangal Urban',
    city: 'Khazipet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f329',
    },
    state: 'Telangana',
    district: 'Warangal Urban',
    city: 'Khila Warangal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f32a',
    },
    state: 'Telangana',
    district: 'Warangal Urban',
    city: 'Velair',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f32b',
    },
    state: 'Telangana',
    district: 'Warangal Urban',
    city: 'Inavole',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f32c',
    },
    state: 'Telangana',
    district: 'Warangal Urban',
    city: 'Warangal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f32d',
    },
    state: 'Telangana',
    district: 'Yadadri Bhuvanagiri',
    city: 'Addaguduru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f32e',
    },
    state: 'Telangana',
    district: 'Yadadri Bhuvanagiri',
    city: 'Atmakur(M)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f32f',
    },
    state: 'Telangana',
    district: 'Yadadri Bhuvanagiri',
    city: 'Bhongir',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f330',
    },
    state: 'Telangana',
    district: 'Yadadri Bhuvanagiri',
    city: 'Alair',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f331',
    },
    state: 'Telangana',
    district: 'Yadadri Bhuvanagiri',
    city: 'Bibinagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f332',
    },
    state: 'Telangana',
    district: 'Yadadri Bhuvanagiri',
    city: 'Bommala Ramaram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f333',
    },
    state: 'Telangana',
    district: 'Yadadri Bhuvanagiri',
    city: 'B.Pochampally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f334',
    },
    state: 'Telangana',
    district: 'Yadadri Bhuvanagiri',
    city: 'Gundala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f335',
    },
    state: 'Telangana',
    district: 'Yadadri Bhuvanagiri',
    city: 'Motakonduru',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f336',
    },
    state: 'Telangana',
    district: 'Yadadri Bhuvanagiri',
    city: 'Mothkur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f337',
    },
    state: 'Telangana',
    district: 'Yadadri Bhuvanagiri',
    city: 'Choutuppal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f338',
    },
    state: 'Telangana',
    district: 'Yadadri Bhuvanagiri',
    city: 'Narayanapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f339',
    },
    state: 'Telangana',
    district: 'Yadadri Bhuvanagiri',
    city: 'Rajapet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f33a',
    },
    state: 'Telangana',
    district: 'Yadadri Bhuvanagiri',
    city: 'Ramannapeta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f33b',
    },
    state: 'Telangana',
    district: 'Yadadri Bhuvanagiri',
    city: 'Thurkapally',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f33c',
    },
    state: 'Telangana',
    district: 'Yadadri Bhuvanagiri',
    city: 'Valigonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f33d',
    },
    state: 'Telangana',
    district: 'Yadadri Bhuvanagiri',
    city: 'Yadagirigutta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f33e',
    },
    state: 'The Dadra And Nagar Haveli And Daman And Diu',
    district: 'Daman',
    city: 'Daman',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f33f',
    },
    state: 'The Dadra And Nagar Haveli And Daman And Diu',
    district: 'Diu',
    city: 'Diu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f340',
    },
    state: 'Tripura',
    district: 'Dhalai',
    city: 'Ambassa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f341',
    },
    state: 'Tripura',
    district: 'Dhalai',
    city: 'Chawmanu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f342',
    },
    state: 'The Dadra And Nagar Haveli And Daman And Diu',
    district: 'Dadra And Nagar Haveli',
    city: 'Dadra Nagar Haveli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f343',
    },
    state: 'Tripura',
    district: 'Dhalai',
    city: 'Dumburnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f344',
    },
    state: 'Tripura',
    district: 'Dhalai',
    city: 'Durgachowmuhani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f345',
    },
    state: 'Tripura',
    district: 'Dhalai',
    city: 'Ganganagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f346',
    },
    state: 'Tripura',
    district: 'Dhalai',
    city: 'Manu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f347',
    },
    state: 'Tripura',
    district: 'Dhalai',
    city: 'Raishyabari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f348',
    },
    state: 'Tripura',
    district: 'Dhalai',
    city: 'Salema',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f349',
    },
    state: 'Tripura',
    district: 'Gomati',
    city: 'Amarpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f34a',
    },
    state: 'Tripura',
    district: 'Gomati',
    city: 'Kakraban',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f34b',
    },
    state: 'Tripura',
    district: 'Gomati',
    city: 'Karbook',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f34c',
    },
    state: 'Tripura',
    district: 'Gomati',
    city: 'Killa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f34d',
    },
    state: 'Tripura',
    district: 'Gomati',
    city: 'Matabari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f34e',
    },
    state: 'Tripura',
    district: 'Gomati',
    city: 'Ompi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f34f',
    },
    state: 'Tripura',
    district: 'Gomati',
    city: 'Silachari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f350',
    },
    state: 'Tripura',
    district: 'Gomati',
    city: 'Tepania',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f351',
    },
    state: 'Tripura',
    district: 'Khowai',
    city: 'Khowai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f352',
    },
    state: 'Tripura',
    district: 'Khowai',
    city: 'Mungiakami',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f353',
    },
    state: 'Tripura',
    district: 'Khowai',
    city: 'Padmabil',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f354',
    },
    state: 'Tripura',
    district: 'Khowai',
    city: 'Teliamura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f355',
    },
    state: 'Tripura',
    district: 'Khowai',
    city: 'Tulashikhar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f356',
    },
    state: 'Tripura',
    district: 'North Tripura',
    city: 'Damcherra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f357',
    },
    state: 'Tripura',
    district: 'North Tripura',
    city: 'Dasda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f358',
    },
    state: 'Tripura',
    district: 'North Tripura',
    city: 'Jampui Hills',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f359',
    },
    state: 'Tripura',
    district: 'North Tripura',
    city: 'Jubarajnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f35a',
    },
    state: 'Tripura',
    district: 'North Tripura',
    city: 'Kadamtala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f35b',
    },
    state: 'Tripura',
    district: 'North Tripura',
    city: 'Kalacherra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f35c',
    },
    state: 'Tripura',
    district: 'Khowai',
    city: 'Kalyanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f35d',
    },
    state: 'Tripura',
    district: 'North Tripura',
    city: 'Laljuri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f35e',
    },
    state: 'Tripura',
    district: 'Sepahijala',
    city: 'Bishalgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f35f',
    },
    state: 'Tripura',
    district: 'North Tripura',
    city: 'Panisagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f360',
    },
    state: 'Tripura',
    district: 'Sepahijala',
    city: 'Boxanagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f361',
    },
    state: 'Tripura',
    district: 'Sepahijala',
    city: 'Jampuijala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f362',
    },
    state: 'Tripura',
    district: 'Sepahijala',
    city: 'Kathalia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f363',
    },
    state: 'Tripura',
    district: 'Sepahijala',
    city: 'Mohanbhog',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f364',
    },
    state: 'Tripura',
    district: 'Sepahijala',
    city: 'Nalchar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f365',
    },
    state: 'Tripura',
    district: 'Sepahijala',
    city: 'Charilam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f366',
    },
    state: 'Tripura',
    district: 'South Tripura',
    city: 'Bharat Chandra Nagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f367',
    },
    state: 'Tripura',
    district: 'South Tripura',
    city: 'Bokafa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f368',
    },
    state: 'Tripura',
    district: 'South Tripura',
    city: 'Hrishyamukh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f369',
    },
    state: 'Tripura',
    district: 'South Tripura',
    city: 'Jolaibari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f36a',
    },
    state: 'Tripura',
    district: 'South Tripura',
    city: 'Rajnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f36b',
    },
    state: 'Tripura',
    district: 'South Tripura',
    city: 'Rupaichari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f36c',
    },
    state: 'Tripura',
    district: 'South Tripura',
    city: 'Satchand',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f36d',
    },
    state: 'Tripura',
    district: 'Unakoti',
    city: 'Chandipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f36e',
    },
    state: 'Tripura',
    district: 'Unakoti',
    city: 'Gournagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f36f',
    },
    state: 'Tripura',
    district: 'Unakoti',
    city: 'Kumarghat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f370',
    },
    state: 'Tripura',
    district: 'Unakoti',
    city: 'Pecharthal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f371',
    },
    state: 'Tripura',
    district: 'West Tripura',
    city: 'Bamutia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f372',
    },
    state: 'Tripura',
    district: 'West Tripura',
    city: 'Belbari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f373',
    },
    state: 'Tripura',
    district: 'West Tripura',
    city: 'Dukli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f374',
    },
    state: 'Tripura',
    district: 'West Tripura',
    city: 'Hezamara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f375',
    },
    state: 'Tripura',
    district: 'South Tripura',
    city: 'Poangbari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f376',
    },
    state: 'Tripura',
    district: 'West Tripura',
    city: 'Jirania',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f377',
    },
    state: 'Tripura',
    district: 'West Tripura',
    city: 'Mandwai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f378',
    },
    state: 'Tripura',
    district: 'West Tripura',
    city: 'Lefunga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f379',
    },
    state: 'Tripura',
    district: 'West Tripura',
    city: 'Mohanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f37a',
    },
    state: 'Uttarakhand',
    district: 'Almora',
    city: 'Bhaisiya Chhana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f37b',
    },
    state: 'Uttarakhand',
    district: 'Almora',
    city: 'Bhikiyasain',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f37c',
    },
    state: 'Uttarakhand',
    district: 'Almora',
    city: 'Chaukhutiya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f37d',
    },
    state: 'Uttarakhand',
    district: 'Almora',
    city: 'Dhauladevi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f37e',
    },
    state: 'Uttarakhand',
    district: 'Almora',
    city: 'Dwarahat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f37f',
    },
    state: 'Tripura',
    district: 'West Tripura',
    city: 'Old Agartala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f380',
    },
    state: 'Uttarakhand',
    district: 'Almora',
    city: 'Hawalbag',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f381',
    },
    state: 'Uttarakhand',
    district: 'Almora',
    city: 'Lamgara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f382',
    },
    state: 'Uttarakhand',
    district: 'Almora',
    city: 'Sult',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f383',
    },
    state: 'Uttarakhand',
    district: 'Almora',
    city: 'Takula',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f384',
    },
    state: 'Uttarakhand',
    district: 'Almora',
    city: 'Tarikhet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f385',
    },
    state: 'Uttarakhand',
    district: 'Bageshwar',
    city: 'Bageshwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f386',
    },
    state: 'Uttarakhand',
    district: 'Bageshwar',
    city: 'Garur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f387',
    },
    state: 'Uttarakhand',
    district: 'Almora',
    city: 'Syaldey',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f388',
    },
    state: 'Uttarakhand',
    district: 'Bageshwar',
    city: 'Kapkote',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f389',
    },
    state: 'Uttarakhand',
    district: 'Chamoli',
    city: 'Dewal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f38a',
    },
    state: 'Uttarakhand',
    district: 'Chamoli',
    city: 'Dasholi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f38b',
    },
    state: 'Uttarakhand',
    district: 'Chamoli',
    city: 'Gairsain',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f38c',
    },
    state: 'Uttarakhand',
    district: 'Chamoli',
    city: 'Joshimath',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f38d',
    },
    state: 'Uttarakhand',
    district: 'Chamoli',
    city: 'Ghat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f38e',
    },
    state: 'Uttarakhand',
    district: 'Chamoli',
    city: 'Karnaprayag',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f38f',
    },
    state: 'Uttarakhand',
    district: 'Chamoli',
    city: 'Narayanbagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f390',
    },
    state: 'Uttarakhand',
    district: 'Chamoli',
    city: 'Pokhari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f391',
    },
    state: 'Uttarakhand',
    district: 'Chamoli',
    city: 'Tharali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f392',
    },
    state: 'Uttarakhand',
    district: 'Champawat',
    city: 'Barakot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f393',
    },
    state: 'Uttarakhand',
    district: 'Champawat',
    city: 'Champawat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f394',
    },
    state: 'Uttarakhand',
    district: 'Champawat',
    city: 'Lohaghat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f395',
    },
    state: 'Uttarakhand',
    district: 'Champawat',
    city: 'Pati',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f396',
    },
    state: 'Uttarakhand',
    district: 'Dehradun',
    city: 'Doiwala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f397',
    },
    state: 'Uttarakhand',
    district: 'Dehradun',
    city: 'Kalsi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f398',
    },
    state: 'Uttarakhand',
    district: 'Dehradun',
    city: 'Raipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f399',
    },
    state: 'Uttarakhand',
    district: 'Dehradun',
    city: 'Sahaspur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f39a',
    },
    state: 'Uttarakhand',
    district: 'Dehradun',
    city: 'Chakrata',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f39b',
    },
    state: 'Uttarakhand',
    district: 'Haridwar',
    city: 'Bahadrabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f39c',
    },
    state: 'Uttarakhand',
    district: 'Dehradun',
    city: 'Vikasnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f39d',
    },
    state: 'Uttarakhand',
    district: 'Haridwar',
    city: 'Bhagwanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f39e',
    },
    state: 'Uttarakhand',
    district: 'Haridwar',
    city: 'Khanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f39f',
    },
    state: 'Uttarakhand',
    district: 'Haridwar',
    city: 'Narsan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3a0',
    },
    state: 'Uttarakhand',
    district: 'Haridwar',
    city: 'Laksar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3a1',
    },
    state: 'Uttarakhand',
    district: 'Haridwar',
    city: 'Roorkee',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3a2',
    },
    state: 'Uttarakhand',
    district: 'Nainital',
    city: 'Betalghat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3a3',
    },
    state: 'Uttarakhand',
    district: 'Nainital',
    city: 'Bhimtal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3a4',
    },
    state: 'Uttarakhand',
    district: 'Nainital',
    city: 'Dhari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3a5',
    },
    state: 'Uttarakhand',
    district: 'Nainital',
    city: 'Kotabag',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3a6',
    },
    state: 'Uttarakhand',
    district: 'Nainital',
    city: 'Okhalkanda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3a7',
    },
    state: 'Uttarakhand',
    district: 'Nainital',
    city: 'Ramgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3a9',
    },
    state: 'Uttarakhand',
    district: 'Nainital',
    city: 'Haldwani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3aa',
    },
    state: 'Uttarakhand',
    district: 'Pauri Garhwal',
    city: 'Bironkhal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3ab',
    },
    state: 'Uttarakhand',
    district: 'Nainital',
    city: 'Ramnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3ac',
    },
    state: 'Uttarakhand',
    district: 'Pauri Garhwal',
    city: 'Dwarikhal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3ad',
    },
    state: 'Uttarakhand',
    district: 'Pauri Garhwal',
    city: 'Duggada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3ae',
    },
    state: 'Uttarakhand',
    district: 'Pauri Garhwal',
    city: 'Kaljikhal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3af',
    },
    state: 'Uttarakhand',
    district: 'Pauri Garhwal',
    city: 'Khirsu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3b0',
    },
    state: 'Uttarakhand',
    district: 'Pauri Garhwal',
    city: 'Kot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3b1',
    },
    state: 'Uttarakhand',
    district: 'Pauri Garhwal',
    city: 'Nainidanda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3b2',
    },
    state: 'Uttarakhand',
    district: 'Pauri Garhwal',
    city: 'Pabau',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3b3',
    },
    state: 'Uttarakhand',
    district: 'Pauri Garhwal',
    city: 'Pauri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3b4',
    },
    state: 'Uttarakhand',
    district: 'Pauri Garhwal',
    city: 'Pokhra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3b5',
    },
    state: 'Uttarakhand',
    district: 'Pauri Garhwal',
    city: 'Rikhnikhal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3b6',
    },
    state: 'Uttarakhand',
    district: 'Pauri Garhwal',
    city: 'Thalisain',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3b7',
    },
    state: 'Uttarakhand',
    district: 'Pauri Garhwal',
    city: 'Yamkeshwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3b8',
    },
    state: 'Uttarakhand',
    district: 'Pauri Garhwal',
    city: 'Zahrikhal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3b9',
    },
    state: 'Uttarakhand',
    district: 'Pithoragarh',
    city: 'Berinag',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3ba',
    },
    state: 'Uttarakhand',
    district: 'Pithoragarh',
    city: 'Dharchula',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3bb',
    },
    state: 'Uttarakhand',
    district: 'Pauri Garhwal',
    city: 'Ekeshwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3bc',
    },
    state: 'Uttarakhand',
    district: 'Pithoragarh',
    city: 'Didihat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3bd',
    },
    state: 'Uttarakhand',
    district: 'Pithoragarh',
    city: 'Gangolihat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3be',
    },
    state: 'Uttarakhand',
    district: 'Pithoragarh',
    city: 'Kanalichina',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3bf',
    },
    state: 'Uttarakhand',
    district: 'Pithoragarh',
    city: 'Munakot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3c0',
    },
    state: 'Uttarakhand',
    district: 'Pithoragarh',
    city: 'Munsyari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3c1',
    },
    state: 'Uttarakhand',
    district: 'Pithoragarh',
    city: 'Pithoragarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3c2',
    },
    state: 'Uttarakhand',
    district: 'Rudra Prayag',
    city: 'Augustmuni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3c3',
    },
    state: 'Uttarakhand',
    district: 'Rudra Prayag',
    city: 'Jakholi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3c4',
    },
    state: 'Uttarakhand',
    district: 'Rudra Prayag',
    city: 'Ukhimath',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3c5',
    },
    state: 'Uttarakhand',
    district: 'Tehri Garhwal',
    city: 'Bhilangna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3c6',
    },
    state: 'Uttarakhand',
    district: 'Tehri Garhwal',
    city: 'Chamba',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3c7',
    },
    state: 'Uttarakhand',
    district: 'Tehri Garhwal',
    city: 'Jakhnidhar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3c8',
    },
    state: 'Uttarakhand',
    district: 'Tehri Garhwal',
    city: 'Jaunpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3c9',
    },
    state: 'Uttarakhand',
    district: 'Tehri Garhwal',
    city: 'Kirtinagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3ca',
    },
    state: 'Uttarakhand',
    district: 'Tehri Garhwal',
    city: 'Deoprayag',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3cb',
    },
    state: 'Uttarakhand',
    district: 'Tehri Garhwal',
    city: 'Narendra Nagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3cc',
    },
    state: 'Uttarakhand',
    district: 'Tehri Garhwal',
    city: 'Pratapnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3cd',
    },
    state: 'Uttarakhand',
    district: 'Udam Singh Nagar',
    city: 'Bajpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3ce',
    },
    state: 'Uttarakhand',
    district: 'Udam Singh Nagar',
    city: 'Gadarpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3cf',
    },
    state: 'Uttarakhand',
    district: 'Udam Singh Nagar',
    city: 'Jaspur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3d0',
    },
    state: 'Uttarakhand',
    district: 'Udam Singh Nagar',
    city: 'Kashipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3d1',
    },
    state: 'Uttarakhand',
    district: 'Tehri Garhwal',
    city: 'Thauldhar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3d2',
    },
    state: 'Uttarakhand',
    district: 'Udam Singh Nagar',
    city: 'Khatima',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3d3',
    },
    state: 'Uttarakhand',
    district: 'Udam Singh Nagar',
    city: 'Rudrapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3d4',
    },
    state: 'Uttarakhand',
    district: 'Uttar Kashi',
    city: 'Bhatwari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3d5',
    },
    state: 'Uttarakhand',
    district: 'Uttar Kashi',
    city: 'Chinyalisaur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3d6',
    },
    state: 'Uttarakhand',
    district: 'Udam Singh Nagar',
    city: 'Sitarganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3d7',
    },
    state: 'Uttarakhand',
    district: 'Uttar Kashi',
    city: 'Dunda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3d8',
    },
    state: 'Uttarakhand',
    district: 'Uttar Kashi',
    city: 'Mori',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3d9',
    },
    state: 'Uttarakhand',
    district: 'Uttar Kashi',
    city: 'Naugaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3da',
    },
    state: 'Uttarakhand',
    district: 'Uttar Kashi',
    city: 'Purola',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3db',
    },
    state: 'Uttar Pradesh',
    district: 'Agra',
    city: 'Achhnera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3dc',
    },
    state: 'Uttar Pradesh',
    district: 'Agra',
    city: 'Akola',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3dd',
    },
    state: 'Uttar Pradesh',
    district: 'Agra',
    city: 'Bah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3de',
    },
    state: 'Uttar Pradesh',
    district: 'Agra',
    city: 'Barauli Ahir',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3df',
    },
    state: 'Uttar Pradesh',
    district: 'Agra',
    city: 'Bichpuri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3e0',
    },
    state: 'Uttar Pradesh',
    district: 'Agra',
    city: 'Etmadpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3e1',
    },
    state: 'Uttar Pradesh',
    district: 'Agra',
    city: 'Fatehpur Sikri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3e2',
    },
    state: 'Uttar Pradesh',
    district: 'Agra',
    city: 'Jagner',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3e3',
    },
    state: 'Uttar Pradesh',
    district: 'Agra',
    city: 'Jaitpur Kalan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3e4',
    },
    state: 'Uttar Pradesh',
    district: 'Agra',
    city: 'Khandauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3e5',
    },
    state: 'Uttar Pradesh',
    district: 'Agra',
    city: 'Kheragarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3e6',
    },
    state: 'Uttar Pradesh',
    district: 'Agra',
    city: 'Fatehabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3e7',
    },
    state: 'Uttar Pradesh',
    district: 'Agra',
    city: 'Saiyan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3e8',
    },
    state: 'Uttar Pradesh',
    district: 'Agra',
    city: 'Shamsabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3e9',
    },
    state: 'Uttar Pradesh',
    district: 'Agra',
    city: 'Pinahat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3ea',
    },
    state: 'Uttar Pradesh',
    district: 'Aligarh',
    city: 'Atrauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3eb',
    },
    state: 'Uttar Pradesh',
    district: 'Aligarh',
    city: 'Akrabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3ec',
    },
    state: 'Uttar Pradesh',
    district: 'Aligarh',
    city: 'Bijauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3ed',
    },
    state: 'Uttar Pradesh',
    district: 'Aligarh',
    city: 'Chandaus',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3ee',
    },
    state: 'Uttar Pradesh',
    district: 'Aligarh',
    city: 'Dhanipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3ef',
    },
    state: 'Uttar Pradesh',
    district: 'Aligarh',
    city: 'Gangiri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3f0',
    },
    state: 'Uttar Pradesh',
    district: 'Aligarh',
    city: 'Gonda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3f1',
    },
    state: 'Uttar Pradesh',
    district: 'Aligarh',
    city: 'Jawan Sikanderpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3f2',
    },
    state: 'Uttar Pradesh',
    district: 'Aligarh',
    city: 'Khair',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3f3',
    },
    state: 'Uttar Pradesh',
    district: 'Aligarh',
    city: 'Iglas',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3f4',
    },
    state: 'Uttar Pradesh',
    district: 'Aligarh',
    city: 'Tappal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3f5',
    },
    state: 'Uttar Pradesh',
    district: 'Ambedkar Nagar',
    city: 'Akbarpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3f6',
    },
    state: 'Uttar Pradesh',
    district: 'Ambedkar Nagar',
    city: 'Baskhari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3f7',
    },
    state: 'Uttar Pradesh',
    district: 'Ambedkar Nagar',
    city: 'Bhiti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3f8',
    },
    state: 'Uttar Pradesh',
    district: 'Ambedkar Nagar',
    city: 'Bhiyawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3f9',
    },
    state: 'Uttar Pradesh',
    district: 'Ambedkar Nagar',
    city: 'Jahangir Ganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3fa',
    },
    state: 'Uttar Pradesh',
    district: 'Aligarh',
    city: 'Lodha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3fb',
    },
    state: 'Uttar Pradesh',
    district: 'Ambedkar Nagar',
    city: 'Jalal Pur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3fc',
    },
    state: 'Uttar Pradesh',
    district: 'Ambedkar Nagar',
    city: 'Katehari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3fd',
    },
    state: 'Uttar Pradesh',
    district: 'Ambedkar Nagar',
    city: 'Tanda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3fe',
    },
    state: 'Uttar Pradesh',
    district: 'Amethi',
    city: 'Amethi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f3ff',
    },
    state: 'Uttar Pradesh',
    district: 'Ambedkar Nagar',
    city: 'Ram Nagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f400',
    },
    state: 'Uttar Pradesh',
    district: 'Amethi',
    city: 'Bahadurpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f401',
    },
    state: 'Uttar Pradesh',
    district: 'Amethi',
    city: 'Bhadar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f402',
    },
    state: 'Uttar Pradesh',
    district: 'Amethi',
    city: 'Bhetua',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f403',
    },
    state: 'Uttar Pradesh',
    district: 'Amethi',
    city: 'Gauriganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f404',
    },
    state: 'Uttar Pradesh',
    district: 'Amethi',
    city: 'Jagdishpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f405',
    },
    state: 'Uttar Pradesh',
    district: 'Amethi',
    city: 'Jamo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f406',
    },
    state: 'Uttar Pradesh',
    district: 'Amethi',
    city: 'Musafir Khana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f407',
    },
    state: 'Uttar Pradesh',
    district: 'Amethi',
    city: 'Shahgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f408',
    },
    state: 'Uttar Pradesh',
    district: 'Amethi',
    city: 'Shukul Bazar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f409',
    },
    state: 'Uttar Pradesh',
    district: 'Amethi',
    city: 'Singhpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f40a',
    },
    state: 'Uttar Pradesh',
    district: 'Amethi',
    city: 'Tiloi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f40b',
    },
    state: 'Uttar Pradesh',
    district: 'Amroha',
    city: 'Amroha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f40c',
    },
    state: 'Uttar Pradesh',
    district: 'Amethi',
    city: 'Sangrampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f40d',
    },
    state: 'Uttar Pradesh',
    district: 'Amroha',
    city: 'Dhanaura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f40e',
    },
    state: 'Uttar Pradesh',
    district: 'Amroha',
    city: 'Gajraula',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f40f',
    },
    state: 'Uttar Pradesh',
    district: 'Amroha',
    city: 'Gangeshwari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f410',
    },
    state: 'Uttar Pradesh',
    district: 'Amroha',
    city: 'Hasanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f411',
    },
    state: 'Uttar Pradesh',
    district: 'Amroha',
    city: 'Joya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f412',
    },
    state: 'Uttar Pradesh',
    district: 'Auraiya',
    city: 'Achchalda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f413',
    },
    state: 'Uttar Pradesh',
    district: 'Auraiya',
    city: 'Ajitmal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f414',
    },
    state: 'Uttar Pradesh',
    district: 'Auraiya',
    city: 'Auraiya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f415',
    },
    state: 'Uttar Pradesh',
    district: 'Auraiya',
    city: 'Bhagyanagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f416',
    },
    state: 'Uttar Pradesh',
    district: 'Auraiya',
    city: 'Bidhuna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f417',
    },
    state: 'Uttar Pradesh',
    district: 'Auraiya',
    city: 'Erwa Katra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f418',
    },
    state: 'Uttar Pradesh',
    district: 'Auraiya',
    city: 'Sahar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f419',
    },
    state: 'Uttar Pradesh',
    district: 'Faizabad',
    city: 'Amaniganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f41a',
    },
    state: 'Uttar Pradesh',
    district: 'Faizabad',
    city: 'Hariyangatanganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f41b',
    },
    state: 'Uttar Pradesh',
    district: 'Faizabad',
    city: 'Masodha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f41c',
    },
    state: 'Uttar Pradesh',
    district: 'Faizabad',
    city: 'Mawai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f41d',
    },
    state: 'Uttar Pradesh',
    district: 'Faizabad',
    city: 'Bikapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f41e',
    },
    state: 'Uttar Pradesh',
    district: 'Faizabad',
    city: 'Maya Bazar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f41f',
    },
    state: 'Uttar Pradesh',
    district: 'Faizabad',
    city: 'Milkipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f420',
    },
    state: 'Uttar Pradesh',
    district: 'Faizabad',
    city: 'Pura Bazar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f421',
    },
    state: 'Uttar Pradesh',
    district: 'Faizabad',
    city: 'Rudauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f422',
    },
    state: 'Uttar Pradesh',
    district: 'Faizabad',
    city: 'Sohawal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f423',
    },
    state: 'Uttar Pradesh',
    district: 'Faizabad',
    city: 'Tarun',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f424',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Atraulia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f425',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Azmatgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f426',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Bilariyaganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f427',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Haraiya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f428',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Jahanaganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f429',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Koilsa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f42a',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Lalganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f42b',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Mahrajganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f42c',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Martinganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f42d',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Mehnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f42e',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Ahiraula',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f42f',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Mirzapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f430',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Mohammadpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f431',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Palhani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f432',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Pawai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f433',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Phulpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f434',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Rani Ki Sarai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f435',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Sathiyaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f436',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Tahbarpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f437',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Tarwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f438',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Thekma',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f439',
    },
    state: 'Uttar Pradesh',
    district: 'Azamgarh',
    city: 'Palhana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f43a',
    },
    state: 'Uttar Pradesh',
    district: 'Baghpat',
    city: 'Baraut',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f43b',
    },
    state: 'Uttar Pradesh',
    district: 'Baghpat',
    city: 'Binauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f43c',
    },
    state: 'Uttar Pradesh',
    district: 'Baghpat',
    city: 'Chhaprauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f43d',
    },
    state: 'Uttar Pradesh',
    district: 'Baghpat',
    city: 'Baghpat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f43e',
    },
    state: 'Uttar Pradesh',
    district: 'Baghpat',
    city: 'Khekra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f43f',
    },
    state: 'Uttar Pradesh',
    district: 'Baghpat',
    city: 'Pilana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f440',
    },
    state: 'Uttar Pradesh',
    district: 'Bahraich',
    city: 'Chitaura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f441',
    },
    state: 'Uttar Pradesh',
    district: 'Bahraich',
    city: 'Huzoorpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f442',
    },
    state: 'Uttar Pradesh',
    district: 'Bahraich',
    city: 'Jarwal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f443',
    },
    state: 'Uttar Pradesh',
    district: 'Bahraich',
    city: 'Balaha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f444',
    },
    state: 'Uttar Pradesh',
    district: 'Bahraich',
    city: 'Mahasi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f445',
    },
    state: 'Uttar Pradesh',
    district: 'Bahraich',
    city: 'Kaisarganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f446',
    },
    state: 'Uttar Pradesh',
    district: 'Bahraich',
    city: 'Mihinpurwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f447',
    },
    state: 'Uttar Pradesh',
    district: 'Bahraich',
    city: 'Nawabganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f448',
    },
    state: 'Uttar Pradesh',
    district: 'Bahraich',
    city: 'Payagpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f449',
    },
    state: 'Uttar Pradesh',
    district: 'Bahraich',
    city: 'Risia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f44a',
    },
    state: 'Uttar Pradesh',
    district: 'Bahraich',
    city: 'Shivpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f44b',
    },
    state: 'Uttar Pradesh',
    district: 'Bahraich',
    city: 'Phakharpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f44c',
    },
    state: 'Uttar Pradesh',
    district: 'Bahraich',
    city: 'Tejwapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f44d',
    },
    state: 'Uttar Pradesh',
    district: 'Bahraich',
    city: 'Visheshwarganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f44e',
    },
    state: 'Uttar Pradesh',
    district: 'Ballia',
    city: 'Bairia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f44f',
    },
    state: 'Uttar Pradesh',
    district: 'Ballia',
    city: 'Belhari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f450',
    },
    state: 'Uttar Pradesh',
    district: 'Ballia',
    city: 'Bansdih',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f451',
    },
    state: 'Uttar Pradesh',
    district: 'Ballia',
    city: 'Beruarbari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f452',
    },
    state: 'Uttar Pradesh',
    district: 'Ballia',
    city: 'Chilkahar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f453',
    },
    state: 'Uttar Pradesh',
    district: 'Ballia',
    city: 'Garwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f454',
    },
    state: 'Uttar Pradesh',
    district: 'Ballia',
    city: 'Hanumanganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f455',
    },
    state: 'Uttar Pradesh',
    district: 'Ballia',
    city: 'Maniar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f456',
    },
    state: 'Uttar Pradesh',
    district: 'Ballia',
    city: 'Murlichhapra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f457',
    },
    state: 'Uttar Pradesh',
    district: 'Ballia',
    city: 'Nagra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f458',
    },
    state: 'Uttar Pradesh',
    district: 'Ballia',
    city: 'Navanagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f459',
    },
    state: 'Uttar Pradesh',
    district: 'Ballia',
    city: 'Pandah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f45a',
    },
    state: 'Uttar Pradesh',
    district: 'Ballia',
    city: 'Rasra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f45b',
    },
    state: 'Uttar Pradesh',
    district: 'Ballia',
    city: 'Reoti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f45c',
    },
    state: 'Uttar Pradesh',
    district: 'Ballia',
    city: 'Siar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f45d',
    },
    state: 'Uttar Pradesh',
    district: 'Ballia',
    city: 'Sohanv',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f45f',
    },
    state: 'Uttar Pradesh',
    district: 'Ballia',
    city: 'Dubhar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f460',
    },
    state: 'Uttar Pradesh',
    district: 'Balrampur',
    city: 'Gaisri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f461',
    },
    state: 'Uttar Pradesh',
    district: 'Balrampur',
    city: 'Gaindas Bujurg',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f462',
    },
    state: 'Uttar Pradesh',
    district: 'Balrampur',
    city: 'Pachpedwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f463',
    },
    state: 'Uttar Pradesh',
    district: 'Balrampur',
    city: 'Harriya Satgharwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f464',
    },
    state: 'Uttar Pradesh',
    district: 'Balrampur',
    city: 'Rehera Bazaar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f465',
    },
    state: 'Uttar Pradesh',
    district: 'Balrampur',
    city: 'Tulsipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f466',
    },
    state: 'Uttar Pradesh',
    district: 'Balrampur',
    city: 'Shriduttganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f467',
    },
    state: 'Uttar Pradesh',
    district: 'Balrampur',
    city: 'Utraula',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f468',
    },
    state: 'Uttar Pradesh',
    district: 'Banda',
    city: 'Badokhar Khurd',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f469',
    },
    state: 'Uttar Pradesh',
    district: 'Banda',
    city: 'Bisanda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f46a',
    },
    state: 'Uttar Pradesh',
    district: 'Banda',
    city: 'Jaspura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f46b',
    },
    state: 'Uttar Pradesh',
    district: 'Banda',
    city: 'Kamasin',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f46c',
    },
    state: 'Uttar Pradesh',
    district: 'Banda',
    city: 'Baberu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f46d',
    },
    state: 'Uttar Pradesh',
    district: 'Banda',
    city: 'Mahuva',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f46e',
    },
    state: 'Uttar Pradesh',
    district: 'Banda',
    city: 'Tindwari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f46f',
    },
    state: 'Uttar Pradesh',
    district: 'Bara Banki',
    city: 'Bani Kodar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f470',
    },
    state: 'Uttar Pradesh',
    district: 'Bara Banki',
    city: 'Banki',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f471',
    },
    state: 'Uttar Pradesh',
    district: 'Banda',
    city: 'Naraini',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f472',
    },
    state: 'Uttar Pradesh',
    district: 'Bara Banki',
    city: 'Dariyabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f473',
    },
    state: 'Uttar Pradesh',
    district: 'Bara Banki',
    city: 'Dewa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f474',
    },
    state: 'Uttar Pradesh',
    district: 'Bara Banki',
    city: 'Fatehpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f475',
    },
    state: 'Uttar Pradesh',
    district: 'Bara Banki',
    city: 'Haidargarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f476',
    },
    state: 'Uttar Pradesh',
    district: 'Bara Banki',
    city: 'Harakh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f477',
    },
    state: 'Uttar Pradesh',
    district: 'Bara Banki',
    city: 'Masauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f478',
    },
    state: 'Uttar Pradesh',
    district: 'Bara Banki',
    city: 'Puredalai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f479',
    },
    state: 'Uttar Pradesh',
    district: 'Bara Banki',
    city: 'Ramnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f47a',
    },
    state: 'Uttar Pradesh',
    district: 'Bara Banki',
    city: 'Sidhaur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f47b',
    },
    state: 'Uttar Pradesh',
    district: 'Bara Banki',
    city: 'Sirauli Gauspur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f47c',
    },
    state: 'Uttar Pradesh',
    district: 'Bara Banki',
    city: 'Nindaura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f47d',
    },
    state: 'Uttar Pradesh',
    district: 'Bara Banki',
    city: 'Suratganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f47e',
    },
    state: 'Uttar Pradesh',
    district: 'Bara Banki',
    city: 'Trivediganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f47f',
    },
    state: 'Uttar Pradesh',
    district: 'Bareilly',
    city: 'Aalampur Jafarabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f480',
    },
    state: 'Uttar Pradesh',
    district: 'Bareilly',
    city: 'Baheri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f481',
    },
    state: 'Uttar Pradesh',
    district: 'Bareilly',
    city: 'Bhojipura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f482',
    },
    state: 'Uttar Pradesh',
    district: 'Bareilly',
    city: 'Bhadpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f483',
    },
    state: 'Uttar Pradesh',
    district: 'Bareilly',
    city: 'Bhuta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f484',
    },
    state: 'Uttar Pradesh',
    district: 'Bareilly',
    city: 'Bithiri Chainpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f485',
    },
    state: 'Uttar Pradesh',
    district: 'Bareilly',
    city: 'Damkhauda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f486',
    },
    state: 'Uttar Pradesh',
    district: 'Bareilly',
    city: 'Faridpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f487',
    },
    state: 'Uttar Pradesh',
    district: 'Bareilly',
    city: 'Fatehganj West',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f488',
    },
    state: 'Uttar Pradesh',
    district: 'Bareilly',
    city: 'Kyara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f489',
    },
    state: 'Uttar Pradesh',
    district: 'Bareilly',
    city: 'Mirganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f48a',
    },
    state: 'Uttar Pradesh',
    district: 'Bareilly',
    city: 'Nawabganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f48b',
    },
    state: 'Uttar Pradesh',
    district: 'Bareilly',
    city: 'Ramnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f48c',
    },
    state: 'Uttar Pradesh',
    district: 'Bareilly',
    city: 'Majhgawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f48d',
    },
    state: 'Uttar Pradesh',
    district: 'Basti',
    city: 'Bahadurpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f48e',
    },
    state: 'Uttar Pradesh',
    district: 'Bareilly',
    city: 'Shergarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f48f',
    },
    state: 'Uttar Pradesh',
    district: 'Basti',
    city: 'Basti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f490',
    },
    state: 'Uttar Pradesh',
    district: 'Basti',
    city: 'Dubauliya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f491',
    },
    state: 'Uttar Pradesh',
    district: 'Basti',
    city: 'Bankati',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f492',
    },
    state: 'Uttar Pradesh',
    district: 'Basti',
    city: 'Gaur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f493',
    },
    state: 'Uttar Pradesh',
    district: 'Basti',
    city: 'Harraiya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f494',
    },
    state: 'Uttar Pradesh',
    district: 'Basti',
    city: 'Kaptanganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f495',
    },
    state: 'Uttar Pradesh',
    district: 'Basti',
    city: 'Kudaraha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f496',
    },
    state: 'Uttar Pradesh',
    district: 'Basti',
    city: 'Paras Rampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f497',
    },
    state: 'Uttar Pradesh',
    district: 'Basti',
    city: 'Ramnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f498',
    },
    state: 'Uttar Pradesh',
    district: 'Basti',
    city: 'Rudauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f499',
    },
    state: 'Uttar Pradesh',
    district: 'Basti',
    city: 'Saltaua Gopal Pur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f49a',
    },
    state: 'Uttar Pradesh',
    district: 'Basti',
    city: 'Vikram Jot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f49b',
    },
    state: 'Uttar Pradesh',
    district: 'Basti',
    city: 'Sau Ghat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f49c',
    },
    state: 'Uttar Pradesh',
    district: 'Sant Ravidas Nagar',
    city: 'Aurai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f49d',
    },
    state: 'Uttar Pradesh',
    district: 'Sant Ravidas Nagar',
    city: 'Abhauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f49e',
    },
    state: 'Uttar Pradesh',
    district: 'Sant Ravidas Nagar',
    city: 'Bhadohi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f49f',
    },
    state: 'Uttar Pradesh',
    district: 'Sant Ravidas Nagar',
    city: 'Deegh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4a0',
    },
    state: 'Uttar Pradesh',
    district: 'Sant Ravidas Nagar',
    city: 'Gyanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4a1',
    },
    state: 'Uttar Pradesh',
    district: 'Sant Ravidas Nagar',
    city: 'Suriyavan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4a2',
    },
    state: 'Uttar Pradesh',
    district: 'Bijnor',
    city: 'Afzalgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4a3',
    },
    state: 'Uttar Pradesh',
    district: 'Bijnor',
    city: 'Budhanpur Seohara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4a4',
    },
    state: 'Uttar Pradesh',
    district: 'Bijnor',
    city: 'Haldaur(Khari Jhalu)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4a5',
    },
    state: 'Uttar Pradesh',
    district: 'Bijnor',
    city: 'Jalilpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4a6',
    },
    state: 'Uttar Pradesh',
    district: 'Bijnor',
    city: 'Kiratpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4a7',
    },
    state: 'Uttar Pradesh',
    district: 'Bijnor',
    city: 'Kotwali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4a8',
    },
    state: 'Uttar Pradesh',
    district: 'Bijnor',
    city: 'Mohammedpur Deomal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4a9',
    },
    state: 'Uttar Pradesh',
    district: 'Bijnor',
    city: 'Najibabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4aa',
    },
    state: 'Uttar Pradesh',
    district: 'Bijnor',
    city: 'Nehtaur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4ab',
    },
    state: 'Uttar Pradesh',
    district: 'Bijnor',
    city: 'Noorpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4ac',
    },
    state: 'Uttar Pradesh',
    district: 'Budaun',
    city: 'Ambiapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4ad',
    },
    state: 'Uttar Pradesh',
    district: 'Budaun',
    city: 'Asafpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4ae',
    },
    state: 'Uttar Pradesh',
    district: 'Budaun',
    city: 'Bisauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4af',
    },
    state: 'Uttar Pradesh',
    district: 'Budaun',
    city: 'Dahgavan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4b0',
    },
    state: 'Uttar Pradesh',
    district: 'Budaun',
    city: 'Dataganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4b1',
    },
    state: 'Uttar Pradesh',
    district: 'Budaun',
    city: 'Islamnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4b2',
    },
    state: 'Uttar Pradesh',
    district: 'Bijnor',
    city: 'Dhampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4b3',
    },
    state: 'Uttar Pradesh',
    district: 'Budaun',
    city: 'Jagat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4b4',
    },
    state: 'Uttar Pradesh',
    district: 'Budaun',
    city: 'Mion',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4b5',
    },
    state: 'Uttar Pradesh',
    district: 'Budaun',
    city: 'Qadar Chowk',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4b6',
    },
    state: 'Uttar Pradesh',
    district: 'Budaun',
    city: 'Sahaswan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4b7',
    },
    state: 'Uttar Pradesh',
    district: 'Budaun',
    city: 'Samrer',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4b8',
    },
    state: 'Uttar Pradesh',
    district: 'Budaun',
    city: 'Ujhani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4b9',
    },
    state: 'Uttar Pradesh',
    district: 'Budaun',
    city: 'Usawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4ba',
    },
    state: 'Uttar Pradesh',
    district: 'Budaun',
    city: 'Wazirganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4bb',
    },
    state: 'Uttar Pradesh',
    district: 'Bulandshahr',
    city: 'Agauta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4bc',
    },
    state: 'Uttar Pradesh',
    district: 'Bulandshahr',
    city: 'Anupshahr',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4bd',
    },
    state: 'Uttar Pradesh',
    district: 'Bulandshahr',
    city: 'Araniya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4be',
    },
    state: 'Uttar Pradesh',
    district: 'Bulandshahr',
    city: 'Bhawan Bahadur Nagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4bf',
    },
    state: 'Uttar Pradesh',
    district: 'Budaun',
    city: 'Salarpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4c0',
    },
    state: 'Uttar Pradesh',
    district: 'Bulandshahr',
    city: 'Danpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4c1',
    },
    state: 'Uttar Pradesh',
    district: 'Bulandshahr',
    city: 'Bulandshahr',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4c2',
    },
    state: 'Uttar Pradesh',
    district: 'Bulandshahr',
    city: 'Dibai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4c3',
    },
    state: 'Uttar Pradesh',
    district: 'Bulandshahr',
    city: 'Gulaothi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4c4',
    },
    state: 'Uttar Pradesh',
    district: 'Bulandshahr',
    city: 'Jahangirabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4c5',
    },
    state: 'Uttar Pradesh',
    district: 'Bulandshahr',
    city: 'Khurja',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4c6',
    },
    state: 'Uttar Pradesh',
    district: 'Bulandshahr',
    city: 'Lakhaothi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4c7',
    },
    state: 'Uttar Pradesh',
    district: 'Bulandshahr',
    city: 'Shikarpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4c8',
    },
    state: 'Uttar Pradesh',
    district: 'Bulandshahr',
    city: 'Sikandrabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4c9',
    },
    state: 'Uttar Pradesh',
    district: 'Bulandshahr',
    city: 'Pahasu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4ca',
    },
    state: 'Uttar Pradesh',
    district: 'Bulandshahr',
    city: 'Unchagaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4cb',
    },
    state: 'Uttar Pradesh',
    district: 'Chandauli',
    city: 'Berahani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4cc',
    },
    state: 'Uttar Pradesh',
    district: 'Chandauli',
    city: 'Chahniya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4cd',
    },
    state: 'Uttar Pradesh',
    district: 'Chandauli',
    city: 'Chakiya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4ce',
    },
    state: 'Uttar Pradesh',
    district: 'Chandauli',
    city: 'Chandauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4cf',
    },
    state: 'Uttar Pradesh',
    district: 'Chandauli',
    city: 'Dhanapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4d0',
    },
    state: 'Uttar Pradesh',
    district: 'Chandauli',
    city: 'Naugarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4d1',
    },
    state: 'Uttar Pradesh',
    district: 'Chandauli',
    city: 'Niyamatabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4d2',
    },
    state: 'Uttar Pradesh',
    district: 'Chandauli',
    city: 'Sahabganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4d3',
    },
    state: 'Uttar Pradesh',
    district: 'Chandauli',
    city: 'Sakaldiha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4d4',
    },
    state: 'Uttar Pradesh',
    district: 'Chitrakoot',
    city: 'Karwi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4d5',
    },
    state: 'Uttar Pradesh',
    district: 'Chitrakoot',
    city: 'Manikpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4d6',
    },
    state: 'Uttar Pradesh',
    district: 'Chitrakoot',
    city: 'Mau',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4d7',
    },
    state: 'Uttar Pradesh',
    district: 'Chitrakoot',
    city: 'Pahari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4d8',
    },
    state: 'Uttar Pradesh',
    district: 'Bulandshahr',
    city: 'Syana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4d9',
    },
    state: 'Uttar Pradesh',
    district: 'Chitrakoot',
    city: 'Ramnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4da',
    },
    state: 'Uttar Pradesh',
    district: 'Deoria',
    city: 'Baitalpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4db',
    },
    state: 'Uttar Pradesh',
    district: 'Deoria',
    city: 'Bankata',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4dc',
    },
    state: 'Uttar Pradesh',
    district: 'Deoria',
    city: 'Barhaj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4dd',
    },
    state: 'Uttar Pradesh',
    district: 'Deoria',
    city: 'Bhagalpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4de',
    },
    state: 'Uttar Pradesh',
    district: 'Deoria',
    city: 'Bhaluani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4df',
    },
    state: 'Uttar Pradesh',
    district: 'Deoria',
    city: 'Bhatni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4e0',
    },
    state: 'Uttar Pradesh',
    district: 'Deoria',
    city: 'Bhatpar Rani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4e1',
    },
    state: 'Uttar Pradesh',
    district: 'Deoria',
    city: 'Deoria Sadar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4e2',
    },
    state: 'Uttar Pradesh',
    district: 'Deoria',
    city: 'Gauri Bazar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4e3',
    },
    state: 'Uttar Pradesh',
    district: 'Deoria',
    city: 'Desai Deoria',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4e4',
    },
    state: 'Uttar Pradesh',
    district: 'Deoria',
    city: 'Lar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4e5',
    },
    state: 'Uttar Pradesh',
    district: 'Deoria',
    city: 'Rampur Karkhana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4e6',
    },
    state: 'Uttar Pradesh',
    district: 'Deoria',
    city: 'Rudrapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4e7',
    },
    state: 'Uttar Pradesh',
    district: 'Deoria',
    city: 'Salempur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4e8',
    },
    state: 'Uttar Pradesh',
    district: 'Deoria',
    city: 'Tarkalua',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4e9',
    },
    state: 'Uttar Pradesh',
    district: 'Etah',
    city: 'Aliganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4ea',
    },
    state: 'Uttar Pradesh',
    district: 'Etah',
    city: 'Awagarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4eb',
    },
    state: 'Uttar Pradesh',
    district: 'Etah',
    city: 'Jaithara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4ec',
    },
    state: 'Uttar Pradesh',
    district: 'Etah',
    city: 'Jalesar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4ed',
    },
    state: 'Uttar Pradesh',
    district: 'Etah',
    city: 'Marehra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4ee',
    },
    state: 'Uttar Pradesh',
    district: 'Etah',
    city: 'Nidhauli Kalan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4ef',
    },
    state: 'Uttar Pradesh',
    district: 'Deoria',
    city: 'Pathar Dewa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4f0',
    },
    state: 'Uttar Pradesh',
    district: 'Etah',
    city: 'Shitalpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4f1',
    },
    state: 'Uttar Pradesh',
    district: 'Etah',
    city: 'Sakit',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4f2',
    },
    state: 'Uttar Pradesh',
    district: 'Etawah',
    city: 'Basrehar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4f3',
    },
    state: 'Uttar Pradesh',
    district: 'Etawah',
    city: 'Bharthana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4f4',
    },
    state: 'Uttar Pradesh',
    district: 'Etawah',
    city: 'Chakarnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4f5',
    },
    state: 'Uttar Pradesh',
    district: 'Etawah',
    city: 'Barhpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4f6',
    },
    state: 'Uttar Pradesh',
    district: 'Etawah',
    city: 'Jaswantnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4f7',
    },
    state: 'Uttar Pradesh',
    district: 'Etawah',
    city: 'Mahewa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4f8',
    },
    state: 'Uttar Pradesh',
    district: 'Etawah',
    city: 'Sefai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4f9',
    },
    state: 'Uttar Pradesh',
    district: 'Etawah',
    city: 'Takha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4fa',
    },
    state: 'Uttar Pradesh',
    district: 'Farrukhabad',
    city: 'Barhpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4fb',
    },
    state: 'Uttar Pradesh',
    district: 'Farrukhabad',
    city: 'Kaimganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4fc',
    },
    state: 'Uttar Pradesh',
    district: 'Farrukhabad',
    city: 'Kamalganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4fd',
    },
    state: 'Uttar Pradesh',
    district: 'Farrukhabad',
    city: 'Nawabganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4fe',
    },
    state: 'Uttar Pradesh',
    district: 'Farrukhabad',
    city: 'Rajepur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f4ff',
    },
    state: 'Uttar Pradesh',
    district: 'Farrukhabad',
    city: 'Shamsabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f500',
    },
    state: 'Uttar Pradesh',
    district: 'Farrukhabad',
    city: 'Mohamdabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f501',
    },
    state: 'Uttar Pradesh',
    district: 'Fatehpur',
    city: 'Amauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f502',
    },
    state: 'Uttar Pradesh',
    district: 'Fatehpur',
    city: 'Airayan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f503',
    },
    state: 'Uttar Pradesh',
    district: 'Fatehpur',
    city: 'Asothar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f504',
    },
    state: 'Uttar Pradesh',
    district: 'Fatehpur',
    city: 'Bahua',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f505',
    },
    state: 'Uttar Pradesh',
    district: 'Fatehpur',
    city: 'Bhitaura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f506',
    },
    state: 'Uttar Pradesh',
    district: 'Fatehpur',
    city: 'Devmai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f507',
    },
    state: 'Uttar Pradesh',
    district: 'Fatehpur',
    city: 'Dhata',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f508',
    },
    state: 'Uttar Pradesh',
    district: 'Fatehpur',
    city: 'Haswa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f509',
    },
    state: 'Uttar Pradesh',
    district: 'Fatehpur',
    city: 'Hathgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f50a',
    },
    state: 'Uttar Pradesh',
    district: 'Fatehpur',
    city: 'Khajuha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f50b',
    },
    state: 'Uttar Pradesh',
    district: 'Fatehpur',
    city: 'Malwan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f50c',
    },
    state: 'Uttar Pradesh',
    district: 'Fatehpur',
    city: 'Telyani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f50d',
    },
    state: 'Uttar Pradesh',
    district: 'Fatehpur',
    city: 'Vijayipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f50e',
    },
    state: 'Uttar Pradesh',
    district: 'Firozabad',
    city: 'Araon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f50f',
    },
    state: 'Uttar Pradesh',
    district: 'Firozabad',
    city: 'Eka',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f510',
    },
    state: 'Uttar Pradesh',
    district: 'Firozabad',
    city: 'Firozabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f511',
    },
    state: 'Uttar Pradesh',
    district: 'Firozabad',
    city: 'Hathwant',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f512',
    },
    state: 'Uttar Pradesh',
    district: 'Firozabad',
    city: 'Jasrana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f513',
    },
    state: 'Uttar Pradesh',
    district: 'Firozabad',
    city: 'Madanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f514',
    },
    state: 'Uttar Pradesh',
    district: 'Firozabad',
    city: 'Narkhi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f515',
    },
    state: 'Uttar Pradesh',
    district: 'Firozabad',
    city: 'Tundla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f516',
    },
    state: 'Uttar Pradesh',
    district: 'Firozabad',
    city: 'Shikohabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f517',
    },
    state: 'Uttar Pradesh',
    district: 'Gautam Buddha Nagar',
    city: 'Dadri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f518',
    },
    state: 'Uttar Pradesh',
    district: 'Gautam Buddha Nagar',
    city: 'Bisrakh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f519',
    },
    state: 'Uttar Pradesh',
    district: 'Gautam Buddha Nagar',
    city: 'Dankaur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f51a',
    },
    state: 'Uttar Pradesh',
    district: 'Gautam Buddha Nagar',
    city: 'Jewar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f51b',
    },
    state: 'Uttar Pradesh',
    district: 'Ghaziabad',
    city: 'Loni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f51c',
    },
    state: 'Uttar Pradesh',
    district: 'Ghaziabad',
    city: 'Muradnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f51d',
    },
    state: 'Uttar Pradesh',
    district: 'Ghaziabad',
    city: 'Bhojpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f51e',
    },
    state: 'Uttar Pradesh',
    district: 'Ghaziabad',
    city: 'Rajapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f51f',
    },
    state: 'Uttar Pradesh',
    district: 'Ghazipur',
    city: 'Bhanwarkol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f520',
    },
    state: 'Uttar Pradesh',
    district: 'Ghazipur',
    city: 'Devkali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f521',
    },
    state: 'Uttar Pradesh',
    district: 'Ghazipur',
    city: 'Bhadaura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f522',
    },
    state: 'Uttar Pradesh',
    district: 'Ghazipur',
    city: 'Ghazipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f523',
    },
    state: 'Uttar Pradesh',
    district: 'Ghazipur',
    city: 'Jakhania',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f524',
    },
    state: 'Uttar Pradesh',
    district: 'Ghazipur',
    city: 'Karanda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f525',
    },
    state: 'Uttar Pradesh',
    district: 'Ghazipur',
    city: 'Kasimabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f526',
    },
    state: 'Uttar Pradesh',
    district: 'Ghazipur',
    city: 'Manihari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f527',
    },
    state: 'Uttar Pradesh',
    district: 'Ghazipur',
    city: 'Mardah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f528',
    },
    state: 'Uttar Pradesh',
    district: 'Ghazipur',
    city: 'Revatipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f529',
    },
    state: 'Uttar Pradesh',
    district: 'Ghazipur',
    city: 'Mohammadabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f52a',
    },
    state: 'Uttar Pradesh',
    district: 'Ghazipur',
    city: 'Saidpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f52b',
    },
    state: 'Uttar Pradesh',
    district: 'Ghazipur',
    city: 'Varachakwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f52c',
    },
    state: 'Uttar Pradesh',
    district: 'Ghazipur',
    city: 'Virno',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f52d',
    },
    state: 'Uttar Pradesh',
    district: 'Ghazipur',
    city: 'Zamania',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f52e',
    },
    state: 'Uttar Pradesh',
    district: 'Gonda',
    city: 'Babhanjot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f52f',
    },
    state: 'Uttar Pradesh',
    district: 'Ghazipur',
    city: 'Sadat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f530',
    },
    state: 'Uttar Pradesh',
    district: 'Gonda',
    city: 'Belsar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f531',
    },
    state: 'Uttar Pradesh',
    district: 'Gonda',
    city: 'Chhapia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f532',
    },
    state: 'Uttar Pradesh',
    district: 'Gonda',
    city: 'Colonelganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f533',
    },
    state: 'Uttar Pradesh',
    district: 'Gonda',
    city: 'Haldharmau',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f534',
    },
    state: 'Uttar Pradesh',
    district: 'Gonda',
    city: 'Itiathok',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f535',
    },
    state: 'Uttar Pradesh',
    district: 'Gonda',
    city: 'Jhanjhari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f536',
    },
    state: 'Uttar Pradesh',
    district: 'Gonda',
    city: 'Katra Bazar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f537',
    },
    state: 'Uttar Pradesh',
    district: 'Gonda',
    city: 'Mankapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f538',
    },
    state: 'Uttar Pradesh',
    district: 'Gonda',
    city: 'Mujehana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f539',
    },
    state: 'Uttar Pradesh',
    district: 'Gonda',
    city: 'Nawabganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f53a',
    },
    state: 'Uttar Pradesh',
    district: 'Gonda',
    city: 'Pandri Kripal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f53b',
    },
    state: 'Uttar Pradesh',
    district: 'Gonda',
    city: 'Paraspur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f53c',
    },
    state: 'Uttar Pradesh',
    district: 'Gonda',
    city: 'Rupaideeh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f53d',
    },
    state: 'Uttar Pradesh',
    district: 'Gonda',
    city: 'Wazirganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f53e',
    },
    state: 'Uttar Pradesh',
    district: 'Gorakhpur',
    city: 'Bansgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f53f',
    },
    state: 'Uttar Pradesh',
    district: 'Gorakhpur',
    city: 'Barhalganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f540',
    },
    state: 'Uttar Pradesh',
    district: 'Gorakhpur',
    city: 'Belghat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f541',
    },
    state: 'Uttar Pradesh',
    district: 'Gonda',
    city: 'Tarabganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f542',
    },
    state: 'Uttar Pradesh',
    district: 'Gorakhpur',
    city: 'Bharohiya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f543',
    },
    state: 'Uttar Pradesh',
    district: 'Gorakhpur',
    city: 'Bhathat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f544',
    },
    state: 'Uttar Pradesh',
    district: 'Gorakhpur',
    city: 'Brahmpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f545',
    },
    state: 'Uttar Pradesh',
    district: 'Gorakhpur',
    city: 'Campierganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f546',
    },
    state: 'Uttar Pradesh',
    district: 'Gorakhpur',
    city: 'Chargawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f547',
    },
    state: 'Uttar Pradesh',
    district: 'Gorakhpur',
    city: 'Gagaha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f548',
    },
    state: 'Uttar Pradesh',
    district: 'Gorakhpur',
    city: 'Gola',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f549',
    },
    state: 'Uttar Pradesh',
    district: 'Gorakhpur',
    city: 'Jangal Kaudia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f54a',
    },
    state: 'Uttar Pradesh',
    district: 'Gorakhpur',
    city: 'Kauri Ram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f54b',
    },
    state: 'Uttar Pradesh',
    district: 'Gorakhpur',
    city: 'Khajni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f54c',
    },
    state: 'Uttar Pradesh',
    district: 'Gorakhpur',
    city: 'Khorabar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f54d',
    },
    state: 'Uttar Pradesh',
    district: 'Gorakhpur',
    city: 'Pali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f54e',
    },
    state: 'Uttar Pradesh',
    district: 'Gorakhpur',
    city: 'Piprauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f54f',
    },
    state: 'Uttar Pradesh',
    district: 'Gorakhpur',
    city: 'Sahjanawa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f550',
    },
    state: 'Uttar Pradesh',
    district: 'Gorakhpur',
    city: 'Pipraich',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f551',
    },
    state: 'Uttar Pradesh',
    district: 'Gorakhpur',
    city: 'Sardarnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f552',
    },
    state: 'Uttar Pradesh',
    district: 'Gorakhpur',
    city: 'Uruwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f553',
    },
    state: 'Uttar Pradesh',
    district: 'Hamirpur',
    city: 'Gohand',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f554',
    },
    state: 'Uttar Pradesh',
    district: 'Hamirpur',
    city: 'Maudaha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f555',
    },
    state: 'Uttar Pradesh',
    district: 'Hamirpur',
    city: 'Muskara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f556',
    },
    state: 'Uttar Pradesh',
    district: 'Hamirpur',
    city: 'Kurara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f557',
    },
    state: 'Uttar Pradesh',
    district: 'Hamirpur',
    city: 'Rath',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f558',
    },
    state: 'Uttar Pradesh',
    district: 'Hamirpur',
    city: 'Sarila',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f559',
    },
    state: 'Uttar Pradesh',
    district: 'Hapur',
    city: 'Dhaulana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f55a',
    },
    state: 'Uttar Pradesh',
    district: 'Hamirpur',
    city: 'Sumerpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f55b',
    },
    state: 'Uttar Pradesh',
    district: 'Hapur',
    city: 'Garh Mukteshwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f55c',
    },
    state: 'Uttar Pradesh',
    district: 'Hapur',
    city: 'Hapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f55d',
    },
    state: 'Uttar Pradesh',
    district: 'Hapur',
    city: 'Simbhawali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f55e',
    },
    state: 'Uttar Pradesh',
    district: 'Hardoi',
    city: 'Bawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f55f',
    },
    state: 'Uttar Pradesh',
    district: 'Hardoi',
    city: 'Behendar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f560',
    },
    state: 'Uttar Pradesh',
    district: 'Hardoi',
    city: 'Bharawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f561',
    },
    state: 'Uttar Pradesh',
    district: 'Hardoi',
    city: 'Ahirori',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f562',
    },
    state: 'Uttar Pradesh',
    district: 'Hardoi',
    city: 'Bharkhani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f563',
    },
    state: 'Uttar Pradesh',
    district: 'Hardoi',
    city: 'Bilgram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f564',
    },
    state: 'Uttar Pradesh',
    district: 'Hardoi',
    city: 'Hariyawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f565',
    },
    state: 'Uttar Pradesh',
    district: 'Hardoi',
    city: 'Harpalpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f566',
    },
    state: 'Uttar Pradesh',
    district: 'Hardoi',
    city: 'Kachauna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f567',
    },
    state: 'Uttar Pradesh',
    district: 'Hardoi',
    city: 'Kothawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f568',
    },
    state: 'Uttar Pradesh',
    district: 'Hardoi',
    city: 'Mallawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f569',
    },
    state: 'Uttar Pradesh',
    district: 'Hardoi',
    city: 'Madhoganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f56a',
    },
    state: 'Uttar Pradesh',
    district: 'Hardoi',
    city: 'Pihani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f56b',
    },
    state: 'Uttar Pradesh',
    district: 'Hardoi',
    city: 'Sandi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f56c',
    },
    state: 'Uttar Pradesh',
    district: 'Hardoi',
    city: 'Sandila',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f56d',
    },
    state: 'Uttar Pradesh',
    district: 'Hardoi',
    city: 'Shahabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f56e',
    },
    state: 'Uttar Pradesh',
    district: 'Hardoi',
    city: 'Tandiyawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f56f',
    },
    state: 'Uttar Pradesh',
    district: 'Hardoi',
    city: 'Todarpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f570',
    },
    state: 'Uttar Pradesh',
    district: 'Hathras',
    city: 'Hasayan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f571',
    },
    state: 'Uttar Pradesh',
    district: 'Hathras',
    city: 'Hathras',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f572',
    },
    state: 'Uttar Pradesh',
    district: 'Hathras',
    city: 'Mursan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f573',
    },
    state: 'Uttar Pradesh',
    district: 'Hathras',
    city: 'Sadabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f574',
    },
    state: 'Uttar Pradesh',
    district: 'Hathras',
    city: 'Sasni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f575',
    },
    state: 'Uttar Pradesh',
    district: 'Hathras',
    city: 'Sehpau',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f576',
    },
    state: 'Uttar Pradesh',
    district: 'Hathras',
    city: 'Sikandrarao',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f577',
    },
    state: 'Uttar Pradesh',
    district: 'Jalaun',
    city: 'Dakore',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f578',
    },
    state: 'Uttar Pradesh',
    district: 'Jalaun',
    city: 'Jalaun',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f579',
    },
    state: 'Uttar Pradesh',
    district: 'Jalaun',
    city: 'Kadaura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f57a',
    },
    state: 'Uttar Pradesh',
    district: 'Jalaun',
    city: 'Konch',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f57b',
    },
    state: 'Uttar Pradesh',
    district: 'Jalaun',
    city: 'Kuthaund',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f57c',
    },
    state: 'Uttar Pradesh',
    district: 'Jalaun',
    city: 'Madhogarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f57d',
    },
    state: 'Uttar Pradesh',
    district: 'Jalaun',
    city: 'Maheva',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f57e',
    },
    state: 'Uttar Pradesh',
    district: 'Hardoi',
    city: 'Sursa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f57f',
    },
    state: 'Uttar Pradesh',
    district: 'Jalaun',
    city: 'Nadigaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f580',
    },
    state: 'Uttar Pradesh',
    district: 'Jalaun',
    city: 'Rampura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f581',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Baksha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f582',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Barasathi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f583',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Dharma Pur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f584',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Dobhi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f585',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Jalal Pur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f586',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Badla Pur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f587',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Karanja Kala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f588',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Kerakat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f589',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Khuthan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f58a',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Maharaj Ganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f58b',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Mariyahu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f58c',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Mufti Ganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f58d',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Mungra Badshah Pur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f58e',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Machchali Shahar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f58f',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Ram Pur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f590',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Ram Nagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f591',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Shah Ganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f592',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Sikrara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f593',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Sirkoni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f594',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Suitha Kala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f595',
    },
    state: 'Uttar Pradesh',
    district: 'Jaunpur',
    city: 'Sujan Ganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f596',
    },
    state: 'Uttar Pradesh',
    district: 'Jhansi',
    city: 'Babina',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f597',
    },
    state: 'Uttar Pradesh',
    district: 'Jhansi',
    city: 'Badagaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f598',
    },
    state: 'Uttar Pradesh',
    district: 'Jhansi',
    city: 'Bamaur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f599',
    },
    state: 'Uttar Pradesh',
    district: 'Jhansi',
    city: 'Bangra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f59a',
    },
    state: 'Uttar Pradesh',
    district: 'Jhansi',
    city: 'Gursarai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f59b',
    },
    state: 'Uttar Pradesh',
    district: 'Jhansi',
    city: 'Chirgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f59c',
    },
    state: 'Uttar Pradesh',
    district: 'Jhansi',
    city: 'Mauranipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f59d',
    },
    state: 'Uttar Pradesh',
    district: 'Kannauj',
    city: 'Chhibramau',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f59e',
    },
    state: 'Uttar Pradesh',
    district: 'Jhansi',
    city: 'Moth',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f59f',
    },
    state: 'Uttar Pradesh',
    district: 'Kannauj',
    city: 'Gughrapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5a0',
    },
    state: 'Uttar Pradesh',
    district: 'Kannauj',
    city: 'Haseran',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5a1',
    },
    state: 'Uttar Pradesh',
    district: 'Kannauj',
    city: 'Jalalabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5a2',
    },
    state: 'Uttar Pradesh',
    district: 'Kannauj',
    city: 'Saurikh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5a3',
    },
    state: 'Uttar Pradesh',
    district: 'Kannauj',
    city: 'Talgram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5a4',
    },
    state: 'Uttar Pradesh',
    district: 'Kannauj',
    city: 'Kannauj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5a5',
    },
    state: 'Uttar Pradesh',
    district: 'Kannauj',
    city: 'Umarda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5a6',
    },
    state: 'Uttar Pradesh',
    district: 'Kanpur Dehat',
    city: 'Akbarpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5a7',
    },
    state: 'Uttar Pradesh',
    district: 'Kanpur Dehat',
    city: 'Derapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5a8',
    },
    state: 'Uttar Pradesh',
    district: 'Kanpur Dehat',
    city: 'Amrodha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5a9',
    },
    state: 'Uttar Pradesh',
    district: 'Kanpur Dehat',
    city: 'Jhinjhak',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5aa',
    },
    state: 'Uttar Pradesh',
    district: 'Kanpur Dehat',
    city: 'Maitha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5ab',
    },
    state: 'Uttar Pradesh',
    district: 'Kanpur Dehat',
    city: 'Malasa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5ac',
    },
    state: 'Uttar Pradesh',
    district: 'Kanpur Dehat',
    city: 'Rasulabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5ad',
    },
    state: 'Uttar Pradesh',
    district: 'Kanpur Dehat',
    city: 'Sandalpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5ae',
    },
    state: 'Uttar Pradesh',
    district: 'Kanpur Dehat',
    city: 'Sarbankhera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5af',
    },
    state: 'Uttar Pradesh',
    district: 'Kanpur Nagar',
    city: 'Bhitargaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5b0',
    },
    state: 'Uttar Pradesh',
    district: 'Kanpur Nagar',
    city: 'Bilhaur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5b1',
    },
    state: 'Uttar Pradesh',
    district: 'Kanpur Nagar',
    city: 'Chaubeypur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5b2',
    },
    state: 'Uttar Pradesh',
    district: 'Kanpur Nagar',
    city: 'Ghatampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5b3',
    },
    state: 'Uttar Pradesh',
    district: 'Kanpur Nagar',
    city: 'Kakwan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5b4',
    },
    state: 'Uttar Pradesh',
    district: 'Kanpur Nagar',
    city: 'Kalyanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5b5',
    },
    state: 'Uttar Pradesh',
    district: 'Kanpur Nagar',
    city: 'Patara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5b6',
    },
    state: 'Uttar Pradesh',
    district: 'Kanpur Dehat',
    city: 'Rajpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5b7',
    },
    state: 'Uttar Pradesh',
    district: 'Kanpur Nagar',
    city: 'Sarsol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5b8',
    },
    state: 'Uttar Pradesh',
    district: 'Kanpur Nagar',
    city: 'Shivrajpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5b9',
    },
    state: 'Uttar Pradesh',
    district: 'Kasganj',
    city: 'Amanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5ba',
    },
    state: 'Uttar Pradesh',
    district: 'Kasganj',
    city: 'Ganj Dundwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5bb',
    },
    state: 'Uttar Pradesh',
    district: 'Kasganj',
    city: 'Kasganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5bc',
    },
    state: 'Uttar Pradesh',
    district: 'Kasganj',
    city: 'Patiyali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5bd',
    },
    state: 'Uttar Pradesh',
    district: 'Kasganj',
    city: 'Sahawar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5be',
    },
    state: 'Uttar Pradesh',
    district: 'Kasganj',
    city: 'Sidhpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5bf',
    },
    state: 'Uttar Pradesh',
    district: 'Kasganj',
    city: 'Soron',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5c0',
    },
    state: 'Uttar Pradesh',
    district: 'Kaushambi',
    city: 'Chail',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5c1',
    },
    state: 'Uttar Pradesh',
    district: 'Kaushambi',
    city: 'Kara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5c2',
    },
    state: 'Uttar Pradesh',
    district: 'Kaushambi',
    city: 'Kaushambi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5c3',
    },
    state: 'Uttar Pradesh',
    district: 'Kaushambi',
    city: 'Manjhanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5c4',
    },
    state: 'Uttar Pradesh',
    district: 'Kaushambi',
    city: 'Mooratganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5c5',
    },
    state: 'Uttar Pradesh',
    district: 'Kanpur Nagar',
    city: 'Vidhunu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5c6',
    },
    state: 'Uttar Pradesh',
    district: 'Kaushambi',
    city: 'Sarsawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5c7',
    },
    state: 'Uttar Pradesh',
    district: 'Kaushambi',
    city: 'Nevada',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5c8',
    },
    state: 'Uttar Pradesh',
    district: 'Kaushambi',
    city: 'Sirathu',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5c9',
    },
    state: 'Uttar Pradesh',
    district: 'Lakhimpur Kheri',
    city: 'Bankeyganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5ca',
    },
    state: 'Uttar Pradesh',
    district: 'Lakhimpur Kheri',
    city: 'Behjam',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5cb',
    },
    state: 'Uttar Pradesh',
    district: 'Lakhimpur Kheri',
    city: 'Bijuwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5cc',
    },
    state: 'Uttar Pradesh',
    district: 'Lakhimpur Kheri',
    city: 'Dhaurhara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5cd',
    },
    state: 'Uttar Pradesh',
    district: 'Lakhimpur Kheri',
    city: 'Isanagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5ce',
    },
    state: 'Uttar Pradesh',
    district: 'Lakhimpur Kheri',
    city: 'Kumbhigola',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5cf',
    },
    state: 'Uttar Pradesh',
    district: 'Lakhimpur Kheri',
    city: 'Mitauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5d0',
    },
    state: 'Uttar Pradesh',
    district: 'Lakhimpur Kheri',
    city: 'Lakhimpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5d1',
    },
    state: 'Uttar Pradesh',
    district: 'Lakhimpur Kheri',
    city: 'Mohammadi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5d2',
    },
    state: 'Uttar Pradesh',
    district: 'Lakhimpur Kheri',
    city: 'Nakaha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5d3',
    },
    state: 'Uttar Pradesh',
    district: 'Lakhimpur Kheri',
    city: 'Nighasan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5d4',
    },
    state: 'Uttar Pradesh',
    district: 'Lakhimpur Kheri',
    city: 'Pasgawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5d5',
    },
    state: 'Uttar Pradesh',
    district: 'Lakhimpur Kheri',
    city: 'Phoolbehar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5d6',
    },
    state: 'Uttar Pradesh',
    district: 'Lakhimpur Kheri',
    city: 'Ramia Behar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5d7',
    },
    state: 'Uttar Pradesh',
    district: 'Kushinagar',
    city: 'Dudhahi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5d8',
    },
    state: 'Uttar Pradesh',
    district: 'Kushinagar',
    city: 'Fazilnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5d9',
    },
    state: 'Uttar Pradesh',
    district: 'Kushinagar',
    city: 'Hata',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5da',
    },
    state: 'Uttar Pradesh',
    district: 'Kushinagar',
    city: 'Kaptainganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5db',
    },
    state: 'Uttar Pradesh',
    district: 'Kushinagar',
    city: 'Kasaya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5dc',
    },
    state: 'Uttar Pradesh',
    district: 'Kushinagar',
    city: 'Khadda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5dd',
    },
    state: 'Uttar Pradesh',
    district: 'Lakhimpur Kheri',
    city: 'Palia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5de',
    },
    state: 'Uttar Pradesh',
    district: 'Kushinagar',
    city: 'Motichak',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5df',
    },
    state: 'Uttar Pradesh',
    district: 'Kushinagar',
    city: 'Nebua Naurangia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5e0',
    },
    state: 'Uttar Pradesh',
    district: 'Kushinagar',
    city: 'Kubernath',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5e1',
    },
    state: 'Uttar Pradesh',
    district: 'Kushinagar',
    city: 'Padrauna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5e2',
    },
    state: 'Uttar Pradesh',
    district: 'Kushinagar',
    city: 'Seorahi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5e3',
    },
    state: 'Uttar Pradesh',
    district: 'Kushinagar',
    city: 'Sukrauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5e4',
    },
    state: 'Uttar Pradesh',
    district: 'Kushinagar',
    city: 'Tamkuhiraj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5e5',
    },
    state: 'Uttar Pradesh',
    district: 'Kushinagar',
    city: 'Ramkola',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5e6',
    },
    state: 'Uttar Pradesh',
    district: 'Kushinagar',
    city: 'Vishunpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5e7',
    },
    state: 'Uttar Pradesh',
    district: 'Lalitpur',
    city: 'Birdha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5e8',
    },
    state: 'Uttar Pradesh',
    district: 'Lalitpur',
    city: 'Bar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5e9',
    },
    state: 'Uttar Pradesh',
    district: 'Lalitpur',
    city: 'Jakhaura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5ea',
    },
    state: 'Uttar Pradesh',
    district: 'Lalitpur',
    city: 'Mandawara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5eb',
    },
    state: 'Uttar Pradesh',
    district: 'Lalitpur',
    city: 'Mehroni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5ec',
    },
    state: 'Uttar Pradesh',
    district: 'Lalitpur',
    city: 'Talbehat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5ed',
    },
    state: 'Uttar Pradesh',
    district: 'Lucknow',
    city: 'Chinhat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5ee',
    },
    state: 'Uttar Pradesh',
    district: 'Lucknow',
    city: 'Gosaiganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5ef',
    },
    state: 'Uttar Pradesh',
    district: 'Lucknow',
    city: 'Kakori',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5f0',
    },
    state: 'Uttar Pradesh',
    district: 'Lucknow',
    city: 'Mal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5f1',
    },
    state: 'Uttar Pradesh',
    district: 'Lucknow',
    city: 'Malihabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5f2',
    },
    state: 'Uttar Pradesh',
    district: 'Lucknow',
    city: 'Mohanlalganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5f3',
    },
    state: 'Uttar Pradesh',
    district: 'Lucknow',
    city: 'Sarojaninagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5f4',
    },
    state: 'Uttar Pradesh',
    district: 'Maharajganj',
    city: 'Bridgemanganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5f5',
    },
    state: 'Uttar Pradesh',
    district: 'Maharajganj',
    city: 'Dhani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5f6',
    },
    state: 'Uttar Pradesh',
    district: 'Maharajganj',
    city: 'Ghughli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5f7',
    },
    state: 'Uttar Pradesh',
    district: 'Maharajganj',
    city: 'Lakshmipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5f8',
    },
    state: 'Uttar Pradesh',
    district: 'Maharajganj',
    city: 'Mahrajganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5f9',
    },
    state: 'Uttar Pradesh',
    district: 'Maharajganj',
    city: 'Mithaura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5fa',
    },
    state: 'Uttar Pradesh',
    district: 'Lucknow',
    city: 'Bakshi-Ka-Talab',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5fb',
    },
    state: 'Uttar Pradesh',
    district: 'Maharajganj',
    city: 'Nichlaul',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5fc',
    },
    state: 'Uttar Pradesh',
    district: 'Maharajganj',
    city: 'Paniyara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5fd',
    },
    state: 'Uttar Pradesh',
    district: 'Maharajganj',
    city: 'Partawal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5fe',
    },
    state: 'Uttar Pradesh',
    district: 'Maharajganj',
    city: 'Pharenda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f5ff',
    },
    state: 'Uttar Pradesh',
    district: 'Maharajganj',
    city: 'Nautanwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f600',
    },
    state: 'Uttar Pradesh',
    district: 'Maharajganj',
    city: 'Siswa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f601',
    },
    state: 'Uttar Pradesh',
    district: 'Mahoba',
    city: 'Jaitpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f602',
    },
    state: 'Uttar Pradesh',
    district: 'Mahoba',
    city: 'Charkhari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f603',
    },
    state: 'Uttar Pradesh',
    district: 'Mahoba',
    city: 'Kabrai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f604',
    },
    state: 'Uttar Pradesh',
    district: 'Mahoba',
    city: 'Panwari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f605',
    },
    state: 'Uttar Pradesh',
    district: 'Mainpuri',
    city: 'Barnahal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f606',
    },
    state: 'Uttar Pradesh',
    district: 'Mainpuri',
    city: 'Ghiror',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f607',
    },
    state: 'Uttar Pradesh',
    district: 'Mainpuri',
    city: 'Jageer',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f608',
    },
    state: 'Uttar Pradesh',
    district: 'Mainpuri',
    city: 'Karhal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f609',
    },
    state: 'Uttar Pradesh',
    district: 'Mainpuri',
    city: 'Kishni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f60a',
    },
    state: 'Uttar Pradesh',
    district: 'Mainpuri',
    city: 'Bewar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f60b',
    },
    state: 'Uttar Pradesh',
    district: 'Mainpuri',
    city: 'Mainpuri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f60c',
    },
    state: 'Uttar Pradesh',
    district: 'Mainpuri',
    city: 'Kuraoli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f60d',
    },
    state: 'Uttar Pradesh',
    district: 'Mainpuri',
    city: 'Sultanganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f60e',
    },
    state: 'Uttar Pradesh',
    district: 'Mathura',
    city: 'Baldeo',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f60f',
    },
    state: 'Uttar Pradesh',
    district: 'Mathura',
    city: 'Chaumuha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f610',
    },
    state: 'Uttar Pradesh',
    district: 'Mathura',
    city: 'Chhata',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f611',
    },
    state: 'Uttar Pradesh',
    district: 'Mathura',
    city: 'Farah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f612',
    },
    state: 'Uttar Pradesh',
    district: 'Mathura',
    city: 'Govardhan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f613',
    },
    state: 'Uttar Pradesh',
    district: 'Mathura',
    city: 'Mat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f614',
    },
    state: 'Uttar Pradesh',
    district: 'Mathura',
    city: 'Mathura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f615',
    },
    state: 'Uttar Pradesh',
    district: 'Mathura',
    city: 'Nandgaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f616',
    },
    state: 'Uttar Pradesh',
    district: 'Mathura',
    city: 'Raya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f617',
    },
    state: 'Uttar Pradesh',
    district: 'Mau',
    city: 'Badraon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f618',
    },
    state: 'Uttar Pradesh',
    district: 'Mau',
    city: 'Dohri Ghat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f619',
    },
    state: 'Uttar Pradesh',
    district: 'Mau',
    city: 'Fatehpur Madaun',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f61a',
    },
    state: 'Uttar Pradesh',
    district: 'Mathura',
    city: 'Nohjhil',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f61b',
    },
    state: 'Uttar Pradesh',
    district: 'Mau',
    city: 'Ghosi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f61c',
    },
    state: 'Uttar Pradesh',
    district: 'Mau',
    city: 'Kopaganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f61d',
    },
    state: 'Uttar Pradesh',
    district: 'Mau',
    city: 'Mohammadabad Gohana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f61e',
    },
    state: 'Uttar Pradesh',
    district: 'Mau',
    city: 'Pardaha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f61f',
    },
    state: 'Uttar Pradesh',
    district: 'Mau',
    city: 'Ratanpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f620',
    },
    state: 'Uttar Pradesh',
    district: 'Meerut',
    city: 'Daurala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f621',
    },
    state: 'Uttar Pradesh',
    district: 'Mau',
    city: 'Ranipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f622',
    },
    state: 'Uttar Pradesh',
    district: 'Meerut',
    city: 'Hastinapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f623',
    },
    state: 'Uttar Pradesh',
    district: 'Meerut',
    city: 'Janikhurd',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f624',
    },
    state: 'Uttar Pradesh',
    district: 'Meerut',
    city: 'Kharkhoda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f625',
    },
    state: 'Uttar Pradesh',
    district: 'Meerut',
    city: 'Machra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f626',
    },
    state: 'Uttar Pradesh',
    district: 'Meerut',
    city: 'Mawana Kalan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f627',
    },
    state: 'Uttar Pradesh',
    district: 'Meerut',
    city: 'Meerut',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f628',
    },
    state: 'Uttar Pradesh',
    district: 'Meerut',
    city: 'Parikshitgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f629',
    },
    state: 'Uttar Pradesh',
    district: 'Meerut',
    city: 'Rajpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f62a',
    },
    state: 'Uttar Pradesh',
    district: 'Meerut',
    city: 'Sardhana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f62b',
    },
    state: 'Uttar Pradesh',
    district: 'Meerut',
    city: 'Sarurpur Khurd',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f62c',
    },
    state: 'Uttar Pradesh',
    district: 'Meerut',
    city: 'Rohta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f62d',
    },
    state: 'Uttar Pradesh',
    district: 'Mirzapur',
    city: 'Hallia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f62e',
    },
    state: 'Uttar Pradesh',
    district: 'Mirzapur',
    city: 'Chhanvey',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f62f',
    },
    state: 'Uttar Pradesh',
    district: 'Mirzapur',
    city: 'Jamalpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f630',
    },
    state: 'Uttar Pradesh',
    district: 'Mirzapur',
    city: 'Kon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f631',
    },
    state: 'Uttar Pradesh',
    district: 'Mirzapur',
    city: 'Lalganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f632',
    },
    state: 'Uttar Pradesh',
    district: 'Mirzapur',
    city: 'Majhawa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f633',
    },
    state: 'Uttar Pradesh',
    district: 'Mirzapur',
    city: 'Nagar (City)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f634',
    },
    state: 'Uttar Pradesh',
    district: 'Mirzapur',
    city: 'Narainpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f635',
    },
    state: 'Uttar Pradesh',
    district: 'Mirzapur',
    city: 'Patehra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f636',
    },
    state: 'Uttar Pradesh',
    district: 'Mirzapur',
    city: 'Pahari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f637',
    },
    state: 'Uttar Pradesh',
    district: 'Mirzapur',
    city: 'Rajgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f638',
    },
    state: 'Uttar Pradesh',
    district: 'Mirzapur',
    city: 'Shikhar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f639',
    },
    state: 'Uttar Pradesh',
    district: 'Moradabad',
    city: 'Bhagatpur Tanda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f63a',
    },
    state: 'Uttar Pradesh',
    district: 'Moradabad',
    city: 'Bilari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f63b',
    },
    state: 'Uttar Pradesh',
    district: 'Moradabad',
    city: 'Chhajlet',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f63c',
    },
    state: 'Uttar Pradesh',
    district: 'Moradabad',
    city: 'Kundarki',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f63d',
    },
    state: 'Uttar Pradesh',
    district: 'Moradabad',
    city: 'Moradabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f63e',
    },
    state: 'Uttar Pradesh',
    district: 'Moradabad',
    city: 'Munda Pandey',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f63f',
    },
    state: 'Uttar Pradesh',
    district: 'Moradabad',
    city: 'Thakurdwara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f640',
    },
    state: 'Uttar Pradesh',
    district: 'Muzaffarnagar',
    city: 'Baghara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f641',
    },
    state: 'Uttar Pradesh',
    district: 'Muzaffarnagar',
    city: 'Budhana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f642',
    },
    state: 'Uttar Pradesh',
    district: 'Muzaffarnagar',
    city: 'Charthawal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f643',
    },
    state: 'Uttar Pradesh',
    district: 'Muzaffarnagar',
    city: 'Jansath',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f644',
    },
    state: 'Uttar Pradesh',
    district: 'Muzaffarnagar',
    city: 'Khatauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f645',
    },
    state: 'Uttar Pradesh',
    district: 'Muzaffarnagar',
    city: 'Morna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f646',
    },
    state: 'Uttar Pradesh',
    district: 'Muzaffarnagar',
    city: 'Muzaffarnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f647',
    },
    state: 'Uttar Pradesh',
    district: 'Muzaffarnagar',
    city: 'Purkaji',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f648',
    },
    state: 'Uttar Pradesh',
    district: 'Muzaffarnagar',
    city: 'Shahpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f649',
    },
    state: 'Uttar Pradesh',
    district: 'Pilibhit',
    city: 'Amariya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f64a',
    },
    state: 'Uttar Pradesh',
    district: 'Pilibhit',
    city: 'Barkhera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f64b',
    },
    state: 'Uttar Pradesh',
    district: 'Pilibhit',
    city: 'Bilsanda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f64c',
    },
    state: 'Uttar Pradesh',
    district: 'Moradabad',
    city: 'Dilari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f64d',
    },
    state: 'Uttar Pradesh',
    district: 'Pilibhit',
    city: 'Bisalpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f64e',
    },
    state: 'Uttar Pradesh',
    district: 'Pilibhit',
    city: 'Lalaurikhera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f64f',
    },
    state: 'Uttar Pradesh',
    district: 'Pilibhit',
    city: 'Marori',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f650',
    },
    state: 'Uttar Pradesh',
    district: 'Pilibhit',
    city: 'Puranpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f651',
    },
    state: 'Uttar Pradesh',
    district: 'Pratapgarh',
    city: 'Aspur Deosara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f652',
    },
    state: 'Uttar Pradesh',
    district: 'Pratapgarh',
    city: 'Baba Belkharnath Dham',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f653',
    },
    state: 'Uttar Pradesh',
    district: 'Pratapgarh',
    city: 'Babaganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f654',
    },
    state: 'Uttar Pradesh',
    district: 'Pratapgarh',
    city: 'Bihar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f655',
    },
    state: 'Uttar Pradesh',
    district: 'Pratapgarh',
    city: 'Gaura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f656',
    },
    state: 'Uttar Pradesh',
    district: 'Pratapgarh',
    city: 'Kalakankar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f657',
    },
    state: 'Uttar Pradesh',
    district: 'Pratapgarh',
    city: 'Lakshamanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f658',
    },
    state: 'Uttar Pradesh',
    district: 'Pratapgarh',
    city: 'Kunda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f659',
    },
    state: 'Uttar Pradesh',
    district: 'Pratapgarh',
    city: 'Magraura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f65a',
    },
    state: 'Uttar Pradesh',
    district: 'Pratapgarh',
    city: 'Lalganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f65b',
    },
    state: 'Uttar Pradesh',
    district: 'Pratapgarh',
    city: 'Mandhata',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f65c',
    },
    state: 'Uttar Pradesh',
    district: 'Pratapgarh',
    city: 'Pratapgarh (Sadar)',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f65d',
    },
    state: 'Uttar Pradesh',
    district: 'Pratapgarh',
    city: 'Patti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f65e',
    },
    state: 'Uttar Pradesh',
    district: 'Pratapgarh',
    city: 'Rampur Sanramgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f65f',
    },
    state: 'Uttar Pradesh',
    district: 'Pratapgarh',
    city: 'Sandwa Chandrika',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f660',
    },
    state: 'Uttar Pradesh',
    district: 'Pratapgarh',
    city: 'Sangipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f661',
    },
    state: 'Uttar Pradesh',
    district: 'Pratapgarh',
    city: 'Shivgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f662',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Bahadurpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f663',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Bahria',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f664',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Bhagwatpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f665',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Chaka',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f666',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Dhanupur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f667',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Handia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f668',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Holagarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f669',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Jasra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f66a',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Karchhana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f66b',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Kaudhiyara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f66c',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Kaurihar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f66d',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Koraon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f66e',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Manda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f66f',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Mauaima',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f670',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Meja',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f671',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Phulpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f672',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Pratappur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f673',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Sahson',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f674',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Saidabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f675',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Shankargarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f676',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Soraon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f677',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Sringverpur Dham',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f678',
    },
    state: 'Uttar Pradesh',
    district: 'Prayagraj',
    city: 'Uruwan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f679',
    },
    state: 'Uttar Pradesh',
    district: 'Rae Bareli',
    city: 'Amawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f67a',
    },
    state: 'Uttar Pradesh',
    district: 'Rae Bareli',
    city: 'Bachharawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f67b',
    },
    state: 'Uttar Pradesh',
    district: 'Rae Bareli',
    city: 'Chhatoh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f67c',
    },
    state: 'Uttar Pradesh',
    district: 'Rae Bareli',
    city: 'Dalmau',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f67d',
    },
    state: 'Uttar Pradesh',
    district: 'Rae Bareli',
    city: 'Deenshah Gaura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f67e',
    },
    state: 'Uttar Pradesh',
    district: 'Rae Bareli',
    city: 'Dih',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f67f',
    },
    state: 'Uttar Pradesh',
    district: 'Rae Bareli',
    city: 'Harchandpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f680',
    },
    state: 'Uttar Pradesh',
    district: 'Rae Bareli',
    city: 'Jagatpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f681',
    },
    state: 'Uttar Pradesh',
    district: 'Rae Bareli',
    city: 'Khiron',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f682',
    },
    state: 'Uttar Pradesh',
    district: 'Rae Bareli',
    city: 'Lalganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f683',
    },
    state: 'Uttar Pradesh',
    district: 'Rae Bareli',
    city: 'Mahrajganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f684',
    },
    state: 'Uttar Pradesh',
    district: 'Rae Bareli',
    city: 'Rahi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f685',
    },
    state: 'Uttar Pradesh',
    district: 'Rae Bareli',
    city: 'Salon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f686',
    },
    state: 'Uttar Pradesh',
    district: 'Rae Bareli',
    city: 'Rohania',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f687',
    },
    state: 'Uttar Pradesh',
    district: 'Rae Bareli',
    city: 'Sareni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f688',
    },
    state: 'Uttar Pradesh',
    district: 'Rae Bareli',
    city: 'Sataon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f689',
    },
    state: 'Uttar Pradesh',
    district: 'Rae Bareli',
    city: 'Unchahar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f68a',
    },
    state: 'Uttar Pradesh',
    district: 'Rampur',
    city: 'Bilaspur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f68b',
    },
    state: 'Uttar Pradesh',
    district: 'Rampur',
    city: 'Chamraon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f68c',
    },
    state: 'Uttar Pradesh',
    district: 'Rampur',
    city: 'Milak',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f68d',
    },
    state: 'Uttar Pradesh',
    district: 'Rae Bareli',
    city: 'Shivgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f68e',
    },
    state: 'Uttar Pradesh',
    district: 'Rampur',
    city: 'Shahabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f68f',
    },
    state: 'Uttar Pradesh',
    district: 'Rampur',
    city: 'Saidnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f690',
    },
    state: 'Uttar Pradesh',
    district: 'Rampur',
    city: 'Suar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f691',
    },
    state: 'Uttar Pradesh',
    district: 'Saharanpur',
    city: 'Ballia Kheri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f692',
    },
    state: 'Uttar Pradesh',
    district: 'Saharanpur',
    city: 'Deoband',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f693',
    },
    state: 'Uttar Pradesh',
    district: 'Saharanpur',
    city: 'Muzaffarabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f694',
    },
    state: 'Uttar Pradesh',
    district: 'Saharanpur',
    city: 'Nagal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f695',
    },
    state: 'Uttar Pradesh',
    district: 'Saharanpur',
    city: 'Gangoh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f696',
    },
    state: 'Uttar Pradesh',
    district: 'Saharanpur',
    city: 'Nakur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f697',
    },
    state: 'Uttar Pradesh',
    district: 'Saharanpur',
    city: 'Nanauta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f698',
    },
    state: 'Uttar Pradesh',
    district: 'Saharanpur',
    city: 'Rampur Maniharan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f699',
    },
    state: 'Uttar Pradesh',
    district: 'Saharanpur',
    city: 'Sadauli Qadeem',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f69a',
    },
    state: 'Uttar Pradesh',
    district: 'Saharanpur',
    city: 'Sarsawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f69b',
    },
    state: 'Uttar Pradesh',
    district: 'Sambhal',
    city: 'Asmauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f69c',
    },
    state: 'Uttar Pradesh',
    district: 'Sambhal',
    city: 'Bahjoi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f69d',
    },
    state: 'Uttar Pradesh',
    district: 'Sambhal',
    city: 'Baniyakhera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f69e',
    },
    state: 'Uttar Pradesh',
    district: 'Sambhal',
    city: 'Gunnaur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f69f',
    },
    state: 'Uttar Pradesh',
    district: 'Sambhal',
    city: 'Junawai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6a0',
    },
    state: 'Uttar Pradesh',
    district: 'Sambhal',
    city: 'Panwasa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6a1',
    },
    state: 'Uttar Pradesh',
    district: 'Sambhal',
    city: 'Rajpura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6a2',
    },
    state: 'Uttar Pradesh',
    district: 'Sambhal',
    city: 'Sambhal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6a3',
    },
    state: 'Uttar Pradesh',
    district: 'Sant Kabir Nagar',
    city: 'Baghauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6a4',
    },
    state: 'Uttar Pradesh',
    district: 'Sant Kabir Nagar',
    city: 'Belhar Kala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6a5',
    },
    state: 'Uttar Pradesh',
    district: 'Sant Kabir Nagar',
    city: 'Hainsar Bazar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6a6',
    },
    state: 'Uttar Pradesh',
    district: 'Sant Kabir Nagar',
    city: 'Khalilabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6a7',
    },
    state: 'Uttar Pradesh',
    district: 'Saharanpur',
    city: 'Puwarka',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6a8',
    },
    state: 'Uttar Pradesh',
    district: 'Sant Kabir Nagar',
    city: 'Mehdawal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6a9',
    },
    state: 'Uttar Pradesh',
    district: 'Sant Kabir Nagar',
    city: 'Pauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6aa',
    },
    state: 'Uttar Pradesh',
    district: 'Sant Kabir Nagar',
    city: 'Santha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6ab',
    },
    state: 'Uttar Pradesh',
    district: 'Sant Kabir Nagar',
    city: 'Semariyawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6ac',
    },
    state: 'Uttar Pradesh',
    district: 'Shahjahanpur',
    city: 'Banda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6ad',
    },
    state: 'Uttar Pradesh',
    district: 'Sant Kabir Nagar',
    city: 'Nath Nagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6ae',
    },
    state: 'Uttar Pradesh',
    district: 'Shahjahanpur',
    city: 'Bhawal Khera',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6af',
    },
    state: 'Uttar Pradesh',
    district: 'Shahjahanpur',
    city: 'Dadrol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6b0',
    },
    state: 'Uttar Pradesh',
    district: 'Shahjahanpur',
    city: 'Jalalabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6b1',
    },
    state: 'Uttar Pradesh',
    district: 'Shahjahanpur',
    city: 'Jaitipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6b2',
    },
    state: 'Uttar Pradesh',
    district: 'Shahjahanpur',
    city: 'Kanth',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6b3',
    },
    state: 'Uttar Pradesh',
    district: 'Shahjahanpur',
    city: 'Kalan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6b4',
    },
    state: 'Uttar Pradesh',
    district: 'Shahjahanpur',
    city: 'Khudaganj Katra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6b5',
    },
    state: 'Uttar Pradesh',
    district: 'Shahjahanpur',
    city: 'Khutar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6b6',
    },
    state: 'Uttar Pradesh',
    district: 'Shahjahanpur',
    city: 'Madnapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6b7',
    },
    state: 'Uttar Pradesh',
    district: 'Shahjahanpur',
    city: 'Nigohi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6b8',
    },
    state: 'Uttar Pradesh',
    district: 'Shahjahanpur',
    city: 'Powayan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6b9',
    },
    state: 'Uttar Pradesh',
    district: 'Shahjahanpur',
    city: 'Sindhauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6ba',
    },
    state: 'Uttar Pradesh',
    district: 'Shahjahanpur',
    city: 'Tilhar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6bb',
    },
    state: 'Uttar Pradesh',
    district: 'Shamli',
    city: 'Kairana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6bc',
    },
    state: 'Uttar Pradesh',
    district: 'Shamli',
    city: 'Kandhla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6bd',
    },
    state: 'Uttar Pradesh',
    district: 'Shamli',
    city: 'Shamli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6be',
    },
    state: 'Uttar Pradesh',
    district: 'Shamli',
    city: 'Thana Bhawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6bf',
    },
    state: 'Uttar Pradesh',
    district: 'Shamli',
    city: 'Un',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6c0',
    },
    state: 'Uttar Pradesh',
    district: 'Shrawasti',
    city: 'Ekona',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6c1',
    },
    state: 'Uttar Pradesh',
    district: 'Shrawasti',
    city: 'Gilaula',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6c2',
    },
    state: 'Uttar Pradesh',
    district: 'Shrawasti',
    city: 'Hariharpur Rani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6c3',
    },
    state: 'Uttar Pradesh',
    district: 'Shrawasti',
    city: 'Jamunaha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6c4',
    },
    state: 'Uttar Pradesh',
    district: 'Shrawasti',
    city: 'Sirsiya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6c5',
    },
    state: 'Uttar Pradesh',
    district: 'Siddharthnagar',
    city: 'Bansi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6c6',
    },
    state: 'Uttar Pradesh',
    district: 'Siddharthnagar',
    city: 'Barhni',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6c7',
    },
    state: 'Uttar Pradesh',
    district: 'Shahjahanpur',
    city: 'Mirzapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6c8',
    },
    state: 'Uttar Pradesh',
    district: 'Siddharthnagar',
    city: 'Bhanwapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6c9',
    },
    state: 'Uttar Pradesh',
    district: 'Siddharthnagar',
    city: 'Birdpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6ca',
    },
    state: 'Uttar Pradesh',
    district: 'Siddharthnagar',
    city: 'Domariyaganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6cb',
    },
    state: 'Uttar Pradesh',
    district: 'Siddharthnagar',
    city: 'Itwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6cc',
    },
    state: 'Uttar Pradesh',
    district: 'Siddharthnagar',
    city: 'Jogia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6cd',
    },
    state: 'Uttar Pradesh',
    district: 'Siddharthnagar',
    city: 'Khesraha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6ce',
    },
    state: 'Uttar Pradesh',
    district: 'Siddharthnagar',
    city: 'Khuniyaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6cf',
    },
    state: 'Uttar Pradesh',
    district: 'Siddharthnagar',
    city: 'Lotan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6d0',
    },
    state: 'Uttar Pradesh',
    district: 'Siddharthnagar',
    city: 'Mithwal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6d1',
    },
    state: 'Uttar Pradesh',
    district: 'Siddharthnagar',
    city: 'Naugarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6d2',
    },
    state: 'Uttar Pradesh',
    district: 'Siddharthnagar',
    city: 'Shoharatgarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6d3',
    },
    state: 'Uttar Pradesh',
    district: 'Siddharthnagar',
    city: 'Uska Bazar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6d4',
    },
    state: 'Uttar Pradesh',
    district: 'Sitapur',
    city: 'Ailiya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6d5',
    },
    state: 'Uttar Pradesh',
    district: 'Sitapur',
    city: 'Behta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6d6',
    },
    state: 'Uttar Pradesh',
    district: 'Sitapur',
    city: 'Biswan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6d7',
    },
    state: 'Uttar Pradesh',
    district: 'Sitapur',
    city: 'Gondlamau',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6d8',
    },
    state: 'Uttar Pradesh',
    district: 'Sitapur',
    city: 'Hargaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6d9',
    },
    state: 'Uttar Pradesh',
    district: 'Sitapur',
    city: 'Kasmanda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6da',
    },
    state: 'Uttar Pradesh',
    district: 'Sitapur',
    city: 'Khairabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6db',
    },
    state: 'Uttar Pradesh',
    district: 'Sitapur',
    city: 'Machhrehta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6dc',
    },
    state: 'Uttar Pradesh',
    district: 'Sitapur',
    city: 'Laharpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6dd',
    },
    state: 'Uttar Pradesh',
    district: 'Sitapur',
    city: 'Maholi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6de',
    },
    state: 'Uttar Pradesh',
    district: 'Sitapur',
    city: 'Mahmudabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6df',
    },
    state: 'Uttar Pradesh',
    district: 'Sitapur',
    city: 'Misrikh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6e0',
    },
    state: 'Uttar Pradesh',
    district: 'Sitapur',
    city: 'Parsendi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6e1',
    },
    state: 'Uttar Pradesh',
    district: 'Sitapur',
    city: 'Pahala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6e2',
    },
    state: 'Uttar Pradesh',
    district: 'Sitapur',
    city: 'Pisawan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6e3',
    },
    state: 'Uttar Pradesh',
    district: 'Sitapur',
    city: 'Rampur Mathura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6e4',
    },
    state: 'Uttar Pradesh',
    district: 'Sitapur',
    city: 'Reusa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6e5',
    },
    state: 'Uttar Pradesh',
    district: 'Sitapur',
    city: 'Sakran',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6e6',
    },
    state: 'Uttar Pradesh',
    district: 'Sitapur',
    city: 'Sidhauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6e7',
    },
    state: 'Uttar Pradesh',
    district: 'Sonbhadra',
    city: 'Babhani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6e8',
    },
    state: 'Uttar Pradesh',
    district: 'Sonbhadra',
    city: 'Chatra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6e9',
    },
    state: 'Uttar Pradesh',
    district: 'Sonbhadra',
    city: 'Chopan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6ea',
    },
    state: 'Uttar Pradesh',
    district: 'Sonbhadra',
    city: 'Dudhi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6eb',
    },
    state: 'Uttar Pradesh',
    district: 'Sonbhadra',
    city: 'Ghorawal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6ec',
    },
    state: 'Uttar Pradesh',
    district: 'Sonbhadra',
    city: 'Karma',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6ed',
    },
    state: 'Uttar Pradesh',
    district: 'Sonbhadra',
    city: 'Kone',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6ee',
    },
    state: 'Uttar Pradesh',
    district: 'Sonbhadra',
    city: 'Myorpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6ef',
    },
    state: 'Uttar Pradesh',
    district: 'Sonbhadra',
    city: 'Nagwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6f0',
    },
    state: 'Uttar Pradesh',
    district: 'Sonbhadra',
    city: 'Robertsganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6f1',
    },
    state: 'Uttar Pradesh',
    district: 'Sultanpur',
    city: 'Akhand Nagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6f2',
    },
    state: 'Uttar Pradesh',
    district: 'Sultanpur',
    city: 'Baldirai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6f3',
    },
    state: 'Uttar Pradesh',
    district: 'Sultanpur',
    city: 'Bhadaiya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6f4',
    },
    state: 'Uttar Pradesh',
    district: 'Sultanpur',
    city: 'Dhanpatganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6f5',
    },
    state: 'Uttar Pradesh',
    district: 'Sultanpur',
    city: 'Dostpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6f6',
    },
    state: 'Uttar Pradesh',
    district: 'Sultanpur',
    city: 'Dubepur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6f7',
    },
    state: 'Uttar Pradesh',
    district: 'Sultanpur',
    city: 'Jaisinghpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6f8',
    },
    state: 'Uttar Pradesh',
    district: 'Sultanpur',
    city: 'Kadipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6f9',
    },
    state: 'Uttar Pradesh',
    district: 'Sultanpur',
    city: 'Karaudikala',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6fa',
    },
    state: 'Uttar Pradesh',
    district: 'Sultanpur',
    city: 'Kurebhar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6fb',
    },
    state: 'Uttar Pradesh',
    district: 'Sultanpur',
    city: 'Kurwar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6fc',
    },
    state: 'Uttar Pradesh',
    district: 'Sultanpur',
    city: 'Lambhua',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6fd',
    },
    state: 'Uttar Pradesh',
    district: 'Sultanpur',
    city: 'Motigarpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6fe',
    },
    state: 'Uttar Pradesh',
    district: 'Sultanpur',
    city: 'P.P.Kamaicha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f6ff',
    },
    state: 'Uttar Pradesh',
    district: 'Unnao',
    city: 'Asoha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f700',
    },
    state: 'Uttar Pradesh',
    district: 'Unnao',
    city: 'Auras',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f701',
    },
    state: 'Uttar Pradesh',
    district: 'Unnao',
    city: 'Bangarmau',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f702',
    },
    state: 'Uttar Pradesh',
    district: 'Unnao',
    city: 'Bichhiya',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f703',
    },
    state: 'Uttar Pradesh',
    district: 'Unnao',
    city: 'Bighapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f704',
    },
    state: 'Uttar Pradesh',
    district: 'Unnao',
    city: 'Fatehpur Chaurasi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f705',
    },
    state: 'Uttar Pradesh',
    district: 'Unnao',
    city: 'Ganj Moradabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f706',
    },
    state: 'Uttar Pradesh',
    district: 'Unnao',
    city: 'Hasanganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f707',
    },
    state: 'Uttar Pradesh',
    district: 'Unnao',
    city: 'Hilauli',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f708',
    },
    state: 'Uttar Pradesh',
    district: 'Unnao',
    city: 'Mianganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f709',
    },
    state: 'Uttar Pradesh',
    district: 'Unnao',
    city: 'Nawabganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f70a',
    },
    state: 'Uttar Pradesh',
    district: 'Unnao',
    city: 'Purwa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f70b',
    },
    state: 'Uttar Pradesh',
    district: 'Unnao',
    city: 'Safipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f70c',
    },
    state: 'Uttar Pradesh',
    district: 'Unnao',
    city: 'Sikandarpur Karan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f70d',
    },
    state: 'Uttar Pradesh',
    district: 'Unnao',
    city: 'Sikandarpur Sarausi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f70e',
    },
    state: 'Uttar Pradesh',
    district: 'Unnao',
    city: 'Sumerpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f70f',
    },
    state: 'Uttar Pradesh',
    district: 'Varanasi',
    city: 'Arajiline',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f710',
    },
    state: 'Uttar Pradesh',
    district: 'Varanasi',
    city: 'Baragaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f711',
    },
    state: 'Uttar Pradesh',
    district: 'Varanasi',
    city: 'Chiraigaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f712',
    },
    state: 'Uttar Pradesh',
    district: 'Varanasi',
    city: 'Cholapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f713',
    },
    state: 'Uttar Pradesh',
    district: 'Varanasi',
    city: 'Harahua',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f714',
    },
    state: 'Uttar Pradesh',
    district: 'Varanasi',
    city: 'Kashi Vidyapeeth',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f715',
    },
    state: 'Uttar Pradesh',
    district: 'Varanasi',
    city: 'Pindra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f716',
    },
    state: 'Uttar Pradesh',
    district: 'Varanasi',
    city: 'Sevapuri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f717',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Baduria',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f718',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Amdanga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f719',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Bagda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f71a',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Barasat-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f71b',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Barasat-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f71c',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Barrackpur-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f71d',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Barrackpur-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f71e',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Basirhat-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f71f',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Basirhat-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f720',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Bongaon',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f721',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Deganga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f722',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Gaighata',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f723',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Habra-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f724',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Habra-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f725',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Haroa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f726',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Hasnabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f727',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Hingalganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f728',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Minakhan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f729',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Rajarhat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f72a',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Sandeshkhali-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f72b',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Sandeshkhali-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f72c',
    },
    state: 'West Bengal',
    district: '24 Paraganas North',
    city: 'Swarupnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f72d',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Baruipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f72e',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Basanti',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f72f',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Bhangar-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f730',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Bhangar-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f731',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Bishnupur-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f732',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Bishnupur-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f733',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Budge Budge-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f734',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Budge Budge-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f735',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Canning-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f736',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Canning-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f737',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Diamond Harbour-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f738',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Diamond Harbour-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f739',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Falta',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f73a',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Gosaba',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f73b',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Jaynagar-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f73c',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Kak Dwip',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f73d',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Jaynagar-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f73e',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Kulpi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f73f',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Kultali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f740',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Magra Hat-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f741',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Magra Hat-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f742',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Mandirbazar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f743',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Mathurapur I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f744',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Mathurapur-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f745',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Namkhana',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f746',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Pathar Pratima',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f747',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Sagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f748',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Sonarpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f749',
    },
    state: 'West Bengal',
    district: '24 Paraganas South',
    city: 'Thakurpukur Mahestola',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f74a',
    },
    state: 'West Bengal',
    district: 'Alipurduar',
    city: 'Alipurduar-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f74b',
    },
    state: 'West Bengal',
    district: 'Alipurduar',
    city: 'Alipurduar-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f74c',
    },
    state: 'West Bengal',
    district: 'Alipurduar',
    city: 'Falakata',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f74d',
    },
    state: 'West Bengal',
    district: 'Alipurduar',
    city: 'Kalchini',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f74e',
    },
    state: 'West Bengal',
    district: 'Alipurduar',
    city: 'Kumargram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f74f',
    },
    state: 'West Bengal',
    district: 'Alipurduar',
    city: 'Madarihat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f750',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Bankura-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f751',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Bankura-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f752',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Barjora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f753',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Chhatna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f754',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Gangajal Ghati',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f755',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Hirbandh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f756',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Indpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f757',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Indus',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f758',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Jaypur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f759',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Khatra-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f75a',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Mejhia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f75b',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Kotulpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f75c',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Onda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f75d',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Raipur-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f75e',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Patrasayer',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f75f',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Ranibundh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f760',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Saltora',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f761',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Sarenga',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f762',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Simlapal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f763',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Sonamukhi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f764',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Vishnupur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f765',
    },
    state: 'West Bengal',
    district: 'Bankura',
    city: 'Taldangra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f766',
    },
    state: 'West Bengal',
    district: 'Birbhum',
    city: 'Bolpur-Sriniketan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f767',
    },
    state: 'West Bengal',
    district: 'Birbhum',
    city: 'Illambazar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f768',
    },
    state: 'West Bengal',
    district: 'Birbhum',
    city: 'Dubrajpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f769',
    },
    state: 'West Bengal',
    district: 'Birbhum',
    city: 'Khoyrasol',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f76a',
    },
    state: 'West Bengal',
    district: 'Birbhum',
    city: 'Labpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f76b',
    },
    state: 'West Bengal',
    district: 'Birbhum',
    city: 'Mayureswar-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f76c',
    },
    state: 'West Bengal',
    district: 'Birbhum',
    city: 'Mohammad Bazar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f76d',
    },
    state: 'West Bengal',
    district: 'Birbhum',
    city: 'Mayureswar-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f76e',
    },
    state: 'West Bengal',
    district: 'Birbhum',
    city: 'Murarai-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f76f',
    },
    state: 'West Bengal',
    district: 'Birbhum',
    city: 'Murarai-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f770',
    },
    state: 'West Bengal',
    district: 'Birbhum',
    city: 'Nalhati-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f771',
    },
    state: 'West Bengal',
    district: 'Birbhum',
    city: 'Nalhati-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f772',
    },
    state: 'West Bengal',
    district: 'Birbhum',
    city: 'Nanoor',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f773',
    },
    state: 'West Bengal',
    district: 'Birbhum',
    city: 'Rajnagar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f774',
    },
    state: 'West Bengal',
    district: 'Birbhum',
    city: 'Rampurhat-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f775',
    },
    state: 'West Bengal',
    district: 'Birbhum',
    city: 'Rampurhat-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f776',
    },
    state: 'West Bengal',
    district: 'Birbhum',
    city: 'Suri-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f777',
    },
    state: 'West Bengal',
    district: 'Birbhum',
    city: 'Sainthia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f778',
    },
    state: 'West Bengal',
    district: 'Birbhum',
    city: 'Suri-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f779',
    },
    state: 'West Bengal',
    district: 'Coochbehar',
    city: 'Cooch Behar-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f77a',
    },
    state: 'West Bengal',
    district: 'Coochbehar',
    city: 'Cooch Behar Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f77b',
    },
    state: 'West Bengal',
    district: 'Coochbehar',
    city: 'Dinhata-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f77c',
    },
    state: 'West Bengal',
    district: 'Coochbehar',
    city: 'Haldibari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f77d',
    },
    state: 'West Bengal',
    district: 'Coochbehar',
    city: 'Dinhata-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f77e',
    },
    state: 'West Bengal',
    district: 'Coochbehar',
    city: 'Mathabhanga-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f77f',
    },
    state: 'West Bengal',
    district: 'Coochbehar',
    city: 'Mathabhanga Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f780',
    },
    state: 'West Bengal',
    district: 'Coochbehar',
    city: 'Mekliganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f781',
    },
    state: 'West Bengal',
    district: 'Coochbehar',
    city: 'Sitai',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f782',
    },
    state: 'West Bengal',
    district: 'Coochbehar',
    city: 'Sitalkuchi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f783',
    },
    state: 'West Bengal',
    district: 'Coochbehar',
    city: 'Tufanganj-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f784',
    },
    state: 'West Bengal',
    district: 'Coochbehar',
    city: 'Tufanganj-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f785',
    },
    state: 'West Bengal',
    district: 'Darjeeling',
    city: 'Darjeeling-Pulbazar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f786',
    },
    state: 'West Bengal',
    district: 'Darjeeling',
    city: 'Jore Bunglow-Sukiapokhri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f787',
    },
    state: 'West Bengal',
    district: 'Darjeeling',
    city: 'Kharibari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f788',
    },
    state: 'West Bengal',
    district: 'Darjeeling',
    city: 'Kurseong',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f789',
    },
    state: 'West Bengal',
    district: 'Darjeeling',
    city: 'Matigara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f78a',
    },
    state: 'West Bengal',
    district: 'Darjeeling',
    city: 'Mirik',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f78b',
    },
    state: 'West Bengal',
    district: 'Darjeeling',
    city: 'Naxal Bari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f78c',
    },
    state: 'West Bengal',
    district: 'Darjeeling',
    city: 'Phansidewa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f78d',
    },
    state: 'West Bengal',
    district: 'Darjeeling',
    city: 'Rangli Rangliot',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f78e',
    },
    state: 'West Bengal',
    district: 'Dinajpur Dakshin',
    city: 'Balurghat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f78f',
    },
    state: 'West Bengal',
    district: 'Dinajpur Dakshin',
    city: 'Bansihari',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f790',
    },
    state: 'West Bengal',
    district: 'Dinajpur Dakshin',
    city: 'Gangarampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f792',
    },
    state: 'West Bengal',
    district: 'Dinajpur Dakshin',
    city: 'Harirampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f793',
    },
    state: 'West Bengal',
    district: 'Dinajpur Dakshin',
    city: 'Hili',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f794',
    },
    state: 'West Bengal',
    district: 'Dinajpur Dakshin',
    city: 'Kumarganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f795',
    },
    state: 'West Bengal',
    district: 'Dinajpur Dakshin',
    city: 'Kushmandi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f796',
    },
    state: 'West Bengal',
    district: 'Dinajpur Uttar',
    city: 'Chopra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f797',
    },
    state: 'West Bengal',
    district: 'Dinajpur Uttar',
    city: 'Goalpokhar-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f798',
    },
    state: 'West Bengal',
    district: 'Dinajpur Uttar',
    city: 'Goalpokhar Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f799',
    },
    state: 'West Bengal',
    district: 'Dinajpur Uttar',
    city: 'Hemtabad',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f79a',
    },
    state: 'West Bengal',
    district: 'Dinajpur Uttar',
    city: 'Islampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f79b',
    },
    state: 'West Bengal',
    district: 'Dinajpur Uttar',
    city: 'Itahar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f79c',
    },
    state: 'West Bengal',
    district: 'Dinajpur Uttar',
    city: 'Kaliaganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f79d',
    },
    state: 'West Bengal',
    district: 'Dinajpur Uttar',
    city: 'Karandighi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f79e',
    },
    state: 'West Bengal',
    district: 'Dinajpur Uttar',
    city: 'Raiganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f79f',
    },
    state: 'West Bengal',
    district: 'Hooghly',
    city: 'Arambagh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7a0',
    },
    state: 'West Bengal',
    district: 'Hooghly',
    city: 'Balagarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7a1',
    },
    state: 'West Bengal',
    district: 'Hooghly',
    city: 'Chanditala-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7a2',
    },
    state: 'West Bengal',
    district: 'Hooghly',
    city: 'Chanditala-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7a3',
    },
    state: 'West Bengal',
    district: 'Hooghly',
    city: 'Chinsurah-Magrah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7a4',
    },
    state: 'West Bengal',
    district: 'Dinajpur Dakshin',
    city: 'Tapan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7a5',
    },
    state: 'West Bengal',
    district: 'Hooghly',
    city: 'Dhaniakhali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7a6',
    },
    state: 'West Bengal',
    district: 'Hooghly',
    city: 'Goghat-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7a7',
    },
    state: 'West Bengal',
    district: 'Hooghly',
    city: 'Goghat-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7a8',
    },
    state: 'West Bengal',
    district: 'Hooghly',
    city: 'Haripal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7a9',
    },
    state: 'West Bengal',
    district: 'Hooghly',
    city: 'Jangipara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7aa',
    },
    state: 'West Bengal',
    district: 'Hooghly',
    city: 'Khanakul-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7ab',
    },
    state: 'West Bengal',
    district: 'Hooghly',
    city: 'Khanakul-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7ac',
    },
    state: 'West Bengal',
    district: 'Hooghly',
    city: 'Pandua',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7ad',
    },
    state: 'West Bengal',
    district: 'Hooghly',
    city: 'Pursurah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7ae',
    },
    state: 'West Bengal',
    district: 'Hooghly',
    city: 'Singur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7af',
    },
    state: 'West Bengal',
    district: 'Hooghly',
    city: 'Sirampur-Uttarpara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7b0',
    },
    state: 'West Bengal',
    district: 'Hooghly',
    city: 'Tarakeswar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7b1',
    },
    state: 'West Bengal',
    district: 'Hooghly',
    city: 'Polba-Dadpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7b2',
    },
    state: 'West Bengal',
    district: 'Howrah',
    city: 'Amta-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7b3',
    },
    state: 'West Bengal',
    district: 'Howrah',
    city: 'Amta-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7b4',
    },
    state: 'West Bengal',
    district: 'Howrah',
    city: 'Bagnan-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7b5',
    },
    state: 'West Bengal',
    district: 'Howrah',
    city: 'Bagnan-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7b6',
    },
    state: 'West Bengal',
    district: 'Howrah',
    city: 'Bally-Jagacha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7b7',
    },
    state: 'West Bengal',
    district: 'Howrah',
    city: 'Domjur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7b8',
    },
    state: 'West Bengal',
    district: 'Howrah',
    city: 'Jagatballavpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7b9',
    },
    state: 'West Bengal',
    district: 'Howrah',
    city: 'Panchla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7ba',
    },
    state: 'West Bengal',
    district: 'Howrah',
    city: 'Sankrail',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7bb',
    },
    state: 'West Bengal',
    district: 'Howrah',
    city: 'Shyampur-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7bc',
    },
    state: 'West Bengal',
    district: 'Howrah',
    city: 'Shyampur-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7bd',
    },
    state: 'West Bengal',
    district: 'Howrah',
    city: 'Uluberia-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7be',
    },
    state: 'West Bengal',
    district: 'Howrah',
    city: 'Uluberia-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7bf',
    },
    state: 'West Bengal',
    district: 'Jalpaiguri',
    city: 'Dhupguri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7c0',
    },
    state: 'West Bengal',
    district: 'Howrah',
    city: 'Udaynarayanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7c1',
    },
    state: 'West Bengal',
    district: 'Jalpaiguri',
    city: 'Mal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7c2',
    },
    state: 'West Bengal',
    district: 'Jalpaiguri',
    city: 'Jalpaiguri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7c3',
    },
    state: 'West Bengal',
    district: 'Jalpaiguri',
    city: 'Matiali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7c4',
    },
    state: 'West Bengal',
    district: 'Jalpaiguri',
    city: 'Maynaguri',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7c5',
    },
    state: 'West Bengal',
    district: 'Jalpaiguri',
    city: 'Nagrakata',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7c6',
    },
    state: 'West Bengal',
    district: 'Jhargram',
    city: 'Binpur-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7c7',
    },
    state: 'West Bengal',
    district: 'Jhargram',
    city: 'Binpur-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7c8',
    },
    state: 'West Bengal',
    district: 'Jalpaiguri',
    city: 'Rajganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7c9',
    },
    state: 'West Bengal',
    district: 'Jhargram',
    city: 'Gopiballavpur-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7ca',
    },
    state: 'West Bengal',
    district: 'Jhargram',
    city: 'Gopiballav Pur -Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7cb',
    },
    state: 'West Bengal',
    district: 'Jhargram',
    city: 'Jhargram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7cc',
    },
    state: 'West Bengal',
    district: 'Jhargram',
    city: 'Nayagram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7cd',
    },
    state: 'West Bengal',
    district: 'Jhargram',
    city: 'Sankrail',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7ce',
    },
    state: 'West Bengal',
    district: 'Kalimpong',
    city: 'Gorubathan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7cf',
    },
    state: 'West Bengal',
    district: 'Kalimpong',
    city: 'Kalimpong-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7d0',
    },
    state: 'West Bengal',
    district: 'Kalimpong',
    city: 'Kalimpong-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7d1',
    },
    state: 'West Bengal',
    district: 'Kalimpong',
    city: 'Lava',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7d2',
    },
    state: 'West Bengal',
    district: 'Kalimpong',
    city: 'Pedong',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7d3',
    },
    state: 'West Bengal',
    district: 'Jhargram',
    city: 'Jambani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7d4',
    },
    state: 'West Bengal',
    district: 'Maldah',
    city: 'Bamongola',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7d5',
    },
    state: 'West Bengal',
    district: 'Maldah',
    city: 'Chanchal-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7d6',
    },
    state: 'West Bengal',
    district: 'Maldah',
    city: 'Chanchal-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7d7',
    },
    state: 'West Bengal',
    district: 'Maldah',
    city: 'English Bazar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7d8',
    },
    state: 'West Bengal',
    district: 'Maldah',
    city: 'Habibpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7d9',
    },
    state: 'West Bengal',
    district: 'Maldah',
    city: 'Harishchandrapur-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7da',
    },
    state: 'West Bengal',
    district: 'Maldah',
    city: 'Harishchandrapur-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7db',
    },
    state: 'West Bengal',
    district: 'Maldah',
    city: 'Kaliachak-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7dc',
    },
    state: 'West Bengal',
    district: 'Maldah',
    city: 'Gazole',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7dd',
    },
    state: 'West Bengal',
    district: 'Maldah',
    city: 'Kaliachak-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7de',
    },
    state: 'West Bengal',
    district: 'Maldah',
    city: 'Kaliachak-Iii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7df',
    },
    state: 'West Bengal',
    district: 'Maldah',
    city: 'Manikchak',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7e0',
    },
    state: 'West Bengal',
    district: 'Maldah',
    city: 'Old Malda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7e1',
    },
    state: 'West Bengal',
    district: 'Maldah',
    city: 'Ratua-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7e2',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Bhagawanpur-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7e3',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Bhagawanpur-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7e4',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Chandipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7e5',
    },
    state: 'West Bengal',
    district: 'Maldah',
    city: 'Ratua-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7e6',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Contai-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7e7',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Contai-Iii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7e8',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Deshapran',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7e9',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Egra-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7ea',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Egra-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7eb',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Haldia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7ec',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Khejuri-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7ed',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Khejuri-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7ee',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Kolaghat',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7ef',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Moyna',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7f0',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Nandakumar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7f1',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Nandigram-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7f2',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Nandigram-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7f3',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Mahishadal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7f4',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Panskura-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7f5',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Patashpur-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7f6',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Patashpur-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7f7',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Ramnagar-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7f8',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Ramnagar-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7f9',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Shahid Matangini',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7fa',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Sutahata',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7fb',
    },
    state: 'West Bengal',
    district: 'Medinipur East',
    city: 'Tamluk',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7fc',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Chandrakona-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7fd',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Chandrakona-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7fe',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Dantan-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f7ff',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Dantan-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f800',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Daspur-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f801',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Debra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f802',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Garbeta-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f803',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Daspur-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f804',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Garbeta-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f805',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Garbeta-Iii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f806',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Ghatal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f807',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Keshpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f808',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Keshiary',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f809',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Kharagpur-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f80a',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Kharagpur-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f80b',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Midnapore',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f80c',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Mohanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f80d',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Narayangarh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f80e',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Pingla',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f80f',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Sabang',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f810',
    },
    state: 'West Bengal',
    district: 'Medinipur West',
    city: 'Salbani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f811',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Beldanga-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f812',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Berhampore',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f813',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Bhagabangola-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f814',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Bhagawangola-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f815',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Bharatpur-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f816',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Bharatpur-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f817',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Burwan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f818',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Domkal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f819',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Farakka',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f81a',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Hariharpara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f81b',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Jalangi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f81c',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Beldanga-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f81d',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Kandi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f81e',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Khargram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f81f',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Lalgola',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f820',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Murshidabad-Jiagunj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f821',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Nabagram',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f822',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Nawda',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f823',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Raghunathganj-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f824',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Raghunathganj-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f825',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Raninagar-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f826',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Raninagar-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f827',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Sagardighi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f828',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Shamsherganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f829',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Suti-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f82a',
    },
    state: 'West Bengal',
    district: 'Murshidabad',
    city: 'Suti-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f82b',
    },
    state: 'West Bengal',
    district: 'Nadia',
    city: 'Chapra',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f82c',
    },
    state: 'West Bengal',
    district: 'Nadia',
    city: 'Chakdah',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f82d',
    },
    state: 'West Bengal',
    district: 'Nadia',
    city: 'Hanskhali',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f82e',
    },
    state: 'West Bengal',
    district: 'Nadia',
    city: 'Haringhata',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f82f',
    },
    state: 'West Bengal',
    district: 'Nadia',
    city: 'Kaliganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f830',
    },
    state: 'West Bengal',
    district: 'Nadia',
    city: 'Kalyani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f831',
    },
    state: 'West Bengal',
    district: 'Nadia',
    city: 'Karimpur-1',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f832',
    },
    state: 'West Bengal',
    district: 'Nadia',
    city: 'Karimpur-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f833',
    },
    state: 'West Bengal',
    district: 'Nadia',
    city: 'Krishnaganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f834',
    },
    state: 'West Bengal',
    district: 'Nadia',
    city: 'Krishnagar-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f835',
    },
    state: 'West Bengal',
    district: 'Nadia',
    city: 'Krishnagar-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f836',
    },
    state: 'West Bengal',
    district: 'Nadia',
    city: 'Nabadwip',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f837',
    },
    state: 'West Bengal',
    district: 'Nadia',
    city: 'Ranaghat-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f838',
    },
    state: 'West Bengal',
    district: 'Nadia',
    city: 'Nakashipara',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f839',
    },
    state: 'West Bengal',
    district: 'Nadia',
    city: 'Ranaghat-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f83a',
    },
    state: 'West Bengal',
    district: 'Nadia',
    city: 'Santipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f83b',
    },
    state: 'West Bengal',
    district: 'Nadia',
    city: 'Tehatta-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f83c',
    },
    state: 'West Bengal',
    district: 'Paschim Bardhaman',
    city: 'Barabani',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f83d',
    },
    state: 'West Bengal',
    district: 'Paschim Bardhaman',
    city: 'Faridpur - Durgapur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f83e',
    },
    state: 'West Bengal',
    district: 'Nadia',
    city: 'Tehatta-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f83f',
    },
    state: 'West Bengal',
    district: 'Paschim Bardhaman',
    city: 'Jamuria',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f840',
    },
    state: 'West Bengal',
    district: 'Paschim Bardhaman',
    city: 'Kanksa',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f841',
    },
    state: 'West Bengal',
    district: 'Paschim Bardhaman',
    city: 'Ondal',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f842',
    },
    state: 'West Bengal',
    district: 'Paschim Bardhaman',
    city: 'Pandabeswar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f843',
    },
    state: 'West Bengal',
    district: 'Paschim Bardhaman',
    city: 'Raniganj',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f844',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Ausgram-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f845',
    },
    state: 'West Bengal',
    district: 'Paschim Bardhaman',
    city: 'Salanpur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f846',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Ausgram-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f847',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Bhatar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f848',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Burdwan-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f849',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Burdwan-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f84a',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Galsi -I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f84b',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Galsi-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f84c',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Kalna-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f84d',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Kalna Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f84e',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Jamal Pur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f84f',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Katwa-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f850',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Katwa-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f851',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Ketugram_I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f852',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Khandaghosh',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f853',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Ketugram-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f854',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Mangolkote',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f855',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Manteswar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f856',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Memari-1',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f857',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Memari-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f858',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Purbasthali-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f859',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Purbasthali-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f85a',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Raina-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f85b',
    },
    state: 'West Bengal',
    district: 'Purba Bardhaman',
    city: 'Raina-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f85c',
    },
    state: 'West Bengal',
    district: 'Purulia',
    city: 'Arsha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f85d',
    },
    state: 'West Bengal',
    district: 'Purulia',
    city: 'Balarampur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f85e',
    },
    state: 'West Bengal',
    district: 'Purulia',
    city: 'Barabazar',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f85f',
    },
    state: 'West Bengal',
    district: 'Purulia',
    city: 'Bagmundi',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f860',
    },
    state: 'West Bengal',
    district: 'Purulia',
    city: 'Bundwan',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f861',
    },
    state: 'West Bengal',
    district: 'Purulia',
    city: 'Hura',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f862',
    },
    state: 'West Bengal',
    district: 'Purulia',
    city: 'Jaipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f863',
    },
    state: 'West Bengal',
    district: 'Purulia',
    city: 'Jhalda-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f864',
    },
    state: 'West Bengal',
    district: 'Purulia',
    city: 'Kashipur',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f865',
    },
    state: 'West Bengal',
    district: 'Purulia',
    city: 'Jhalda-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f866',
    },
    state: 'West Bengal',
    district: 'Purulia',
    city: 'Manbazar-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f867',
    },
    state: 'West Bengal',
    district: 'Purulia',
    city: 'Manbazar-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f868',
    },
    state: 'West Bengal',
    district: 'Purulia',
    city: 'Neturia',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f869',
    },
    state: 'West Bengal',
    district: 'Purulia',
    city: 'Para',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f86a',
    },
    state: 'West Bengal',
    district: 'Purulia',
    city: 'Puncha',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f86b',
    },
    state: 'West Bengal',
    district: 'Purulia',
    city: 'Purulia-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f86c',
    },
    state: 'West Bengal',
    district: 'Purulia',
    city: 'Purulia-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f86d',
    },
    state: 'West Bengal',
    district: 'Purulia',
    city: 'Raghunath Pur-I',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f86e',
    },
    state: 'West Bengal',
    district: 'Purulia',
    city: 'Raghunathpur-Ii',
  },
  {
    _id: {
      $oid: '60ab6e9586ed1c1a3559f86f',
    },
    state: 'West Bengal',
    district: 'Purulia',
    city: 'Santuri',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263e5',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Alwal',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263e6',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Begampet',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263e7',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Chandanagar',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263e8',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Chandrayangutta',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263e9',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Falaknuma',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263ea',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Gachibowli',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263eb',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Gajularamaram',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263ec',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Goshamahal',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263ed',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Hayathnagar',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263ee',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Jubilee Hills',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263ef',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Kapra',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263f0',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Karwan',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263f1',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Kukatpally',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263f2',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'LB Nagar',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263f3',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Malakpet',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263f4',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Malkajgiri',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263f5',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Mehidipatnam',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263f6',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Moosapet',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263f7',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Quthbullapur',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263f8',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Rajendranagar',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263f9',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'RC Puram',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263fa',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Santosh Nagar',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263fb',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Uppal',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263fc',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Yousufguda',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263fd',
    },
    state: 'Telangana',
    district: 'Karimnagar',
    city: 'Karimnagar',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263fe',
    },
    state: 'Telangana',
    district: 'Mahabubabad',
    city: 'Mahabubabad',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa263ff',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Boduppal',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa26400',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Dammaiguda',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa26401',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Gundlapochampally',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa26402',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Jawaharnagar',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa26403',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Komapally',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa26404',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Nagaram',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa26405',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Nizampet',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa26406',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Peerjadiguda',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa26407',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Pocharam',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa26408',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Secunderabad',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa26409',
    },
    state: 'Telangana',
    district: 'Medchal',
    city: 'Thumkunta',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa2640a',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Haliya',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa2640b',
    },
    state: 'Telangana',
    district: 'Nalgonda',
    city: 'Nandikonda',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa2640c',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Adibatla',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa2640d',
    },
    state: 'Telangana',
    district: 'Hyderabad',
    city: 'Saroornagar',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa2640e',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Manikonda',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa2640f',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Badangpet',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa26410',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Bandlaguda Jagir',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa26411',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Pedda Amberpet',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa26412',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Shadnagar',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa26413',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Thukkuguda',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa26414',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Turkayamjal',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa26415',
    },
    state: 'Telangana',
    district: 'Rangareddy',
    city: 'Narsingi',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa26416',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Bollaram',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa26417',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Tellapur',
  },
  {
    _id: {
      $oid: '61d585a6c9658483bfa26418',
    },
    state: 'Telangana',
    district: 'Sangareddy',
    city: 'Andol Jogipet',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692be',
    },
    state: 'Delhi',
    district: 'New Delhi',
    city: 'Delhi Cantonment',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692bf',
    },
    state: 'Delhi',
    district: 'New Delhi',
    city: 'Chanakya Puri',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692c0',
    },
    state: 'Delhi',
    district: 'North Delhi',
    city: 'Narela',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692c1',
    },
    state: 'Delhi',
    district: 'New Delhi',
    city: 'Vasant Vihar',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692c2',
    },
    state: 'Delhi',
    district: 'North West Delhi',
    city: 'Rohini',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692c3',
    },
    state: 'Delhi',
    district: 'North Delhi',
    city: 'Alipur',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692c4',
    },
    state: 'Delhi',
    district: 'North Delhi',
    city: 'Model Town',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692c5',
    },
    state: 'Delhi',
    district: 'North West Delhi',
    city: 'Saraswati Vihar',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692c6',
    },
    state: 'Delhi',
    district: 'West Delhi',
    city: 'Patel Nagar',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692c7',
    },
    state: 'Delhi',
    district: 'West Delhi',
    city: 'Punjabi Bagh',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692c8',
    },
    state: 'Delhi',
    district: 'North West Delhi',
    city: 'Khanjhawala',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692c9',
    },
    state: 'Delhi',
    district: 'West Delhi',
    city: 'Rajouri Garden',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692ca',
    },
    state: 'Delhi',
    district: 'South West Delhi',
    city: 'Najafgarh',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692cb',
    },
    state: 'Delhi',
    district: 'South West Delhi',
    city: 'Dwarka',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692cc',
    },
    state: 'Delhi',
    district: 'South West Delhi',
    city: 'Kapashera',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692cd',
    },
    state: 'Delhi',
    district: 'South Delhi',
    city: 'Hauz Khas',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692ce',
    },
    state: 'Delhi',
    district: 'South Delhi',
    city: 'Mehrauli',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692cf',
    },
    state: 'Delhi',
    district: 'South Delhi',
    city: 'Saket',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692d0',
    },
    state: 'Delhi',
    district: 'Central Delhi',
    city: 'Civil Lines',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692d1',
    },
    state: 'Delhi',
    district: 'South East Delhi',
    city: 'Defence Colony',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692d2',
    },
    state: 'Delhi',
    district: 'Central Delhi',
    city: 'Kotwali',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692d3',
    },
    state: 'Delhi',
    district: 'North East Delhi',
    city: 'Seelam Pur',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692d4',
    },
    state: 'Delhi',
    district: 'North East Delhi',
    city: 'Yamuna Vihar',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692d5',
    },
    state: 'Delhi',
    district: 'North East Delhi',
    city: 'Karawal Nagar',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692d6',
    },
    state: 'Delhi',
    district: 'Central Delhi',
    city: 'Karol Bagh',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692d7',
    },
    state: 'Delhi',
    district: 'Shahdara',
    city: 'Seema Puri',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692d8',
    },
    state: 'Delhi',
    district: 'East Delhi',
    city: 'Mayur Vihar',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692d9',
    },
    state: 'Delhi',
    district: 'South East Delhi',
    city: 'Sarita Vihar',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692da',
    },
    state: 'Delhi',
    district: 'South East Delhi',
    city: 'Kalkaji',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692db',
    },
    state: 'Delhi',
    district: 'Shahdara',
    city: 'Vivek Vihar',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692dc',
    },
    state: 'Delhi',
    district: 'Shahdara',
    city: 'Shahdara',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692dd',
    },
    state: 'Delhi',
    district: 'East Delhi',
    city: 'Gandhi Nagar',
  },
  {
    _id: {
      $oid: '6461db53657d3abcc2e692de',
    },
    state: 'Delhi',
    district: 'East Delhi',
    city: 'Preet Vihar',
  },
]
